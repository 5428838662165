import http from "./httpService";
import { apiRoutes } from "../utils/apiRoutes";

const getModel3Data = async (data: any) => {
  return await http.post(apiRoutes.GET_MODEL_3_SIMULATION_DATA, data);
};

const deleteSimulation = async (data: any) => {
  return await http.post(apiRoutes.DELETE_SIMULATION, data);
};

const SimulationService = {
  getModel3Data,
  deleteSimulation,
};

export default SimulationService;
