import { userConstants } from '../constants/userConstants';

export const fetching = (loading: boolean) => {
  return {
    type: userConstants.FETCH,
    loading,
  };
}

export const usersAction = (users: any[]) => {
  return {
    type: userConstants.USERS,
    users,
  };
};
export const userAction = (user: any) => {
  return {
    type: userConstants.USER,
    user,
  };
};
export const newUserAction = (user: any) => {
  return {
    type: userConstants.NEW_USER,
    user,
  };
};

export const updateUserAction = (user: any) => {
  return {
    type: userConstants.UPDATE_USER,
    user,
  };
};
