import axios from "axios";

// Create an instance of axios
const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // You can still set default headers here, but leave out the Authorization header
});

// Use Axios interceptors to add the Authorization token before each request
http.interceptors.request.use(
  (config) => {
    // Attempt to retrieve the storage item
    const storage = localStorage.getItem('okta-token-storage');

    // Check if the retrieved item is not null before parsing
    if (storage) {
      const accessToken = JSON.parse(storage)?.accessToken?.accessToken;

      // If the token exists, set it in the Authorization header
      if (accessToken) {
        config.headers['Authorization'] = accessToken;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


export default http;
