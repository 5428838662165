import { Fragment } from "react";

import RenderIf from "../RenderIf";
import TableHeader, { TableHeadProps } from "./TableHeader";
import TableRow, { TableRowItemType } from "./TableRow";

interface TableProps {
  heads: TableHeadProps[];
  data: TableRowItemType[][];
  style?: string;
}

const Table = (props: TableProps) => {
  const { heads, data, style = "table" } = props;
  return (
    <table className={style}>
      {/* Table Header */}
      <RenderIf condition={Boolean(heads?.length)}>
        <TableHeader heads={heads} />
      </RenderIf>

      {/* Table Body */}
      <tbody>
        <RenderIf condition={Boolean(data.length)} >
          {data?.map((rowData, index: number) => (
            <Fragment key={index}>
              <TableRow heads={heads} data={rowData} />
            </Fragment>
          ))}
        </RenderIf>
      </tbody>
    </table>
  )
}

export default Table;
