import { Controller } from 'react-hook-form';

const CheckboxController = (props: any) => {
    return (
        <div className="col-md-6">
            <label className="custom-checkbox" htmlFor={props?.name}>
                <Controller
                    name={props?.name}
                    control={props?.control}
                    defaultValue={props?.value??false}
                    render={({ field: { onBlur, onChange, value } }) => {
                        return <>
                            <input type="checkbox" className="ip" name={props?.name}
                                onBlur={onBlur} // notify when input is touched
                                onChange={onChange} // send value to hook form
                                checked={props?.value ?? false}
                            />
                        </>
                    }

                    }
                />
                <span className="check"></span>
                <span className="txt">{props?.label}</span>
            </label>
        </div>
    )
}

export default CheckboxController