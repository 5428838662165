import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

import RenderIf from "../common/RenderIf";

// helpers
import {
    gasGeneralFields,
    gasAttachmentsFields,
    liquidAttachmentsFields,
    liquidGeneralFields,
    materialGeneralFields,
    solidAttachmentsFields,
    solidGeneralFields,
    VisibleModuleType,
} from "../../utils/materialHelper";

type ExportFieldsProps = {
    type: "gas" | "liquid" | "solid" | "material";
    isAttachmentsRequired : boolean;
    moduleType : VisibleModuleType;
};

const solidLabels = (key: string): JSX.Element => {
    const labelMap: Record<string, string> = {
      impurity_and_residuals : "Impurity & Residuals",
        drug_info: "Drug Information",
        flow_energy: "Flow Energy",
        shear_cell: "Shear Cell",
        thermal_tga: "Thermal (TGA)",
        thermal_dsc: "Thermal (DSC)",
        spectralInfo: "Spectral Information",
    };

    const newKey = labelMap[key] || key;
    return <label>{newKey.charAt(0).toUpperCase() + newKey.slice(1)}</label>;
};

const ExportFields = (props: ExportFieldsProps) => {
    const methods = useFormContext();

    const parseFields = (fields: Record<string, any>, value: boolean) => {
        return Object.keys(fields).reduce((acc, fieldKey) => {
            acc[fieldKey] = value;
            return acc;
        }, {} as Record<string, any>);
    };

    const parseNestedObjectFields = (value: boolean, generalFields: Record<string, any[]>, attachmentFields: Record<string, any[]>) => {
      const generalKeys = Object.keys(generalFields).reduce((acc, key) => {
        acc[key] = generalFields[key]
          .filter((obj) => obj.visibleModule?.includes(props.moduleType))
          .reduce((innerAcc, field) => {
            innerAcc[field.value] = value;
            return innerAcc;
          }, {} as Record<string, boolean>);
        return acc;
      }, {} as Record<string, any>);
      const attachmentKeys = Object.keys(attachmentFields).reduce(
        (acc, key) => {
          acc[key] = attachmentFields[key].reduce((innerAcc, field) => {
            innerAcc[field.value] = value;
            return innerAcc;
          }, {} as Record<string, boolean>);
          return acc;
        },
        {} as Record<string, any>
      );
      return { ...generalKeys, attachments: attachmentKeys };
    };

    const handleChange = (value: boolean) => {
        const { material } = methods.control._formValues;
        switch (props.type) {
            case "gas":
                methods.setValue("gas", parseNestedObjectFields(value, gasGeneralFields, gasAttachmentsFields));
                break;
            case "liquid":
                methods.setValue("liquid", parseNestedObjectFields(value, liquidGeneralFields, liquidAttachmentsFields ));
                break;
            case "solid":
                methods.setValue("solid", parseNestedObjectFields(value, solidGeneralFields, solidAttachmentsFields ));
                break;
            case "material":
                methods.setValue("material", parseFields(material, value));
                break;
            default:
                console.log("No valid tab selected");
        }
    };

    useEffect(() => {
        methods.watch();
    }, [methods]);

    const renderFields = (
      generalFields: Record<string, any[]>,
      attachmentFields: Record<string, any[]>,
      labelRenderer: (key: string) => JSX.Element
    ) => (
      <>
        {Object.keys(generalFields).map((key) => (
          <>
            <div key={key} className="col-12 mt-2 mb-2">
              {labelRenderer(key)}
            </div>
            {generalFields[key].map((item) => (
              <RenderIf condition={item?.visibleModule?.includes(props.moduleType)}>
               <div className="col-md-6 mb-2" key={item.value}>
                <label className="custom-checkbox">
                  <Controller
                    name={`${props.type}.${key}.${item.value}`}
                    control={methods.control}
                    defaultValue={false}
                    render={() => (
                      <input
                        className="ip"
                        type="checkbox"
                        {...methods.register(
                          `${props.type}.${key}.${item.value}`
                        )}
                      />
                    )}
                  />
                  <span className="check"></span>
                  <span className="txt ml-1">{item.name}</span>
                </label>
              </div>
              </RenderIf>
            ))}
          </>
        ))}
        <RenderIf condition={props.isAttachmentsRequired}>
          <div className="col-12 divider"></div>
          <div className="col-12 mb-4 font-bold text-lg">Attachments</div>
          {Object.keys(attachmentFields).map((key) => (
            <>
              <div key={key} className="col-12 mt-2 mb-2">
                {labelRenderer(key)}
              </div>
              {attachmentFields[key].map((item) => (
                <div className="col-md-6 mb-2" key={item.value}>
                  <label className="custom-checkbox">
                    <Controller
                      name={`${props.type}.attachments.${key}.${item.value}`}
                      control={methods.control}
                      defaultValue={false}
                      render={() => (
                        <input
                          className="ip"
                          type="checkbox"
                          {...methods.register(
                            `${props.type}.attachments.${key}.${item.value}`
                          )}
                        />
                      )}
                    />
                    <span className="check"></span>
                    <span className="txt ml-1">{item.name}</span>
                  </label>
                </div>
              ))}
            </>
          ))}
        </RenderIf>
      </>
    );

    return (
        <div className="filter-sidebar list-tab">
            <div className="filter-block">
                <div>
                    <div className="row">
                        <div className="col-md-6 mb-2 mt-3">
                            <label className="custom-checkbox">
                                <Controller
                                    name={`${props.type}.selectAll`}
                                    control={methods.control}
                                    render={() => (
                                        <input className="ip" type="checkbox"
                                            {...methods.register(`${props.type}.selectAll`)}
                                            onChange={(e: any) => { handleChange(e.target.checked) }} />
                                    )}
                                />
                                <span className="check"></span>
                                <span className="txt ml-1">Select All</span>
                            </label>
                        </div>
                    </div>

                    <div className="row xxs-row">
                        <RenderIf condition={props?.type == 'material'}>
                            {materialGeneralFields?.map((item: any) => (
                                <div className="col-md-6 mb-2" key={item.value}>
                                    <RenderIf condition={item?.value && item.name && item?.visibleModule?.includes(props.moduleType)}>
                                        <label className={`custom-checkbox ${item.disabled ? 'disabled' : ''}`} title={item.name}>
                                            <Controller
                                                name={`material.${item.value}`}
                                                control={methods.control}
                                                render={() => (
                                                    <input className="ip" type="checkbox" checked={item.disabled ? true : undefined}
                                                        {...methods.register(`material.${item.value}`)} />
                                                )}
                                            />
                                            <span className="check"></span>
                                            <span className="txt ml-1">{item.name}</span>
                                        </label>
                                    </RenderIf>
                                </div>
                            ))}
                        </RenderIf>

                        <RenderIf condition={props.type === "solid"}>
                            {renderFields(solidGeneralFields, solidAttachmentsFields, solidLabels)}
                        </RenderIf>

                        <RenderIf condition={props.type === "liquid"}>
                            {renderFields(liquidGeneralFields, liquidAttachmentsFields, solidLabels)}
                        </RenderIf>

                        <RenderIf condition={props.type === "gas"}>
                            {renderFields(gasGeneralFields, gasAttachmentsFields, solidLabels)}
                        </RenderIf>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ExportFields;
