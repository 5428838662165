import { useEffect, useState } from "react";

import ImageNotAvailable from "../../../../assets/Image_not_available.png";
import FileService from "../../../../services/fileService";
import RenderIf from "../../../../components/common/RenderIf";

const ImageGrid = (props: any) => {
  // init
  const { unchainedData } = props;

  // states
  const [gridData, setGridData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const loadGridData = async () => {
    setLoading(true);
    const csvData = unchainedData.csv_data;
    const gData: any[] = csvData.map((data: any, index: any) => ({
      index,
      solvent: data["Solvent Name"],
    }));

    for (let i = 0; i < gData.length; i += 4) {
      const resArr = await Promise.allSettled(
        gData.slice(i, i + 4).map((d: any, index: number) => FileService.create("/files/download", {
          file: unchainedData[`file__${i + index + 1}`].file_name,
        })),
      );
      resArr.forEach(({ value: res }: any, index: number) => {
        gData[i + index].image = unchainedData[`file__${i + index + 1}`].file_name != "NA" ? res.data.body : ImageNotAvailable;
      });
    }
    setGridData(gData);
    setLoading(false);
  }

  // load grid data on page load
  useEffect(() => {
    loadGridData();
  }, [unchainedData]);

  return (
    <>
      {/* Loading Spinner */}
      <RenderIf condition={loading}>
        <div className="w-full h-full flex flex-1 justify-center items-center">
          <div className="loader"></div>
        </div>
      </RenderIf>

      {/* Image Grid */}
      <RenderIf condition={!loading}>
        <div className="grid grid-cols-6 border border-gray-400">
          {/* {gridData.map((grid: any) => <GridElement gridElement={grid} key={grid.key} />)} */}
          {gridData.map((gridElement: any) => (
            <div className={`border-r p-1 border-b border-gray-400 flex justify-between items-center flex-col`}>
              <img alt="Material Image" src={gridElement.image} />
              <div className="flex justify-between">
                <span>{gridElement.solvent}</span>
              </div>
            </div>
          ))}
        </div>
      </RenderIf>
    </>
  );
}

export default ImageGrid;
