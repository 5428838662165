import { Controller, useForm, useFormContext } from "react-hook-form";

// components
import MultiFileUpload from "../../../../common/MultiFileUpload";

// helpers
import { NUMBER_INPUT_MAXLENGTH, UPLOAD_FILE_MAXLENGTH } from "../../../../../utils";
import { limitDigits, setTitleNumberInput } from "../../../../../utils/common";
import { alertCloseAction, alertOpenAction } from "../../../../../redux/actions";
import { useEffect } from "react";

const Morphologi = (props: any) => {
    const { control } = useForm<any>({ defaultValues: props.initialData });
    const methods = useFormContext();
    useEffect(() => {
        // set default title on number input fields.
        setTitleNumberInput();
        // params?.id ? setSavedData(checkSavedData(material?.solid)) : ''
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="aspect_ratio">
                            <span className="form-label">Aspect Ratio D10</span>
                            {!props.readable ? (
                                <Controller
                                    name="morphologi.aspect_ratio"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.morphologi?.aspect_ratio??""}
                                    render={({ field }) => <input
                                      data-cy="morphology_aspect_ratio"
                                      type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.aspect_ratio}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="aeration_ratio_d50">
                            <span className="form-label">Aspect Ratio D50</span>
                            {!props.readable ? (
                                <Controller
                                    name="morphologi.aeration_ratio_d50"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.morphologi?.aeration_ratio_d50??""}
                                    render={({ field }) => <input
                                      data-cy="morphology_aeration_ratio_d50"
                                      type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.aeration_ratio_d50}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="aeration_ratio_d90">
                            <span className="form-label">Aspect Ratio D90</span>
                            {!props.readable ? (
                                <Controller
                                    name="morphologi.aeration_ratio_d90"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.morphologi?.aeration_ratio_d90??""}
                                    render={({ field }) => <input
                                      data-cy="morphology_aeration_ratio_d90"
                                      type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.aeration_ratio_d90}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="elnNumber">
                            <span className="form-label">ELN Number</span>
                            {!props.readable ? (
                                <Controller
                                    name="morphologi.eln_number"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.morphologi?.eln_number??""}
                                    render={({ field }) => <input
                                      data-cy="morphology_eln_number"
                                      className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.eln_number}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="method_name">
                            <span className="form-label">Method Name/#</span>
                            {!props.readable ? (
                                <Controller
                                    name="morphologi.method_name"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.morphologi?.method_name??""}
                                    render={({ field }) => <input
                                      data-cy="morphology_method_name"
                                      className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.method_name}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label
                            className="ip-wrap"
                            htmlFor="regulatory_filing"
                        >
                            <span className="form-label">
                                Regulatory Filing
                            </span>
                            <div className="input-wrap select-outer">
                                {!props.readable ? (
                                    <Controller
                                        name="morphologi.regulatory_filing"
                                        control={methods.control}
                                        defaultValue={methods.control._formValues?.morphologi?.regulatory_filing??""}
                                        render={({ field }) =>
                                            <select className="theme-ip" {...field} data-cy="morphology_regulatory_filing">
                                                <option value="">Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>}
                                    />
                                ) : (
                                    <p className="theme-ip pt-3 disabled">{control?._formValues?.regulatory_filing}</p>
                                )}
                            </div>
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    {!props.readable ? (
                        <MultiFileUpload
                            title="SOP File, Instrument Information"
                            name="morphologiAttachments"
                            dataCy="attachment_morphologiAttachments"
                            attachments={methods.control._formValues.morphologi.attachments}
                            handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                let displayNames = props.material?.solid?.morphologi?.attachments ?? [];
                                displayNames = displayNames?.map((attachment: any) => attachment.display_name);
                                const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                                    if (displayNames.includes(e?.target?.files?.[key].name)) {
                                        return true;
                                    }
                                });
                                if (isDuplicate) {
                                    props.dispatch(alertOpenAction('Same file already uploaded.', 'error'));
                                    setTimeout(() => props.dispatch(alertCloseAction()));
                                    return;
                                }
                                methods.setValue('morphologi.attachments', [
                                    ...(methods.getValues('morphologi.attachments') || []),
                                    ...Object.keys(e?.target?.files ?? []).map(
                                        (key: any) => {
                                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                                            return {
                                                category: 'Morphologi G3',
                                                file: e?.target?.files?.[key],
                                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                                            }
                                        })
                                ], { shouldDirty: true, shouldTouch: true });
                            }}
                            removeFile={(index: number, name: string) => {
                                methods.setValue('morphologi.attachments', (methods.getValues('morphologi.attachments') || []).filter((attachment: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true })
                            }}
                        />
                    ) :
                        <MultiFileUpload
                            title="SOP File, Instrument Information"
                            name="morphologiFiles"
                            readable={props.readable}
                            attachments={props.attachments}
                            handleFileChange={props.handleFileChange}
                            removeFile={props.removeFile}
                            downloadFile={props.downloadFile}
                        />
                    }
                </div>
            </div>
        </>
    );
};

export default Morphologi;