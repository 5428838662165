import React, { useState } from 'react';

interface SolventMixType {
    S1: string,
    S1P: number,
    S2: string,
    S2P: number,
    S3: string,
    S3P: number,
}
const EditableTable = () => {
    const [rows, setRows] = useState<SolventMixType[]>([{ S1: '', S1P: 0, S2: '', S2P: 0, S3: '', S3P: 0 }]);

    const handleDeleteRow = (index: any) => {
        const updatedRows = rows?.filter((row, i) => i !== index);
        setRows(updatedRows);
    };

    const handleAddRow = () => {
        setRows([
            ...rows,
            { S1: '', S1P: 0, S2: '', S2P: 0, S3: '', S3P: 0 }
        ]);
    };

    const handleInputChange = (index: any, event: any) => {
        const updatedRows = rows.map((row, i) =>
            i === index ? { ...row, [event.target.name]: event.target.value } : row
        );
        setRows(updatedRows);
    };

    return (
        <div className="container mx-auto p-4">
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr className="border-b">
                        <th className="px-2 py-2 border-b text-center">S1</th>
                        <th className="px-2 py-2 border-b text-center">%</th>
                        <th className="px-2 py-2 border-b text-center">S2</th>
                        <th className="px-2 py-2 border-b text-center">%</th>
                        <th className="px-2 py-2 border-b text-center">S3</th>
                        <th className="px-2 py-2 border-b text-center">%</th>
                        <th className="px-2 py-2 border-b text-center">Operate</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row: SolventMixType, index) => (
                        <tr key={index} className="border-b">
                            <td className="px-2 py-2 justify-center">
                                <div className='input-wrap select-outer W-32'>
                                    <select className="theme-ip w-full" onChange={(e) => handleInputChange(index, e)}
                                    >
                                        <option value="" disabled={true}>Select</option>
                                    </select>
                                </div>

                            </td>
                            <td className="px-2 py-2 justify-center">
                                <div className='input-wrap w-32'>
                                    <input
                                        type="number"
                                        name="activityName"
                                        value={row.S1P}
                                        onChange={(e) => handleInputChange(index, e)}
                                        className="theme-ip w-full" />
                                </div>
                            </td>
                            <td className="px-2 py-2 justify-center">
                                <div className='input-wrap select-outer w-32'>
                                    <select className="theme-ip w-full" onChange={(e) => handleInputChange(index, e)}
                                    >
                                        <option value="" disabled={true}>Select</option>
                                    </select>
                                </div>
                            </td>
                            <td className="px-2 py-2 justify-center">
                                <div className='input-wrap w-32'>
                                    <input
                                        type="number"
                                        name="activityName"
                                        value={row.S1P}
                                        onChange={(e) => handleInputChange(index, e)}
                                        className="theme-ip w-full" />
                                </div>
                            </td>
                            <td className="px-2 py-2 justify-center">
                                <div className='input-wrap select-outer w-32'>
                                    <select className="theme-ip w-full" onChange={(e) => handleInputChange(index, e)}
                                    >
                                        <option value="" disabled={true}>Select</option>
                                    </select>
                                </div>

                            </td>
                            <td className="px-2 py-2 justify-center">
                                <div className='input-wrap w-32'>
                                    <input
                                        type="number"
                                        name="activityName"
                                        value={row.S1P}
                                        onChange={(e) => handleInputChange(index, e)}
                                        className="theme-ip w-full" />
                                </div>
                            </td>
                            <td className="px-2 py-2 justify-center text-center">
                                <button
                                    onClick={() => handleDeleteRow(index)}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="mt-4">
                <button
                    onClick={handleAddRow}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                    + Add a row of data
                </button>
            </div>
        </div >
    );
};

export default EditableTable;
