import dashboardImage from '../../../assets/materialHelp/dashboard.png';

const DashboardHelpContent = () => {
    return (
        <div className="row">
            <div className="col-md-4">
                <div><img src={dashboardImage} alt=""/></div>
            </div>
            <div className='col-md-8'>
                <p>
                    On this page, you'll find a user-friendly interface featuring four essential buttons, each catering
                    to distinct functionalities.
                </p>
                <p>
                    The Material Library empowers users with tools to seamlessly add
                    materials, find specific materials, conduct material screenings, and calculate solubility.
                </p>

                <p>
                    The Equipment Library, provides a streamlined approach to handling
                    equipment-related tasks. Users can effortlessly add new equipment, locate specific items within
                    the library, perform equipment screenings, and even explore detailed equipment models.
                </p>

                <p>
                    Internal Processes and External Cos sections are under development right now

                </p>
                <a href="/public" target="_blank">Reference Link</a>
            </div>
        </div>
    )
}

export default DashboardHelpContent