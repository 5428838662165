import React, { useEffect, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { useOktaAuth, OktaContext } from "@okta/okta-react";
import { getUserInfo } from "../utils/userHelper";
import { useUser } from "./context/UserContext";
import { PERMISSION_MESSAGE } from "../utils/constant";

const CustomSecureRoute: React.FC<any>  = ({ component: Component, requiredPermission, ...rest }) => {
  const { oktaAuth } = useOktaAuth();
  const oktaContext = useContext(OktaContext);
  const { user, updateUser, clearUser } = useUser();

  useEffect(() => {
    (async () => {
      const isAuthenticated = await oktaAuth.isAuthenticated();
      const oktaAuthState = await oktaAuth?.authStateManager?.getAuthState();
      if (isAuthenticated && oktaAuthState) {
        // const oktaUser = await oktaAuth?.getUser();
        // update user context
        updateUser(await getUserInfo(oktaAuthState));
        // updateUser(await getUserInfo(oktaUser, oktaAuthState));
      } else {
        clearUser();
      }
    })();
  }, [oktaContext?.authState]);

  const hasPermission = (userDetails: any) => {
    const hasReadMaterialPermission = userDetails?.permissions?.updated?.material?.hasRead??false;
    const hasWriteMaterialPermission = userDetails?.permissions?.updated?.material?.hasReadAndWrite??false;
    const hasReadEquipmentPermission = userDetails?.permissions?.updated?.equipment?.hasRead??false;
    const hasWriteEquipmentPermission = userDetails?.permissions?.updated?.equipment?.hasReadAndWrite??false;
    if (requiredPermission === 'read' && rest.path.includes('materials') && !hasReadMaterialPermission) return false;
    if (requiredPermission === 'write' && rest.path.includes('materials') && !hasWriteMaterialPermission) return false;
    if (requiredPermission === 'read' && rest.path.includes('equipments') && !hasReadEquipmentPermission) return false;
    if (requiredPermission === 'write' && rest.path.includes('equipments') && !hasWriteEquipmentPermission) return false;
    return true;
  };

  // if (!oktaContext?.authState?.isAuthenticated && typeof oktaContext?.authState?.accessToken !== 'undefined') {
  //   return <div>Loading...</div>;
  // }

  // authState have no accessToken.
  if (
    oktaContext?.authState && (
      typeof oktaContext?.authState?.accessToken === 'undefined' ||
      typeof oktaContext?.authState?.idToken === 'undefined')) {
    return <Redirect to="/login" />;
  }
  const userDetails = getUserInfo(oktaContext?.authState);
  if (user && userDetails) {
    const errorsKeys  = Object.keys(userDetails?.errors??{});
    if (errorsKeys?.length) {
      return (
        <Redirect
          to={{
            pathname: "/permission-denied",
            state: {
              message: userDetails?.errors[errorsKeys[0]]?.message??PERMISSION_MESSAGE,
            }
          }}
        />
      );
    }

    const permission = hasPermission(userDetails);
    if (!permission) {
      return (
        <Redirect
          to={{
            pathname: "/permission-denied",
            state: {
              message: PERMISSION_MESSAGE,
            }
          }}
        />
      );
    }
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
  // return hasPermission() ? <SecureRoute {...props} /> : <Redirect to="/permission-denied" />;
};

export default CustomSecureRoute;