import { equipmentConstants } from "../constants/equipmentConstants";

const initialState = {
  loading: false,
  items: [],
  item: null,
};

export function equipmentReducer(state = initialState, action: any) {
  switch (action.type) {
    case equipmentConstants.EQUIPMENTS:
      return {
        ...state,
        items: action.equipments,
        loading: false,
      };
    case equipmentConstants.EQUIPMENT:
      return {
        ...state,
        item: action.equipment,
        loading: false,
      };
    case equipmentConstants.ADD_EQUIPMENT:
      return {
        ...state,
        items: [action.equipment, ...state.items],
        loading: false,
      };
    case equipmentConstants.UPDATE_EQUIPMENT: {
      const updatedEquipment = state.items.map((item: any) => {
        if (item.id === action.equipment.id) {
          item = action.equipment;
          return item;
        }
        return item;
      });

      return {
        ...state,
        items: updatedEquipment,
        loading: false,
      };
    }
    case equipmentConstants.DELETE_EQUIPMENT:
      return {
        ...state,
        items: state.items.filter((item: any) => item.id !== action.id),
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
}
