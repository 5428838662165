import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// components
import Layout from "../../components/layout";

// props
import { UserProps } from "../../types/userTypes";

const initialData = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  role: "",
  address: "",
  city: "",
  state: "",
}

const schema = yup.object({
  firstName: yup.string().required("Please enter First Name."),
  lastName: yup.string().required("Please enter Last Name."),
  email: yup.string().required("Please enter valid Email."),
  password: yup.string().required("Please enter Password."),
});

const UserNew = () => {

  const { control, handleSubmit, reset } = useForm<UserProps>({
    // resolver: yupResolver(schema), defaultValues: initialData
  });
  return (
    <Layout title="Add User">
      <div className="inner-container">
        <div className="main-data">
          <div className="sec-info control-head">
            <div className="back"></div>
            <div className="head">
              <h1 className="head-lg">Add New User</h1>
            </div>
            <div className="right">
              <Link to="/users/list" title="Close" className="icon-btn alter">
                <i className="fa-solid fa-xmark" />
              </Link>
              <Link to="#" title="Help" className="icon-btn alter">
                <i className="fa-solid fa-question" />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="theme-card">
                <div className="body">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group">
                            <label htmlFor="userName" className="ip-wrap required">
                              <span className="form-label">First Name</span>
                              <input className="theme-ip" name="user_name" />
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group">
                            <label htmlFor="userName" className="ip-wrap required">
                              <span className="form-label">Last Name</span>
                              <input className="theme-ip" name="user_name" />
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group">
                            <label className="ip-wrap required" htmlFor="role">
                              <span className="form-label">Role</span>
                              <div className="input-wrap select-outer">
                                <select className="theme-ip" name="role">
                                  <option value="" >Select</option>
                                  <option value="Admin">Admin</option>
                                  <option value="Vendor">Vendor</option>
                                </select>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group">
                            <label htmlFor="userName" className="ip-wrap required">
                              <span className="form-label">Email</span>
                              <input type="" className="theme-ip" name="user_name" />
                            </label>
                          </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6">
                          <div className="form-group">
                            <label className="ip-wrap required" htmlFor="vendor">
                              <span className="form-label">Location</span>
                              <div className="input-wrap select-outer">
                                <select className="theme-ip" name="vendor_name">
                                  <option value="" >Select</option>
                                  <option value="India">India</option>
                                  <option value="Canada">Canada</option>
                                  <option value="J.T.Baker">J.T.Baker</option>
                                  <option value="Koptec">Koptec</option>
                                  <option value="Nalas">Nalas</option>
                                  <option value="SigmaAldrich">SigmaAldrich</option>
                                  <option value="Takeda">Takeda</option>
                                </select>
                              </div>
                            </label>
                          </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group">
                            <label htmlFor="password" className="ip-wrap required">
                              <span className="form-label">Password</span>
                              <input className="theme-ip" name="password" />
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group">
                            <label htmlFor="address" className="ip-wrap">
                              <span className="form-label">Address</span>
                              <input className="theme-ip" name="address" />
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group">
                            <label htmlFor="city" className="ip-wrap">
                              <span className="form-label">City</span>
                              <input className="theme-ip" name="city" />
                            </label>

                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group">
                            <label htmlFor="state" className="ip-wrap">
                              <span className="form-label">State</span>
                              <input type="" className="theme-ip" name="state" />
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 items-center flex">
                          <div className="theme-btn w-full">Submit</div>
                        </div>
                        <div className="col-lg-3 col-md-6 ">
                          <div className="theme-btn w-full">Submit</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  );
};

export default UserNew;