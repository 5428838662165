import { Control, Controller } from "react-hook-form";

import RenderIf from "./RenderIf";

export interface DropdownItem {
  label: string;
  value: string | number;
  description?: string;
}

interface DropdownProps {
  control: Control<any>;
  label: string;
  fieldName: string;
  defaultValue?: string;
  errorMessage?: string;
  htmlFor?: string
  isRequired?: boolean;
  options: DropdownItem[]
  disabled?: boolean;
}

const Dropdown = (props: DropdownProps) => {
  const { control, label, fieldName, defaultValue, errorMessage = '', disabled = false, htmlFor, isRequired = false, options } = props;
  
  const description = options?.find((option: DropdownItem) => option.value === control._formValues[fieldName])?.description ?? "";

  return (
    <div className="form-group">
      <label className={`ip-wrap ${isRequired ? "required" : ""}`} title={label} htmlFor={htmlFor}>
        <span className="form-label">{label}</span>
        <div className="input-wrap select-outer">
          <Controller
            name={fieldName}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <select className="theme-ip" disabled={disabled} {...field}>
                <option value="" disabled={isRequired} selected>Select</option>
                {options?.map((option: DropdownItem, index: number) => (
                  <option key={index} value={option?.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            )}
          />
        </div>

        <RenderIf condition={description?.length > 0}>
          <p className="dropdown-desc">{description}</p>
        </RenderIf>

        <RenderIf condition={errorMessage?.length > 0}>
          <p className="error">{errorMessage}</p>
        </RenderIf>
      </label>
    </div>
  )
};

export default Dropdown;
