import { useLocation } from 'react-router-dom';
import { PERMISSION_MESSAGE } from '../../utils/constant';

// components
import Layout from "../../components/layout";

const PermissionDenied = () => {
  const location: any = useLocation();
  const message = location?.state?.message??PERMISSION_MESSAGE;
  return (
    <Layout title="Permission Error">
      <div className="lg-container">
        <div className="theme-card not-found-page">
          <div className="body">
            <div className="row">
              <div className="col-12">
                <h1 className='head-lg head-404 pb-2'>Insufficient permissions</h1>
                {/* <p>Sorry, you don't have access.</p> */}
                <div className="row justify-center form-label">
                  <div className="col-lg-6 col-md-6 text-left head-xs text-grey" dangerouslySetInnerHTML={{__html: message}}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default PermissionDenied;