import http from "./httpService";
import { apiRoutes } from "../utils/apiRoutes";

const getAll = async (data: any) => {
  return await http.post(apiRoutes.SEARCH_MATERIAL, data);
};

const getById = async (id: string) => {
  return await http.get(`${apiRoutes.MATERIALS}/${id}`);
};

const create = async (url: string, data: any) => {
  return await http.post(url, data);
};

const update = async (data: any) => {
  return await http.put(apiRoutes.MATERIALS, data);
};

const remove = async (id: any) => {
  return await http.delete<any>(`/users/${id}`);
};

const deleteMaterial = async (data: any) => {
  return await http.post(apiRoutes.DELETE_MATERIAL, data);
};

async function getSolvents() {
  return http.post(apiRoutes.LIST_LIQUID_SOLVENTS);
}

async function saveCMAC(data: any) {
  return http.post(apiRoutes.SAVE_CMAC, data);
}

async function getCMAC(data: any) {
  return http.post(apiRoutes.GET_CMAC, data);
}

async function calculateCMACSolubility(data: any) {
  return http.post(apiRoutes.CMAC_CALCULATE, data);
}

async function deleteCMACRecord(data: any) {
  return http.post(apiRoutes.DELETE_CMAC, data);
}

async function downloadAuditTrail(data:any) {
  return http.post(apiRoutes.DOWNLOAD_MATERIAL_AUDIT_TRAIL, data)
}

const MaterialService = {
  getAll,
  getById,
  create,
  update,
  remove,
  deleteMaterial,
  getSolvents,
  saveCMAC,
  getCMAC,
  calculateCMACSolubility,
  deleteCMACRecord,
  downloadAuditTrail
};

export default MaterialService;