import { useEffect } from "react";
import { useForm, useFormContext } from "react-hook-form";

// components
import TextController from "../../form/TextController";
import SelectController from "../../form/SelectController";
import MultiFileUpload from "../../common/MultiFileUpload";

// helpers
import { mixerShaftConnector, mixerTextFields, mixerType } from "../../../utils/equipmentData";
import { setTitleNumberInput } from "../../../utils/common";
import { UPLOAD_FILE_MAXLENGTH } from "../../../utils";

const Mixer = (props: any) => {
    const methods = useFormContext();

    useEffect(() => {
        methods?.watch();
        // set default title on number input fields.
        setTitleNumberInput();
    });

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'drawing':
                methods.setValue('mixer.attachments.drawing', [
                    ...methods.control._formValues.mixer?.attachments?.drawing ?? [],
                    ...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                            return {
                                category: 'Drawing',
                                file: e?.target?.files?.[key],
                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                            }
                        })
                ], { shouldDirty: true, shouldTouch: true });
                break;
            case 'manufacturer_manual':
                methods.setValue('mixer.attachments.manufacturer_manual', [
                    ...methods.control._formValues.mixer?.attachments?.manufacturer_manual ?? [],
                    ...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                            return {
                                category: 'Manufacturer Manual',
                                file: e?.target?.files?.[key],
                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                            }
                        })
                ], { shouldDirty: true, shouldTouch: true });
                break;
            case 'sop':
                methods.setValue('mixer.attachments.sop', [
                    ...methods.control._formValues.mixer?.attachments?.sop ?? [],
                    ...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                            return {
                                category: 'SOP',
                                file: e?.target?.files?.[key],
                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                            }
                        })
                ], { shouldDirty: true, shouldTouch: true });
                break;
            case 'service_log':
                methods.setValue('mixer.attachments.service_log', [
                    ...methods.control._formValues.mixer?.attachments?.service_log ?? [],
                    ...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                            return {
                                category: 'Service Log',
                                file: e?.target?.files?.[key],
                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                            }
                        })
                ], { shouldDirty: true, shouldTouch: true });
                break;
            default:
            // default
        }
    }

    // Remove selected file
    const removeFile = (index: number, name: string) => {
        switch (name) {
            case 'drawing':
                methods.setValue('mixer.attachments.drawing', methods.control._formValues.mixer.attachments.drawing.filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
                break;
            case 'manufacturer_manual':
                methods.setValue('mixer.attachments.manufacturer_manual', methods.control._formValues.mixer.attachments.manufacturer_manual.filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
                break;
            case 'sop':
                methods.setValue('mixer.attachments.sop', methods.control._formValues.mixer.attachments.sop.filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
                break;
            case 'service_log':
                methods.setValue('mixer.attachments.service_log', methods.control._formValues.mixer.attachments.service_log.filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
                break;
            default:
            // default
        }
    }

    return (
        <>
            <div className="row">
                <SelectController
                    label="Type"
                    name="type"
                    page="mixer"
                    value={methods?.control._formValues?.mixer?.type ?? ''}
                    methods={methods}
                    options={mixerType}
                    readOnly={props?.readOnly}
                />

                {mixerTextFields.map((item: any) => (
                    <TextController
                        name={`mixer.${item.value}`}
                        value={methods?.control._formValues?.[methods?.control._formValues.equipment_type]?.[item.value] ?? ''}
                        label={item.label}
                        methods={methods}
                        required={item?.required ?? false}
                        type={item?.type ?? "text"}
                        readOnly={props?.readOnly ?? false}
                    />
                ))}
                <SelectController
                    label="Shaft Connector"
                    page="mixer"
                    value={methods?.control._formValues?.mixer?.shaft_connector ?? ''}
                    name="shaft_connector"
                    methods={methods}
                    options={mixerShaftConnector}
                    readOnly={props?.readOnly}
                />
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="Drawing"
                        name="drawing"
                        hoverText="CAD, Pictures"
                        handleFileChange={handleFileChange}
                        removeFile={removeFile}
                        readable={props?.readOnly}
                        downloadFile={props?.downloadFile}
                        attachments={props.attachments?.drawing ?? []}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="Manufacturer Manual"
                        name="manufacturer_manual"
                        handleFileChange={handleFileChange}
                        removeFile={removeFile}
                        readable={props?.readOnly}
                        downloadFile={props?.downloadFile}
                        attachments={props.attachments?.manufacturer_manual ?? []}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="SOP"
                        name="sop"
                        handleFileChange={handleFileChange}
                        removeFile={removeFile}
                        readable={props?.readOnly}
                        downloadFile={props?.downloadFile}
                        attachments={props.attachments?.sop ?? []}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="Service Log"
                        name="service_log"
                        handleFileChange={handleFileChange}
                        removeFile={removeFile}
                        readable={props?.readOnly}
                        downloadFile={props?.downloadFile}
                        attachments={props.attachments?.service_log ?? []}
                    />
                </div>
            </div>
        </>
    );
};

export default Mixer;