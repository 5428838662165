import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';

// compoenents
import Layout from '../../../components/layout';

// props
import { UserProps } from '../../../types';
import { layoutTitles } from '../../../utils/constant';

const Profile = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserProps | null>(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info: any) => {
        setUserInfo(info);
      }).catch((err) => {
        console.error(err);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  return (
    <Layout title={layoutTitles.profile}>
      <div className="sec-info control-head">
        <div className="head">
          <h1 className='head-lg'>User Profile</h1>
        </div>
        <div className="right">
          <Link to="#" title='Close' className="icon-btn alter">
            <i className="fa-solid fa-xmark" />
          </Link>
          <Link to="#" title='Help' className="icon-btn alter">
            <i className="fa-solid fa-question" />
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="theme-card profile-content ">
            <div className="md-container">
              {!userInfo ? (
                <div className="theme-loader show">
                  <div className="loader-outer">
                    <div className="loader"></div>
                  </div>
                </div>
              ) : (
                <div className="body">
                  <div className="row">
                    <div className="col-12 full-flex">
                      <div className="profile-picture">
                        {/* <div className="edit-img">
                              <i className="fa-solid fa-pen-to-square"></i>
                            </div> */}
                        <img src="https://placehold.co/330x330" alt="Profile Picture" width={330} height={330} className='img-fluid profile-img' />
                      </div>
                    </div>
                  </div>

                  <div className="row items-center">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label className="ip-wrap" htmlFor="brand_name">
                          <span className="form-label">First Name</span>
                          <p className="pt-2">{userInfo?.given_name ?? ""}</p>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label className="ip-wrap" htmlFor="brand_name">
                          <span className="form-label">Last Name</span>
                          <p className="pt-2">{userInfo?.family_name ?? ""}</p>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label className="ip-wrap" htmlFor="brand_name">
                          <span className="form-label">Email</span>
                          <p className="pt-2">{userInfo?.email ?? ""}</p>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label className="ip-wrap" htmlFor="brand_name">
                          <span className="form-label">Address</span>
                          <p className="pt-2">{userInfo?.address ?? ""}</p>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label className="ip-wrap" htmlFor="City">
                          <span className="form-label">City</span>
                          <p className="pt-2">{userInfo?.city ?? ""}</p>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label className="ip-wrap" htmlFor="State">
                          <span className="form-label">State</span>
                          <p className="pt-2">{userInfo?.state ?? ""}</p>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label className="ip-wrap" htmlFor="Zip">
                          <span className="form-label">Zip</span>
                          <p className="pt-2">{userInfo?.zipCode ?? ""}</p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* <form action="">
                    <div className="row items-center">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="brand_name">
                            <span className="form-label">First Name</span>
                            <input className="theme-ip" name="first_name" value="" />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="brand_name">
                            <span className="form-label">Last Name</span>
                            <input className="theme-ip" name="Last_name" value="" />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group disabled">
                          <label className="ip-wrap" htmlFor="brand_name">
                            <span className="form-label">Email</span>
                            <input className="theme-ip" name="Email" value="" />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="brand_name">
                            <span className="form-label">Address</span>
                            <input className="theme-ip" name="Address" value="" />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="City">
                            <span className="form-label">City</span>
                            <input className="theme-ip" name="brand_name" value="" />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="State">
                            <span className="form-label">State</span>
                            <input className="theme-ip" name="brand_name" value="" />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="Zip">
                            <span className="form-label">Zip</span>
                            <input className="theme-ip" name="zip" value="" />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 ">
                        <div className="theme-btn w-full">Submit</div>
                      </div>
                    </div>
                  </form> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;