import http from "./httpService";

const get = async (url: string, data: any) => {
  return await http.post(url, data);
};

const LogService = {
  get,
};

export default LogService;