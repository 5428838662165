import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberInput from "../NumberInput";

import useAlert from "../../../../hooks/useAlert";
import useFileDownload from "../../../../hooks/useFileDownload";

const defaultValues = {
  api_url: process.env.REACT_APP_API_URL,
  file_path: "",
  file: "",
  sm_mw: null,
  prod_mw: null,
  sol_yield: null,
  solv0: null,
  anti0: null,
  temp0: null,
  anti1: null,
  temp1: null,
  user_query: "Yes",
};

const schema = yup.object().shape({
  api_url: yup.string(),
  file: yup.mixed().required("Please upload a file"),
  file_path: yup.string(),
  sm_mw: yup.number().required("Please enter Starting Material MW"),
  prod_mw: yup.number().required("Please enter Product MW"),
  sol_yield: yup.number().required("Please enter Solution Yield (%)"),
  solv0: yup.number().required("Please enter Start Solvent (L/kg)"),
  anti0: yup.number().required("Please enter Start Antisolvent (L/kg)"),
  temp0: yup.number().required("Please enter Start Temperature (Celsius)"),
  anti1: yup.number().required("Please enter Antisolvent to Add (L/kg)"),
  temp1: yup.number().required("Please enter Final Temperature (Celsius)"),
  user_query: yup.string(),
});

type FormSchema = yup.InferType<typeof schema>;

export default function Tool2CrystallizationForm({
  onSubmit,
  responseData,
  setResponseData,
}: {
  onSubmit: any;
  responseData: any;
  setResponseData: any;
}) {
  const form = useForm<FormSchema>({
    // @ts-ignore
    resolver: yupResolver(schema), defaultValues,
  });
  const [file, setFile] = useState<any>(null);
  const { setValue, handleSubmit } = form;
  const { errorAlert, successAlert } = useAlert();
  const {
    formState: { errors },
  } = form;
  useEffect(() => {
    if (Object.keys(errors).length) {
      const key: any = Object.keys(errors)[0];
      // @ts-ignore
      const errorMessage = errors[key].message;
      errorAlert(errorMessage);
    }
  }, [errors]);
  const { downloadFile, loading } = useFileDownload();
  const [showGenFile, setShowGenFile] = useState(true);
  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(async (data) => {
          setResponseData(null);
          onSubmit(data, file);
          setShowGenFile(true);
        })}

      >
        <div className="grid grid-cols-3 gap-4 p-4 pl-0"><div><h3>Input to Crystallization</h3></div><div></div><div><h3>Crystallization Process Variables</h3></div></div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-4">
          <div className="h-24 flex ">
            <div className="w-full">
              <label htmlFor="sm_mw">
                <span className="form-label">Starting Material MW</span>
                {/*<input {...register("sm_mw")}*/}
                {/*       type="number"*/}
                {/*       className="theme-ip" onInput={e => limitDigits(e, 12)} />*/}
                <NumberInput name="sm_mw" />
              </label>
            </div>
          </div>
          <div className="h-24 flex">
            <div className="w-full">
              <label htmlFor="sol_yield">
                <span className="form-label">Solution Yield (%):</span>
                <NumberInput name="sol_yield" />
              </label>
            </div>
          </div>
          <div className="h-24 flex">
            <div className="w-full">
              <label htmlFor="anti1">
                <span className="form-label">Antisolvent to add (L/kg):</span>
                <NumberInput name="anti1" />
              </label>
            </div>

          </div>
          <div className="h-24 flex">
            <div className="w-full">
              <label htmlFor="prod_mw">
                <span className="form-label">Product MW</span>
                <NumberInput name="prod_mw" />
              </label>
            </div></div>
          <div className="h-24 flex">
            <div className="w-full">
              <label htmlFor="solv0">
                <span className="form-label">Start Solvent (L/kg):</span>
                <NumberInput name="solv0" />
              </label>
            </div>
          </div>
          <div className="h-24 flex">
            <div className="w-full">
              <label htmlFor="temp1">
                <span className="form-label">Final Temperature (Celsius):</span>
                <NumberInput name="temp1" />
              </label>
            </div>
          </div>
          <div className="h-24 flex">
            <div className="w-full">
              <label className="ip-wrap" htmlFor="">
                <span className="from-label" title={"upload Toolbox_cooling_antisolvent from dynochem"}>File to upload</span>
                <div className="custom-upload">
                  <Controller
                    render={({ field }) => (
                      <>
                        <input
                          type="file"
                          {...field}
                          id="file-upload"
                          onChange={(e) => {
                            field.onChange(e);
                            // @ts-ignore
                            setFile(e.target.files && e.target.files[0]);
                            setShowGenFile(false);
                          }}
                          accept=".xlsx, .xls, .csv, .xlxm, .xlsm"
                          name="file-upload"
                          hidden
                        />
                        <div
                          className="theme-ip"
                          onClick={() => {
                            const fileInput =
                              document.querySelector("#file-upload");
                            // @ts-ignore
                            fileInput.click();
                          }}
                        >
                          <i className="fa-solid fa-paperclip" />
                          <span className="text">No File Chosen</span>
                          <span className="theme-btn sm light-btn">Choose</span>
                        </div>
                      </>
                    )}
                    name="file"
                  />
                </div>
              </label>
              {file && (
                <div className="uploaded-files">
                  <label className="form-label">Uploaded {file.name}</label>
                  <div className="upfile">
                    <span onClick={() => { }}>
                      <i className={`fa-solid fa-file`} />
                    </span>
                    <div className="text">
                      <div className="name ">
                        <div className="attachment-name">{file.name}</div>
                      </div>
                    </div>

                    <span className="ml-auto full-flex">
                      <i
                        className="fa-solid fa-xmark close block"
                        onClick={() => {
                          setFile(null);
                          // @ts-ignore
                          setValue("file", "");
                          setShowGenFile(false);
                        }}
                      />
                    </span>
                  </div>
                </div>
              )}
              {responseData && showGenFile && (
                <div className="uploaded-files">
                  <label className="form-label">Generated Output File</label>
                  <div className={`upfile ${loading && "cursor-wait"}`}>
                    <span onClick={() => { }}>
                      <i className={`fa-solid fa-file`} />
                    </span>
                    <div
                      className="text w-full"
                      style={{
                        marginRight: "0 !important",
                      }}
                    >
                      <div className="attachment-name w-full flex justify-between">
                        <span>
                          {responseData.xlsx_file_name.split("/").slice(-1)[0]}
                        </span>
                        <button
                          type="button"
                          className="fa-solid fa-download close"
                          style={{
                            cursor: loading ? "wait" : "pointer",
                          }}
                          onClick={() =>
                            !loading && downloadFile(responseData.xlsx_file_name)
                          }
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="h-24 flex">
            <div className="w-full">
              <label htmlFor="anti0">
                <span className="form-label">Start Antisolvent (L/kg):</span>
                <NumberInput name="anti0" />
              </label>
            </div>
          </div>
          <div className="h-24 flex items-center justify-center">
            <div className="w-full space-x-4 flex justify-center">
              <button
                type="submit"
                className="bg-theme text-white py-2 px-4 rounded-lg text-lg "
              >
                Run
              </button>
              <button
                type="reset"
                className="bg-theme text-white py-2 px-4 rounded-lg text-lg"
                onClick={(e) => {
                  e.preventDefault();
                  // @ts-ignore
                  form.reset(defaultValues);
                  setFile(null);
                  setResponseData(null);
                }}
              >
                Reset
              </button>
            </div>
          </div>
          <div className="h-24 flex">

          </div>
          <div className="h-24 flex">
            <div className="w-full">
              <label htmlFor="temp0">
                <span className="form-label">Start Temperature (Celsius):</span>
                <NumberInput name="temp0" />
              </label>
            </div>
          </div>
          <div className="h-24 flex">
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
