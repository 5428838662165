import { useMemo } from "react";

const defaultData = {
  "Total L/kg": 0,
  "Antisolvent wt%": 0,
  "Temp (Celsius)": 0,
  "Solubility": 0,
  "Yield %": 0,
  "Dosed Antisolvent L/kg": 0,
};

export default function MaxSolubillityTable({ data }: { data: any }) {
  const { max, maxObj, index } = useMemo(() => {
    let max = -1;
    let maxObj: any = null;
    let index = 0;
    maxObj = Object.values(defaultData).map((value) => value.toString());
    if (data) {
      data.forEach((dp: any, _i: any) => {
        const _yield = parseFloat(dp[4]);
        if (!Number.isNaN(dp)) {
          if (_yield > max) {
            max = _yield;
            maxObj = dp;
            index = _i - 1;
          }
        }
      });
    }

    return { max, maxObj, index };
  }, [data]);
  const labels = data ? data[0] : Object.keys(defaultData);

  return (
    <div className="mt-10">
      <h6 className="text-xl ml-8">The max yield is observed at:</h6>
      <table className="mt-6 ml-20">
        <thead>
          <tr className="bg-gray-100 border-1 border">
            <th className="border-r border-1 py-1 px-2"></th>
            <th className="py-1 px-2">{index}</th>
          </tr>
        </thead>
        <tbody>
          {labels.map((label: any, ind: any) => (
            <tr className="border border-1">
              <td className="border-r border-1 py-1 px-2">{label}</td>
              <td className="py-1 px-2">
                {parseFloat(maxObj[ind]).toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
