import http from "./httpService";

export function generateMaterialChains(payload: any) {
  return http.post("/material-chains/generate", payload);
}

export function saveMaterialChains(payload: any) {
  return http.post("/material-chains/save", payload);
}

export function getMaterialChains(payload: any) {
  return http.post("/material-chains/get", payload);
}
