import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { Controller, useFormContext } from 'react-hook-form';

// props
import { VendorProps } from '../../../types/materialsTypes';

// helpers
import { vendorNames } from '../../../utils/materialHelper';
import MaterialService from '../../../services/materialService';
import { alertCloseAction, alertOpenAction } from '../../../redux/actions';
import { apiRoutes } from '../../../utils/apiRoutes';
import { uniqueItemsByKey, sortItemsByKey } from '../../../utils/common';
import Dropdown from '../../../components/common/Dropdown';

const SelectMaterialHeader = (props: any) => {
    const dispatch = useDispatch();
    const methods = useFormContext();

    // auth
    const { authState } = useOktaAuth();
    const auth: any = authState ? authState?.accessToken : '';
    const uid = useMemo(() => auth?.claims?.uid, [auth]);

    // states
    const [loading, setLoading] = useState<boolean>(false);
    const [materialNameOptions, setMaterialNameOptions] = useState<any[]>([]);
    const [lotNumberOptions, setLotNumberOptions] = useState<any[]>([]);
    const [vendors, setVendors] = useState<VendorProps[]>(vendorNames);

    // method to show alert message
    const showAlert = (message: string, type: 'error' | 'success') => {
        dispatch(alertOpenAction(message, type));
        setTimeout(() => dispatch(alertCloseAction()), 3000);
    };

    // method to return material list based on selected vendor_name field.
    const getMaterialNames = async (vendor_name: string) => {
        if (!uid) return;

        setLoading(true);
        try {
            const payload = { uid, vendor_name };
            const { data } = await MaterialService.create(apiRoutes.LIST_MATERIAL_NAMES_MATERIAL, payload);

            if (data.code === 200 && data.body.length) {
                const uniqueMaterials = uniqueItemsByKey(data.body, 'material_name');
                setMaterialNameOptions(sortItemsByKey(uniqueMaterials, 'material_name'));
            } else {
                showAlert('No related materials present.', 'error');
            }
        } catch (error) {
            showAlert('Error fetching material names.', 'error');
        } finally {
            setLoading(false);
        }
    };

    // method to return lot number based on selected vendor_name and material_name fields.
    const getLotNumbers = async (vendor_name: string, material_name: string) => {
        if (!uid) return;

        setLoading(true);
        try {
            const payload = { uid, vendor_name, material_name };
            const { data } = await MaterialService.create(apiRoutes.LIST_LOT_NUMBERS_MATERIAL, payload);

            if (data.code === 200 && data.body.length) {
                const uniqueLots = uniqueItemsByKey(data.body, 'lot_number');
                setLotNumberOptions(sortItemsByKey(uniqueLots, 'lot_number'));
            } else {
                showAlert('Unable to load related Lot Numbers.', 'error');
            }
        } catch (error) {
            showAlert('Error fetching lot numbers.', 'error');
        } finally {
            setLoading(false);
        }
    };

    // Watch form changes
    useEffect(() => {
        methods.watch();
    });

    // Set vendors for external users
    useEffect(() => {
        if (props.user) {
            const { userType, vendors } = props.user;
            if (userType.includes('external')) {
                // okta dashboard vendors for external users.
                const vendorOptions = [
                    { label: 'Generic', value: 'Generic' },
                    ...vendors.map((vendor: VendorProps) => ({ label: vendor, value: vendor })),
                ];
                setVendors(sortItemsByKey(vendorOptions, 'label'));
            }
        }
    }, [props.user]);

    useEffect(() => {
        const { vendor_name, material_name, lot_number } = methods.control._formValues;
        if (vendor_name?.length && !material_name?.length && !lot_number?.length) {
            setMaterialNameOptions([]);
            getMaterialNames(vendor_name);
        } else if (vendor_name?.length && material_name?.length && !lot_number?.length) {
            setLotNumberOptions([]);
            getLotNumbers(vendor_name, material_name);
        } else if (vendor_name?.length && material_name?.length && lot_number?.length) {
            props?.handleSelectedFields({
                loading,
                vendor_name,
                material_name,
                lot_number,
            });
        }
    }, [
        methods.control._formValues.vendor_name,
        methods.control._formValues.material_name,
        methods.control._formValues.lot_number,
    ]);

    // Pre-fill lot number if only one option is available
    useEffect(() => {
        if (lotNumberOptions.length === 1) {
            methods.setValue("lot_number", lotNumberOptions[0].lot_number);
        }
    }, [lotNumberOptions]);

    return (
        <div className="theme-card">
            <div className="body">
                <div className="row">
                    {/* Vendor */}
                    <div className="col-lg-4 col-md-6">
                        <Dropdown
                            isRequired
                            label='Vendor'
                            control={methods.control}
                            fieldName='vendor_name'
                            defaultValue={methods.control._formValues.vendor_name}
                            htmlFor='vendor'
                            options={vendors}
                        />
                    </div>

                    {/* Material Name */}
                    <div className="col-lg-4 col-md-6">
                        <Dropdown
                            isRequired
                            label='Material Name'
                            control={methods.control}
                            fieldName='material_name'
                            defaultValue={methods.control._formValues.material_name}
                            htmlFor='material_name'
                            options={materialNameOptions.map((item: any) => ({ label: item.material_name, value: item.material_name }))}
                            disabled={!methods.control._formValues.vendor_name}
                        />
                    </div>

                    {/* Lot Number */}
                    <div className="col-lg-4 col-md-6">
                        <Dropdown
                            isRequired
                            label='Lot Number'
                            control={methods.control}
                            fieldName='lot_number'
                            defaultValue={methods.control._formValues.lot_number}
                            htmlFor='lot_number'
                            options={lotNumberOptions.map((item: any) => ({ label: item.lot_number, value: item.lot_number }))}
                            disabled={!methods.control._formValues.vendor_name || !methods.control._formValues.material_name}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectMaterialHeader;