import DashboardHelpContent from "../components/helpContent/materialHelp/dashboard"
import NewEquipmentContent from "../components/helpContent/equipmentHelp/newEquipmentHelp";
import FindEquipmentContent from "../components/helpContent/equipmentHelp/findEquipmentHelp";
import ListEquipmentContent from "../components/helpContent/equipmentHelp/listEquipment";

export const DashboardHelp = {
    title: 'Dashboard Help',
    content: <DashboardHelpContent/>
}

export const NewEquipmentHelp = {
    title: 'New Equipment Help',
    content: <NewEquipmentContent/>
}

export const FindEquipmentHelp = {
    title: 'Find Equipment Help',
    content: <FindEquipmentContent/>
}

export const ListEquipmentHelp = {
    title: 'List Equipment Help',
    content: <ListEquipmentContent/>
}
