import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// components
import { ButtonIcons, ButtonProps, ButtonTypes } from "../../components/common/Button";
import { TableHeadProps, TableHeadType } from "../../components/common/Table/TableHeader";
import { TableRowItemType } from "../../components/common/Table/TableRow";
import BodyHeader from "../../components/layout/BodyHeader";
import CustomLoader from "../../components/common/CustomLoader";
import FilterSidebar from "../../components/equipment/FilterSidebar";
import Layout from "../../components/layout";
import RenderIf from "../../components/common/RenderIf";
import Table from "../../components/common/Table";
import TableActionButtons from "../../components/common/TableActionButtons";

// modals
import ConfirmCloneModal from "../../components/modals/ConfirmCloneModal";
import ExportEquipmentModal from "../../components/modals/ExportEquipmentModal";
import HelpModal from "../../components/modals/HelpModal";

// context
import { useUser } from "../../components/context/UserContext";

// services
import EquipmentService from "../../services/equipmentService";

// redux actions
import { alertCloseAction, alertOpenAction } from "../../redux/actions";
import { equipmentAction } from "../../redux/actions/equipmentActions";

// helpers
import { capitalise } from "../../utils";
import { EQUIPMENTS_SCREENING_PAGE_TITLE, layoutTitles } from "../../utils/constant";
import { getLabelFromValue, labelObject } from "../../utils/equipmentHelper";
import { ListEquipmentHelp } from "../../utils/equipmentHelpContent";
import { sortByField } from "../../utils/common";
import { SortOrder } from "../../types";

const EquipmentScreening = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useUser();

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : "";
  const hasWritePermission = user?.permissions?.updated?.equipment?.hasReadAndWrite ?? false;

  // states
  const [expandedRowIds, setExpandedRowIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [openCloneModal, setOpenCloneModal] = useState<boolean>(false);
  const [openHelpModal, setOpenHelpModal] = useState<boolean>(false);
  const [isFilterSectionActive, setFilterSectionActive] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>();
  const [sortBy, setSortBy] = useState<string>("y");
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.DESC);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [allEquipments, setAllEquipments] = useState<any>([]);
  const [equipmentList, setEquipmentList] = useState<any>([]);
  const [cloneId, setCloneId] = useState<any>();
  const [typeLabels, setTypeLabels] = useState<any>();

  const equipmentTypes = useMemo(() => allEquipments.filter((eq: any) => selectedRows.includes(eq.id)).map((eq: any) => eq.equipment_type), [selectedRows, allEquipments]);
  const isAllEquipmentsSelected = selectedRows.length === allEquipments?.length;

  // Handle sort change
  const manageSort = (newSortBy: string) => {
    let newSortOrder: SortOrder = SortOrder.DESC;
    if (newSortBy == sortBy) {
      newSortOrder = sortOrder == SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    }
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
    setEquipmentList(sortByField(equipmentList, newSortBy, newSortOrder));
  }

  // Handle row checkbox click
  const handleRowSelect = (rowId: any) => {
    const newSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id: any) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(newSelectedRows);
  };

  // To expand div: show/hide fields
  const toggleExpand = (index: number) => {
    if (expandedRowIds.includes(index)) {
      setExpandedRowIds(expandedRowIds.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRowIds([...expandedRowIds, index]);
    }
  };

  // Reset selected rows
  const handleReset = () => setSelectedRows([]);

  // Search equipments
  const searchEquipments = async (filters: any = {}) => {
    const payload = {
      uid: `${auth?.claims?.uid}`,
      ...filters,
    };

    setIsLoading(true);
    const res = await EquipmentService.getAll(payload);

    if (res?.data?.code === 200) {
      const result = res?.data?.body;
      if (result?.Items) {
        //getting labels for values to display
        let TypeArr = getLabelFromValue(result.Items, "type");
        setTypeLabels(labelObject(TypeArr, "type"));

        setAllEquipments(result.Items);
      } else {
        setAllEquipments([]);
      }
    } else {
      dispatch(alertOpenAction("Oops ! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
    setIsLoading(false);
  };

  // Handle select all checkbox click
  const handleSelectAll = (e: any) => {
    const checked = e.target.checked;
    setSelectedRows(checked ? allEquipments?.map((row: any) => row.id) : []);
  };

  // Handle Export button click
  const handleExportModal = () => {
    selectedRows?.length ? setOpenExportModal(true) : dispatch(alertOpenAction("Please select rows you want to export.", "error"));
    setTimeout(() => dispatch(alertCloseAction()));
  };

  // method to edit equipment functionality
  const editEquipment = (data: any) => {
    dispatch(equipmentAction(null));
    history.push({
      pathname: `/equipments/${data?.id}/update`,
      state: { id: `${data?.id}`, page: `list` },
    });
  };

  // method to view equipment functionality
  const viewEquipment = (data: any) => {
    dispatch(equipmentAction(null));
    history.push({
      pathname: `/equipments/${data?.id}/view`,
      state: { id: `${data?.id}`, page: `list` },
    });
  };

  // method to clone equipment functionality
  const cloneEquipment = (item: any) => {
    setOpenCloneModal(true);
    setCloneId(item.id);
  };

  // Handle filter icon button
  const toggleFilterSection = () => {
    setFilterSectionActive(!isFilterSectionActive);
    const filterButton = document.querySelector(".filter-responsive") as HTMLButtonElement;
    if (filterButton && filterButton.classList.contains("alter")) {
      filterButton.classList.remove("alter");
    } else {
      filterButton.classList.add("alter");
    }
  };

  // Header Buttons
  const headerButtons: ButtonProps[] = [
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.FILTER,
      title: "Filter",
      type: ButtonTypes.SECONDARY,
      onClick: toggleFilterSection,
      hideForDesktop: true
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.EXPORT,
      title: "Export",
      type: ButtonTypes.PRIMARY,
      onClick: handleExportModal,
    },
    {
      isIconButton: true,
      navigateTo: "/equipments",
      icon: ButtonIcons.HOME,
      title: "Home",
      type: ButtonTypes.SECONDARY,
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.HELP,
      title: "Help",
      type: ButtonTypes.SECONDARY,
      onClick: () => setOpenHelpModal(true)
    }
  ];

  // Table Headers
  const tableHeaders: TableHeadProps[] = [
    {
      type: TableHeadType.CHECKBOX,
      onClick: handleSelectAll,
      checked: isAllEquipmentsSelected
    },
    {
      type: TableHeadType.EXPAND,
      style: 'w-1'
    },
    {
      title: "Equipment Type",
      fieldName: "equipment_type",
      type: TableHeadType.SORTABLE,
      alignCenter: false,
      sortBy,
      sortOrder,
      manageSort
    },
    {
      title: "Equipment Owner",
      fieldName: "equipment_owner",
      type: TableHeadType.SORTABLE,
      alignCenter: false,
      sortBy,
      sortOrder,
      manageSort
    },
    {
      title: "Equipment Tag Number",
      fieldName: "tag_number",
      type: TableHeadType.SORTABLE,
      alignCenter: false,
      sortBy,
      sortOrder,
      manageSort
    },
    {
      title: "Location",
      fieldName: "location",
      type: TableHeadType.SORTABLE,
      alignCenter: false,
      sortBy,
      sortOrder,
      manageSort
    },
    {
      title: "Application & Scale",
      fieldName: "application_and_scale",
      type: TableHeadType.SORTABLE,
      alignCenter: false,
      sortBy,
      sortOrder,
      manageSort
    },
    {
      title: 'Actions',
      style: "w-10"
    }
  ];

  // Get Expanded Row details formatted
  const getExpandedContent = (equipment: Record<string, any>): TableRowItemType[][] => {
    return [[
      { value: "Brand/Manufacturer", style: "f-heavy w-20" },
      { value: equipment?.brand ?? "N/A" },
      { value: "Manufacturer Model Number", style: "f-heavy w-20" },
      { value: equipment?.manufacturer_model_number ?? "N/A" },
      { value: "Material of Construction", style: "f-heavy w-20" },
      { value: equipment?.material_of_construction ?? "N/A" },
    ]];
  }

  // Table Details
  const customTableDetails: TableRowItemType[][] = equipmentList.map((equipment: any, index: number) => {
    return [
      {
        value: selectedRows.includes(equipment?.id),
        onClick: () => handleRowSelect(equipment?.id),
      },
      {
        value: expandedRowIds.includes(index),
        onClick: () => toggleExpand(index),
        customElement: (
          <tr className="child-data">
            <td colSpan={10}>
              <Table style="sm-table" heads={[]} data={getExpandedContent(equipment)} />
            </td>
          </tr>
        )
      },
      {
        value: typeLabels?.[equipment?.equipment_type] ?? "N/A",
        style: "f-heavy"
      },
      {
        value: equipment?.equipment_owner ?? "N/A"
      },
      {
        value: equipment?.tag_number ?? "N/A"
      },
      {
        value: equipment?.location ?? "N/A"
      },
      {
        value: capitalise(equipment?.application_and_scale) ?? "N/A"
      },
      {
        value: (
          <TableActionButtons
            hasWritePermission={hasWritePermission}
            onEditClick={() => editEquipment(equipment)}
            onViewClick={() => viewEquipment(equipment)}
            onCloneClick={() => cloneEquipment(equipment)}
          />
        )
      }
    ] as TableRowItemType[];
  });

  // Set Equipments data on fetch complete
  useEffect(() => {
    if (allEquipments && Array.isArray(allEquipments))
      setEquipmentList(allEquipments);
  }, [allEquipments]);

  // search Equipments on filers change
  useEffect(() => {
    if (auth?.claims?.uid && filters) {
      searchEquipments(filters);
    }
  }, [auth?.claims?.uid, filters]);

  // fetch all Equipments on page render
  useEffect(() => {
    if (auth?.claims?.uid) {
      searchEquipments({});
    }
  }, [auth?.claims?.uid]);

  return (
    <Layout title={layoutTitles.equipmentScreening}>
      {/* Header */}
      <BodyHeader showBackButton onBackClick={() => history.goBack()} title={EQUIPMENTS_SCREENING_PAGE_TITLE} buttons={headerButtons} />

      {/* Body */}
      <div className="theme-card sm filter-card">
        <div className="body p-0">
          <div className="row sm-row row-responsive">
            {/* Filters */}
            <div className={isFilterSectionActive ? "left click-block" : "left"}>
              <FilterSidebar filters={setFilters} user={user} handleReset={handleReset} />
            </div>

            {/* Loading Spinner */}
            <RenderIf condition={isLoading}>
              <CustomLoader />
            </RenderIf>

            {/* Table details */}
            <RenderIf condition={!isLoading}>
              <div className={`right ${(equipmentList?.length) ? "" : "full-flex"}`}>

                {/* Empty Table Message */}
                <RenderIf condition={!equipmentList?.length}>
                  <div>
                    <h1>No data Found !</h1>
                  </div>
                </RenderIf>

                {/* Table */}
                <RenderIf condition={equipmentList?.length}>
                  <div className="table-outer">
                    <div className="table-responsive theme-table">
                      <Table heads={tableHeaders} data={customTableDetails} />
                    </div>
                  </div>
                </RenderIf>

              </div>
            </RenderIf>

          </div>
        </div>
      </div>

      {/* Modals */}
      <ExportEquipmentModal
        equipmentTypes={equipmentTypes}
        open={openExportModal}
        setOpen={setOpenExportModal}
        selectedRows={selectedRows}
        selectAllChecked={isAllEquipmentsSelected}
        filters={filters}
      />
      <ConfirmCloneModal
        open={openCloneModal}
        setOpen={setOpenCloneModal}
        type="Equipment"
        cloneId={cloneId}
        uid={auth?.claims?.uid ?? null}
        operation="cloneFromList"
      />
      <HelpModal
        open={openHelpModal}
        setOpen={setOpenHelpModal}
        title={ListEquipmentHelp.title}
        content={ListEquipmentHelp.content} />
    </Layout>
  );
};

export default EquipmentScreening;
