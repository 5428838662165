import findMaterialHelp from '../../../assets/materialHelp/screen-material-help.png'


const ListMaterial = () => {
    return (
        <div className="instruction-modal">
            <div className="row ">
                <div className="col-md-4 ">
                    <div><img src={findMaterialHelp} alt=""/></div>
                </div>
                <div className='col-md-8'>
                    <p>
                        This section allows user to list all the materials. The user can also filter the data based on
                        Vendor Filter, Shelf Life Filter, SMILES Filter and Phase Property Filter
                    </p>
                    <a href="/public" target="_blank">Reference Link</a>
                </div>

            </div>

        </div>
    )
}

export default ListMaterial
