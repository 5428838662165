import { userConstants } from '../constants/userConstants';

const initialState = {
  loading: false,
  items: [],
  item: null,
};

export function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case userConstants.FETCH:
      return {
        ...state,
        loading: action.loading,
      };
    case userConstants.USERS:
      return {
        ...state,
        items: action.users,
        loading: false,
      };
    case userConstants.USER:
      return {
        ...state,
        item: action.user,
        loading: false,
      };
    
    case userConstants.NEW_USER:
      return {
        ...state,
        items: [action.user, ...state.items],
        loading: false,
      };
    case userConstants.UPDATE_USER: {
      const updatedMaterial = state.items.map((item: any) => {
        if (item.id === action.material.id) {
          item = action.material;
          return item;
        }
        return item;
      });

      return {
        ...state,
        items: updatedMaterial,
        loading: false,
      };
    }
    case userConstants.DELETE_USER:
      return {
        ...state,
        items: state.items.filter((item: any) => item.id !== action.id),
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
}
