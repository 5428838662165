import screenEquipment from '../../../assets/materialHelp/equipment-screening.png'


const ListEquipment = () => {
    return (
        <div className="instruction-modal">
            <div className="row ">
                <div className="col-md-4 ">
                    <div><img src={screenEquipment} alt=""/></div>
                </div>
                <div className='col-md-8'>
                    <p>
                        This section allows user to list all the equipments. The user can also filter the data based on
                        Equipment Type, Equipment Owners, Application/Scale and Equipment Type Properties.
                    </p>
                    <a href="/public" target="_blank">Reference Link</a>
                </div>

            </div>

        </div>
    )
}

export default ListEquipment
