import { Modal, Button, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import ReactJsonViewCompare  from 'react-json-view-compare';

const CompareAuditModal = (props: any) => {    
    return (
        <Modal
            open={props.open}
            size="large"
            dimmer='blurring'
            onClose={() => props.onClose(false)}
        >
            <Modal.Header align="center" className='border-none pb-10'>
                <Icon
                    name="times circle outline"
                    color="grey"
                    className="close-icon "
                    onClick={() => props.onClose(false)}
                ></Icon>
            </Modal.Header>
            <Modal.Content className='audit-modal'>
                <Modal.Description>
                <h2 className='head-lg text-center pb-3'>Audit Trail</h2>
                    <ReactJsonViewCompare oldData={JSON.parse(props?.item?.old_object)??{}} newData={JSON.parse(props?.item?.new_object)??{}} />
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions className='border-none text-center bg-none mb-6'>
                <Button title="Close" onClick={() => { props.onClose(false) }}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
};
export default CompareAuditModal;