import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import BodyHeader from "../../components/layout/BodyHeader";
import { ButtonIcons, ButtonProps, ButtonTypes } from "../../components/common/Button";
import Layout from "../../components/layout";

// modals
import HelpModal from "../../components/modals/HelpModal";

// context
import { useUser } from "../../components/context/UserContext";

// redux actions
import { materialAction } from "../../redux/actions/materialActions";
import { APPS_AND_MODELS_PAGE_TITLE, layoutTitles } from "../../utils/constant";

// helpers

export default function InternalProcessesIndex() {
  const dispatch = useDispatch();

  // user context
  const { user } = useUser();

  // state
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  const headerButtons: ButtonProps[] = [
    {
      isIconButton: true,
      navigateTo: "/",
      icon: ButtonIcons.HOME,
      title: "Home",
      type: ButtonTypes.SECONDARY,
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.HELP,
      title: "Help",
      type: ButtonTypes.SECONDARY,
      onClick: () => setOpenHelp(true)
    }
  ];

  useEffect(() => {
    dispatch(materialAction(null));
  }, []);

  return (
    <Layout title={layoutTitles.modelsDashboard}>
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={"Apps & Models"}
        content={"Apps & Models"} />
      
      <BodyHeader title={APPS_AND_MODELS_PAGE_TITLE} showBackButton onBackClick={() => history.back()} buttons={headerButtons}/>
      <div className="theme-card admin-h">
        <div className="body">
          <div className="md-container-2">

            <div className="row justify-between btn-list">
              <div className="col-md-5">
                <a
                  href="http://lab-tbos-00225:5000"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="theme-btn brick w-full fs-28"
                  title="Click to view Apps & Models (Requires VPN)"
                >
                  SMPD-Lab
                </a>
              </div>

              <div className="col-md-5">
                <button
                  type="button"
                  className="theme-btn w-full brick fs-28"
                  onClick={() => null}
                >
                  Green Dashboard
                </button>
              </div>
            </div>

            <div className="row justify-between btn-list">
              <div className="col-md-5">
                <button
                  type="button"
                  onClick={() => null}
                  className="theme-btn brick w-full fs-28"
                >
                  Reaction
                </button>
              </div>

              <div className="col-md-5">
                <Link
                  to="/apps-and-models/crystallization"
                  className="theme-btn w-full brick fs-28"
                  title="Crystallization"
                >
                  Crystallization
                </Link>
              </div>
            </div>


            <div className="row justify-between btn-list">
              <div className="col-md-5">
                <button
                  type="button"
                  onClick={() => null}
                  className="theme-btn brick w-full fs-28"
                >
                  Dynochem
                </button>
              </div>

              <div className="col-md-5">
                <Link
                  to="/materials/material-solubility"
                  className="theme-btn w-full brick fs-28"
                  title="Solubility Models"
                >
                  Solubility Models
                </Link>
              </div>
            </div>


          </div>
        </div>
      </div>

    </Layout>
  )
    ;
};

