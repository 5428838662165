import { useEffect } from "react";
import { useForm, useFormContext } from "react-hook-form";

// components
import TextController from "../../form/TextController";
import SelectController from "../../form/SelectController";
import MultiFileUpload from "../../common/MultiFileUpload";

// helpers
import {
    filtrationFilterMaterial,
    filtrationTextFields,
    filtrationType,
    filtrationWorkingPressure,
    yesNoOptions,
} from "../../../utils/equipmentData";
import { setTitleNumberInput } from "../../../utils/common";
import { UPLOAD_FILE_MAXLENGTH } from "../../../utils";


const Filtration = (props: any) => {
    const methods = useFormContext();

    useEffect(() => {
        methods?.watch();
        // set default title on number input fields.
        setTitleNumberInput();
    });

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'drawing':
                methods.setValue('filtration.attachments.drawing', [
                    ...methods.control._formValues.filtration?.attachments?.drawing ?? [],
                    ...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                            return {
                                category: 'Drawing',
                                file: e?.target?.files?.[key],
                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                            }
                        })
                ], { shouldDirty: true, shouldTouch: true });
                break;
            case 'manufacturer_manual':
                methods.setValue('filtration.attachments.manufacturer_manual', [
                    ...methods.control._formValues.filtration?.attachments?.manufacturer_manual ?? [],
                    ...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                            return {
                                category: 'Manufacturer Manual',
                                file: e?.target?.files?.[key],
                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                            }
                        })
                ], { shouldDirty: true, shouldTouch: true });
                break;
            case 'sop':
                methods.setValue('filtration.attachments.sop', [
                    ...methods.control._formValues?.filtration?.attachments.sop ?? [],
                    ...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                            return {
                                category: 'SOP',
                                file: e?.target?.files?.[key],
                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                            }
                        })
                ], { shouldDirty: true, shouldTouch: true });
                break;
            case 'service_log':
                methods.setValue('filtration.attachments.service_log', [
                    ...methods.control._formValues.filtration?.attachments?.service_log ?? [],
                    ...Object.keys(e?.target?.files ?? []).map(
                        (key: any) => {
                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                            return {
                                category: 'Service Log',
                                file: e?.target?.files?.[key],
                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                            }
                        })
                ], { shouldDirty: true, shouldTouch: true });
                break;
            default:
            // default
        }
    }

    // Remove selected file
    const removeFile = (index: number, name: string) => {
        switch (name) {
            case 'drawing':
                methods.setValue('filtration.attachments.drawing', methods.control._formValues.filtration.attachments.drawing.filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
                break;
            case 'manufacturer_manual':
                methods.setValue('filtration.attachments.manufacturer_manual', methods.control._formValues.filtration.attachments.manufacturer_manual.filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
                break;
            case 'sop':
                methods.setValue('filtration.attachments.sop', methods.control._formValues.filtration.attachments.sop.filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
                break;
            case 'service_log':
                methods.setValue('filtration.attachments.service_log', methods.control._formValues.filtration.attachments.service_log.filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
                break;
            default:
            // default
        }
    }

    return (
        <>
            <div className="row">
                <SelectController
                    label="Type"
                    name="type"
                    page="filtration"
                    readOnly={props?.readOnly}
                    value={methods?.control._formValues?.filtration?.type ?? ''}
                    methods={methods}
                    options={filtrationType}
                />
                <SelectController
                    label="Filter Material"
                    name="filter_material"
                    page="filtration"
                    readOnly={props?.readOnly}
                    value={methods?.control._formValues?.filtration?.filter_material ?? ''}
                    methods={methods}
                    options={filtrationFilterMaterial}
                />

                {filtrationTextFields.map((item: any) => (
                    <TextController
                        name={`filtration.${item.value}`}
                        title={item ?.title ?? ''}
                        value={methods?.control._formValues?.[methods?.control._formValues?.equipment_type]?.[item.value] ?? ''}
                        label={item.label}
                        methods={methods}
                        required={item?.required ?? false}
                        type={item?.type ?? "text"}
                        readOnly={props?.readOnly ?? false}
                    />
                ))}

                <SelectController
                    label="Solvent Wash"
                    name="solvent_wash"
                    page="filtration"
                    readOnly={props?.readOnly}
                    value={methods?.control._formValues?.filtration?.solvent_wash ?? ''}
                    methods={methods}
                    options={yesNoOptions}
                />
                <SelectController
                    label="Working Pressure"
                    name="working_pressure"
                    page="filtration"
                    readOnly={props?.readOnly}
                    value={methods?.control._formValues?.filtration?.working_pressure ?? ''}
                    methods={methods}
                    options={filtrationWorkingPressure}
                />
                <SelectController
                    label="Filter Backwash"
                    name="filter_backwash"
                    page="filtration"
                    readOnly={props?.readOnly}
                    value={methods?.control._formValues?.filtration?.filter_backwash ?? ''}
                    methods={methods}
                    options={yesNoOptions}
                />
                <SelectController
                    label="Heated"
                    name="heated"
                    page="filtration"
                    readOnly={props?.readOnly}
                    value={methods?.control._formValues.filtration?.heated ?? ''}
                    methods={methods}
                    options={yesNoOptions}
                />
                <SelectController
                    label="Mixer"
                    name="mixer"
                    page="filtration"
                    readOnly={props?.readOnly}
                    value={methods?.control._formValues.filtration?.mixer ?? ''}
                    methods={methods}
                    options={yesNoOptions}
                />
                <SelectController
                    label="CIP"
                    name="cip"
                    title="Cleaning in Place"
                    page="filtration"
                    readOnly={props?.readOnly}
                    value={methods?.control._formValues.filtration?.cip ?? ''}
                    methods={methods}
                    options={yesNoOptions}
                />
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="Drawing"
                        name="drawing"
                        hoverText="CAD, Pictures"
                        handleFileChange={handleFileChange}
                        removeFile={removeFile}
                        readable={props?.readOnly}
                        downloadFile={props?.downloadFile}
                        attachments={props.attachments?.drawing ?? []}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="Manufacturer Manual"
                        name="manufacturer_manual"
                        handleFileChange={handleFileChange}
                        removeFile={removeFile}
                        readable={props?.readOnly}
                        downloadFile={props?.downloadFile}
                        attachments={props.attachments?.manufacturer_manual ?? []}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="SOP"
                        name="sop"
                        handleFileChange={handleFileChange}
                        removeFile={removeFile}
                        readable={props?.readOnly}
                        downloadFile={props?.downloadFile}
                        attachments={props.attachments?.sop ?? []}
                    />
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="Service Log"
                        name="service_log"
                        handleFileChange={handleFileChange}
                        removeFile={removeFile}
                        readable={props?.readOnly}
                        downloadFile={props?.downloadFile}
                        attachments={props.attachments?.service_log ?? []}
                    />
                </div>
            </div>
        </>
    );
};

export default Filtration;