import { BreadcrumbItem } from "../components/common/Breadcrumb";

export interface LayoutProps {
  children: React.ReactNode;
  title: string;
  breadCrumbItems?: BreadcrumbItem[];
}

export interface ResponseProps {
  code: number;
  status: string;
  message: string;
  data: any;
}

export interface UserProps {
  given_name: string;
  family_name: string;
  email: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

export interface FileProps {
  name: string;
  size: number;
  type: string;
  lastModifiedDate?: Date;
  lastModified: number;
  webkitRelativePath: string;
}

export interface FilesProps {
  category?: string;
  file?: FileProps;
  error?: boolean;
}
export interface UserInfoProps {
  name: string
}
export interface LoginProps {
  setCorsErrorModalOpen: (value: boolean) => void;
}

export interface AttachmentTypeProps {
  label: string;
  value: string;
}

export interface MultiFileUploadProps {
  title: string;
  hoverText?:string,
  name: string;
  acceptFormat?: string;
  readable?: boolean;
  attachments: any[];
  showDownload?: boolean;
  hasPermission?: boolean | undefined;
  hasAttachmentTypes?: boolean;
  attachmentsTypes?: AttachmentTypeProps[];
  selectedAttachement?: string;
  setSelectedAttachmentType?: (value: string) => void;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  removeFile: (index: number, name: string) => void;
  downloadFile?: (file: any) => void;
  multiple?: boolean;
  dataCy?: string
}

export interface MaterialData {
  page?:string,
  material_name: string,
  vendor_name: string,
  phase?: string,
  lot_number: string,
  abbreviation?: string,
  brand_name?: string,
  synonyms?: string,
  boiling_point?: number,
  cas_number?: number,
  chemical_formula?: string,
  incompatibility?: string,
  shelf_life?: number,
  melting_point?: number,
  price?: number,
  molecular_weight?: number,
  purity?: number,
  smiles?: string,
  manufacturing_number?: number,
  project_name?: string,
  process_name?: string,
  chemical_safety?: number,
  regulatory_filing_data?: string,
  attachments?: any,
  process_attachment?: any,
}

export enum SortOrder {
  ASC = "asc",
  DESC = 'desc'
}
