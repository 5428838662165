import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

// reducers
import { alertReducer } from './reducers/alertReducer';
import { authReducer } from './reducers/authReducer';
import { sidebarReducer } from './reducers/sidebarReducer';
import { materialReducer } from './reducers/materialReducer';
import { equipmentReducer } from './reducers/equipmentReducer';
import { userReducer } from './reducers/userReducer';

export const store = configureStore({
  reducer: {
    alert: alertReducer,
    auth: authReducer,
    user: userReducer,
    sidebar: sidebarReducer,
    material: materialReducer,
    equipment: equipmentReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
