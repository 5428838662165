import React from "react";

const NoDataComponent = () => {
  return (
    <div className="flex flex-col items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-10 w-10 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 6h16M4 10h16m-7 10h7"
        />
      </svg>
      <p className="text-gray-500 mt-2">No records found</p>
    </div>
  );
};

export default NoDataComponent;
