import addEquipmentImage from '../../../assets/materialHelp/add-new-equipment.png'

const NewEquipmentHelp = () => {
    return (
        <div className="instruction-modal">
            <div className="row ">
                <div className="col-md-4 ">
                    <div><img src={addEquipmentImage} alt=""/></div>
                </div>
                <div className='col-md-8'>
                    <p>
                        This page hosts a form dedicated to the addition of new equipments. Essential information
                        includes the Equipment Type, Equipment Owner and Equipment Tag Number.
                    </p>
                </div>

            </div>

        </div>
    )
}

export default NewEquipmentHelp
