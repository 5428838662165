import { Modal, Button, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormProvider, useForm } from 'react-hook-form';
import ExportFields from '../material/ExportFields';
import { VisibleModuleType, gasInitialFields, liquidInitialFields, materialInitialFields, solidInitialFields } from '../../utils/materialHelper';
import { MaterialVisualizationPropsType } from '../../pages/materials/visualize';
import { flattenArray } from '../../utils/common';

const initialData = {
    solid: solidInitialFields,
    material: materialInitialFields,
    liquid: liquidInitialFields,
    gas: gasInitialFields
}

const VisualizeMaterialModal = (props: any) => {

    const [loading, setLoading] = useState<boolean>(false);
    const methods = useForm({ defaultValues: initialData });

    const handleVisualization = () => {
      setLoading(true);
      setTimeout(() => {
        let materialArr: any = [];
        let phaseFields: any = [];
        let attachmentsArr: any = [];

        materialArr = methods?.control?._formValues?.material
          ? Object.keys(methods?.control?._formValues?.material) &&
            Object.keys(methods?.control?._formValues?.material).filter(
              (key: string) => {
                if (methods?.control?._formValues?.material[key] == true) {
                  return key;
                }
              }
            )
          : "";
        materialArr =
          materialArr &&
          materialArr?.filter((item: any) => item !== "selectAll");

        if (props.phase) {
          phaseFields = methods?.control?._formValues?.[
            props.phase.toLowerCase()
          ]
            ? Object.keys(
                methods?.control?._formValues?.[props.phase.toLowerCase()]
              ) &&
              Object.keys(
                methods?.control?._formValues?.[props.phase.toLowerCase()]
              ).map((key: string) => {
                let o: any = {};
                o[key] = Object.keys(
                  methods?.control?._formValues?.[props.phase.toLowerCase()][
                    key
                  ]
                ).filter((item: string) => {
                  if (
                    methods?.control?._formValues?.[props.phase.toLowerCase()][
                      key
                    ][item] == true
                  ) {
                    return item;
                  }
                });
                return o;
              })
            : [];
          phaseFields =
            phaseFields &&
            phaseFields?.filter((item: any) => item !== "selectAll");

          attachmentsArr = methods?.control?._formValues?.[
            props.phase.toLowerCase()
          ]?.attachments
            ? Object.keys(
                methods?.control?._formValues?.[props.phase.toLowerCase()]
                  ?.attachments
              ) &&
              Object.keys(
                methods?.control?._formValues?.[props.phase.toLowerCase()]
                  ?.attachments
              ).map((key: string) => {
                let o: any = {};
                o[key] = Object.keys(
                  methods?.control?._formValues?.[props.phase.toLowerCase()]
                    ?.attachments?.[key]
                ).filter((item: string) => {
                  if (
                    methods?.control?._formValues?.[props.phase.toLowerCase()]
                      ?.attachments?.[key][item] == true
                  ) {
                    return item;
                  }
                });
                return o;
              })
            : [];
        }

        const required_fields = flattenArray(
          phaseFields,
          props.phase.toLowerCase()
        );

        const required_attachments = flattenArray(
          attachmentsArr,
          "attachments"
        );

        if (attachmentsArr.length > 0) {
          required_fields.push("attachments");
        }

        let materialVisualizationProps: MaterialVisualizationPropsType = {
          materialIds: props.selectedRows,
          materialFields: materialArr,
          phase: props.phase,
          required_fields: required_fields,
          required_attachments: required_attachments,
        };
        localStorage.setItem(
          "materialVisualizationProps",
          JSON.stringify(materialVisualizationProps)
        );
        window.open("/materials/visualize", "_blank");
        setLoading(false);
      }, 10);
    };


    useEffect(() => {
        methods.watch();
    })

    return (
        <>
            <Modal
                open={props.open}
                size='large'
                dimmer='blurring'
                onClose={() => { methods.reset({}), props.setOpen(false) }}
            >
                <FormProvider {...methods}>
                    <form>
                        <Modal.Header align="center" className='border-none pb-0'>
                            <Icon name="times circle outline" onClick={() => { methods.reset({}), props.setOpen(false) }} color="grey" className='close-icon '></Icon>
                        </Modal.Header>
                        <Modal.Content scrolling>
                            <Modal.Description>
                                <Tabs>
                                    <div className='sticky-tab-list'>
                                        <p className='head-sm pt-3 text-left pl-4 pb-0 mb-0 pb-1'>Please select the fields you want to Visualize !</p>
                                        <div className="admin-tabs mb-6">
                                            <TabList className="inner mb-3 flex-wrap">
                                                <Tab>
                                                    <div className={`tab-link`}>Material</div>
                                                </Tab>
                                                <Tab>
                                                    <div className={`tab-link`}>{props.phase}</div>
                                                </Tab>
                                            </TabList>
                                        </div>
                                    </div>
                                    <TabPanel>
                                        <ExportFields type="material" isAttachmentsRequired={true} moduleType={VisibleModuleType.VISUALIZE} />
                                    </TabPanel>
                                    <TabPanel>
                                        <ExportFields type={props?.phase?.toLowerCase()} isAttachmentsRequired={true} moduleType={VisibleModuleType.VISUALIZE} />
                                    </TabPanel>

                                </Tabs>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions className='border-none text-center bg-none mb-6 pt-3'>
                            <Button title="Export" color='red' type='submit' disabled={loading ? true : false} onClick={handleVisualization}>
                                {loading ?
                                    <>
                                        <i className="fa-solid fa-circle-notch fa-spin"></i> Visualize
                                        <div className="theme-loader">
                                            <div className="loader-outer">
                                                <div className="loader"></div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        Visualize
                                    </>
                                }
                            </Button>
                            <Button title="Cancel" onClick={() => { methods.reset({}), props.setOpen(false) }}>
                                Cancel
                            </Button>
                        </Modal.Actions>
                    </form>
                </FormProvider>
            </Modal>
        </>
    )
};
export default VisualizeMaterialModal;