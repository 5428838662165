import Tool1Form from "./Tool1Form";
import MaxSolubillityTable from "../MaxSolubillityTable";
import ContourGraph from "../ContourGraph";
import React, { useState } from "react";
import CrystallizationService from "../../../../services/crystallization";
import CustomLoader from "../../../../components/common/CustomLoader";

export default function Tool1() {

  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState<any>(null);

  async function handleFormSubmit(data: any) {
    setLoading(true);
    try {
      const simulationResponse = await CrystallizationService.simulateTool1(
        {
          ...data,
          rho_solv: parseFloat(data.rho_solv),
          rho_antisolv: parseFloat(data.rho_antisolv),
          sheet_data: JSON.stringify(
            data.sheet_data.map(
              (obj: any) => Array.from(Object.values(obj)),
            ),
          ),
        },
      );
      const resData = await simulationResponse.data.body;
      setResponseData(resData);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  return <div className="grid grid-cols-12">
    {loading && <CustomLoader />}
    <div className="col-span-4">
      <Tool1Form onSubmit={handleFormSubmit} responseData={responseData} setResponseData={setResponseData} />
    </div>
    <div className="col-span-1"></div>
    <div className="col-span-7">
        <MaxSolubillityTable data={responseData?.solubility_data} />
      {
        // responseData &&
        <ContourGraph plot={responseData?.plot1} />
      }
    </div>
  </div>;
}
