import { ChangeEvent, HTMLInputTypeAttribute } from "react";
import { Control, Controller } from "react-hook-form";

import RenderIf from "./RenderIf";

interface TextFieldProps {
  control: Control<any>;
  label: string;
  fieldName: string;
  defaultValue?: string;
  errorMessage?: string;
  htmlFor?: string
  isRequired?: boolean;
  type?: HTMLInputTypeAttribute;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TextField = (props: TextFieldProps) => {
  const { control, label, onChange, fieldName, defaultValue, errorMessage = '', htmlFor, isRequired = false, type = "text" } = props;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === "function") {
      onChange(event);
    }
  }
  return (
    <div className="form-group">
      <label className={`ip-wrap ${isRequired ? "required" : ""}`} htmlFor={htmlFor}>
        <span className="form-label">{label}</span>
        <Controller
          name={fieldName}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => <input type={type} onInput={handleInputChange} className="theme-ip" {...field} />}
        />

        <RenderIf condition={errorMessage?.length > 0}>
          <p className="error">{errorMessage}</p>
        </RenderIf>
      </label>
    </div>
  )
};

export default TextField;
