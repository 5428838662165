import { materialConstants } from '../constants/materialConstants';

const initialState = {
  loading: false,
  items: [],
  solid:[],
  item: null,
};

export function materialReducer(state = initialState, action: any) {
  switch (action.type) {
    case materialConstants.MATERIALS:
      return {
        ...state,
        items: action.materials,
        loading: false,
      };
    case materialConstants.MATERIAL:
      return {
        ...state,
        item: action.material,
        loading: false,
      };
    case materialConstants.SOLID_MATERIAL:
      return {
        ...state,
        solid: action.material,
        loading: false,
      }
    case materialConstants.ADD_MATERIAL:
      return {
        ...state,
        items: [action.material, ...state.items],
        loading: false,
      };
    case materialConstants.UPDATE_MATERIAL: {
      const updatedMaterial = state.items.map((item: any) => {
        if (item.id === action.material.id) {
          item = action.material;
          return item;
        }
        return item;
      });

      return {
        ...state,
        items: updatedMaterial,
        loading: false,
      };
    }
    case materialConstants.DELETE_MATERIAL:
      return {
        ...state,
        items: state.items.filter((item: any) => item.id !== action.id),
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
}
