import { Control } from "react-hook-form";
import { HTMLInputTypeAttribute } from "react";

import { attachmentsTypes } from "../../utils/materialHelper";
import { FilesProps } from "../../types";
import { limitDigits } from "../../utils/common";
import { NUMBER_INPUT_MAXLENGTH } from "../../utils";
import CustomLoader from "../common/CustomLoader";
import Dropdown, { DropdownItem } from "../common/Dropdown";
import MultiFileUpload from "../common/MultiFileUpload";
import RenderIf from "../common/RenderIf";
import Tabs, { TabType } from "../common/Tabs";
import TextField from "../common/TextField";

export interface FormInputDetails {
  isRequired?: boolean;
  isDropdown?: boolean;
  options?: DropdownItem[];
  label: string;
  key: string;
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
}

interface MutateMaterialFormProps {
  isLoading: boolean;
  tabs: TabType[];
  control: Control<any>;
  materialFormOneData: FormInputDetails[];
  materialFormTwoData: FormInputDetails[];
  errors: Record<string, any>;
  handleFileChange: (e: any) => void;
  removeFile: (index: number, type: string) => void;
  documents: FilesProps[];
  attachments: FilesProps[];
  setSelectedAttachmentType: (type: string) => void;
}

const MutateMaterialForm = (props: MutateMaterialFormProps) => {
  const { isLoading, tabs, control, materialFormOneData, materialFormTwoData, errors, handleFileChange, attachments, documents, removeFile, setSelectedAttachmentType } = props;

  return (
    <div className="row">
      <div className="col-12">
        <div className="theme-card">
          {/* Stepper Tabs */}
          <div className="head">
            <Tabs tabs={tabs} />
          </div>

          <div className="body">
            {/* Form 1 */}
            <div className="row">
              <div className="col-lg-9">
                <div className="row">
                  {materialFormOneData.map((currentItem, index) => (
                    <div key={index} className={`${currentItem.key === "smiles" ? "col-lg-8 col-md-12" : "col-lg-4 col-md-6"}`}>
                      <RenderIf condition={!currentItem.isDropdown}>
                        <TextField
                          isRequired={Boolean(currentItem?.isRequired)}
                          label={currentItem.label}
                          control={control}
                          fieldName={currentItem.key}
                          type={currentItem.type || "text"}
                          onChange={currentItem.type === "number" ? (e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH) : undefined}
                          htmlFor={currentItem.key}
                          defaultValue={control._formValues?.[currentItem.key] ?? ""}
                          errorMessage={errors?.[currentItem.key]?.message?.toString() || ""}
                        />
                      </RenderIf>

                      <RenderIf condition={Boolean(currentItem.isDropdown)}>
                        <Dropdown
                          isRequired={Boolean(currentItem?.isRequired)}
                          label={currentItem.label}
                          control={control}
                          fieldName={currentItem.key}
                          htmlFor={currentItem.key}
                          defaultValue={control._formValues?.[currentItem.key] ?? ""}
                          errorMessage={errors?.[currentItem.key]?.message?.toString() || ""}
                          options={currentItem.options ?? []}
                          disabled={currentItem.disabled}
                        />
                      </RenderIf>
                    </div>
                  ))}
                </div>
              </div>

              {/* Attachments */}
              <div className="col-lg-3 col-md-6">
                <MultiFileUpload
                  title="Upload File"
                  name="uploadFiles"
                  hasAttachmentTypes={true}
                  attachmentsTypes={attachmentsTypes}
                  setSelectedAttachmentType={setSelectedAttachmentType}
                  handleFileChange={handleFileChange}
                  removeFile={removeFile}
                  attachments={attachments}
                />
              </div>
            </div>

            {/* Divider */}
            <div className="row">
              <div className="col-lg-9">
                <div className="divider" />
              </div>
            </div>

            {/* Form 2 */}
            <div className="row">
              <div className="col-lg-9">
                <div className="row">
                  {materialFormTwoData.map((currentItem, index) => (
                    <div key={index} className="col-lg-4 col-md-6">
                      <RenderIf condition={!currentItem.isDropdown}>
                        <TextField
                          isRequired={Boolean(currentItem?.isRequired)}
                          label={currentItem.label}
                          control={control}
                          fieldName={currentItem.key}
                          htmlFor={currentItem.key}
                          defaultValue={control._formValues?.[currentItem.key] ?? ""}
                          errorMessage={errors?.[currentItem.key]?.message?.toString() || ""}
                        />
                      </RenderIf>

                      <RenderIf condition={Boolean(currentItem.isDropdown)}>
                        <Dropdown
                          isRequired={Boolean(currentItem?.isRequired)}
                          label={currentItem.label}
                          control={control}
                          fieldName={currentItem.key}
                          htmlFor={currentItem.key}
                          defaultValue={control._formValues?.[currentItem.key] ?? ""}
                          errorMessage={errors?.[currentItem.key]?.message?.toString() || ""}
                          options={currentItem.options ?? []}
                        />
                      </RenderIf>
                    </div>
                  ))}
                </div>
              </div>

              {/* Process Attachments */}
              <div className="col-lg-3">
                <MultiFileUpload
                  title="Process Attachment"
                  name="processAttachments"
                  handleFileChange={handleFileChange}
                  removeFile={removeFile}
                  attachments={documents}
                />
              </div>
            </div>
          </div>

          {/* Loading Spinner */}
          <RenderIf condition={isLoading}>
            <CustomLoader />
          </RenderIf>
        </div>
      </div>
    </div>
  );
}

export default MutateMaterialForm;
