import { Modal, Button, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

const CancelConfirmModal = (props: any) => {
    return (
        <Modal
            open={props.open}
            //   trigger={<Button>Show Modal</Button>}
            size="tiny"
            dimmer='blurring'
            onClose={() => props.setOpen(false)}
        >
            <Modal.Header align="center" className='border-none '>
                <div className='mt-8'>
                    <Icon name='warning sign' color="yellow" size="big" />
                </div>
                <Icon name="times circle outline" onClick={() => props.setOpen(false)} color="grey" className='close-icon'></Icon>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <p className='head-lg text-center p-0'>Are you sure you want to close?</p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions className='border-none text-center bg-none mb-6'>
                <Button title="Save & Close" color='red' onClick={props.saveMaterial}>
                    Save & Close
                </Button>
                <Button title="Don't Save & Close"  basic color="red" onClick={props.dontSave}>
                    Don't Save & Close
                </Button>
                <Button title="Cancel" onClick={() => props.setOpen(false)}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    )
};
export default CancelConfirmModal;