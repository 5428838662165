import { useState } from "react";

import { sortByField } from "../../../../utils/common";
import { SortOrder } from "../../../../types";
import { TableHeadProps, TableHeadType } from "../../../../components/common/Table/TableHeader";
import { TableRowItemType } from "../../../../components/common/Table/TableRow";
import Table from "../../../../components/common/Table";

const SolubilityTable = (props: any) => {
  // init
  const { unchainedData } = props;
  const csvData = unchainedData.csv_data;

  // states
  const [sortBy, setSortBy] = useState<string>("y");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [csvDataSorted, setCSVDataSorted] = useState(csvData);

  const manageSort = (newSortBy: string) => {
    let newSortOrder: SortOrder = SortOrder.DESC;
    if (newSortBy === sortBy) {
      newSortOrder = sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    }
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
    setCSVDataSorted(
      sortByField(
        csvData,
        newSortBy,
        newSortOrder,
      ),
    );
  }

  const formatNumber = (value: number) => new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 4 }).format(value);

  const tableHeads: TableHeadProps[] = [
    { type: TableHeadType.SORTABLE, title: "Vial" },
    { type: TableHeadType.SORTABLE, title: "Temperature (C)" },
    { type: TableHeadType.SORTABLE, title: "Solvent", fieldName: "Solvent", sortBy, sortOrder, manageSort },
    { type: TableHeadType.SORTABLE, title: "Solubility (mg/ml)", fieldName: "Solubility", sortBy, sortOrder, manageSort },
  ]

  const tableData: TableRowItemType[][] = csvDataSorted.map((row: any) => {
    return [
      { value: row["Vial #"], style: "text-center" },
      { value: row["Temperature (°C)"] ?? row["Temperature (�C)"], style: "text-center" },
      { value: row["Solvent Name"], style: "text-center" },
      { value: row["Solubility (mg/ml)"] ? formatNumber(row["Solubility (mg/ml)"]) : '', style: "text-center" },
    ];
  });

  return (
    <div>
      <div className="table-outer model-3-table">
        <div className="table-responsive theme-table">
          <Table heads={tableHeads} data={tableData} />
        </div>
      </div>
    </div>
  );
}

export default SolubilityTable;
