import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./index.css";
import { getMaterialChains } from "../../../../services/materialChains";
import { RootState } from "../../../../redux/store";
import CustomLoader from "../../../../components/common/CustomLoader";
import ImageGrid from "./ImageGrid";
import SolubilityTable from "./SolubilityTable";
import UnchainedForm from "./UnchainedForm";
import RenderIf from "../../../../components/common/RenderIf";

const UnchainedLabs = (props: any) => {
  // init
  const { setSubmitFn } = props;
  const material = useSelector((state: RootState) => state.material);

  // states
  const [unchainedData, setUnchainedData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!material?.item) return;

    setUnchainedData(null);
    setLoading(true);

    getMaterialChains({
      uid: material?.item.uid,
      vendor_name: material?.item.vendor_name,
      material_name: material?.item.material_name,
      lot_number: material?.item.lot_number,
      material_id: material?.item.material_id,
    }).then(res => {
      if (res.data.body.saved && res.data.body.saved === "yes") {
        setUnchainedData(res.data.body);
      }
    }).finally(() => setLoading(false));

  }, [material?.item]);

  return (
    <div>
      <RenderIf condition={loading}>
        <CustomLoader />
      </RenderIf>

      <RenderIf condition={!loading}>
        <div className="grid grid-cols-12 gap-12">
          <div className="col-span-6 xl:col-span-3 lg-screen-col-2">
            <UnchainedForm
              setUnchainedData={setUnchainedData}
              unchainedData={unchainedData}
              setSubmitFn={setSubmitFn}
            />
          </div>

          <div className="col-span-6 xl:col-span-4 lg-screen-col-3">
            <RenderIf condition={unchainedData}>
              <SolubilityTable unchainedData={unchainedData} />
            </RenderIf>
          </div>

          <div className="col-span-12 xl:col-span-5 lg-screen-col-7">
            <div className="flex gap-4 flex-col min-h-full">
              <RenderIf condition={unchainedData}>
                <ImageGrid unchainedData={unchainedData} />
              </RenderIf>

              <div className="mt-12 pl-12 flex items-center">
                <div className="flex items-center mr-12">
                  <span className="text-2xl font-bold text-right mr-2">Material Name:</span>
                  <span className="text-2xl font-bold">{material?.item?.material_name}</span>
                </div>

                <div className="flex items-center">
                  <span className="text-2xl font-bold text-right mr-2">Project Name:</span>
                  <span className="text-2xl font-bold">{material?.item?.project_name}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RenderIf>
    </div>
  );
}

export default UnchainedLabs;
