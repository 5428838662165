import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useOktaAuth } from "@okta/okta-react";

// components
import Layout from "../../components/layout";
import HeaderRow from "../../components/layout/HeaderRow";

// modals
import ConfirmCloneModal from "../../components/modals/ConfirmCloneModal";
import HelpModal from "../../components/modals/HelpModal";

// Tabs
import {
  Material,
  Gas,
  Liquid,
  SolubilityInformation,
  SpectralInformation,
  EnvironmentalData,
  General,
  Aeration,
  Compressibility,
  Densities,
  DrugInformation,
  FlowEnergy,
  Attachments,
  Hygroscopicity,
  Morphologi,
  Permeability,
  PSD,
  SEM,
  ShearCell,
  ThermalAnalysis,
  ImpurityResiduals
} from "../../components/material/tabs";
import BodyHeader from "../../components/layout/BodyHeader";
import { ButtonIcons, ButtonProps, ButtonTypes } from "../../components/common/Button";
import AuditTrail from "../../components/common/AuditTrail";

// context
import { useUser } from "../../components/context/UserContext";

// props
import { RootState } from "../../redux/store";
import { FilesProps } from "../../types";

// redux actions
import { materialAction } from "../../redux/actions/materialActions";
import { alertCloseAction, alertOpenAction } from "../../redux/actions";

// helpers
import {
  aerationInitialData,
  compressibilityInitialData,
  densityInitialData,
  drugInfoInitialData,
  environmentInitialData,
  flowEnergyInitialData,
  formatMaterialFiles,
  gasInitialData,
  generalSolidInitialData,
  getSolubilityFileFields,
  hygroscopicityInitialData,
  impurityAndResidualsInitialData,
  initialMaterialData,
  liquidInitialData,
  materialSolubilityFileFields,
  morphologiInitialData,
  parseAttachments,
  permeabilityInitialData,
  psdInitialData,
  semInitialData,
  shearCellInitialData,
  solubilityInitialData,
  thermalInitialData,
} from "../../utils/materialHelper";
import { UPLOAD_FILE_MAXLENGTH } from "../../utils";
import { FindMaterialHelp } from "../../utils/helpContent";
import MaterialService from "../../services/materialService";
import LogService from "../../services/logService";
import FileService from "../../services/fileService";
import { apiRoutes } from "../../utils/apiRoutes";
import DeleteConfirmModal from "../../components/modals/deleteConfirmModal";
import { downloadFileFromURL } from "../../utils/downloadFile";
//import { useFormContext } from "react-hook-form";
import CustomLoader from "../../components/common/CustomLoader";
import { setTitleNumberInput } from "../../utils/common";
import UploadSolubilityData from "../../components/solubility/uploadSolubilityData";
import { useForm } from "react-hook-form";
import RenderIf from "../../components/common/RenderIf";
import AppTabs, { AppTabProps } from "../../components/common/AppTabs";
import { layoutTitles, MATERIAL_FIND_PAGE_TITLE } from "../../utils/constant";

interface filesByCategoryProps {
  solubility: FilesProps[] | [];
  spectralInformation: {
    atrIR: FilesProps[] | [];
    gcMS: FilesProps[] | [];
    ir: FilesProps[] | [];
    lcMS: FilesProps[] | [];
    msMS: FilesProps[] | [];
    nmr: FilesProps[] | [];
    raman: FilesProps[] | [];
  };
  environmental: FilesProps[] | [];
  attachments: {
    chemicalSafety: FilesProps[] | [];
    molecularStructure: FilesProps[] | [];
    purityHPLC: FilesProps[] | [];
    smiles: FilesProps[] | [];
    uspMonograph: FilesProps[] | [];
    othersDocuments: FilesProps[] | [];
    takedaProcess: FilesProps[] | [];
  };
}

const MaterialFind = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  //const methods = useFormContext();

  const material = useSelector((state: RootState) => state.material.item);

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : "";

  // Added for solubility attachment fields to handle attachments
  const [simulation, setSimulation] = useState<any>(null);
  // solubility
  useEffect(() => {
    if (simulation) resetUploadSolubility(simulation);
  }, [simulation]);

  // user context
  const { user } = useUser();

  // states
  const [loading, setLoading] = useState<boolean>(false);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [headerData, setHeaderData] = useState<any>();
  const [logs, setLogs] = useState<any>();
  const [openCloneModal, setOpenCloneModal] = useState<boolean>(false);
  const [cloneId, setCloneId] = useState<any>();
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  const [deletableId, setDeletableId] = useState<any>(null);
  const [confirmMessage, setConfirmMessage] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [materialDeleted, setMaterialDeleted] = useState<number>(0);
  const [downloadingFile, setDownloadingFile] = useState(false);

  const [filesByCategory, setFilesByCategory] = useState<filesByCategoryProps>({
    solubility: [],
    spectralInformation: {
      atrIR: [],
      gcMS: [],
      ir: [],
      lcMS: [],
      msMS: [],
      nmr: [],
      raman: [],
    },
    environmental: [],
    attachments: {
      chemicalSafety: [],
      molecularStructure: [],
      purityHPLC: [],
      smiles: [],
      uspMonograph: [],
      othersDocuments: [],
      takedaProcess: [],
    },
  });
  // Added for solubility attachment fields to handle attachments
  const initialData: any = {
    model: "",
    solvent: "",
    solvent1: "",
    solvent2: "",
    temperature: 5,
    solubility_value: "",
    file_path: "",
    turbidity_measured: "",
    visual: false,
    plot: false,
    material_name: material?.material_name ?? null,
    vendor_name: material?.vendor_name ?? null,
    lot_number: material?.lot_number ?? null,
    solubility: {
      main_solvent: material?.solubility?.main_solvent?.toString() ?? "",
      solubility_in_main_solvent:
        material?.solubility?.solubility_in_main_solvent?.toString() ?? "",
      main_solvent_temperature:
        material?.solubility?.main_solvent_temperature?.toString() ?? "",
      main_solvent_reference:
        material?.solubility?.main_solvent_reference?.toString() ?? "",
      second_solvent: material?.solubility?.second_solvent?.toString() ?? "",
      solubility_in_second_solvent:
        material?.solubility?.solubility_in_second_solvent?.toString() ?? "",
      second_solvent_temperature:
        material?.solubility?.second_solvent_temperature?.toString() ?? "",
      second_solvent_reference:
        material?.solubility?.second_solvent_reference?.toString() ?? "",
      attachments: material?.solubility?.attachments
        ? parseAttachments(material?.solubility?.attachments)
        : [],
    },
    // Added for solubility attachment fields to handle attachments
    attachments: {
      model3: [],
      solubility: {
        unchainedLab: [],
        hplc: [],
        crystal16: [],
        crystalline: [],
        gravimetryMethod: [],
        solubilityCurve1: [],
        solubilityCurve2: [],
        solubilityCurve3: [],
        solubilityCurve4: [],
        solubilityCurve5: [],
      },
    },
  };
  let allAttachments: any = {
    model3: [],
    solubility: {
      unchainedLab: [],
      hplc: [],
      crystal16: [],
      crystalline: [],
      gravimetryMethod: [],
      solubilityCurve1: [],
      solubilityCurve2: [],
      solubilityCurve3: [],
      solubilityCurve4: [],
      solubilityCurve5: [],
    },
  };

  const methods = useForm({ defaultValues: initialData });

  //form
  const handleHeaderData = (data: any) => {
    setLoading(data.loading);
    setHeaderData(data);
  };

  useEffect(() => {
    if (auth?.accessToken) {
      if (
        headerData?.material_name &&
        headerData.lot_number &&
        headerData?.lot_number
      ) {
        findMaterial();
      } else {
        setLogs([]);
      }
    }
  }, [
    headerData?.material_name,
    headerData?.vendor_name,
    headerData?.lot_number,
  ]);

  const getSaveSimulations = async (id: string) => {
    const res = await MaterialService.create(
      "/simulations/get-simulations-files-data",
      {
        id,
        uid: auth?.claims?.uid,
        simulation_type: "material",
      }
    );

    if (res?.data?.code == 200) {
      const result = res?.data;
      if (result.body && Object.keys(result.body).length) {
        setSimulation(result.body);
      }
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
      return false;
    }
  };

  const findMaterial = async () => {
    const payload = {
      // ...watchedFields,
      ...headerData,
      uid: `${auth?.claims?.uid}`,
    };
    // setLoading(true); // enable loading
    const res = await MaterialService.create(apiRoutes.FIND_MATERIAL, payload);
    // setLoading(true); // disable loading
    if (res?.data?.code === 200) {
      const result = res?.data;
      dispatch(materialAction(result.body));
      await getSaveSimulations(res?.data?.body?.id);

      await getLogs(result?.body?.id);
      filesByCategory.solubility = [];
      filesByCategory.environmental = [];
      filesByCategory.spectralInformation.atrIR = [];
      filesByCategory.spectralInformation.gcMS = [];
      filesByCategory.spectralInformation.ir = [];
      filesByCategory.spectralInformation.lcMS = [];
      filesByCategory.spectralInformation.msMS = [];
      filesByCategory.spectralInformation.nmr = [];
      filesByCategory.spectralInformation.raman = [];
      // solubility files
      if (result?.body?.solubility?.attachments?.length) {
        filesByCategory.solubility =
          result?.body?.solubility?.attachments ?? [];
      }

      // environment files
      if (result?.body?.environment?.attachments?.length) {
        filesByCategory.environmental =
          result?.body?.environment?.attachments ?? [];
      }

      // spectral information files
      if (result?.body?.spectralInfo?.attachments) {
        for (var item in result?.body?.spectralInfo?.attachments) {
          switch (item) {
            case "atrIR":
              filesByCategory.spectralInformation.atrIR =
                result?.body?.spectralInfo?.attachments[item];
              break;
            case "gcMS":
              filesByCategory.spectralInformation.gcMS =
                result?.body?.spectralInfo?.attachments[item];
              break;
            case "ir":
              filesByCategory.spectralInformation.ir =
                result?.body?.spectralInfo?.attachments[item];
              break;
            case "lcMS":
              filesByCategory.spectralInformation.lcMS =
                result?.body?.spectralInfo?.attachments[item];
              break;
            case "msMS":
              filesByCategory.spectralInformation.msMS =
                result?.body?.spectralInfo?.attachments[item];
              break;
            case "nmr":
              filesByCategory.spectralInformation.nmr =
                result?.body?.spectralInfo?.attachments[item];
              break;
            case "raman":
              filesByCategory.spectralInformation.raman =
                result?.body?.spectralInfo?.attachments[item];
              break;
            default:
            // default
          }
        }
      }

      setFilesByCategory({ ...filesByCategory });
      if (
        result?.body?.attachments &&
        Array.isArray(result?.body?.attachments) &&
        result?.body?.attachments?.length
      ) {
        const attachments = formatMaterialFiles(result?.body?.attachments);
        for (var attachment in attachments) {
          switch (attachment) {
            case "Solubility Curve":
              filesByCategory.solubility = attachments[attachment];
              break;
            case "XX Report":
              filesByCategory.environmental = attachments[attachment];
              break;
            case "ATR-IR":
              filesByCategory.spectralInformation.atrIR =
                attachments[attachment];
              break;
            case "GC-MS":
              filesByCategory.spectralInformation.gcMS =
                attachments[attachment];
              break;
            case "IR":
              filesByCategory.spectralInformation.ir = attachments[attachment];
              break;
            case "LC-MS":
              filesByCategory.spectralInformation.lcMS =
                attachments[attachment];
              break;
            case "MS-MS":
              filesByCategory.spectralInformation.msMS =
                attachments[attachment];
              break;
            case "NMR":
              filesByCategory.spectralInformation.nmr = attachments[attachment];
              break;
            case "Raman":
              filesByCategory.spectralInformation.raman =
                attachments[attachment];
              break;
            case "Molecular Structure":
              filesByCategory.attachments.molecularStructure =
                attachments[attachment];
              break;
            case "USP Monograph":
              filesByCategory.attachments.uspMonograph =
                attachments[attachment];
              break;
            case "Chemical Safety":
              filesByCategory.attachments.chemicalSafety =
                attachments[attachment];
              break;
            case "HPLC Method":
              filesByCategory.attachments.purityHPLC = attachments[attachment];
              break;
            case "SMILES":
              filesByCategory.attachments.smiles = attachments[attachment];
              break;
            case "Process Attachment":
              filesByCategory.attachments.takedaProcess =
                attachments[attachment];
              break;
            case "Other Documents":
              filesByCategory.attachments.othersDocuments =
                attachments[attachment];
              break;
            case "Others":
              filesByCategory.attachments.othersDocuments =
                attachments[attachment];
              break;
            default:
            // default
          }
        }
        setFilesByCategory({ ...filesByCategory });
      }
      dispatch(materialAction(result.body));
    }
  };

  const getLogs = async (id: number) => {
    const payload = {
      entity_type: "material",
      entity_id: id,
      uid: `${auth?.claims?.uid}`,
    };
    const res = await LogService.get("/logs/get", payload);
    if (res?.data?.code === 200) {
      setLogs(res?.data?.body ?? []);
    } else {
      setLogs([]);
    }
  };

  const editMaterial = (data: any) => {
    if (data?.id) {
      history.push({
        pathname: `/materials/${data?.id}/update`,
        state: `find`,
      });
      getSaveSimulations(data.id);
    } else {
      dispatch(alertOpenAction("Please select material first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const downloadFile = async (file: any) => {
    const res = await FileService.create("/files/download", {
      file: file.file_name,
    });
    if (res?.status === 200) {
      const fileRes = await fetch(res?.data?.body);
      const blob = await fileRes.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = file.display_name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      dispatch(alertOpenAction("File downloaded successfully.", "success"));
      setTimeout(() => dispatch(alertCloseAction()));
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const cloneMaterial = (data: any) => {
    if (data?.id) {
      setOpenCloneModal(true);
      setCloneId(data?.id);
    } else {
      dispatch(alertOpenAction("Please select material first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const hasWritePermission =
    user?.permissions?.updated?.material?.hasReadAndWrite ?? false;

  const deleteHandle = async () => {
    setOpen(false); // enable loading
    setLoading(true); // enable loading
    const payload = {
      id: deletableId,
      uid: `${auth?.claims?.uid}`,
    };
    const res = await MaterialService.deleteMaterial(payload);
    if (res?.data?.code === 200) {
      // methods.setValue("material_name", ""); // clear material_name field
      // methods.setValue("lot_number", ""); // clear material_name field
      //dispatch(materialAction(null)); // clear material object

      setMaterialDeleted(Date.now());
    }

    setLoading(false);
  };

  const deleteMaterial = (data: any) => {
    if (data?.id) {
      setDeletableId(data.id);
      setConfirmMessage(
        `Are you sure you want to delete material “${data.material_name}” with lot number “${data.lot_number}”?`
      );
      setOpen(true);
    } else {
      dispatch(alertOpenAction("Please select material first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  useEffect(() => {
    // @ts-ignore
    document
      .querySelector(".material-data")
      .querySelectorAll(".theme-ip")
      .forEach((element) => {
        const labelParent = element.closest("label");
        if (labelParent?.getAttribute("for")) {
          // @ts-ignore
          element.setAttribute("data-cy", labelParent.getAttribute("for"));
        }
      });
  }, [material]);
  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
    // params?.id ? setSavedData(checkSavedData(material?.solid)) : ''
  }, []);

  // *****************  Solubility Attachments fields code ******************  //
  // method trigger to download selected file.
  const downloadFileSolubilityAttachments = async (file: any) => {
    let attachments: any = getSolubilityFileFields(simulation);

    if (!file?.display_name) {
      file = attachments.find((attachment: any) => {
        if (
          attachment.category === file.category &&
          attachment.display_name === file.file.name
        ) {
          return file;
        }
      });
    }
    if (!file) return;
    setFileLoading(true); // enable loading
    const res = await FileService.create("/files/download", {
      file: file.file_name,
    });
    setFileLoading(false); // disable loading
    if (res?.status === 200) {
      const fileRes = await fetch(res?.data.body);
      const blob = await fileRes.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = file.display_name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      dispatch(alertOpenAction("File downloaded successfully.", "success"));
      setTimeout(() => dispatch(alertCloseAction()));
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };
  const parseFileSolubility = (items: any[]) => {
    return items.map((item: any) => ({
      category: item.category,
      file: {
        name: item.display_name,
        type: item.mime_type,
      },
      error: false,
      isUpload: true,
    }));
  };

  // // method trigger when for reset upload, SOLUBILITY
  const resetUploadSolubility = (simulation: any, override = {}) => {
    let data: any = { ...initialData, ...methods.control._formValues };
    data.vendor_name = simulation?.vendor_name ?? "";
    data.material_name = simulation?.material_name ?? "";
    data.lot_number = simulation?.lot_number ?? "";
    data.model = methods.control._formValues?.model
      ? methods.control._formValues?.model
      : simulation?.model ?? "";
    data.solvent = methods.control._formValues?.solvent
      ? methods.control._formValues?.solvent
      : simulation?.solvent ?? "";
    data.temperature = methods.control._formValues?.temperature
      ? methods.control._formValues?.temperature
      : simulation?.temperature ?? 5;
    data.turbidity_measured = methods.control._formValues?.turbidity_measured
      ? methods.control._formValues?.turbidity_measured
      : simulation?.turbidity_measured ?? 1017.16;
    data.solubility_value = methods.control._formValues?.solubility_value
      ? methods.control._formValues?.solubility_value
      : simulation?.solubility_value ?? "";
    // data.solvent1 = simulation?.solvent1 ?? "";
    // data.solvent2 = simulation?.solvent2 ?? "";
    // data.temperature = simulation?.temperature ?? 5;
    // data.solubility_value = simulation?.solubility_value ?? "";
    data.visual = methods.control._formValues?.visual ?? false;
    data.plot = methods.control._formValues?.plot ?? false;
    data.attachments = { ...allAttachments };

    let allSolubilityAttachmentNames: Array<string> = [];

    for (const attachment in allAttachments.solubility) {
      if (
        Object.prototype.hasOwnProperty.call(
          allAttachments.solubility,
          attachment
        )
      ) {
        allSolubilityAttachmentNames.push(attachment);
      }
    }

    for (const attachment of allSolubilityAttachmentNames) {
      data.attachments.solubility[attachment] = simulation?.[attachment]
        ? parseFileSolubility(simulation[attachment])
        : [];
    }

    if (data.model == "Model 3") {
      data.solubility_value = "";
      data.solvent = "";
      data.solvent1 = "";
      data.temperature = 5;
      data.turbidity_measured = "";
    }

    methods.reset({ ...data, ...override });
  };

  const solidTabs: AppTabProps[] = [
    {
      title: "#",
      component: (
        <General
          readable={true}
          initialData={generalSolidInitialData(material)}
          attachments={generalSolidInitialData(material)?.attachments}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
    {
      title: "Impurity & residuals",
      component: (
        <ImpurityResiduals
          readable={true}
          initialData={impurityAndResidualsInitialData(material)}
        />
      ),
    },
    {
      title: "Aeration",
      component: (
        <Aeration
          readable={true}
          initialData={aerationInitialData(material)}
          attachments={aerationInitialData(material)?.attachments}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
    {
      title: "Compressibility",
      component: (
        <Compressibility
          readable={true}
          initialData={compressibilityInitialData(material)}
          attachments={compressibilityInitialData(material)?.attachments}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
    {
      title: "Densities",
      component: (
        <Densities readable={true} initialData={densityInitialData(material)} />
      ),
    },
    {
      title: "Drug Info",
      component: (
        <DrugInformation
          readable={true}
          initialData={drugInfoInitialData(material)}
          attachments={drugInfoInitialData(material)?.attachments}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
    {
      title: "Flow Energy",
      component: (
        <FlowEnergy
          readable={true}
          initialData={flowEnergyInitialData(material)}
          attachments={flowEnergyInitialData(material)?.attachments}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
    {
      title: "Hygroscopicity",
      component: (
        <Hygroscopicity
          readable={true}
          initialData={hygroscopicityInitialData(material)}
          attachments={hygroscopicityInitialData(material)?.attachments}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
    {
      title: "Morphologi G3",
      component: (
        <Morphologi
          readable={true}
          initialData={morphologiInitialData(material)}
          attachments={morphologiInitialData(material)?.attachments}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
    {
      title: "Permeability",
      component: (
        <Permeability
          readable={true}
          initialData={permeabilityInitialData(material)}
          attachments={permeabilityInitialData(material)?.attachments}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
    {
      title: "PSD",
      component: (
        <PSD
          readable={true}
          initialData={psdInitialData(material)}
          attachments={psdInitialData(material)?.attachments}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
    {
      title: "SEM",
      component: (
        <SEM
          readable={true}
          initialData={semInitialData(material)}
          attachments={semInitialData(material)?.attachments}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
    {
      title: "Shear Cell",
      component: (
        <ShearCell
          readable={true}
          initialData={shearCellInitialData(material)}
          attachments={shearCellInitialData(material)?.attachments}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
    {
      title: "Thermal",
      component: (
        <ThermalAnalysis
          readable={true}
          initialData={thermalInitialData(material)}
          attachments={thermalInitialData(material)?.attachments}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
  ];

  const liquidTabs: AppTabProps[] = [
    {
      title: "Liquid Information",
      component: (
        <Liquid initialData={liquidInitialData(material)} readable={true} />
      ),
    },
  ];

  const gasTabs: AppTabProps[] = [
    {
      title: "Gas Information",
      component: <Gas initialData={gasInitialData(material)} readable={true} />,
    },
  ];

  const tabs: AppTabProps[] = [
    {
      title: "Material",
      component: (
        <Material readable={true} initialData={initialMaterialData(material)} />
      ),
    },
    {
      title: "Solubility",
      component: (
        <>
          <SolubilityInformation
            readable={true}
            initialData={solubilityInitialData(material)}
            attachments={filesByCategory.solubility}
            handleFileChange={undefined}
            removeFile={undefined}
            downloadFile={(file: any) => downloadFile(file)}
            isFromFindMaterial={true}
          />
          <div className="divider"></div>
          <UploadSolubilityData
            readable={true}
            loading={fileLoading}
            attachments={methods.control._formValues?.attachments?.solubility}
            handleFileChange={undefined}
            removeFile={undefined}
            downloadFile={downloadFileSolubilityAttachments}
          />
        </>
      ),
    },
    {
      title: "Spectral Information",
      component: (
        <SpectralInformation
          readable={true}
          attachments={filesByCategory.spectralInformation}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
    {
      title: "Environmental Data",
      component: (
        <EnvironmentalData
          readable={true}
          initialData={environmentInitialData(material)}
          attachments={filesByCategory.environmental}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
    {
      title: "Attachments",
      component: (
        <Attachments
          readable={true}
          attachments={filesByCategory.attachments}
          handleFileChange={undefined}
          removeFile={undefined}
          downloadFile={(file: any) => downloadFile(file)}
        />
      ),
    },
    {
      title: "Audit Trail",
      component: (
        <>
          {" "}
          <div className="flex w-full items-center justify-center">
            <button
              data-cy="create_audit_trail"
              className="ml-auto theme-btn sm"
              type="button"
              onClick={async () => {
                let res;
                if (material?.id) {
                  setDownloadingFile(true);
                  res = await MaterialService.downloadAuditTrail({
                    material_id: material.id,
                  });
                  if (res && res?.data?.body)
                    downloadFileFromURL(
                      res.data.body,
                      dispatch,
                      `${material.id}.pdf`
                    )
                      .then(() => setDownloadingFile(false))
                      .catch(() => setDownloadingFile(false));
                  else setDownloadingFile(false);
                }
              }}
            >
              Generate Audit Report
            </button>
          </div>
          <div className="find-tab-scroll-trail">
            <AuditTrail items={logs} readable={true} />
          </div>
        </>
      ),
    },
  ];

  const headerButtons: ButtonProps[] = [
    ...(hasWritePermission ? [
      {
        isIconButton: true,
        title: "Delete",
        onClick: () => deleteMaterial(material),
        icon: ButtonIcons.TRASH,
        dataCy: "delete-material-btn",
        type: ButtonTypes.SECONDARY
      },
      {
        isIconButton: true,
        title: "Edit",
        onClick: () => editMaterial(material),
        icon: ButtonIcons.EDIT,
        type: ButtonTypes.SECONDARY
      },
      {
        isIconButton: true,
        title: "Clone",
        onClick: () => cloneMaterial(material),
        icon: ButtonIcons.CLONE,
        type: ButtonTypes.SECONDARY
      },
    ] : []),
    {
      isIconButton: true,
      title: "Close",
      onClick: () => history.push("/materials"),
      icon: ButtonIcons.CROSS,
      type: ButtonTypes.SECONDARY
    },
    {
      isIconButton: true,
      title: "Help",
      onClick: () => setOpenHelp(true),
      icon: ButtonIcons.HELP,
      type: ButtonTypes.SECONDARY
    },
  ];

  return (
    <>
      {downloadingFile && <CustomLoader />}
      <Layout title={layoutTitles.findMaterial}>
        <ConfirmCloneModal
          open={openCloneModal}
          setOpen={setOpenCloneModal}
          type="Material"
          cloneId={cloneId}
          uid={auth?.claims?.uid ?? ""}
          operation="cloneFromFind"
        />
        <DeleteConfirmModal
          open={open}
          confirmMessage={confirmMessage}
          setOpen={setOpen}
          deleteHandle={deleteHandle}
        />
        <HelpModal
          open={openHelp}
          setOpen={setOpenHelp}
          title={FindMaterialHelp.title}
          content={FindMaterialHelp.content}
        />

        <BodyHeader
          title={MATERIAL_FIND_PAGE_TITLE}
          showBackButton
          onBackClick={() => history.goBack()}
          buttons={headerButtons}
        />

        <div className="row">
          <div className="col-12">
            <div className="theme-card">
              <div className="body">
                <form>
                  <HeaderRow
                    headerData={handleHeaderData}
                    user={user}
                    materialDeleted={materialDeleted}
                  />
                </form>
              </div>
            </div>
            {loading ? (
              <div className="theme-loader show">
                <div className="loader-outer">
                  <div className="loader"></div>
                </div>
              </div>
            ) : (
              <div className="theme-card material-data">
                <div className="body">
                  <AppTabs data={tabs} />
                  {/* divider */}
                  <div
                    className={material?.phase == "Solid" ? "divider" : "hide"}
                  ></div>

                  <RenderIf condition={material?.phase == "Gas"}>
                    <AppTabs data={gasTabs} />
                  </RenderIf>
                  <RenderIf condition={material?.phase == "Liquid"}>
                    <AppTabs data={liquidTabs} />
                  </RenderIf>
                  <RenderIf condition={material?.phase == "Solid"}>
                    <AppTabs data={solidTabs} />
                  </RenderIf>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default MaterialFind;
