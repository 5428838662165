import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Title,
  SubTitle
} from "chart.js";
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Legend, Tooltip, Title, SubTitle);

// format reference
// const data = {
//   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
//   datasets: [
//     {
//       label: "First dataset",
//       data: [33, 53, 85, 41, 44, 65],
//       fill: true,
//       backgroundColor: "rgba(75,192,192,0.2)",
//       borderColor: "rgba(75,192,192,1)"
//     },
//     {
//       label: "Second dataset",
//       data: [33, 25, 35, 51, 54, 76],
//       fill: false,
//       borderColor: "#742774"
//     }
//   ]
// };

const options: any = {
  // responsive: false,
  maintainAspectRatio : false,

  interaction: {
    mode: "index",
    intersect: false,
  },
  centerText: {
    display: true,
    text: `100%`
  },
  plugins: {
    title: {
      display: true,
      text: 'Solubility (mg/ml)',
      
      padding: {
        top: 10,
        bottom: 10
      }
    },
    subtitle: {
      display: true,
      text: 'Temperature (C)',
      position: "bottom",
      padding: {
        top: 10,
      }
    },
    legend: {
      display: true,
      position: "top",
      align: "center",
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      }
    },
    y: {
      grid: {
        display: true,
      }
    },
  },
};

const LineChart = ({ data }: any) => {
  return (
    <div className="chart-container">
      <Line
        data={data}
        options={options}
      />
    </div>
  );
};
  
export default LineChart;
