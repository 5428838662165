import { ReactNode } from "react";

import { TableHeadProps, TableHeadType } from "./TableHeader";
import RenderIf from "../RenderIf";
import TableCell from "./TableCell";

export interface TableRowItemType {
  onClick?: () => void;
  value?: any;
  style?: string;
  customElement?: ReactNode;
}

export interface TableRowProps {
  heads: TableHeadProps[];
  data: TableRowItemType[];
}

const TableRow = (props: TableRowProps) => {
  const { data, heads } = props;
  const expandingSectionIndex = heads.findIndex(h => h.type === TableHeadType.EXPAND)
  
  return (
    <>
      <tr>
        {data.map((cell, index) => (
          <TableCell key={index} head={heads[index]} {...cell} />
        ))}
      </tr>

      {/* Expanded Row */}
      <RenderIf condition={expandingSectionIndex !== -1 && Boolean(data?.[expandingSectionIndex]?.value)}>
        {data?.[expandingSectionIndex]?.customElement}
      </RenderIf>
    </>
  )
}

export default TableRow;
