import RenderIf from "./RenderIf";

interface TableActionButtonProps {
  hasWritePermission: boolean;
  onEditClick: (value: any) => void;
  onViewClick: (value: any) => void;
  onCloneClick: (value: any) => void;
}

const TableActionButtons = (props: TableActionButtonProps) => {
  const { hasWritePermission, onCloneClick, onEditClick, onViewClick } = props;
  
  return (
    <div className="tb-actions">
      <RenderIf condition={hasWritePermission}>
        <button type="button" className="icon-btn sm plain" title="Edit" onClick={onEditClick}>
          <i className="fa-solid fa-pen" />
        </button>
      </RenderIf>
      <button type="button" className="icon-btn sm plain" title="View" onClick={onViewClick}>
        <i className="fa-solid fa-eye" />
      </button>
      <RenderIf condition={hasWritePermission}>
        <button type="button" className="icon-btn sm plain" title="Clone" onClick={onCloneClick}>
          <i className="fa-solid fa-clone" />
        </button>
      </RenderIf>
    </div>
  )
}

export default TableActionButtons;
