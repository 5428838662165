import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

// services
import EquipmentService from "../../services/equipmentService";

// redux actions
import { alertCloseAction, alertOpenAction } from "../../redux/actions";

// helpers
import { EquipmentTypes } from "../../utils/equipmentData";
import { onlyUnique } from "../../utils/equipmentHelper";
import { apiRoutes } from "../../utils/apiRoutes";
import { sortItemsByKey } from "../../utils/common";

const EquipmentHeader = (props: any) => {  
  const dispatch = useDispatch();
  const methods = useFormContext();

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : "";

  // states
  const [loading, setLoading] = useState<boolean>(false);
  const [equipmentOwnersOptions, setEquipmentOwnersOptions] = useState<any[]>([]);
  const [tagNumberOptions, setTagNumberOptions] = useState<any[]>([]);

  useEffect(() => {
    const { equipment_type, equipment_owner, tag_number } = methods.control._formValues;
    if (equipment_type?.length && !equipment_owner?.length && !tag_number?.length) {
      getEquipmentOwners(equipment_type);
    } else if (equipment_type?.length && equipment_owner?.length && !tag_number?.length) {
      getTagNumbers(equipment_type, equipment_owner);
    } else if (equipment_type?.length && equipment_owner?.length && tag_number?.length) {
      props?.handleSelectedFields({
        loading,
        equipment_type,
        equipment_owner,
        tag_number,
      });
    }
  }, [
    methods.control._formValues.equipment_type,
    methods.control._formValues.equipment_owner,
    methods.control._formValues.tag_number,
]);

  // method to get equipment owners for selected equipment_type.
  const getEquipmentOwners = async (equipment_type: string) => {
    setEquipmentOwnersOptions([]);
    const payload = {
      equipment_type,
      uid: `${auth?.claims?.uid}`,
    };
    setLoading(true); // enable loading
    const res = await EquipmentService.create(apiRoutes.LIST_OWNERS_EQUIPMENT, payload);
    setLoading(false); // disable loading
    if (res?.data?.code == 200) {
      if (res?.data.body?.length) {
        //getting labels for values to display
        let arr: any = [];
        res?.data?.body.map((key: any) => {
          arr.push(key?.equipment_owner);
        });
        arr = arr.filter(onlyUnique); // To remove repetative names

        // let labels = labelValueArray(arr, "owners");
        // setEquipmentOwnersOptions(labels);
        setEquipmentOwnersOptions(
          sortItemsByKey(arr.map(( item: string[]) => ({ label: item, value: item} )), 'label'));
      } else {
        dispatch(alertOpenAction("No related equipments present.", "error"));
        setTimeout(() => dispatch(alertCloseAction()));
      }
    } else {
      dispatch(
        alertOpenAction("Unable to load the related Equiments.", "error")
      );
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  // method to get equipment tag numbers for selected equipment type and owner.
  const getTagNumbers = async (equipment_type: string, equipment_owner: string) => {
    setTagNumberOptions([]);
    const payload = {
      uid: `${auth?.claims?.uid}`,
      equipment_type,
      equipment_owner,
    };
    setLoading(true); // enable loading
    const res = await EquipmentService.create(apiRoutes.LIST_TAG_NUMBERS_EQUIPMENT, payload);
    setLoading(false); // disable loading
    if (res?.data?.code == 200) {
      if (res?.data?.body?.length) {
        setTagNumberOptions(sortItemsByKey(res?.data?.body??[], 'tag_number'));
      } else {
        dispatch(
          alertOpenAction("Unable to load the related Lot Numbers.", "error")
        );
        setTimeout(() => dispatch(alertCloseAction()));
      }
    } else {
      dispatch(
        alertOpenAction("Unable to load the related Lot Numbers.", "error")
      );
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  return (
    <div className="row">
      <div className="col-lg-4 col-md-6">
        <div className="form-group">
          <label className="ip-wrap" htmlFor="vendor">
            <span className="form-label">Type</span>
            <div
              className={`input-wrap ${props?.readOnly ? "" : "select-outer"}`}
            >
              {!props.readOnly ? (
                <Controller
                  name="equipment_type"
                  control={methods.control}
                  defaultValue={methods.control._formValues?.equipment_type??""}
                  render={({ field }) => (
                    <select className="theme-ip" {...field}>
                      <option value="">Select</option>
                      {EquipmentTypes?.map((type: any, index: number) => (
                        <option key={index} value={type?.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                  )}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">
                  {methods.control._formValues?.equipment_type.toString() ?? ""}
                </p>
              )}
            </div>
          </label>
        </div>
      </div>

      <div className="col-lg-4 col-md-6">
        <div className="form-group">
          <label className="ip-wrap" htmlFor="equipment_owner">
            <span className="form-label">Owner</span>
            <div
              className={`input-wrap ${props?.readOnly ? "" : "select-outer"}`}
            >
              {!props.readOnly ? (
                <Controller
                  name="equipment_owner"
                  control={methods.control}
                  defaultValue={methods.control._formValues?.equipment_owner ?? ""}
                  render={({ field }) => (
                    <select
                      className="theme-ip"
                      disabled={
                        equipmentOwnersOptions?.length &&
                        methods.control._formValues?.equipment_type
                          ? false
                          : true
                      }
                      {...field}
                    >
                      <option value="" disabled={true}> Select </option>
                      {equipmentOwnersOptions?.map(
                          (item: any, index: number) => (
                            <option
                              key={index}
                              value={item?.value}
                              selected={
                                methods.control._formValues?.equipment_owner === item?.value
                              }
                            >
                              {item?.label}
                            </option>
                          )
                        )}
                    </select>
                  )}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">
                  {methods.control._formValues?.equipment_owner.toString() ?? ""}
                </p>
              )}
            </div>
          </label>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="form-group ">
          <label className="ip-wrap" htmlFor="tag_number">
            <span className="form-label">Tag Number</span>
            <div
              className={`input-wrap ${props?.readOnly ? "" : "select-outer"}`}
            >
              {!props.readOnly ? (
                <Controller
                  name="tag_number"
                  control={methods.control}
                  render={({ field }) => (
                    <select
                      className="theme-ip"
                      disabled={
                        tagNumberOptions?.length &&
                        methods.control._formValues?.equipment_type &&
                        methods.control._formValues?.equipment_owner
                          ? false
                          : true
                      }
                      {...field}
                    >
                      <option value="" disabled={true}> Select </option>
                      {tagNumberOptions?.length &&
                        tagNumberOptions?.map((name: any, index: number) => (
                          <option
                            key={index}
                            value={name.tag_number}
                            selected={
                              methods.control._formValues?.tag_number === name?.tag_number
                            }
                          >
                            {name?.tag_number}
                          </option>
                        ))}
                    </select>
                  )}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">
                  {methods.control._formValues?.tag_number.toString() ?? ""}
                </p>
              )}
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default EquipmentHeader;
