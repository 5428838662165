import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';

// components
import CheckboxController from "../../components/form/FilterSidebar/CheckboxController";
import ToggleController from "../../components/form/FilterSidebar/ToggleController";
import SelectFilterController from "../../components/form/FilterSidebar/FilterSelectController";
import FilterInputController from "../../components/form/FilterSidebar/FilterInputController";

// props
import { OwnerProps } from "../../types/equipmentTypes";

// helpers
import {
    EquipmentTypes,
    EquipmentOwners,
    generalApplicationOptions,
    typeProperties,
} from "../../utils/equipmentData";
import { equipmentFormByTypes, equipmentFiltersData } from "../../utils/equipmentHelper";
import { sortItemsByKey } from "../../utils/common";

const initialData = {
    types_checkbox: false,
    owners_checkbox: false,
    application_scale_checkbox: false,
    types_properties_checkbox: false,
    // type_properties_options: false,
    equipment_types: [],
    equipment_owners: [],
    application_scale_options: [],
    vessel_checkbox: false,
    reactor_checkbox: false,
    filtration_checkbox: false,
    distillation_checkbox: false,
    evaporator_checkbox: false,
    mixer_checkbox: false,
    active: {},
}

const equipementTypes = EquipmentTypes.reduce((e: any, t: any) => {
    e[t.value] = t;
    return e;
}, {});

const getChildFields = (key: string, control: any) => {
    // const formFields = typeProperties[field.value];
    // const formFields = Object.keys(control._formValues?.active?.[key]).reduce((e: any[], t: any) => {
    //     e = [...e, control._formValues?.active?.[key]];
    //     return e;
    // }, [])??[];
    const formFields = typeProperties?.[key];
    return (
        <>
            {
                formFields?.map((item: any) => {
                    let disable: boolean = true;
                    // switch (field?.value) {
                    //     case ('vessel'): disable = control._formValues.vessel_checkbox;
                    //         break;
                    //     case ('reactor'): disable = control._formValues.reactor_checkbox;
                    //         break;
                    //     case ('filtration'): disable = control._formValues.filtration_checkbox;
                    //         break;
                    //     case ('distillation'): disable = control._formValues.distillation_checkbox;
                    //         break;
                    //     case ('evaporator'): disable = control._formValues.evaporator_checkbox;
                    //         break;
                    //     case ('mixer'): disable = control._formValues.mixer_checkbox;
                    //         break;
                    //     default: disable = control._formValues.vessel_checkbox;;
                    //         break;
                    // }
                    return (
                        <div className={`row xxs-row child ${disable ? '' : 'disabled hide'}`}>
                            <CheckboxController
                                name={`active.${key}.${item.value}`}
                                control={control}
                                label={item.label}
                                value={control._formValues?.active?.[key]?.[item.value]??false}
                            />
                            {
                                item.type == 'toggle' ? (
                                    <ToggleController
                                        control={control}
                                        name={`active.${key}.${item.value}_options`}
                                        checked={control._formValues?.active?.[key]?.[item.value + '_options']}
                                        // default_value={control._formValues?.[key]?.[item?.value] ?? false}
                                        // disabled={control._formValues?.[field.value]?.[item?.value] ? false : true}
                                        // option_name={`control._formValues?.${field.value}?.${item?.value}_value`}
                                        disabled={!control._formValues?.active?.[key]?.[item.value] ? true : false}
                                        option1={item?.option1}
                                        option2={item?.option2}
                                    />
                                ) : ''
                            }
                            {
                                item.type == 'input' ? (
                                    <FilterInputController
                                        control={control}
                                        name={`active.${key}.${item.value}_options.value`}
                                        operationName={`active.${key}.${item.value}_options.operation`}
                                        disabled={!control._formValues?.active?.[key]?.[item.value] ? true : false}
                                        // operation_name={`${field.value}.${item?.value}_options.operation`}
                                        // value={control._formValues?.[field.value][`${item?.value}_options`]}
                                        // value={item?.value}
                                        // default_value={control._formValues?.[field.value]?.[item?.value] ?? false}
                                    />
                                ) : ''
                            }
                            {
                                item.type == 'select' ? (
                                    <SelectFilterController
                                        control={control}
                                        name={`active.${key}.${item.value}_options`}
                                        value={control._formValues?.active?.[key]?.[item.value + '_options'] ?? ""}
                                        // value={`active.${key}.${item.value}_options`}
                                        // default_value={control._formValues?.[field.value]?.[item?.value] ?? false}
                                        disabled={!control._formValues?.active?.[key]?.[item.value] ? true : false}
                                        options={item?.options??[]}
                                    />
                                ) : ''
                            }
                        </div>
                    )
                })
            }
        </>
    )
}

const FilterSidebar = (props: any) => {
    const methods = useForm<any>({ defaultValues: initialData });
    const {handleReset} = props;

    const { control, watch, handleSubmit, reset } = methods;

    const [owners, setOwners] = useState<OwnerProps[]>(EquipmentOwners);

    useEffect(() => {
        watch(); 
    });

    useEffect(() => {
        if (props.user) {
          const { userType, owners } = props.user;
          if (userType.includes('external')) {
            // okta dashboard vendors for external users.
            setOwners(
                sortItemsByKey([...owners.map((owner: OwnerProps) => ({ label: owner, value: owner }))], 'label'));
          }
        }
    }, []);

    useEffect(() => {
        
        const newFilters = equipmentFiltersData(methods.control._formValues);
        if (methods.formState.isDirty) {
            methods.reset({ ...newFilters });
        }

        // const selectedTypesByKey = equipment_types?.length ? equipment_types.reduce((e: any, t: any) => {
        //     e[t.value] = t;
        //     return e;
        // }, {}):  {};

        // if (!types_properties_checkbox && equipment_types?.length) {
        //     const item: any = {};
        //     methods.reset({
        //         ...methods.control._formValues,
        //         distillation_checkbox: false,
        //         evaporator_checkbox: false,
        //         filtration_checkbox: false,
        //         mixer_checkbox: false,
        //         reactor_checkbox: false,
        //         vessel_checkbox: false,
        //         active: equipment_types.reduce((e: any, t: any) => {
        //             item[t.value] = formTypes[t.value];
        //             e = { ...e, ...item }
        //             return e;
        //         }, {}),
        //     });
        // } else if (!distillation_checkbox && selectedTypesByKey.hasOwnProperty('distillation')) {
        //     methods.reset({
        //         ...methods.control._formValues,
        //         active: {
        //             ...methods.control._formValues.active,
        //             distillation: formTypes.distillation,
        //         }
        //     });
        // } else if (!evaporator_checkbox && selectedTypesByKey.hasOwnProperty('evaporator')) {
        //     methods.reset({
        //         ...methods.control._formValues,
        //         active: {
        //             ...methods.control._formValues.active,
        //             evaporator: formTypes.evaporator,
        //         }
        //     });
        // } else if (!filtration_checkbox && selectedTypesByKey.hasOwnProperty('filtration')) {
        //     methods.reset({
        //         ...methods.control._formValues,
        //         active: {
        //             ...methods.control._formValues.active,
        //             filtration: formTypes.filtration,
        //         }
        //     });
        // } else if (!mixer_checkbox && selectedTypesByKey.hasOwnProperty('mixer')) {
        //     methods.reset({
        //         ...methods.control._formValues,
        //         active: {
        //             ...methods.control._formValues.active,
        //             mixer: formTypes.mixer,
        //         }
        //     });
        // } else if (!reactor_checkbox && selectedTypesByKey.hasOwnProperty('reactor')) {
        //     methods.reset({
        //         ...methods.control._formValues,
        //         active: {
        //             ...methods.control._formValues.active,
        //             reactor: formTypes.reactor,
        //         }
        //     });
        // } else if (!vessel_checkbox && selectedTypesByKey.hasOwnProperty('vessel')) {
        //     methods.reset({
        //         ...methods.control._formValues,
        //         active: {
        //             ...methods.control._formValues.active,
        //             vessel: formTypes.vessel,
        //         }
        //     });
        // }
    }, 
    // [
    //     methods.control._formValues?.types_checkbox,
    //     methods.control._formValues?.owners_checkbox,
    //     methods.control._formValues?.application_scale_checkbox,
    //     methods.control._formValues?.types_properties_checkbox,
    //     methods.control._formValues?.equipment_types,
    //     methods.control._formValues?.equipment_owners,
    //     methods.control._formValues?.application_scale_options,
    //     methods.control._formValues?.distillation_checkbox,
    //     methods.control._formValues?.evaporator_checkbox,
    //     methods.control._formValues?.filtration_checkbox,
    //     methods.control._formValues?.mixer_checkbox,
    //     methods.control._formValues?.reactor_checkbox,
    //     methods.control._formValues?.vessel_checkbox,
    //     methods.control._formValues?.active,
    // ]
    );

    // method to submit form
    const onSubmit = async (data: any) => {
        // let typeArr: any = [];
        // let ownersArr: any = [];
        // let applicationArr: any = [];
        // let typePropertiesObj: any = {};

        // data?.type ? data?.equipment_types && data?.equipment_types.forEach(function (item: any) {
        //     typeArr.push(item.value);
        // }) : '';

        // data?.owners ? data?.equipment_owners && data?.equipment_owners.forEach(function (item: any) {
        //     ownersArr.push(item.value);
        // }) : '';

        // data?.application_and_scale ? data?.application_scale_options && data?.application_scale_options.forEach(function (item: any) {
        //     applicationArr.push(item.value);
        // }) : '';

        // if (data?.type_properties) {
        //     typeArr.includes("vessel") ? typePropertiesObj['vessel'] = {
        //         total_volume: {
        //             operation: control._formValues?.vessel?.total_volume_options?.operation,
        //             value: (control._formValues?.vessel?.total_volume_options?.value)
        //         },
        //         id: {
        //             operation: control._formValues?.vessel?.id_options?.operation,
        //             value: (control._formValues?.vessel?.id_options?.value)
        //         },
        //         rated_pressure: {
        //             operation: control._formValues?.vessel?.rated_pressure_options?.operation,
        //             value: (control._formValues?.vessel?.rated_pressure_options?.value)
        //         },
        //     } ?? {} : '';
        //     typeArr.includes("reactor") ? typePropertiesObj['reactor'] = {
        //         total_volume: {
        //             operation: control._formValues?.reactor?.total_volume_options?.operation,
        //             value: (control._formValues?.reactor?.total_volume_options?.value)
        //         },
        //         id: {
        //             operation: control._formValues?.reactor?.id_options?.operation,
        //             value: (control._formValues?.reactor?.id_options?.value)
        //         },
        //         rated_pressure: {
        //             operation: control._formValues?.reactor?.rated_pressure_options?.operation,
        //             value: (control._formValues?.reactor?.rated_pressure_options?.value)
        //         },
        //         heat_exchanger: control._formValues?.reactor?.heat_exchanger_value ? 'Jacket' : 'Internal'
        //     } ?? {} : '';
        //     typeArr.includes("distillation") ? typePropertiesObj['distillation'] = {
        //         total_volume: {
        //             operation: control._formValues?.distillation?.total_volume_options?.operation,
        //             value: (control._formValues?.distillation?.total_volume_options?.value)
        //         },
        //         id: {
        //             operation: control._formValues?.distillation?.id_options?.operation,
        //             value: (control._formValues?.distillation?.id_options?.value)
        //         },
        //         packing_type: control._formValues?.distillation?.packing_type_value,
        //         tray_type: control._formValues?.distillation?.tray_type_value ? 'Sieve' : 'Valve',
        //         type: control._formValues?.distillation?.type_value,
        //     } ?? {} : '';
        //     typeArr.includes("filtration") ? typePropertiesObj['filtration'] = {
        //         mesh_size: {
        //             operation: control._formValues?.filtration?.mesh_size_options?.operation,
        //             value: (control._formValues?.filtration?.mesh_size_options?.value)
        //         },
        //         id: {
        //             operation: control._formValues?.filtration?.id_options?.operation,
        //             value: (control._formValues?.filtration?.id_options?.value)
        //         },
        //         liquid_volume_capacity: {
        //             operation: control._formValues?.filtration?.liquid_volume_capacity_options?.operation,
        //             value: (control._formValues?.filtration?.liquid_volume_capacity_options?.value)
        //         },
        //         solid_capacity: {
        //             operation: control._formValues?.filtration?.solid_capacity_options?.operation,
        //             value: (control._formValues?.filtration?.solid_capacity_options?.value)
        //         },
        //         type: control._formValues?.filtration?.type_value
        //     } ?? {} : '';
        //     typeArr.includes("evaporator") ? typePropertiesObj['evaporator'] = {
        //         total_volume: {
        //             operation: control._formValues?.evaporator?.total_volume_options?.operation,
        //             value: (control._formValues?.evaporator?.total_volume_options?.value)
        //         },
        //         pressure: {
        //             operation: control._formValues?.evaporator?.pressure_options?.operation,
        //             value: (control._formValues?.evaporator?.pressure_options?.value)
        //         },
        //         solvent_recovery: control._formValues?.evaporator?.solvent_recovery_value ? 'Yes' : 'No',
        //         type: control._formValues?.evaporator?.type_value,
        //     } ?? {} : '';
        //     typeArr.includes("mixer") ? typePropertiesObj['mixer'] = {
        //         mixer_diameter: {
        //             operation: control._formValues?.mixer?.mixer_diameter_options?.operation,
        //             value: (control._formValues?.mixer?.mixer_diameter_options?.value)
        //         },
        //         shaft_length: {
        //             operation: control._formValues?.mixer?.shaft_length_options?.operation,
        //             value: (control._formValues?.mixer?.shaft_length_options?.value)
        //         },
        //         type: control._formValues?.mixer?.type_value,
        //     } ?? {} : '';
        // }

        // //delete unwanted keys
        // typePropertiesObj?.vessel ? delete typePropertiesObj.vessel.heat_exchanger_value : '';
        // typePropertiesObj?.reactor ? delete typePropertiesObj.reactor.heat_exchanger_value : '';
        // typePropertiesObj?.distillation ? delete typePropertiesObj.distillation.packing_type_value : '';
        // typePropertiesObj?.distillation ? delete typePropertiesObj.distillation.tray_type_value : '';
        // typePropertiesObj?.distillation ? delete typePropertiesObj.distillation.type_value : '';
        // typePropertiesObj?.filtration ? delete typePropertiesObj.filtration.type_value : '';
        // typePropertiesObj?.evaporator ? delete typePropertiesObj.evaporator.solvent_recovery_value : '';
        // typePropertiesObj?.evaporator ? delete typePropertiesObj.evaporator.type_value : '';
        // typePropertiesObj?.mixer ? delete typePropertiesObj.mixer.type_value : '';

        // let filters = {
        //     types: data?.type ? typeArr : [],
        //     owners: data?.owners ? ownersArr : [],
        //     application_and_scale: data?.application_and_scale ? applicationArr : [],
        //     typeProperties: data?.type_properties ? typePropertiesObj : {},
        // }

        let selectedItems: any = {};
        if (data?.types_properties_checkbox) {
            Object.keys(control._formValues?.active).forEach((key: string) => {
                const fields = control._formValues?.active?.[key];
                let newFields: any = {}
                for (var field in fields) {
                    if (fields.hasOwnProperty(`${field}_options`)) {
                        if ((
                            typeof fields[`${field}_options`] === 'object' && fields[`${field}_options`]?.operation && fields[`${field}_options`]?.value) || (
                            typeof fields[`${field}_options`] === 'string' && fields[`${field}_options`]?.length)
                        ) {                            
                           if(control._formValues.active?.[key]?.[field]){
                            newFields[field] = fields[`${field}_options`];
                           }
                        }
                        // else if (typeof fields[`${field}_options`] === 'string' && fields[`${field}_options`]?.length) {
                        //     newFields[field] = fields[`${field}_options`];
                        // }
                    }
                }
                selectedItems[key] = newFields;
            });
        }
        const filters = {
            types: data?.types_checkbox ? control._formValues?.equipment_types?.map((item: any) => item.value) : [],
            owners: data?.owners_checkbox ? control._formValues?.equipment_owners?.map((item: any) => item.value) : [],
            application_and_scale: data?.application_scale_checkbox ? control._formValues?.application_scale_options?.map((item: any) => item.value) : [],
            typeProperties: selectedItems,
        }
        props.filters(filters);
    }

    // method to reset filters
    const onReset = () => {
        handleReset();
        reset(initialData);
        props.filters({
            type: [],
            owners: [],
        });
    }

    console.log("Form Data : ", methods.control._formValues);

    return (
        <form onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
            <div className="filter-sidebar">
                <div className="filter-block">
                    <div className="parent">
                        <label className="custom-checkbox">
                            <Controller
                                name="types_checkbox"
                                control={control}
                                defaultValue={control?._formValues?.type ?? false}
                                render={({ field: { onBlur, onChange, value } }) => (
                                    <input type="checkbox" className="ip"
                                        onBlur={onBlur} // notify when input is touched
                                        onChange={onChange} // send value to hook form
                                        checked={value}
                                    />
                                )}
                            />
                            <span className="check"></span>
                            <span className="txt ">Equipment Type</span>
                        </label>
                    </div>
                    <div className={`child ${control?._formValues?.types_checkbox ? '' : 'disabled'}`} >
                        <label className="ip-wrap mb-2" htmlFor="attachment_type">
                            <div className="input-wrap">
                                <Controller
                                    name="equipment_types"
                                    control={control}
                                    defaultValue={control._formValues?.equipment_types??[]}
                                    render={({ field }) =>
                                        <Select
                                            styles={{
                                                control: (base: any, state) => ({
                                                    ...base,
                                                    '&:hover': { borderColor: 'red' }, // border style on hover
                                                    border: '1px solid lightgray', // default border color
                                                    boxShadow: 'none', // no box-shadow
                                                }),
                                            }}
                                            value={control._formValues.equipment_types??[]}
                                            // {...field}
                                            onChange={(arr: any) => {
                                                field.onChange(arr);
                                                const equipmentTypesCheckboxes: any = {
                                                    vessel_checkbox: false,
                                                    reactor_checkbox: false,
                                                    filtration_checkbox: false,
                                                    distillation_checkbox: false,
                                                    evaporator_checkbox: false,
                                                    mixer_checkbox: false,
                                                }
                                                arr.forEach((item: any) => {
                                                    equipmentTypesCheckboxes[`${item.value}_checkbox`] = methods.control._formValues?.[`${item.value}_checkbox`] ?? false;
                                                });
                                                methods.reset({
                                                    ...methods.control._formValues,
                                                    ...equipmentTypesCheckboxes,
                                                    ...{ active: arr.reduce((e: any[], t: any) => {
                                                        let o: any = { ...equipmentFormByTypes[t.value] };
                                                        if (control._formValues?.active?.[t.value]) {
                                                            o = {
                                                                ...o,
                                                                ...control._formValues?.active?.[t.value]
                                                            };
                                                        }
                                                        e[t.value] = o;
                                                        // e[t.value] = {
                                                        //     ...formTypes[t.value],
                                                        //     ...control._formValues?.active?.[t.value]??{}
                                                        // };
                                                        return e;
                                                    }, {})}
                                                });
                                                // methods.reset({
                                                //     ...methods.control._formValues.active,
                                                //     ...{ active: arr.reduce((e: any[], t: any) => {
                                                //     e[t.value] = formTypes[t.value];
                                                //     return e;
                                                // }, {})}});
                                            }}
                                            // value={control?._formValues?.equipment_types ?? []}
                                            // name="colors"
                                            options={EquipmentTypes}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isMulti
                                        />
                                    }
                                />
                            </div>
                        </label>
                    </div>
                </div>

                <div className="filter-block">
                    <div className="parent">
                        <label className="custom-checkbox">
                            <Controller
                                name="owners_checkbox"
                                control={control}
                                defaultValue={false}
                                render={({ field: { onBlur, onChange, value } }) => (
                                    <input type="checkbox" className="ip"
                                        onBlur={onBlur} // notify when input is touched
                                        onChange={onChange} // send value to hook form
                                        checked={value}
                                    />
                                )}
                            />
                            <span className="check"></span>
                            <span className="txt">Equipment Owners</span>
                        </label>
                    </div>
                    <div className={`child ${control._formValues?.owners_checkbox ? '' : 'disabled'}`} >
                        <label className="ip-wrap mb-2" htmlFor="attachment_type">
                            <div className="input-wrap">
                                <Controller
                                    name="equipment_owners"
                                    control={control}
                                    render={({ field }) =>
                                        <Select
                                            styles={{
                                                control: (base: any, state) => ({
                                                    ...base,
                                                    '&:hover': { borderColor: 'red' }, // border style on hover
                                                    border: '1px solid lightgray', // default border color
                                                    boxShadow: 'none', // no box-shadow
                                                }),
                                            }}
                                            {...field}
                                            value={control?._formValues?.equipment_owners ?? []}
                                            isMulti
                                            name="colors"
                                            options={owners}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    }
                                />
                            </div>
                        </label>
                    </div>
                </div>

                <div className="filter-block">
                    <div className="parent">
                        <label className="custom-checkbox">
                            <Controller
                                name="application_scale_checkbox"
                                control={control}
                                defaultValue={false}
                                render={({ field: { onBlur, onChange, value } }) => (
                                    <input type="checkbox" className="ip"
                                        onBlur={onBlur} // notify when input is touched
                                        onChange={onChange} // send value to hook form
                                        checked={value}
                                    />
                                )}
                            />
                            <span className="check"></span>
                            <span className="txt">Application/Scale</span>
                        </label>
                    </div>
                    <div className={`child ${control._formValues?.application_scale_checkbox ? '' : 'disabled'}`} >
                        <label className="ip-wrap mb-2" htmlFor="attachment_type">
                            <div className="input-wrap">
                                <Controller
                                    name="application_scale_options"
                                    control={control}
                                    render={({ field }) =>
                                        <Select
                                            styles={{
                                                control: (base: any, state) => ({
                                                    ...base,
                                                    '&:hover': { borderColor: 'red' }, // border style on hover
                                                    border: '1px solid lightgray', // default border color
                                                    boxShadow: 'none', // no box-shadow
                                                }),
                                            }}
                                            {...field}
                                            value={control?._formValues?.application_scale_options ?? []}
                                            isMulti
                                            name="colors"
                                            options={generalApplicationOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    }
                                />
                            </div>
                        </label>
                    </div>
                </div>

                <div className="filter-block">
                    <div className="parent">
                        <label className="custom-checkbox">
                            <Controller
                                name="types_properties_checkbox"
                                control={control}
                                defaultValue={false}
                                render={({ field: { onBlur, onChange, value } }) => (
                                    <input type="checkbox" className="ip"
                                        onBlur={onBlur} // notify when input is touched
                                        onChange={onChange} // send value to hook form
                                        checked={value}
                                    />
                                )}
                            />
                            <span className="check"></span>
                            <span className="txt">Equipment Type Properties</span>
                        </label>
                    </div>

                    <div className={`child ${control._formValues?.types_properties_checkbox ? '' : 'disabled'}`} >
                        <div>
                            {
                                Object.keys(control._formValues?.active)?.map((key: string, index: number) => (
                                    <div key={index}>
                                        <label className="custom-checkbox">
                                            <Controller
                                                name={`${key}_checkbox`}
                                                control={control}
                                                defaultValue={false}
                                                render={({ field: { onBlur, onChange } }) => (
                                                    <input type="checkbox" className="ip" name={props?.name}
                                                        onBlur={onBlur} // notify when input is touched
                                                        onChange={onChange} // send value to hook form
                                                        checked={control._formValues?.[`${key}_checkbox`] ?? false}
                                                    />
                                                )}
                                            />
                                            {/* <input className="ip" type="checkbox" {...field} value={type?.value} /> */}
                                            <span className="check"></span>
                                            <span className="txt">{equipementTypes?.[key]?.label??""}</span>
                                        </label>
                                        <div className="child">
                                            {control._formValues.types_properties_checkbox && control._formValues[`${key}_checkbox`] && getChildFields(key, control)}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-4">
                <div className="col-6">
                    <button title="Filter" type="submit" className="theme-btn sm w-full">
                        Filter
                    </button>
                </div>
                <div className="col-6">
                    <button title="Reset" type="reset" className="theme-btn sm alter w-full">
                        Reset
                    </button>
                </div>
            </div>
        </form >
    );
};

export default FilterSidebar;