import React from 'react';
import { Link } from 'react-router-dom';

import './Breadcrumb.scss';

export interface BreadcrumbItem {
  label: string;
  path: string;
  onClick?: () => void;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {

  return (
    <nav className="breadcrumb-nav">
      <ol className="breadcrumb">
        {items.map((item, index) => (
          <li key={index} className={`breadcrumb-item ${index === items.length - 1 ? 'active' : ''}`}>
            {index === items.length - 1 ? (
              item.label
            ) : (
              <Link
                to={item.path}
                onClick={() => {
                  if (typeof item.onClick === 'function') {
                    item.onClick();
                  }
                }}
              >{item.label}</Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
