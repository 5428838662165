import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// components
import Button, { ButtonIcons, ButtonProps, ButtonTypes } from "../../components/common/Button";
import BodyHeader from "../../components/layout/BodyHeader";
import Layout from "../../components/layout";
import RenderIf from "../../components/common/RenderIf";

// modals
import HelpModal from "../../components/modals/HelpModal";

// context
import { useUser } from "../../components/context/UserContext";

// redux actions
import { materialAction } from "../../redux/actions/materialActions";

// helpers
import { MaterialDashboardHelp } from "../../utils/helpContent";
import { layoutTitles, MATERIAL_DASHBOARD_PAGE_TITLE } from "../../utils/constant";

const MaterialIndex = () => {
  const dispatch = useDispatch();

  // user context
  const { user } = useUser();

  // states
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  // permissions
  const hasReadPermission = user?.permissions?.updated?.material?.hasRead ?? false;
  const hasWritePermission = user?.permissions?.updated?.material?.hasReadAndWrite ?? false;

  // Header Buttons
  const headerButtons: ButtonProps[]  = [
    {
      isIconButton: true,
      navigateTo: "/",
      icon: ButtonIcons.HOME,
      title: "Home",
      type: ButtonTypes.SECONDARY,
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.HELP,
      title: "Help",
      type: ButtonTypes.SECONDARY,
      onClick: () => setOpenHelp(true)
    }
  ];

  // reset material action
  const resetMaterialAction = () => {
    dispatch(materialAction(null));
  };

  // reset material action on component mount
  useEffect(resetMaterialAction, []);

  return (
    <Layout title={layoutTitles.materialsDashboard}>
      {/* Header */}
      <BodyHeader showBackButton onBackClick={() => history.back()} title={MATERIAL_DASHBOARD_PAGE_TITLE} buttons={headerButtons} />

      {/* Body */}
      <div className="theme-card admin-h">
        <div className="body">
          <div className="md-container-2">
            <div className={`row btn-list ${hasWritePermission ? "justify-between" : "justify-center"}`}>
              <RenderIf condition={hasWritePermission}>
                <div className="col-md-5">
                  <Button
                    navigateTo="/materials/new"
                    title="Add New Material"
                    onClick={resetMaterialAction}
                  />
                </div>
              </RenderIf>

              <RenderIf condition={hasReadPermission}>
                <div className="col-md-5">
                  <Button
                    navigateTo="/materials/find"
                    title="Find Material"
                    onClick={resetMaterialAction}
                  />
                </div>
              </RenderIf>
            </div>

            <RenderIf condition={hasReadPermission}>
              <div className="row btn-list justify-center">
                <div className="col-md-5">
                  <Button
                    navigateTo="/materials/list"
                    title="Material Screening"
                    onClick={resetMaterialAction}
                  />
                </div>

                {/* <div className="col-md-5">
                  <Button
                    navigateTo="/materials/material-solubility"
                    title="Solubility"
                    onClick={resetMaterialAction}
                  />
                </div> */}
              </div>
            </RenderIf>
          </div>
        </div>
      </div>

      {/* Modals */}
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={MaterialDashboardHelp.title}
        content={MaterialDashboardHelp.content}
      />
    </Layout>
  );
};

// export default PermissionsWrapper(MaterialIndex);
export default MaterialIndex;
