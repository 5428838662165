import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Table from './Table';
import ContourGraph from '../ContourGraph';
import { Button, Icon, Loader, Modal } from 'semantic-ui-react';
import SolventListModal from './SolventListModal';
import { useUser } from '../../../../components/context/UserContext';
import { VendorProps } from '../../../../types/materialsTypes';
import { vendorNames } from '../../../../utils/materialHelper';
import { sortItemsByKey, uniqueItemsByKey } from '../../../../utils/common';
import { useOktaAuth } from '@okta/okta-react';
import MaterialService from '../../../../services/materialService';
import { apiRoutes } from '../../../../utils/apiRoutes';
import { alertCloseAction, alertOpenAction } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';
import CustomLoader from '../../../../components/common/CustomLoader';

const Cosmo = () => {

  const [uploadedFile, setUploadedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendors, setVendors] = useState<VendorProps[]>(vendorNames);
  const [lotNumberOptions, setLotNumberOptions] = useState<any[]>([]);
  const [materialNameOptions, setMaterialNameOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [vendorName, setVendorName] = useState<string>("");
  const [material, setMaterial] = useState<string>("");
  const [lotNumber, setLotNumber] = useState<string>("");


  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : '';


  // method to return material list based on selected vendor_name field.
  const getMaterialNames = async (vendor_name: string) => {
    const payload = {
      uid: `${auth?.claims?.uid}`,
      vendor_name,
    };
    setLoading(true); // enable loading
    const res = await MaterialService.create(apiRoutes.LIST_MATERIAL_NAMES_MATERIAL, payload);
    if (res?.data?.code == 200) {
      if (res?.data?.body?.length) {
        const materials = sortItemsByKey(uniqueItemsByKey(res?.data?.body, 'material_name'), 'material_name')
        setMaterialNameOptions(materials);
      } else {
        setMaterialNameOptions([]);
        setMaterial("");
        setLotNumber("");
        setLotNumberOptions([]);
      }
    } else {
      setMaterialNameOptions([]);
      setMaterial("");
      setLotNumber("");
      setLotNumberOptions([]);
      dispatch(
        alertOpenAction("No related materials present.", "error")
      );
      setTimeout(() => dispatch(alertCloseAction()));
    };
    setLoading(false);
  };

  // method to return lot number based on selected vendor_name and material_name fields.
  const getLotNumbers = async () => {
    const payload = {
      uid: `${auth?.claims?.uid}`,
      material_name: material,
      vendor_name: vendorName,
    };
    setLoading(true); // enable loading
    const res = await MaterialService.create(apiRoutes.LIST_LOT_NUMBERS_MATERIAL, payload);
    if (res?.data?.code === 200) {
      if (res?.data?.body?.length) {
        const lotnumbers = sortItemsByKey(uniqueItemsByKey(res?.data?.body, 'lot_number'), 'lot_number');
        setLotNumberOptions(lotnumbers);
        setLotNumber(lotnumbers[0].lot_number)
      } else {
        setLotNumberOptions([]);
        setLotNumber("");
      }
    } else {
      setLotNumberOptions([]);
      setLotNumber("");
      dispatch(alertOpenAction('Unable to load the related Lot Numbers.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
    };
    setLoading(false); // disable loading

  };

  useEffect(() => {
    if (!!vendorName)
      getMaterialNames(vendorName);
  }, [vendorName]);

  useEffect(() => {
    if (!!vendorName && !!material)
      getLotNumbers();
  }, [material]);

  // Function to toggle the modal
  const toggleModal = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    setUploadedFile(file ? file.name : null);
  };

  return (
    <div className="">
      {loading && <CustomLoader />}
      <SolventListModal isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6 p-4 rounded-lg shadow-sm">
        <div className="w-full">
          <div className="form-group">
            <label className="ip-wrap required" htmlFor="vendor">
              <span className="form-label">Vendor</span>
              <div className="input-wrap select-outer">
                <select className="theme-ip" onChange={(e) => { setVendorName(e.target.value) }} value={vendorName} >
                  <option value="" disabled={true}>Select</option>
                  {vendors?.map((vendor: VendorProps, index: number) => (
                    <option key={index} value={vendor?.value}>{vendor.label}</option>
                  ))}
                </select>
              </div>
            </label>
          </div>
        </div>
        <div className="w-full">
          <div className="form-group">
            <label className="ip-wrap required" htmlFor="material_name">
              <span className="form-label">Material Name</span>
              <div className="input-wrap select-outer">
                <select className="theme-ip" onChange={(e) => { setMaterial(e.target.value) }} value={material}>
                  <option value="" disabled={true}>Select</option>
                  {materialNameOptions?.map((name: any, index: number) => (
                    <option key={index} value={name.material_name}>{name?.material_name}</option>
                  ))}
                </select>
              </div>
            </label>
          </div>
        </div>
        <div className="w-full">
          <div className="form-group ">
            <label className="ip-wrap required" htmlFor="lot_number" title={"Lot Number"}>
              <span className="form-label">Lot Number</span>
              <div className="input-wrap select-outer">

                <select className="theme-ip" onChange={(e) => { setLotNumber(e.target.value) }} value={lotNumber}>
                  <option value="" disabled={true}>Select</option>
                  {lotNumberOptions?.map((name: any, index: number) => (
                    <option key={index} value={name.lot_number}>{name?.lot_number}</option>
                  ))}
                </select>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 p-4 mb-6 rounded-lg">
        <div className="w-full sm:w-5/12 flex items-center justify-center">
          <span className="form-label">Create a COSMO molecule file</span>
        </div>
        <div className="w-full sm:w-7/12 flex flex-col items-center sm:items-start gap-2">
          <button
            type="button"
            className="min-w-[120px] max-w-[400px] w-40 sm:w-auto bg-theme text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3"
          >
            Run
          </button>
          <button
            type="button"
            disabled={true}
            className="min-w-[120px] max-w-[400px] w-40 sm:w-auto bg-theme text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3"
            onClick={(e) => {
              // e.preventDefault();
            }}
          >
            Download
          </button>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 p-4 mb-6 rounded-lg">
        <div className="w-full sm:w-5/12 flex items-center justify-center">
          <span className="form-label">Solvent Screeing</span>
        </div>
        <div className="w-full sm:w-7/12 flex flex-col items-center sm:items-start gap-2">
          <button
            type="button"
            className="min-w-[120px] max-w-[400px] w-40 sm:w-auto bg-theme text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3"
            onClick={() => setIsModalOpen(true)}
          >
            Run
          </button>
        </div>
      </div>
      

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4 mb-6p-4 rounded-lg shadow-sm">
        <div className="relative">
          <h3 className='text-center'>Solvents</h3>
          <div className="relative overflow-x-auto overflow-y-auto h-64">
            <Table />
          </div>
        </div>
        <div className="relative">
          <h3 className='text-center'>Anti-Solvents</h3>
          <div className="relative overflow-x-auto overflow-y-auto h-64">
            <Table />
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 mt-6 rounded-lg">
        <div className="w-full sm:w-5/12 flex items-center justify-center">
          <span className="form-label">Solvent List Template</span>
        </div>
        <div className="w-full sm:w-7/12 flex flex-col items-center sm:items-start gap-2">
          <button
            type="button"
            className="min-w-[120px] max-w-[400px] w-40 sm:w-auto bg-theme text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3"
            onClick={() => setIsModalOpen(true)}
          >
            Download
          </button>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 mt-6 mb-6 rounded-lg">
        <div className="w-full sm:w-5/12 flex items-center justify-center">
         <span className="form-label">Experimental Data</span>
         </div>
        <div className="w-full sm:w-7/12 flex flex-col items-center sm:items-start gap-2">
           <div className="custom-upload min-w-[120px] max-w-[400px] w-40">
            <input
              type="file"
              name={"name"}
              onChange={() => { }} style={{ display: 'none' }}
              onClick={(event: React.MouseEvent<HTMLInputElement>) => event.currentTarget.value = ""}
              // accept={acceptFormat ? acceptFormat : ""}
              multiple={true}
            />
            <div className="theme-ip" onClick={() => (document.querySelector(`input[name="${name}"]`) as HTMLInputElement)?.click()}>
              <i className="fa-solid fa-paperclip" />
              {!("attachments.length" === "attachments.length") ? (<span className="text">No File Choosen</span>) :
                (<span className="theme-btn sm light-btn">
                  Choose
                </span>)}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 mb-6 rounded-lg">
        <div className="w-full sm:w-5/12 flex items-center justify-center">
          <span className="form-label">New Solvent List</span>
        </div>
        <div className="w-full sm:w-7/12 flex flex-col items-center sm:items-start gap-2">
          <button
            type="button"
            className="min-w-[120px] max-w-[400px] w-40 sm:w-auto bg-theme text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3"
            onClick={() => setIsModalOpen(true)}
          >
            New Solvent List
          </button>
        </div>
      </div>
      <div className="flex gap-4 p-4 mb-6 rounded-lg shadow-sm">
        <div className="w-5/12 flex items-center justify-center"> <button className="bg-theme text-white py-2 px-4 rounded-lg text-lg w-64">
          Run
        </button></div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4 mb-6p-4 rounded-lg shadow-sm">
        <div className="relative">
          <h3 className='text-center'>Solvents</h3>
          <div className="relative overflow-x-auto overflow-y-auto h-64">
            <Table />
          </div>
        </div>
        <div className="relative">
          <h3 className='text-center'>Anti-Solvents</h3>
          <div className="relative overflow-x-auto overflow-y-auto h-64">
            <Table />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4 mb-6p-4 rounded-lg shadow-sm">
        <div className="w-full flex flex-col items-center justify-center">
          <h3>Parity Plot</h3>
          <div className="relative overflow-x-auto min-w-full">
            <ContourGraph plot={undefined} />
          </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center">
          <h3>Solubilities</h3>
          <div className="relative overflow-x-auto">
            <ContourGraph plot={undefined} />
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center gap-4 p-4 mb-6 rounded-lg shadow-sm justify-center">
        <div className="w-full sm:w-1/6">
          <span className="form-label">Download Results</span>
        </div>
        <div className="w-full sm:w-1/5">
          <div className="input-wrap select-outer">
            <select className="theme-ip w-full">
              <option value="">Select</option>
              <option value="Solvents">Solvents</option>
              <option value="Anti-Solvents">Anti-Solvents</option>
              <option value="Parity Plot">Parity Plot</option>
              <option value="Solubilities">Solubilities</option>
              {/* {lotNumberOptions?.map((name: any, index: number) => (
            <option key={index} value={name.lot_number}>{name?.lot_number}</option>
        ))} */}
            </select>
          </div>
        </div>
        <div className="w-full sm:w-1/6 mt-4 sm:mt-0">
          <button
            type="button"
            className="bg-theme text-white py-2 px-4 rounded-lg text-lg w-full"
          >
            Download
          </button>
        </div>
      </div>
    </div >
  );
};

export default Cosmo;
