import React, { useEffect, useState } from "react";
import Button, {
  ButtonIcons,
  ButtonTypes,
} from "../../components/common/Button";
import { getValueFromConcatenatedKey } from "../../utils/materialKeyLabelHelper";
import {
  Attachment,
  Material,
  MaterialVisualizationTableProps,
  TransformedMaterial,
  transformMaterialsData,
} from "./visualize/utils";
import MaterialBarChartModal from "../../components/modals/MaterialBarChartModal";
import DraggableImageModal from "../../components/modals/DraggableImageModal";
import RenderIf from "../../components/common/RenderIf";
import NoDataComponent from "../../components/helpContent/noDataComponent";

const MaterialVisualizationTable: React.FC<MaterialVisualizationTableProps> = ({
  materials,
  selectedFields,
}) => {
  //states
  const [openModals, setOpenModals] = useState<
    { id: string; attachments: (Attachment & Material)[] }[]
  >([]);
  const [transformedMaterials, setTransformedMaterials] = useState<
    TransformedMaterial[]
  >(transformMaterialsData(materials));
  const [attachmentKeys, setAttachmentKeys] = useState<string[]>([]);
  const [openGraph, setOpenGraph] = useState<boolean>(false);
  const [graphKey, setgraphKey] = useState<string>("boiling_point");

  //method to handle image modal opening
  const openImageModal = (attachments: (Attachment & Material)[]) => {
    const modalId = `${attachments[0]?.category}_${attachments[0]?.id}`;
    if (!openModals.find((modal) => modal.id === modalId)) {
      setOpenModals((prev) => [...prev, { id: modalId, attachments }]);
    }
  };

  //method to handle image modal closing
  const closeModal = (modalId: string) => {
    setOpenModals((prev) => prev.filter((modal) => modal.id !== modalId));
  };

  useEffect(() => {
    let keys: string[] = [];
    transformedMaterials.forEach(
      (tm) => (keys = [...keys, ...Object.keys(tm)])
    );
    setAttachmentKeys(keys);
  }, [transformedMaterials]);

  return (
    <>
      <div className="overflow-x-auto max-h-full">
        <table className="min-w-full border border-gray-200 border-separate max-h-full">
          <thead className="sticky top-0 z-20 bg-gray-100 ">
            <tr className="bg-gray-100">
              <th
                className="px-4 py-2 border border-gray-300 min-w-[100px] sticky top-0 left-0 z-11 bg-gray-100"
                style={{ width: "350px", maxWidth: "350px" }}
              >
                Properties
              </th>
              {materials.map((material, index) => (
                <th key={index} className="p-4 border border-gray-300">
                  <div className="flex flex-col items-start gap-2">
                    {material.material_name && (
                      <div className="flex flex-col justify-center items-start leading-none justify-center">
                        <div
                          className="whitespace-nowrap text-xs text-gray-600"
                          style={{ whiteSpace: "nowrap", lineHeight: "14px" }}
                        >
                          # Material
                        </div>
                        <div
                          className="whitespace-nowrap text-gray-900"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {material.material_name}
                        </div>
                      </div>
                    )}
                    {material.vendor_name && (
                      <div className="flex flex-col justify-center items-start leading-none">
                        <div
                          className="whitespace-nowrap text-xs text-gray-600"
                          style={{ whiteSpace: "nowrap", lineHeight: "14px" }}
                        >
                          # Vendor
                        </div>
                        <div
                          className="whitespace-nowrap text-gray-900"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {material.vendor_name}
                        </div>
                      </div>
                    )}
                    {material.lot_number && (
                      <div className="flex flex-col justify-center items-start leading-none">
                        <div
                          className="whitespace-nowrap text-xs text-gray-600"
                          style={{ whiteSpace: "nowrap", lineHeight: "14px" }}
                        >
                          # Lot Number
                        </div>
                        <div
                          className="whitespace-nowrap text-gray-900"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {material.lot_number}
                        </div>
                      </div>
                    )}
                  </div>
                </th>
              ))}
              <th className="px-4 py-2 border border-gray-300 text-center">
                Graph
              </th>
            </tr>
          </thead>
          <tbody>
            {/* "Material" Section */}
            <tr className="bg-gray-200">
              <td
                colSpan={1}
                className="px-4 py-2 font-bold border-left border-top border-bottom border-gray-300 sticky left-0 z-10"
              >
                Material
              </td>
              <td
                colSpan={materials.length + 1}
                className="px-4 py-2 font-bold border-right border-top border-bottom border-gray-300"
              ></td>
            </tr>
            {selectedFields
              .filter(
                (field) =>
                  !field.key.includes("-") &&
                  field.selected &&
                  ![
                    "material_name",
                    "vendor_name",
                    "lot_number",
                    "attachments",
                    "phase",
                  ].includes(field.key)
              )
              .map((field, fieldIndex) => {
                let isNumeric = false;
                return (
                  <tr key={fieldIndex}>
                    <td className="px-4 py-2 border border-gray-300 min-w-[150px] text-center sticky left-0 z-10 bg-gray-100">
                      {getValueFromConcatenatedKey(field.key)}
                    </td>
                    {materials.map((material, materialIndex) => {
                      const fieldValue = material[field.key];
                      isNumeric = isNumeric || typeof fieldValue === "number";
                      return (
                        <td
                          key={materialIndex}
                          className="px-4 py-2 border border-gray-300 text-center"
                        >
                          <RenderIf
                            condition={
                              typeof fieldValue === "string" ||
                              typeof fieldValue === "number"
                            }
                          >
                            <span>{String(fieldValue)}</span>
                          </RenderIf>
                        </td>
                      );
                    })}
                    <td className="px-4 py-2 border border-gray-300 text-center min-w-[150px]">
                      <RenderIf condition={isNumeric}>
                        <Button
                          title="Graph"
                          isIconButton={true}
                          icon={ButtonIcons.CHART}
                          onClick={() => {
                            setOpenGraph(true);
                            setgraphKey(field.key);
                          }}
                          type={ButtonTypes.PRIMARY}
                        />
                      </RenderIf>
                    </td>
                  </tr>
                );
              })}
            {selectedFields.map((field, fieldIndex) => {
              if (!field.selected) return <></>;
              let isNumeric = false;

              // Split the key to get section and sub-section
              const keys = field.key.split("-");
              if (keys[0] === "attachments") return <></>;
              if (keys.length === 1) return <></>;
              const section = keys[0];
              const subSection = keys.length > 2 ? keys[1] : undefined;

              // Determine if we need to show section or sub-section headers
              const showSubSectionHeader =
                fieldIndex === 0 ||
                (selectedFields[fieldIndex - 1].key
                  .split("-")
                  .slice(0, 2)
                  .join("-") !== `${section}-${subSection}` &&
                  subSection);
              return (
                <React.Fragment key={fieldIndex}>
                  {/* Sub-section Header */}
                  {showSubSectionHeader && subSection && (
                    <tr className="bg-gray-200">
                      <td
                        colSpan={1}
                        className="px-4 py-2 italic border-left border-top border-bottom border-gray-300 sticky left-0 z-10"
                      >
                        {["psd"].includes(subSection)
                          ? subSection.toUpperCase()
                          : subSection
                              .replace(/_/g, " ")
                              .replace(/(?:^|\s|-)\S/g, (c) => c.toUpperCase())}
                      </td>
                      <td
                        colSpan={materials.length + 1}
                        className="px-4 py-2 font-bold border-right border-top border-bottom border-gray-300"
                      ></td>
                    </tr>
                  )}

                  {/* Data row */}
                  <tr key={fieldIndex}>
                    <td className="px-4 py-2 border border-gray-300 min-w-[150px] text-center sticky left-0 z-10 bg-gray-100">
                      {getValueFromConcatenatedKey(field.key)}
                    </td>
                    {materials.map((material, materialIndex) => {
                      const fieldValue = material[field.key];
                      if (
                        field.label === "Heavy Metals" ||
                        field.label === "Residual Solvents" ||
                        field.label === "Chemical Purities"
                      ) {
                        return (
                          <ImpurityAndResidualsTabDataVisualization
                            fieldLabel={field?.label}
                            fieldValue={fieldValue}
                            materialIndex={materialIndex}
                            key={materialIndex}
                          />
                        );
                      }

                      isNumeric = isNumeric || typeof fieldValue === "number";
                      return (
                        <td
                          key={materialIndex}
                          className="px-4 py-2 border border-gray-300 text-center"
                        >
                          <RenderIf
                            condition={
                              typeof fieldValue === "string" ||
                              typeof fieldValue === "number"
                            }
                          >
                            <span>{String(fieldValue)}</span>
                          </RenderIf>
                        </td>
                      );
                    })}
                    <td className="px-4 py-2 border border-gray-300 min-w-[150px] text-center">
                      <RenderIf condition={isNumeric}>
                        <Button
                          title="Graph"
                          isIconButton={true}
                          icon={ButtonIcons.CHART}
                          onClick={() => {
                            setOpenGraph(true);
                            setgraphKey(field.key);
                          }}
                          type={ButtonTypes.PRIMARY}
                        />
                      </RenderIf>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
            {selectedFields.map((field, fieldIndex) => {
              if (!field.selected) return <></>;

              // Split the key to get section and sub-section
              const keys = field.key.split("-");
              if (keys[0] !== "attachments") return <></>;
              if (keys.length === 1) return <></>;
              const section = keys[0];
              const subSection = keys.length > 2 ? keys[1] : undefined;

              // Determine if we need to show section or sub-section headers
              const showSubSectionHeader =
                fieldIndex === 0 ||
                (selectedFields[fieldIndex - 1].key
                  .split("-")
                  .slice(0, 2)
                  .join("-") !== `${section}-${subSection}` &&
                  subSection);

              const isKeyDefault = subSection === "default";

              return (
                <React.Fragment key={fieldIndex}>
                  {/* Sub-section Header */}
                  {showSubSectionHeader && subSection && (
                    <tr className="bg-gray-200">
                      <td
                        colSpan={1}
                        className={`px-4 py-2 ${
                          isKeyDefault ? "font-bold" : "italic"
                        } border-left border-top border-bottom border-gray-300 sticky left-0 z-10`}
                      >
                        {isKeyDefault
                          ? "Attachments"
                          : subSection
                              .replace(/_/g, " ")
                              .replace(/(?:^|\s|-)\S/g, (c) => c.toUpperCase())}
                      </td>
                      <td
                        colSpan={materials.length + 1}
                        className="px-4 py-2 font-bold border-right border-top border-bottom border-gray-300"
                      ></td>
                    </tr>
                  )}

                  {/* Data row */}
                  <tr key={fieldIndex}>
                    <td className="px-4 py-2 border border-gray-300 min-w-[150px] text-center sticky left-0 z-10 bg-gray-100">
                      {getValueFromConcatenatedKey(field.key)}
                    </td>
                    {transformedMaterials.map(
                      (material: TransformedMaterial, materialIndex: any) => {
                        const attachments = material[keys[keys.length - 1]];
                        return (
                          <td
                            key={materialIndex}
                            className="px-4 py-2 border border-gray-300 text-center"
                          >
                            {attachments && (
                              <Button
                                title="View"
                                navigateTo="#"
                                onClick={() => openImageModal(attachments)}
                                className="theme-btn sm alter"
                                type={ButtonTypes.PRIMARY}
                              />
                            )}
                          </td>
                        );
                      }
                    )}
                    <td className="px-4 py-2 border border-gray-300 min-w-[150px] text-center"></td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>

          {/* Sticky Last Row */}
          <tfoot className="sticky bottom-0 z-10 bg-gray-100">
            <tr>
              <td className="px-4 py-2 border border-gray-300 min-w-[150px] sticky left-0 z-10 bg-gray-100">
                View Material
              </td>
              {materials.map((material, index) => (
                <td
                  key={index}
                  className="px-4 py-2 border border-gray-300 text-center"
                >
                  <button
                    type="button"
                    className="icon-btn sm plain"
                    title="View"
                    onClick={() =>
                      window.open(`/materials/${material.id}/view`, "_blank")
                    }
                  >
                    <i className="fa-solid fa-eye" />
                  </button>
                </td>
              ))}
              <td className="px-4 py-2 border border-gray-300 text-center min-w-[150px]"></td>
            </tr>
          </tfoot>
        </table>

        {/* Draggable Image Modals */}

        <MaterialBarChartModal
          open={openGraph}
          handleOpen={setOpenGraph}
          materialProperty={graphKey}
          materials={materials}
        />
      </div>
      {openModals.map((modal, index) => (
        <DraggableImageModal
          key={modal.id}
          modalId={modal.id}
          attachments={modal.attachments}
          onClose={closeModal}
          index={index}
        />
      ))}
    </>
  );
};

export default MaterialVisualizationTable;

type ImpurityAndResidualsTabDataVisualizationProps = {
  fieldLabel: "Heavy Metals" | "Residual Solvents" | "Chemical Purities";
  fieldValue: any[];
  materialIndex: number;
};

const ImpurityAndResidualsTabDataVisualization: React.FC<
  ImpurityAndResidualsTabDataVisualizationProps
> = ({ fieldLabel, fieldValue, materialIndex }) => {
  const orders = {
    "Heavy Metals": [
      "metal",
      "method",
      "value",
      "unit",
      "test_method",
      "sample_name",
    ],
    "Residual Solvents": [
      "solvent",
      "method",
      "value",
      "unit",
      "test_method",
      "sample_name",
    ],
    "Chemical Purities": ["impurity", "method", "value", "unit"],
  };

  const sortByCustomOrder = (array: string[], order: string[]) => {
    return array.sort((a, b) => order.indexOf(a) - order.indexOf(b));
  };

  if (!fieldValue?.length) {
    return (
      <td className="px-1 py-1 border border-gray-300 text-center">
        <NoDataComponent />
      </td>
    );
  }

  let tableFields = fieldValue?.length ? Object.keys(fieldValue[0]) : [];

  if (orders[fieldLabel]) {
    tableFields = sortByCustomOrder(tableFields, orders[fieldLabel]);
  }

  if (!tableFields.length) {
    return (
      <td
        key={materialIndex}
        className="px-1 py-1 border border-gray-300 text-center"
      >
        <NoDataComponent />
      </td>
    );
  }

  return (
    <td
      key={materialIndex}
      className="px-1 py-1 border border-gray-300 text-center"
    >
      <table className="min-w-full border border-gray-200 border-separate max-h-full">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-1 py-1 border border-gray-300">Field</th>
            {fieldValue.map((_: any, rowIndex: number) => (
              <th key={rowIndex} className="px-1 py-1 border border-gray-300">
                Row {rowIndex + 1}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableFields?.map((tableField, fieldIndex) => (
            <tr key={fieldIndex} className="bg-gray-200">
              <td className="px-1 py-1 border border-gray-300 bg-gray-100">
                {getValueFromConcatenatedKey(
                  `solid-impurity_and_residuals-${tableField}`
                )}
              </td>
              {fieldValue.map((row: any, colIndex: number) => (
                <td
                  key={colIndex}
                  className="px-1 py-1 border border-gray-300 bg-gray-100"
                >
                  {row[tableField] ?? ""}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </td>
  );
};
