import { ReactNode } from "react";

interface RenderIfProps {
  condition: boolean;
  children: ReactNode;
}

const RenderIf = ({ condition, children }: RenderIfProps) => {
  if (condition) {
    return (
      <>{children}</>
    )
  }
  return <></>;
}

export default RenderIf;
