import { useDispatch } from "react-redux";
import { alertCloseAction, alertOpenAction } from "../redux/actions";

export default function useAlert() {
  const dispatch = useDispatch();

  function successAlert(message: string) {
    dispatch(alertOpenAction(message, "success"));
    setTimeout(() => dispatch(alertCloseAction()));
  }

  function errorAlert(message: string) {
    dispatch(alertOpenAction(message, "error"));
    setTimeout(() => dispatch(alertCloseAction()));
  }

  return {
    successAlert, errorAlert,
  };

}
