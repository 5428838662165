import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import TextController from "../../components/form/TextController";
import { generalEquipmentTextFieldsFind } from "../../utils/equipmentData";

const FindGeneral = (props: any) => {
    const methods = useFormContext();

    useEffect(() => {
        methods?.watch();
    });

    return (
        <div className="body">
            <div className="row">
                {generalEquipmentTextFieldsFind.map((item: any, index: number) => (
                    <TextController
                        key={index}
                        name={item.value}
                        // value={methods?.control._formValues[item.value] ?? ''}
                        value={props?.equipment?.[item.value] ?? ''}
                        label={item.label}
                        methods={methods}
                        col="col-lg-4"
                        readOnly={true}
                        required={item?.required ?? false}
                        type={item?.type ?? "text"}
                    />
                ))}
            </div>
        </div>


    );
};

export default FindGeneral;