import { Controller } from 'react-hook-form';

const  ToggleController = (props: any) => {
    //control, name, default_value, disabled, option1, option2
    
    return (
        <div className="col-md-6">
            <div className="flex items-center mb-2">
                <div className="custom-switch">
                    <Controller
                        name={props.name}
                        control={props?.control}
                        defaultValue={props?.value ?? ""} // Set the default value to false (off)
                        render={({ field }) => (
                            <input
                                disabled={props?.disabled}
                                type="checkbox"
                                id="toggle"
                                className="ip"
                                {...field}
                            />
                        )}
                    />
                    <span className="toggle"></span>
                </div>
                <div className="text ml-2">{props?.checked ? props?.option1 : props?.option2}</div>
            </div>
        </div>
    )
}

export default ToggleController