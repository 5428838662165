import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

// components
import TextController from "../../form/TextController";
import SelectController from "../../form/SelectController";

// props
import { OwnerProps } from "../../../types/equipmentTypes";

import {
  EquipmentOwners,
  EquipmentTypes,
  equipmentTextFields,
  generalApplicationOptions,
} from "../../../utils/equipmentData";
import { getEquipmentsTypesByKey } from "../../../utils/equipmentHelper";
import { setTitleNumberInput, sortItemsByKey } from "../../../utils/common";

const GeneralEquipment = (props: any) => {
  const methods = useFormContext();

  // state
  const [owners, setOwners] = useState<OwnerProps[]>(EquipmentOwners);

  useEffect(() => {
    methods?.watch();
    // set default title on number input fields.
    setTitleNumberInput();
  });

  useEffect(() => {
    if (props.user) {
      const { userType, owners } = props.user;
      if (userType.includes('external')) {
        // okta dashboard vendors for external users.
        setOwners(sortItemsByKey([...owners.map((owner: OwnerProps) => ({ label: owner, value: owner }))], 'label'));
      }
    }
  }, [props.user]);

  return (
    <div className="row">
      <SelectController
        label="Equipment Type"
        name="equipment_type"
        methods={methods}
        options={EquipmentTypes}
        required={true}
        errors={methods.formState.errors}
        value={getEquipmentsTypesByKey()?.[methods?.control._formValues?.equipment_type]?.label??""}
        readOnly={props?.readOnly}
      />

      <SelectController
        label="Equipment Owner"
        title="Physical Location of the Asset"
        name="equipment_owner"
        methods={methods}
        options={owners}
        required={true}
        errors={methods.formState.errors}
        value={methods?.control._formValues?.equipment_owner ?? ""}
        readOnly={props?.readOnly}
      />

      <TextController
        name={"tag_number"}
        value={methods?.control._formValues?.tag_number ?? ""}
        label={"Equipment Tag Number"}
        title="Equipment number at the Site"
        methods={methods}
        errors={methods.formState.errors}
        required={true}
        // readOnly={props?.readOnly}
        type="text"
      />

      {equipmentTextFields.map((item: any) => (
        <TextController
          name={item.value}
          title={item.title??''}
          value={methods?.control._formValues?.[item.value] ?? ""}
          label={item.label}
          methods={methods}
          errors={methods.formState.errors}
          required={item?.required ?? false}
          type={item?.type ?? "text"}
        />
      ))}

      <SelectController
        label="Application & Scale"
        name="application_and_scale"
        methods={methods}
        options={generalApplicationOptions}
        value={methods?.control._formValues?.application_and_scale ?? ""}
        // readOnly={props?.readOnly}
      />
    </div>
  );
};

export default GeneralEquipment;
