export const material: any = {
  material_name: "Material Name",

  phase: "Phase",

  lot_number: "Lot Number",

  vendor_name: "Vendor",

  boiling_point: "Boiling Point (C)",

  manufacturing_number: "Manufacturing Number",

  abbreviation: "Abbreviation",

  brand_name: "Brand name",

  synonyms: "Synonyms",

  cas_number: "CAS Number",

  chemical_formula: "Chemical Formula",

  chemical_safety: "Chemical Safety",

  shelf_life: "Decomposition/Shelf-life (month)",

  incompatibility: "Incompatibility",

  melting_point: "Melting Point (C)",

  molecular_weight: "Molecular Weight (g/mol)",

  price: "Price ($/g)",

  purity: "Purity (%)",

  smiles: "SMILES",

  regulatory_filing_data: "Regulatory Filing Data",

  process_name: "Process Name",

  project_name: "Project Name",
  solid: {
    general: {
      solid_form: "Solid Form",

      type: "Type",

      application: "Application",

      bulk_modulus: "Bulk Modulus (Pa)",

      color_l: "Color-L",

      color_a: "Color-A",

      color_b: "Color-B",

      counterion: "Counterion",

      heat_of_fusion: "Heat of Fusion (kJ/mol)",

      log_S: "Log S",

      main_impurity_1: "Main Impurity 1",

      main_impurity_2: "Main Impurity 2",

      main_impurity_3: "Main Impurity 3",

      pKa_1: "PKA 1",

      pKa_2: "PKA 2",

      pKa_3: "PKA 3",

      specific_heat_capacity: "Specific Heat Capacity (Cp) (kJ/K)",

      surface_area: "Surface Area (m2)",

      surface_energy: "Surface Energy (J/m2)",

      thermal_conductivity: "Thermal Conductivity (W/mK)",

      yield_stress: "Yield Stress (N/m2)",

      young_modulus: `Young's Modulus (Pa)`,

      form: "Form",

      polymorph: "Polymorph",

      crystal_shape: "Crystal Shape",

      glass_transition_temperature: "Glass Transition Temperature (C)",
    },
    solubility: {
      main_solvent: "Main Solvent",

      main_solvent_temperature: "Main Solvent Temperature (C)",

      main_solvent_reference: "Main Solvent Reference",

      solubility_in_main_solvent: "Solubility in Main Solvent (mg/ml)",

      second_solvent: "Second Solvent",

      second_solvent_temperature: "Second Solvent Temperature (C)",

      second_solvent_reference: "Second Solvent Reference",

      solubility_in_second_solvent: "Solubility in Second Solvent (mg/ml)",
    },
    environment: {
      metric1: "Metric 1",

      metric2: "Metric 2",

      metric3: "Metric 3",

      metric4: "Metric 4",
    },
    impurity_and_residuals : {
      
      test_method : "Test Method",

      related_eln_experiments : "Sample Name",

      assay : "Assay",

      water : "Water",

      chemical_purities : "Chemical Purities",

      impurity : "Impurity",

      method : "Method",

      value : "Value",

      unit : "Unit",

      residual_solvents : "Residual Solvents",

      solvent : "Solvent",

      sample_name : "Sample Name",

      heavy_metals : "Heavy Metals",

      metal : "Metal"
    },
    aeration: {
      aeration_ratio: "Aeration Ratio (4mm/s)",

      aeration_energy: "Aeration Energy (mJ)",

      eln_number: "ELN Number",

      regulatory_filing: "Regulatroy Filing",

      method_name: "Method Name/#",
    },
    compressibility: {
      conditional_bulk_density: "Conditionated Bulk Density (g/ml)",

      compressibility_value: "Compressibility CPS 15 (%)",

      density: "Density at 15 kPa (g/ml)",

      eln_number: "ELN Number",

      regulatory_filing: "Regulatroy Filing",

      method_name: "Method Name/#",
    },
    densities: {
      bulk_densities: "Bulk Density (g/ml)",

      tapped_density: "Tapped Density (g/ml)",

      eln_number: "ELN Number",

      regulatory_filing: "Regulatroy Filing",

      method_number: "Method Number",
    },
    drug_info: {
      dosage_form: "Dosage Form",
    },
    flow_energy: {
      basic_flow_energy: "Basic Flow Energy (mJ)",

      conditional_bulk_density: "Conditionated Bulk Density (g/ml)",

      stability_index: "Stability Index",

      specific_energy: "Specific Energy (mJ/g)",

      flow_rate_index: "Flow rate Index",

      method_name: "Method Name/#",

      eln_number: "ELN Number",

      regulatory_filing: "Regulatroy Filing",
    },
    hygroscopicity: {
      mass_update: "Mass Uptake 25C/80%RH",

      category: "Category",

      eln_number: "ELN Number",

      method_name: "Method Name/#",

      regulatory_filing: "Regulatroy Filing",
    },
    morphologi: {
      aspect_ratio: "Aspect Ratio D10",

      aeration_ratio_d50: "Aspect Ratio D50",

      aeration_ratio_d90: "Aspect Ratio D90",

      eln_number: "ELN Number",

      method_name: "Method Name/#",

      regulatory_filing: "Regulatroy Filing",
    },
    permeability: {
      conditional_bulk_density: "Conditionated Bulk Density (g/ml)",

      density: "Density at 15 kPa (g/ml)",

      permeability_value: "Permeability K 15,2 x10^-9 (cm2)",

      pressure_drop: "Pressure Drop, PD 15,2 (mBar)",

      eln_number: "ELN Number",

      method_name: "Method Name/#",

      regulatory_filing: "Regulatroy Filing",
    },
    psd: {
      psd10: "PSD 10 (mic)",

      psd50: "PSD 50 (mic)",

      psd90: "PSD 90 (mic)",

      eln_number: "ELN Number",

      method_name: "Method Name/#",

      regulatory_filing: "Regulatroy Filing",
    },
    shear_cell: {
      cohesion: "Cohesion (Pa)",

      ffc: "FFC",

      eln_number: "ELN Number",

      method_name: "Method Name/#",

      regulatory_filing: "Regulatroy Filing",
    },
    thermal: {
      tg_onset_dsc: "Tg (Onset) (C) (DSC)",

      first_melting_point_dsc: "First Melting Point (C) (DSC)",

      second_melting_point_dsc: "Second Melting Point (C) (DSC)",

      degradation_temperature_dsc: "Degradation Temperature (C) (DSC)",

      eln_number_dsc: "ELN Number",

      method_name_dsc: "Method Name/#",

      regulatory_filing_dsc: "Regulatroy Filing",

      kf_water_content_tga: "KF Water content (w%) (TGA)",

      mass_loss_tga: "Mass Loss (w%) (TGA)",

      evaporation_temperature_tga: "Evaporation Temperature (C) (TGA)",

      degradation_temperature_tga: "Degradation Temperature (C) (TGA)",

      eln_number_tga: "ELN Number",

      method_name_tga: "Method Name/#",

      regulatory_filing_tga: "Regulatroy Filing",
    },
  },
  liquid: {
    general: {
      autoignition_temperature: "Autoignition temperature (C)",

      type: "Type",

      color_l: "Color-L",

      color_a: "Color-A",

      color_b: "Color-B",

      corrosivity: "Corrosivity",

      density: "Density (g/ml)",

      heat_of_combustion: "Heat of Combustion (kJ/mol)",

      heat_of_vaporization: "Heat of Vaporization (kJ/mol)",

      henry_law_constant: `Henry's Law Constant (atm.m3/mol)`,

      ionization_potential: "Ionization Potential (eV)",

      log_p: "Log P",

      pKa: "PKA",

      refractive_index: "Refractive Index",

      relative_evaporation_rate: "Relative Evaporation Rate",

      specific_heat_capacity: "Specific Heat Capacity (Cp) (kJ/K)",

      surface_tension: "Surface Tension (N/m)",

      vapor_density: "Vapor Density (g/ml)",

      vapor_pressure: "Vapor Pressure (Pa)",

      viscosity: "Viscosity (cP)",

      volatility: "Volatility",
    },
    solubility: {
      main_solvent: "Main Solvent",

      main_solvent_temperature: "Main Solvent Temperature (C)",

      main_solvent_reference: "Main Solvent Reference",

      solubility_in_main_solvent: "Solubility in Main Solvent (mg/ml)",

      second_solvent: "Second Solvent",

      second_solvent_temperature: "Second Solvent Temperature (C)",

      second_solvent_reference: "Second Solvent Reference",

      solubility_in_second_solvent: "Solubility in Second Solvent (mg/ml)",
    },
    environment: {
      metric1: "Metric 1",

      metric2: "Metric 2",

      metric3: "Metric 3",

      metric4: "Metric 4",
    }
  },
  gas: {
    general: {
      application: "Application",

      boiling_point: "Boiling Point(C)",

      density: "Density(g / ml)",

      heat_of_vaporization: "Heat of Vaporization(kJ / mol)",

      vapor_density: "Vapor Density(g / ml)",

      viscosity: "Viscosity(cp)",
    },
    solubility: {
      main_solvent: "Main Solvent",
  
      main_solvent_temperature: "Main Solvent Temperature (C)",
  
      main_solvent_reference: "Main Solvent Reference",
  
      solubility_in_main_solvent: "Solubility in Main Solvent (mg/ml)",
  
      second_solvent: "Second Solvent",
  
      second_solvent_temperature: "Second Solvent Temperature (C)",
  
      second_solvent_reference: "Second Solvent Reference",
  
      solubility_in_second_solvent: "Solubility in Second Solvent (mg/ml)",
    },
    environment: {
      metric1: "Metric 1",
  
      metric2: "Metric 2",
  
      metric3: "Metric 3",
  
      metric4: "Metric 4",
    }
  },
  attachments: {
    spectralInfo: {
      gcMS: "GC-MS",

      nmr: "NMR",

      atrIR: "ATR-IR",

      ir: "IR",

      lcMS: "LC-MS",

      msMS: "MS-MS",

      raman: "Raman",
    },
    drug_info: {
      toxicity: "Toxicity Data",

      clinical_trials: "Clinical Trials Info",

      indication: "Indication",

      ADME: "ADME",
    },
    thermal: {
      sopFileTga: "SOP File 2",

      sopFileDsc: "SOP File",

      heatCurveDsc: "Heat Curve",

      heatCurveTga: "Heat Curve 2",
    },
    hygroscopicity: {
      hygroscopicity2: "Hygroscopicity 2",

      isothermCurve: "Isotherm Curve",
    }
  }
};


export const getValueFromConcatenatedKey = (concatenatedKey: string): any => {
  const keys = concatenatedKey.split("-");
  let current = material;

  for (const key of keys) {
    if (current[key] !== undefined) {
      current = current[key];
    } else {
      // If key path does not exist, return only the last key in concatenatedKey
      return keys[keys.length - 1];
    }
  }

  return current;
}
