import { yupResolver } from "@hookform/resolvers/yup";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as yup from "yup";

// components
import { ButtonIcons, ButtonProps, ButtonTypes } from "../../components/common/Button";
import { TabType } from "../../components/common/Tabs";
import BodyHeader from "../../components/layout/BodyHeader";
import Layout from "../../components/layout";
import MutateMaterialForm, { FormInputDetails } from "../../components/material/MutateMaterialForm";

// modals
import BackConfirmModal from "../../components/modals/BackFormModal";
import CancelConfirmModal from "../../components/modals/CancelFormModal";
import HelpModal from "../../components/modals/HelpModal";

// context
import { useUser } from "../../components/context/UserContext";

// services
import FileService from "../../services/fileService";
import MaterialService from "../../services/materialService";

// redux actions
import { alertCloseAction, alertOpenAction } from "../../redux/actions";
import { materialAction } from "../../redux/actions/materialActions";

// props
import { RootState } from "../../redux/store";
import { FilesProps, MaterialData } from "../../types";
import { VendorProps } from "../../types/materialsTypes";

// helpers
import { UPLOAD_FILE_MAXLENGTH } from "../../utils";
import { findFile, getUpdatedFiles, setTitleNumberInput, sortItemsByKey } from "../../utils/common";
import { DUPLICATE_FILE_ERROR_MESSAGE, DUPLICATE_MATERIAL_ERROR_MESSAGE, ERROR_MESSAGE, layoutTitles, MAX_FILESIZE_ERROR_MESSAGE, UPDATE_MATERIAL_PAGE_TITLE, UPDATE_MATERIAL_SUCCESS_MESSAGE } from "../../utils/constant";
import { MaterialHelp } from "../../utils/helpContent";
import { attachmentsTypes, checkDuplicateFiles, chemicalSafetyOptions, materialPhaseTypes, regulatoryFilingOptions, vendorNames } from "../../utils/materialHelper";

const schema = yup.object({
  material_name: yup.string().required("Please enter material name."),
  vendor_name: yup.string().required("Please enter vendor name."),
  lot_number: yup.string().required("Please enter lot number."),
});

const MaterialUpdate = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const params: any = useParams();
  const { state }: any = useLocation();

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : '';

  // user context
  const { user } = useUser();
  const hasWritePermission = user?.permissions?.updated?.material?.hasReadAndWrite ?? false;

  // Redux data
  const material = useSelector((state: RootState) => state?.material);

  // states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openCancelConfirmModal, setOpenCancelConfirmModal] = useState<boolean>(false);
  const [openBackModal, setOpenBackModal] = useState<boolean>(false);
  const [openHelpModal, setOpenHelpModal] = useState<boolean>(false);
  const [materialData, setMaterialData] = useState<any>();
  const [isFirstStepCompleted, setIsFirstStepCompleted] = useState<boolean>(false);
  const [vendors, setVendors] = useState<VendorProps[]>(vendorNames);
  const [selectedAttachmentType, setSelectedAttachmentType] = useState<string>(attachmentsTypes[0].value);
  const [attachments, setAttachments] = useState<FilesProps[] | []>([]);
  const [documents, setDocuments] = useState<FilesProps[] | []>([]);

  // form
  const initialData: MaterialData = {
    material_name: materialData?.material_name ?? null,
    vendor_name: materialData?.vendor_name ?? null,
    phase: materialData?.phase ?? null,
    lot_number: materialData?.lot_number ?? null,
    abbreviation: materialData?.abbreviation ?? null,
    brand_name: materialData?.brand_name ?? null,
    synonyms: materialData?.synonyms ?? null,
    boiling_point: materialData?.boiling_point ?? null,
    cas_number: materialData?.cas_number ?? null,
    chemical_formula: materialData?.chemical_formula ?? null,
    incompatibility: materialData?.incompatibility ?? null,
    shelf_life: materialData?.shelf_life ?? null,
    melting_point: materialData?.melting_point ?? null,
    price: materialData?.price ?? null,
    molecular_weight: materialData?.molecular_weight ?? null,
    purity: materialData?.purity ?? null,
    smiles: materialData?.smiles ?? null,
    manufacturing_number: materialData?.manufacturing_number ?? null,
    project_name: materialData?.project_name ?? null,
    process_name: materialData?.process_name ?? null,
    chemical_safety: materialData?.chemical_safety ?? null,
    regulatory_filing_data: materialData?.regulatory_filing_data ?? null,
  };

  const { control, handleSubmit, formState: { errors, isDirty, dirtyFields }, reset } = useForm<MaterialData>({
    resolver: yupResolver(schema), defaultValues: initialData
  });

  const refreshData = (e: any) => {
    e.preventDefault();
    e.returnValue = '';
  };

  // method to get material by id
  const getMaterial = async (materialId: string) => {
    setIsLoading(true); // enable loading
    const res = await MaterialService.getById(materialId);
    setIsLoading(false); // disable loading

    if (res?.status === 200) {
      dispatch(materialAction(res?.data?.body?.Item));
      setMaterialData(res?.data?.body?.Item);
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  // method to select file.
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'processAttachments') {
      setDocuments([...documents, ...Object.keys(e?.target?.files ?? []).map(
        (key: any) => {
          const fileSize = e?.target?.files?.[key]?.size ?? 0;
          return {
            category: 'Process Attachment',
            file: e?.target?.files?.[key],
            error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
          }
        })]);
      return;
    }
    setAttachments([
      ...attachments,
      ...Object.keys(e?.target?.files ?? []).map(
        (key: any) => {
          const fileSize = e?.target?.files?.[key]?.size ?? 0;
          return {
            category: selectedAttachmentType,
            file: e?.target?.files?.[key],
            error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
          }
        }),
    ]);
  }

  // method to remove selected file.
  const removeFile = (index: number, name: string) => {
    if (name === 'processAttachments') {
      setDocuments(documents.filter((document, i) => i !== index));
      return;
    }
    setAttachments(attachments.filter((attachment, i) => i !== index));
  }

  const uploadFile = async (file: any) => {
    let newAttachment = await findFile(attachments, file);
    if (!newAttachment) {
      newAttachment = await findFile(documents, file);
    }
    if (newAttachment) {
      const blob = new Blob([newAttachment?.file as any], { type: newAttachment?.file?.type });
      const uploadResponse = await fetch(file.signedUrl,
        {
          method: 'PUT',
          body: blob,
        })
      if (uploadResponse.ok) return true;
      return false;
    }
    return false;
  }

  const uploadFileAPI = async (data: any, attachmentList: any[], id?: string) => {
    if (attachmentList.length) {
      // file upload request
      const filesResponse = await FileService.create("/files/upload", {
        id: material?.item?.id ?? id,
        file_for: 'Material',
        files: attachmentList,
      });

      if (filesResponse?.status === 200) {
        const filesResult = filesResponse?.data;
        return Promise.all(filesResult.body.files.map((file: any) => uploadFile(file)))
          .then(async (res) => {
            // update material object
            filesResult.body.files.map((file: any) => delete file.signedUrl);

            if (material?.item?.attachments) {
              filesResult.body.files = [...material?.item?.attachments, ...filesResult.body.files];
            };

            filesResult.body.files = await getUpdatedFiles(filesResult?.body?.files ?? [], [...attachments, ...documents], !0);

            // material update request
            const materialResponse = await MaterialService.update({
              ...data,
              id: material?.item?.id ?? id,
              uid: `${auth?.claims?.uid}`,
              attachments: filesResult.body.files
            });

            if (materialResponse?.status === 200) {
              dispatch(materialAction(materialResponse?.data?.body));
              setIsFirstStepCompleted(true);
              return true;
            }
            return false;
          })
          .catch(err => {
            return false;
          });
      }
      return false;
    }
    return true;
  }

  const apiRequest = async (data: any, newAttachments: any[]) => {
    // attachments list
    let attachmentList = newAttachments.filter((attachment: any) => !attachment.isUpload).map(
      (attachment) => ({ category: attachment?.category, key: attachment.file?.name, mimeType: attachment?.file?.type }));

      const uploadResponse = await uploadFileAPI(data, attachmentList);
      if (!uploadResponse) {
        return {
          success: false,
          errorCode: null,
          message: "Attachment upload failed.",
        };
      }

    const payload = {
      ...data,
      id: material?.item?.id,
      uid: `${auth?.claims?.uid}`,
      attachments: material?.item?.attachments?.reduce((e: any[], t: any) => {
        let newItem = attachments.find(
          (attachment: any) => (attachment.category === t.category && attachment?.file?.name === t.display_name));
        if (!newItem) {
          newItem = documents.find((document: any) => (document.category === t.category && document?.file?.name.toLocaleLowerCase().replaceAll(' ', '-') === t.display_name));
        }
        if (newItem) {
          e = [...e, t];
        }
        return e;
      }, []),
    };

    // material update request
    const materialResponse = await MaterialService.update(payload);

    if (materialResponse?.status === 200) {
      const responseData = materialResponse?.data;

      if(responseData?.code !== 200){
        return {
          success: false,
          errorCode: responseData?.code,
          message: responseData?.message,
        };
      }
      dispatch(materialAction(responseData?.body));
      setIsFirstStepCompleted(true);
      return { success: true, errorCode: null, message: null };
    }
    return { success: false, errorCode: null, message: ERROR_MESSAGE };
  }

  const validateForm = async () => {
    const invalidateFiles = [...attachments, ...documents].find((attachment: any) => attachment.error);
    if (invalidateFiles) { // Check invalid file
      dispatch(alertOpenAction(MAX_FILESIZE_ERROR_MESSAGE, 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }

    const isDuplicate = await (await checkDuplicateFiles([...attachments, ...documents]))?.map((item: any) => item.isDuplicate).includes(true);
    if (isDuplicate) {
      dispatch(alertOpenAction(DUPLICATE_FILE_ERROR_MESSAGE, 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }
    return true;
  }

  // Form submit
  const onSubmit: SubmitHandler<any> = async () => {
    if (await validateForm()) {
      setIsLoading(true);
      const apiResponse = await apiRequest(control._formValues, [...attachments, ...documents]);
      setIsLoading(false);
      if (apiResponse.success) {
        dispatch(alertOpenAction(UPDATE_MATERIAL_SUCCESS_MESSAGE, 'success'));
      } else {
        if (apiResponse.errorCode === 409) {
          dispatch(alertOpenAction(apiResponse.message || DUPLICATE_MATERIAL_ERROR_MESSAGE, "error"));
        } else {
          dispatch(alertOpenAction(ERROR_MESSAGE, "error"));
        }
      }
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  // Tabs 
  const phaseTab = () => {
    if (materialData.phase == "Solid") {
      history.push({
        pathname: `/materials/${params?.id}/phase/solid`,
        state: 'find'
      })
    } else if (materialData.phase == "Gas") {
      history.push({
        pathname: `/materials/${params?.id}/phase/gas`,
        state: 'find'
      })
    } else if (materialData.phase == "Liquid") {
      history.push({
        pathname: `/materials/${params?.id}/phase/liquid`,
        state: 'find'
      })
    }
  }

  const solubilityTab = () => {
    history.push({
      pathname: `/materials/${params?.id}/solubility`,
      state: 'find'
    })
  }

  const environmentalDataTab = () => {
    history.push({
      pathname: `/materials/${params?.id}/environmental-data`,
      state: 'find'
    })
  }

  const spectralInfoTab = () => {
    history.push({
      pathname: `/materials/${params?.id}/spectral-information`,
      state: 'find'
    })
  }

  // Cancel Modal: Save Changes
  const saveMaterial = async () => {
    if (materialData) {
      setOpenCancelConfirmModal(false);
      setOpenBackModal(false);
      if (await validateForm()) {
        setIsLoading(true); // enable loading
        const apiResponse = await apiRequest(control._formValues, [...attachments, ...documents]);
        setIsLoading(false); // disable loading
        if (apiResponse) {
          dispatch(alertOpenAction('Material updated successfully.', 'success'));
          discardChanges();
          // history.push('/materials');
        } else {
          dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
        }
        setTimeout(() => dispatch(alertCloseAction()));
      }
    } else {
      setOpenCancelConfirmModal(false);
      dispatch(alertOpenAction('No data updated to save.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  // Cancel Modal: Discard Changes
  const discardChanges = () => {
    if (state?.operation == 'cloneFromList' || state?.page == 'list') {
      history.push(`/materials/list`);
    } else if (state?.operation == 'cloneFromFind' || state == 'find') {
      history.push(`/materials/find`);
    } else {
      history.push(`/materials/`);
    }
  }

  // Handle Back button click event
  const handleBackButtonClick = () => {
    if (isDirty || Object.keys(dirtyFields).length) {
      setOpenBackModal(true);
      return;
    }
    history.push(`/materials`);
  }

  // Handle Close button click event
  const handleClose = (path: string) => {
    if (isDirty || Object.keys(dirtyFields)?.length ||
      (Array.isArray(material?.item?.attachments) && (material?.item?.attachments?.length !== [...attachments, ...documents]?.length))
    ) {
      setOpenCancelConfirmModal(true);
      return;
    }
    history.push(path);
  }

  const breadCrumbItems = [
    { label: "Home", path: "#", onClick: () => handleClose('/') },
    { label: "Materials", path: "#", onClick: () => handleClose('/materials') },
    { label: state?.page === 'list' ? "Material Screening" : "Find Material", path: "#", onClick: () => handleClose(state?.page === 'list' ? '/materials/list' : '/materials/find') },
    { label: "Update Material", path: "#" }
  ];

  // Header Buttons
  const headerButtons: ButtonProps[] = [
    ...(hasWritePermission ? [{
      isIconButton: true,
      icon: ButtonIcons.SAVE,
      title: "Save",
      type: ButtonTypes.PRIMARY,
      disabled: !hasWritePermission || isLoading,
      onClick: () => handleSubmit(onSubmit)()
    }] : []),
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.CROSS,
      title: "Close",
      type: ButtonTypes.SECONDARY,
      onClick: () => handleClose('/materials')
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.HELP,
      title: "Help",
      type: ButtonTypes.SECONDARY,
      onClick: () => setOpenHelpModal(true)
    }
  ];

  const tabs: TabType[] = [
    {
      title: 'Material',
      isActive: true,
      isCompleted: isFirstStepCompleted
    },
    {
      title: 'Phase',
      dataCy: "phase-tab",
      isCompleted: materialData && (materialData?.gas || materialData?.solid || materialData?.liquid),
      onClick: phaseTab
    },
    {
      title: 'Solubility',
      dataCy: "solubility-tab",
      isCompleted: materialData && materialData?.solubility,
      onClick: solubilityTab
    },
    {
      title: 'Spectral Information',
      dataCy: "spectral-tab",
      isCompleted: materialData && materialData?.spectralInfo,
      onClick: spectralInfoTab
    },
    {
      title: 'Environmental Data',
      dataCy: "enviornmental-tab",
      isCompleted: materialData && materialData?.environment,
      onClick: environmentalDataTab
    }
  ];

  const materialFormOneData: FormInputDetails[] = [
    {
      isRequired: true,
      label: "Material Name",
      key: "material_name",
    },
    {
      isDropdown: true,
      isRequired: true,
      label: "Vendor",
      key: "vendor_name",
      options: vendors,
    },
    {
      isDropdown: true,
      isRequired: true,
      label: "Phase",
      key: "phase",
      options: materialPhaseTypes,
      disabled: Boolean(materialData)
    },
    {
      label: "Abbreviation",
      key: "abbreviation",
    },
    {
      label: "Brand Name",
      key: "brand_name",
    },
    {
      label: "Synonyms",
      key: "synonyms",
    },
    {
      type: "number",
      label: "Boiling Point (C)",
      key: "boiling_point",
    },
    {
      label: "CAS Number",
      key: "cas_number",
    },
    {
      label: "Chemical Formula",
      key: "chemical_formula",
    },
    {
      label: "Incompatibility",
      key: "incompatibility",
    },
    {
      type: "number",
      label: "Decomposition/Shelf-life (Month)",
      key: "shelf_life",
    },
    {
      isDropdown: true,
      label: "Chemical Safety",
      key: "chemical_safety",
      options: chemicalSafetyOptions,
    },
    {
      type: "number",
      label: "Melting Point (C)",
      key: "melting_point",
    },
    {
      type: "number",
      label: "Price ($/g)",
      key: "price",
    },
    {
      type: "number",
      label: "Molecular Weight (g/mol)",
      key: "molecular_weight",
    },
    {
      type: "number",
      label: "Purity (%)",
      key: "purity",
    },
    {
      label: "SMILES",
      key: "smiles",
    }
  ];

  const materialFormTwoData: FormInputDetails[] = [
    {
      isRequired: true,
      label: "Lot Number",
      key: "lot_number",
    },
    {
      label: "Manufacturing Number",
      key: "manufacturing_number",
    },
    {
      label: "Project Name",
      key: "project_name",
    },
    {
      isDropdown: true,
      label: "Regulatory Filing Data",
      key: "regulatory_filing_data",
      options: regulatoryFilingOptions
    },
    {
      label: "Process Name",
      key: "process_name",
    }
  ];

  // Handle material data
  useEffect(() => {
    setMaterialData(material?.item);
    material.item ? setIsFirstStepCompleted(true) : '';
    reset(material.item);

    if (material?.item?.attachments?.length) {
      let newAttachments: any = [];
      let newDocuments: any = [];
      if (!attachments.length && !documents.length) {
        material?.item?.attachments?.forEach((attachment: any) => {
          let e: any = {};
          let d: any = {};
          if (attachment.category === 'Molecular Structure' ||
            attachment.category === 'USP Monograph' ||
            attachment.category === 'Chemical Safety' ||
            attachment.category === 'HPLC Method' ||
            attachment.category === 'SMILES' ||
            attachment.category === 'Others'
          ) {
            e.category = attachment.category;
            e.error = false;
            e.isUpload = true;
            e.file = {
              name: attachment.display_name,
              type: attachment.mime_type,
            };
            newAttachments.push(e);
          }
          if (attachment.category === 'Process Attachment') {
            d.category = attachment.category;
            d.error = false;
            d.isUpload = true;
            d.file = {
              name: attachment.display_name,
              type: attachment.mime_type,
            };
            newDocuments.push(d);
          }
        });
        setAttachments(newAttachments);
        setDocuments(newDocuments);
      }
    }
  }, [material.item]);

  // To show warning when trying to exit form
  useEffect(() => {
    window.addEventListener('beforeunload', refreshData);
    return () => {
      window.removeEventListener('beforeunload', refreshData);
    };
  }, []);

  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
  }, []);

  // Set vendors for external users
  useEffect(() => {
    if (user) {
      const { userType, vendors } = user;
      if (userType.includes('external')) {
        // okta dashboard vendors for external users.
        setVendors(sortItemsByKey([
          { label: 'Generic', value: 'Generic' },
          ...vendors.map((vendor: any) => ({ label: vendor, value: vendor })),
        ], 'label'));
      }
    }
  }, [user]);

  // Get material data
  useEffect(() => {
    if (params && params?.id) {
      getMaterial(params?.id);
    }
  }, [params]);

  return (
    <Layout title={layoutTitles.updateMaterial} breadCrumbItems={breadCrumbItems}>
      <form onSubmit={(e) => { e.preventDefault(); handleSubmit(onSubmit); }}>
        <BodyHeader title={UPDATE_MATERIAL_PAGE_TITLE} buttons={headerButtons} showBackButton onBackClick={handleBackButtonClick} />

        <MutateMaterialForm
          isLoading={isLoading}
          tabs={tabs}
          control={control}
          materialFormOneData={materialFormOneData}
          materialFormTwoData={materialFormTwoData}
          errors={errors}
          handleFileChange={handleFileChange}
          removeFile={removeFile}
          documents={documents}
          attachments={attachments}
          setSelectedAttachmentType={setSelectedAttachmentType}
        />
      </form>

      {/* Modals */}
      <HelpModal
        open={openHelpModal}
        setOpen={setOpenHelpModal}
        title={MaterialHelp.title}
        content={MaterialHelp.content} />

      <BackConfirmModal
        open={openBackModal}
        setOpen={setOpenBackModal}
        saveMaterial={saveMaterial}
        dontSave={discardChanges} />

      <CancelConfirmModal
        open={openCancelConfirmModal}
        setOpen={setOpenCancelConfirmModal}
        saveMaterial={saveMaterial}
        dontSave={discardChanges} />
    </Layout >
  );
};

export default MaterialUpdate;
