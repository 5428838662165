import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

// components
import Layout from "../../components/layout";
import BackConfirmModal from "../../components/modals/BackFormModal";
import SpectralInformation from "../../components/material/tabs/SpectralIformation";

// modals
import CancelConfirmModal from "../../components/modals/CancelFormModal";
import HelpModal from "../../components/modals/HelpModal";

// services
import FileService from "../../services/fileService";
import MaterialService from "../../services/materialService";

// redux actions
import { materialAction } from "../../redux/actions/materialActions";
import { alertCloseAction, alertOpenAction } from "../../redux/actions";

// props
import { RootState } from "../../redux/store";

// helpers
import {
  checkDuplicateFiles,
  spectralIformationInitialData,
  parseSpectralFiles,
  getAllSpectralInfoFiles
} from "../../utils/materialHelper";
import { UPLOAD_FILE_MAXLENGTH } from "../../utils";
import { findFile, getUpdatedFiles, setTitleNumberInput } from "../../utils/common";
import { MaterialSpectralInfoHelp } from "../../utils/helpContent";
import { layoutTitles } from "../../utils/constant";

const categoryNames: any = {
  atrIR: "ATR-IR",
  gcMS: "GC-MS",
  ir: "IR",
  lcMS: "LC-MS",
  msMS: "MS-MS",
  nmr: "NMR",
  raman: "Raman",
}

const SpectralInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : '';

  // Show data in input fields to update
  const material = useSelector((state: RootState) => state.material.item);

  // states
  const [open, setOpen] = useState<boolean>(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openBack, setOpenBack] = useState<boolean>(false);

  // form
  const methods = useForm<any>({ defaultValues: spectralIformationInitialData(material) });

  useEffect(() => {
    methods.watch();
  });

  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
    if (material) {
      const newSpectralInfoData: any = {
        ...methods.control._formValues,
        material_name: material?.material_name ?? '',
        vendor_name: material?.vendor_name ?? '',
        lot_number: material?.lot_number ?? '',
      };
      if (material?.spectralInfo && Object.keys(material?.spectralInfo?.attachments)?.length) {
        newSpectralInfoData.attachments = parseSpectralFiles(material?.spectralInfo?.attachments ?? {});
      }
      methods.reset(newSpectralInfoData);
    };
  }, [material]);

  // method trigger on file selection
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      methods.setValue(`attachments.${e.target.name}`, [
        ...methods.control._formValues?.attachments?.[e.target.name],
        ...Object.keys(e.target.files).map(
          (key: any) => {
            const fileSize = e?.target?.files?.[key]?.size ?? 0;
            return {
              category: categoryNames[e.target.name],
              file: e?.target?.files?.[key],
              error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
            }
          })
      ], { shouldDirty: true, shouldTouch: true });
    }
  }

  // Remove selected file
  const removeFile = (index: number, name: string) => {
    const newItems: any = methods.control._formValues?.attachments ?? {};
    methods.setValue(`attachments.${name}`, newItems?.[name].filter((file: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
  }

  const uploadFile = async (file: any) => {
    const attachments = Object.keys(methods.control._formValues.attachments).reduce((e: any, t: string) => {
      e = [...e, ...methods.control._formValues.attachments[t]];
      return e;
    }, []).filter((attachment: any) => !attachment.isUpload);
    const newAttachment = await findFile(attachments, file);
    if (newAttachment) {
      const blob = new Blob([newAttachment?.file as any], { type: newAttachment?.file?.type });
      const uploadResponse = await fetch(file.signedUrl,
        {
          method: 'PUT',
          body: blob,
        }
      )
      if (uploadResponse.ok) return true;
      return false
    }
    return false;
  }

  const uploadFileAPI = async (attachmentList: any[]) => {
    if (attachmentList?.length) {
      // file upload request
      const res = await FileService.create("/files/upload", {
        id: material.id,
        file_for: 'Material',
        files: attachmentList.map(
          (attachment: any) => ({
            category: attachment?.category,
            key: attachment.file?.name,
            mimeType: attachment?.file?.type
          })),
      });

      if (res?.status === 200) {
        const filesResult = await res?.data;
        return Promise.all(filesResult.body.files.map((file: any) => uploadFile(file)))
          .then(async (res) => {
            // update material object
            filesResult.body.files.map((file: any) => delete file.signedUrl);

            const r = filesResult?.body?.files?.reduce((e: any, t: any) => {
              switch (t.category) {
                case 'ATR-IR':
                  e.atrIR = [...e?.atrIR, t];
                  break;
                case 'GC-MS':
                  e.gcMS = [...e?.gcMS, t];
                  break;
                case 'IR':
                  e.ir = [...e?.ir, t];
                  break;
                case 'LC-MS':
                  e.lcMS = [...e?.lcMS, t];
                  break;
                case 'MS-MS':
                  e.msMS = [...e?.msMS, t];
                  break;
                case 'NMR':
                  e.nmr = [...e?.nmr, t];
                  break;
                case 'Raman':
                  e.raman = [...e?.raman, t];
                  break;
                default:
                // default
              }
              return e;
            }, {
              atrIR: [],
              gcMS: [],
              ir: [],
              lcMS: [],
              msMS: [],
              nmr: [],
              raman: [],
            });

            const payload = {
              ...material,
              id: material.id,
              uid: auth?.claims?.uid,
              spectralInfo: {
                ...methods.control._formValues,
                attachments: {
                  atrIR: await getUpdatedFiles([...material?.spectralInfo?.attachments?.atrIR ?? [], ...r.atrIR], methods.control._formValues?.attachments?.atrIR ?? [], !0),
                  gcMS: await getUpdatedFiles([...material?.spectralInfo?.attachments?.gcMS ?? [], ...r.gcMS], methods.control._formValues?.attachments?.gcMS ?? [], !0),
                  ir: await getUpdatedFiles([...material?.spectralInfo?.attachments?.ir ?? [], ...r.ir], methods.control._formValues?.attachments?.ir ?? [], !0),
                  lcMS: await getUpdatedFiles([...material?.spectralInfo?.attachments?.lcMS ?? [], ...r.lcMS], methods.control._formValues?.attachments?.lcMS ?? [], !0),
                  msMS: await getUpdatedFiles([...material?.spectralInfo?.attachments?.msMS ?? [], ...r.msMS], methods.control._formValues?.attachments?.msMS ?? [], !0),
                  nmr: await getUpdatedFiles([...material?.spectralInfo?.attachments?.nmr ?? [], ...r.nmr], methods.control._formValues?.attachments?.nmr ?? [], !0),
                  raman: await getUpdatedFiles([...material?.spectralInfo?.attachments?.raman ?? [], ...r.raman], methods.control._formValues?.attachments?.raman ?? [], !0),
                }
              },
            }

            // material update request
            const materialResponse = await MaterialService.update(payload);
            if (materialResponse?.status === 200) {
              dispatch(materialAction(materialResponse?.data?.body));
              return true;
            }
            return false;
          })
          .catch(() => {
            return false;
          });
      }
      return false;
    }
    return true;
  }

  const apiRequest = async () => {
    const newAttachments = getAllSpectralInfoFiles(methods.control._formValues?.attachments ?? {})
      ?.filter((attachment: any) => !attachment.isUpload);
    if (newAttachments?.length) {
      return await uploadFileAPI(newAttachments);
    }

    const payload = {
      ...material,
      id: material.id,
      uid: auth?.claims?.uid,
      spectralInfo: {
        ...methods.control._formValues,
        attachments: {
          atrIR: await getUpdatedFiles(material?.spectralInfo?.attachments?.atrIR ?? [], methods.control._formValues?.attachments?.atrIR ?? [], !1),
          gcMS: await getUpdatedFiles(material?.spectralInfo?.attachments?.gcMS ?? [], methods.control._formValues?.attachments?.gcMS ?? [], !1),
          ir: await getUpdatedFiles(material?.spectralInfo?.attachments?.ir ?? [], methods.control._formValues?.attachments?.ir ?? [], !1),
          lcMS: await getUpdatedFiles(material?.spectralInfo?.attachments?.lcMS ?? [], methods.control._formValues?.attachments?.lcMS ?? [], !1),
          msMS: await getUpdatedFiles(material?.spectralInfo?.attachments?.msMS ?? [], methods.control._formValues?.attachments?.msMS ?? [], !1),
          nmr: await getUpdatedFiles(material?.spectralInfo?.attachments?.nmr ?? [], methods.control._formValues?.attachments?.nmr ?? [], !0),
          raman: await getUpdatedFiles(material?.spectralInfo?.attachments?.raman ?? [], methods.control._formValues?.attachments?.raman ?? [], !1),
        },
      }
    };

    // material update request
    const res = await MaterialService.update(payload);
    if (res?.status === 200) {
      dispatch(materialAction(res?.data?.body));
      return true;
    }
    return false;
  }

  const validateForm = async () => {
    const allAttachments = getAllSpectralInfoFiles(methods.control._formValues.attachments ?? {})
    const newAttachments = allAttachments?.filter((attachment: any) => !attachment.isUpload);
    if (newAttachments?.length) {
      // Check invalid files.
      const invalidateFiles = newAttachments.find((attachment: any) => attachment.error);
      if (invalidateFiles) {
        dispatch(alertOpenAction('Max file size exceed. Please try again with valid files.', 'error'));
        setTimeout(() => dispatch(alertCloseAction()));
        return;
      };
      // Check duplicate files in the same category.
      const isDuplicate = await (await checkDuplicateFiles(allAttachments))?.map(
        (item: any) => item.isDuplicate).includes(true);
      if (isDuplicate) {
        dispatch(alertOpenAction('Please remove duplicate files.', 'error'));
        setTimeout(() => dispatch(alertCloseAction()));
        return;
      }
      return true;
    }
    return true;
  }

  // Submit Form
  const onSubmit: SubmitHandler<any> = async (data: any) => {
    if (await validateForm()) {
      setLoading(true);
      const apiResponse = await apiRequest();
      setLoading(false);
      if (apiResponse) {
        dispatch(alertOpenAction(`Spectral Information ${params?.id ? 'updated' : 'added'} successfully.`, 'success'));
      } else {
        dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
      }
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const saveMaterial = async () => {
    if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
      setOpen(false);
      setOpenBack(false);
      if (await validateForm()) {
        setLoading(true); // enable Loading
        const apiResponse = await apiRequest();
        setLoading(false); // disble Loading
        if (apiResponse) {
          dispatch(alertOpenAction(`Spectral Information ${params?.id ? 'updated' : 'added'} successfully.`, 'success'));
          params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
        } else {
          dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
        }
        setTimeout(() => dispatch(alertCloseAction()));
      }
    } else {
      setOpen(false);
      setOpenBack(false);
      dispatch(alertOpenAction('Please add files first.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  const handleBack = () => {
    params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
  }

  const breadCrumbItems = [
    { label: "Home", path: "/" },
    { label: "Materials", path: "/materials" },
    { label: params.id ? "Update Material" : "Add Material", path: params?.id ? `/materials/${params?.id}/update` : `/materials/new/` }, { label: "Spectral Information", path: "#" }];

  return (
    <>
      <Layout title={layoutTitles.materialSpectralInfo} breadCrumbItems={breadCrumbItems}>
        <CancelConfirmModal open={open} setOpen={setOpen} saveMaterial={saveMaterial} dontSave={handleBack} />
        <BackConfirmModal
          open={openBack}
          setOpen={setOpenBack}
          saveMaterial={saveMaterial}
          dontSave={handleBack}
        />
        <HelpModal
          open={openHelp}
          setOpen={setOpenHelp}
          title={MaterialSpectralInfoHelp.title}
          content={MaterialSpectralInfoHelp.content} />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="sec-info control-head">
              <div className="back">
                <Link to='#' title="Back" className="icon-btn alter"
                  onClick={() => {
                    if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
                      setOpenBack(true);
                      return;
                    }
                    handleBack();
                  }}>
                  <i className="fa-solid fa-arrow-left" />
                </Link>
              </div>
              <div className="head">
                <h1 className="head-lg">Spectral Information</h1>
              </div>
              <div className="right">
                <button data-cy="save-btn" type="submit" title="Save" disabled={loading ? true : false} className={`icon-btn ${loading ? "disabled  " : ""}`}>
                  <i className="fa-regular fa-floppy-disk" />
                </button>
                <Link className="icon-btn alter" title="Close" to="#" onClick={() => {
                  if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
                    setOpenBack(true);
                    return;
                  }
                  handleBack();
                }}>
                  <i className="fa-solid fa-xmark" />
                </Link>
                <Link to="#" className="icon-btn alter" title="Help" onClick={() => { setOpenHelp(true) }}>
                  <i className="fa-solid fa-question" />
                </Link>
              </div>
            </div>

            <div className="theme-card">
              <div className="body">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label className="ip-wrap" htmlFor="materialName" title="materialName">
                        <span className="form-label">Material Name</span>
                        <Controller
                          name="material_name"
                          control={methods.control}
                          render={({ field }) => <input disabled className="theme-ip" {...field} />}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label className="ip-wrap" htmlFor="vendor" title="Vendor">
                        <span className="form-label">Vendor</span>
                        <div className="input-wrap select-outer">
                          <Controller
                            name="vendor_name"
                            control={methods.control}
                            render={({ field }) => <input disabled className="theme-ip" {...field} />}
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label className="ip-wrap" htmlFor="lot_number" title="Lot Number">
                        <span className="form-label">Lot Number</span>
                        <Controller
                          name="lot_number"
                          control={methods.control}
                          render={({ field }) => <input disabled className="theme-ip" {...field} />}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                {/* divider */}
                <div className="divider"></div>

                <SpectralInformation
                  // spectralInfo={spectralInfo}
                  initialData={spectralIformationInitialData(material)}
                  attachments={methods.control._formValues.attachments ?? {}}
                  handleFileChange={handleFileChange}
                  removeFile={removeFile}
                />
              </div>
              {loading ? <div className="theme-loader show fixed">
                <div className="loader-outer">
                  <div className="loader"></div>
                </div>
              </div> : ''}
            </div>
          </form>
        </FormProvider>
      </Layout>
    </>
  );
};

export default SpectralInfo;