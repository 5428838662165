import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal, Button, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { useOktaAuth } from '@okta/okta-react';

// components
import ExportFields from '../../components/equipment/ExportFields';

// redux actions
import { alertCloseAction, alertOpenAction } from '../../redux/actions';

// helpers
import { parseEquipmentFields } from '../../utils/equipmentHelper';
import EquipmentService from '../../services/equipmentService';
import { apiRoutes } from '../../utils/apiRoutes';

const ExportEquipmentModal = (props: any) => {
    const dispatch = useDispatch();
    //auth
    const { authState } = useOktaAuth();
    const auth: any = authState ? authState?.accessToken : '';
    const [loading, setLoading] = useState<boolean>(false);

    const methods = useForm({ defaultValues: parseEquipmentFields });

    const exportList = async () => {
        setLoading(true);
        let fields = checkSelected();

        //to remove extra key from payload
        delete fields.selectAll;

        const payload = props.selectAllChecked ? {
            ...fields,
            filters: props?.filters ?? 'All'
        } : {
            ...fields,
            ids: props.selectedRows
        };

        const res = await EquipmentService.create(apiRoutes.EXPORT_EQUIPMENT, payload);
        setLoading(false);
        props.setOpen(false);
        methods.reset({});
        if (res?.data?.code === 200) {
            window.open(res?.data?.body)
            dispatch(alertOpenAction('Data exported successfully.', 'success'));
            setTimeout(() => dispatch(alertCloseAction()));
        } else {
            dispatch(alertOpenAction('Oops ! something went wrong.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        }
    }

    const checkSelected = () => {
        let equipmentArr: any = [];

        methods?.control?._formValues ?
            Object.keys(methods?.control?._formValues) && Object.keys(methods?.control?._formValues).map(
                (key: string) => {
                    return equipmentArr[key] = Object.keys(methods?.control?._formValues[key]).filter(
                        (item: string) => {
                            if (methods?.control?._formValues[key][item] == true) {
                                return item;
                            }
                        })
                }
            ) : ''

        return {
            ...equipmentArr
        }
    }

    useEffect(() => {
        methods.watch();
    })

    return (
        <>
            <Modal
                open={props.open}
                size='large'
                dimmer='blurring'
                onClose={() => { methods.reset({}), props.setOpen(false) }}
            >
                <FormProvider {...methods}>
                    <form>
                        <Modal.Header align="center" className='border-none pb-0'>
                            <Icon name="times circle outline" onClick={() => { methods.reset({}), props.setOpen(false) }} color="grey" className='close-icon '></Icon>
                        </Modal.Header>
                        <Modal.Content scrolling>
                            <Modal.Description>
                                <Tabs>
                                    <div className='sticky-tab-list'>
                                        <p className='head-sm pt-3 text-left pl-4 pb-0 mb-0 pb-1'>Please select the fields you want to export !</p>
                                        <div className="admin-tabs mb-6">
                                            <TabList className="inner mb-3 flex-wrap">
                                                <Tab>
                                                    <div className={`tab-link`}>Equipment</div>
                                                </Tab>
                                            </TabList>
                                        </div>
                                    </div>
                                    <TabPanel>
                                        <ExportFields equipmentTypes={props.equipmentTypes} />
                                    </TabPanel>
                                </Tabs>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions className='border-none text-center bg-none mb-6 pt-3'>
                            <Button title="Export" color='red' type='submit' disabled={loading ? true : false} onClick={() => exportList()}>
                                {loading ?
                                    <>
                                        <i className="fa-solid fa-circle-notch fa-spin"></i> Export
                                        <div className="theme-loader">
                                            <div className="loader-outer">
                                                <div className="loader"></div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        Export
                                    </>
                                }
                            </Button>
                            <Button title="Cancel" onClick={() => { methods.reset({}), props.setOpen(false) }}>
                                Cancel
                            </Button>
                        </Modal.Actions>
                    </form>
                </FormProvider>
            </Modal>
        </>
    )
};
export default ExportEquipmentModal;