import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// components
import Layout from "../../components/layout";

// redux actions
import { fetching, usersAction } from "../../redux/actions/userActions";
import { alertOpenAction, alertCloseAction } from "../../redux/actions";

// props
import { RootState } from "../../redux/store";

// services
import UserService from "../../services/userService";

const UserList = () => {
    const dispatch = useDispatch();
    const user:any = useSelector((state: RootState) => state.user);
    useEffect(() => {
        dispatch(fetching(!0));
        UserService.getAll()
        .then((res) => {
            if (res.status === 200) {
                const users: any = res?.data?.body??[];
                dispatch(usersAction(users));
            }
        })
        .catch((err) => {
            dispatch(alertOpenAction('Oop! something went wrong.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        });
    }, []);

    return (
        <Layout title="Users ">
            <div className="sec-info control-head">
                {/* <div className="back">
                        <Link to="/materials/" className="icon-btn alter">
                            <i className="fa-solid fa-arrow-left" />
                        </Link>
                    </div>
                */}
                <div className="head">
                    <h1 className="head-lg">Users</h1>
                </div>
                <div className="right">
                    <Link to="/users/new" title="Add User" className="icon-btn alter">
                      <i className="fa-solid fa-user-plus"></i>
                    </Link>
                    <Link to="/materials" title="Home" className="icon-btn alter">
                        <i className="fa-solid fa-home" />
                    </Link>
                    <Link to="#" title="Help" className="icon-btn alter">
                        <i className="fa-solid fa-question" />
                    </Link>
                </div>
            </div>


            <div className="theme-card sm px-5 ">
                <div className="body p-0">
                    <div className="row row-responsive" >
                        <div className="table-outer w-full">
                            <div className="table-responsive theme-table">
                            {user.loading ? (
                                <div className="theme-loader show">
                                    <div className="loader-outer">
                                    <div className="loader"></div>
                                    </div>
                                </div>
                                ): (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                <th>Type</th>
                                                <th>Status</th>
                                                <th className="w-10">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!user.loading && user?.items?.length ? user?.items?.map((user: any, index: number) => (
                                                <tr key={index}>
                                                    <td className="f-heavy">{index + 1}</td>
                                                    <td>{user.firstName}</td>
                                                    <td>{user.lastName}</td>
                                                    <td>{user.email}</td>
                                                    <td>{user.type}</td>
                                                    <td>{user.status}</td>
                                                    <td className="w-10">
                                                        <div className="tb-actions">
                                                            <button title="Edit" type="button" className="icon-btn sm plain">
                                                                <i className="fa-solid fa-pen"></i>
                                                            </button>
                                                            <button title="View" type="button" className="icon-btn sm plain" >
                                                                <i className="fa-solid fa-eye"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )): (
                                                <tr className="text-center">
                                                    <td colSpan={7} >Data not found.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    );
};

export default UserList;
