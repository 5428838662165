import http from "./httpService";
import { apiRoutes } from "../utils/apiRoutes";

const getAll = async (data: any) => {
  return await http.post(apiRoutes.SEARCH_EQUIPMENT, data);
};

const getById = async (id: string) => {
  return await http.get(`${apiRoutes.EQUIPMENTS}/${id}`);
};

const create = async (url: string, data: any) => {
  return await http.post(url, data);
};

const update = async (data: any) => {
  return await http.put(apiRoutes.EQUIPMENTS, data);
};

const remove = async (id: string) => {
  return await http.delete(`${apiRoutes.EQUIPMENTS}/${id}`);
};

const deleteEquipment = async (data: any) => {
  return await http.post(apiRoutes.DELETE_EQUIPMENT, data);
};

async function downloadAuditTrail(data:any) {
  return http.post(apiRoutes.DOWNLOAD_EQUIPMENT_AUDIT_TRAIL, data)
}

const EquipmentService = {
  getAll,
  getById,
  create,
  update,
  remove,
  deleteEquipment,
  downloadAuditTrail
};

export default EquipmentService;
