import { Controller, useForm, useFormContext } from "react-hook-form";
import { NUMBER_INPUT_MAXLENGTH } from "../../../../../utils";
import { limitDigits } from "../../../../../utils/common";

const Liquid = (props: any) => {
    const { control } = useForm({ defaultValues: props?.initialData?.liquid ?? {} });
    const methods = useFormContext();
    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="autoignition_temperature">
                            <span className="form-label">Autoignition temperature (C) </span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.autoignition_temperature"
                                    control={methods?.control??control}
                                    defaultValue={methods.control._formValues?.liquid?.autoignition_temperature??""}
                                    render={({ field }) => <input data-cy="l_autoignition_temperature"
                                      type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field}  />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.autoignition_temperature}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group ">
                        <label className="ip-wrap" htmlFor="type">
                            <span className="form-label">Type</span>
                            <div className={`input-wrap ${!props.readable ?'select-outer':''}`}>
                                {!props.readable ? (
                                    <Controller
                                        name="liquid.type"
                                        control={methods?.control ?? control}
                                        defaultValue={methods.control._formValues?.liquid?.type??""}
                                        render={({ field }) =>
                                            <select className="theme-ip" {...field} data-cy="l_type">
                                                <option value="">Select</option>
                                                <option value="API">API</option>
                                                <option value="Impurity">Impurity</option>
                                                <option value="Intermediate">Intermediate</option>
                                                <option value="Starting Material">Starting Material</option>
                                                <option value="Reagents">Reagents</option>
                                                <option value="Excipients">Excipients</option>
                                                <option value="Catalyst">Catalyst</option>
                                                <option value="Solvent">Solvent</option>
                                            </select>
                                        }
                                    />
                                ) : (
                                    <p className="theme-ip pt-3 disabled">{control._formValues.type}</p>
                                )}
                            </div>
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="color_l">
                            <span className="form-label">Color-L</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.color_l"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.color_l??""}
                                    render={({ field }) => <input
                                      data-cy="l_color_l" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.color_l}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="color_a">
                            <span className="form-label">Color-A</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.color_a"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.color_a??""}
                                    render={({ field }) => <input data-cy="l_color_a" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.color_a}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="color_b">
                            <span className="form-label">Color-B</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.color_b"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.color_b??""}
                                    render={({ field }) => <input data-cy="l_color_b" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.color_b}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="corrosivity">
                            <span className="form-label">Corrosivity</span>
                            <div className={`input-wrap ${!props.readable ? 'select-outer': ''}`}>
                                {!props.readable ? (
                                    <Controller
                                        name="liquid.corrosivity"
                                        control={methods?.control ?? control}
                                        defaultValue={methods.control._formValues?.liquid?.corrosivity??""}
                                        render={({ field }) =>
                                            <select className="theme-ip" {...field} data-cy="l_corrosivity">
                                                <option value="">Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        }
                                    />
                                ) : (
                                    <p className="theme-ip pt-3 disabled">{control._formValues.corrosivity}</p>
                                )}
                            </div>
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="density">
                            <span className="form-label">Density (g/ml)</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.density"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.density??""}
                                    render={({ field }) => <input data-cy="l_density" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.density}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="flash_point">
                            <span className="form-label">Flash Point (C)</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.flash_point"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.flash_point??""}
                                    render={({ field }) => <input data-cy="l_flash_point" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.flash_point}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="heat_of_combustion">
                            <span className="form-label">Heat of Combustion (kJ/mol)</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.heat_of_combustion"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.heat_of_combustion??""}
                                    render={({ field }) => <input data-cy="l_heat_of_combustion" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.heat_of_combustion}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="heat_of_vaporization">
                            <span className="form-label">Heat of Vaporization (kJ/mol)</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.heat_of_vaporization"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.heat_of_vaporization??""}
                                    render={({ field }) => <input data-cy="l_heat_of_vaporization"
                                      type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.heat_of_vaporization}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="henry_law_constant">
                            <span className="form-label">Henry's Law Constant (atm.m3/mol)</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.henry_law_constant"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.henry_law_constant??""}
                                    render={({ field }) => <input data-cy="l_henry_law_constant" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.henry_law_constant}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="ionization_potential">
                            <span className="form-label">Ionization Potential (eV)</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.ionization_potential"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.ionization_potential??""}
                                    render={({ field }) => <input data-cy="l_ionization_potential" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.ionization_potential}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="log_p">
                            <span className="form-label">Log P</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.log_p"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.log_p??""}
                                    render={({ field }) => <input data-cy="l_log_p" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.log_p}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="pKa">
                            <span className="form-label">pKa</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.pKa"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.pKa??""}
                                    render={({ field }) => <input data-cy="l_pKa" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.pKa}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="refractive_index">
                            <span className="form-label">Refractive Index</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.refractive_index"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.refractive_index??""}
                                    render={({ field }) => <input data-cy="l_refractive_index" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.refractive_index}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="relative_evaporation_rate">
                            <span className="form-label">Relative Evaporation Rate</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.relative_evaporation_rate"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.relative_evaporation_rate??""}
                                    render={({ field }) => <input data-cy="l_relative_evaporation_rate" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.relative_evaporation_rate}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="specific_heat_capacity">
                            <span className="form-label">Specific Heat Capacity (Cp) (kJ/K)</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.specific_heat_capacity"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.specific_heat_capacity??""}
                                    render={({ field }) => <input data-cy="l_specific_heat_capacity"
                                      type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.specific_heat_capacity}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="surface_tension">
                            <span className="form-label">Surface Tension (N/m)</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.surface_tension"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.surface_tension??""}
                                    render={({ field }) => <input data-cy="l_surface_tension" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.surface_tension}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="vapor_density">
                            <span className="form-label">Vapor Density (g/ml)</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.vapor_density"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.vapor_density??""}
                                    render={({ field }) => <input data-cy="l_vapor_density" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.vapor_density}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="vapor_pressure">
                            <span className="form-label">Vapor Pressure (Pa)</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.vapor_pressure"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.vapor_pressure??""}
                                    render={({ field }) => <input data-cy="l_vapor_pressure" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.vapor_pressure}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="viscosity">
                            <span className="form-label">Viscosity (cP)</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.viscosity"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.viscosity??""}
                                    render={({ field }) => <input data-cy="l_viscosity" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />} />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.viscosity}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="volatility">
                            <span className="form-label">Volatility</span>
                            {!props.readable ? (
                                <Controller
                                    name="liquid.volatility"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.liquid?.volatility??""}
                                    render={({ field }) => <input data-cy="l_volatility" type="number" className="theme-ip" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.volatility}</p>
                            )}
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Liquid;