import { sidebarConstants } from '../constants';

const initialState = {
  open: false,
  title: '',
};

export function sidebarReducer(state = initialState, action: any) {
  switch (action.type) {
    case sidebarConstants.TOGGLE:
      return {
        ...state,
        open: !state.open,
        title: action.title,
      };
    case sidebarConstants.OPEN:
      return {
        ...state,
        open: true,
        title: action.title,
      };
    case sidebarConstants.CLOSE:
      return {
        ...state,
        open: false,
        title: '',
      };
    default:
      return {
        ...state,
      };
  }
}
