import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Modal, Button, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

// services
import MaterialService from '../../services/materialService';
import EquipmentService from '../../services/equipmentService';

// redux
import { alertCloseAction, alertOpenAction } from '../../redux/actions';

// API routes
import { apiRoutes } from '../../utils/apiRoutes';

// helpers
import { ERROR_MESSAGE } from '../../utils/constant';

const ConfirmCloneModal = (props: any) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const cloneType = props?.type?.toLowerCase() ?? '';

    const [loading, setLoading] = useState<boolean>(false);

    // method to clone existing material.
    const cloneMaterial = async () => {
        const payload = {
            id: props?.cloneId??"",
            uid: props?.uid??"",
        };
        setLoading(true); // enable loading
        const res = await MaterialService.create(apiRoutes.CLONE_MATERIAL, payload);
        setLoading(false); // disable loading
        if (res?.data?.code === 200) {
            props.setOpen(false);
            dispatch(alertOpenAction(res?.data?.message, 'success'));
            setTimeout(() => dispatch(alertCloseAction()));

            setTimeout(() => {
                history.push({
                    pathname: `/materials/${res?.data?.body?.id}/update`,
                    state: { id: `${res?.data?.body?.id}`, operation: `${props.operation}` }
                })
            }, 2000);
        } else {
            dispatch(alertOpenAction(ERROR_MESSAGE, 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        }
    }

    // method to clone existing equipment.
    const cloneEquipment = async () => {
        const payload = {
            id: props?.cloneId??"",
            uid: props?.uid??"",
        };
        setLoading(true); // enable loading
        const res = await EquipmentService.create(apiRoutes.CLONE_EQUIPMENT, payload);
        setLoading(false); // disable loading
        if (res?.data?.code === 200) {
            props.setOpen(false);
            dispatch(alertOpenAction(res?.data?.message, 'success'));
            setTimeout(() => dispatch(alertCloseAction()));

            setTimeout(() => {
                history.push({
                    pathname: `/equipments/${res?.data?.body?.id}/update`,
                    state: { id: `${res?.data?.body?.id}`, operation: `${props.operation}` }
                })
            }, 2000);
        } else {
            dispatch(alertOpenAction(ERROR_MESSAGE, 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        }
    }

    return (
        <Modal
            open={props.open}
            size="tiny"
            dimmer='blurring'
            onClose={() => props.setOpen(false)}
        >
            <Modal.Header align="center" className='border-none pb-0'>
                <div className='mt-8'>
                    <Icon name='clone' color="yellow" size="big" />
                </div>
                <Icon name="times circle outline" onClick={() => props.setOpen(false)} color="grey" className='close-icon'></Icon>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <p className='head-lg text-center pb-0'>Are you sure you want to clone this {props?.type?.toLowerCase()??""}?</p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions className='border-none text-center bg-none mb-6'>
                {cloneType === 'material' && (
                    <Button title="Clone Material" color='red' disabled={loading ? true : false} onClick={() => cloneMaterial()}>
                        {loading ?
                            <>
                                <i className="fa-solid fa-circle-notch fa-spin"></i> Clone {props?.type??""}
                                <div className="theme-loader">
                                    <div className="loader-outer">
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                Clone {props?.type??""}
                            </>
                        }
                    </Button>
                )}

                {cloneType === 'equipment' && (
                    <Button title="Clone Equipment" color='red' disabled={loading ? true : false} onClick={() => cloneEquipment()}>
                        {loading ?
                            <>
                                <i className="fa-solid fa-circle-notch fa-spin"></i> Clone {props?.type??""}
                                <div className="theme-loader">
                                    <div className="loader-outer">
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                Clone {props?.type??""}
                            </>
                        }
                    </Button>
                )}

                <Button title="Cancel" onClick={() => { props.setOpen(false) }}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    )
};
export default ConfirmCloneModal;