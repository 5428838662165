// components
import MultiFileUpload from "../../common/MultiFileUpload";
import { useEffect } from "react";
import { setTitleNumberInput } from "../../../utils/common";

const SpectralInformation = (props: any) => {
    useEffect(() => {
        // set default title on number input fields.
        setTitleNumberInput();
    }, []);
    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        dataCy="attachment_spectral_atrir"
                        title="ATR-IR"
                        name="atrIR"
                        readable={props.readable}
                        attachments={props?.attachments?.atrIR}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props?.downloadFile}
                    />
                </div> 

                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        dataCy="attachment_spectral_gcMS"
                        title="GC-MS"
                        name="gcMS"
                        readable={props.readable}
                        attachments={props?.attachments?.gcMS}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props?.downloadFile}
                    />
                </div> 

                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        dataCy="attachment_spectral_ir"
                        title="IR"
                        name="ir"
                        readable={props.readable}
                        attachments={props?.attachments?.ir}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props?.downloadFile}
                    />
                </div> 

                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        dataCy="attachment_spectral_lcMS"
                        title="LC-MS"
                        name="lcMS"
                        readable={props.readable}
                        attachments={props?.attachments?.lcMS}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props?.downloadFile}
                    />
                </div> 

                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        dataCy="attachment_spectral_msMS"
                        title="MS-MS"
                        name="msMS"
                        readable={props.readable}
                        attachments={props?.attachments?.msMS}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props?.downloadFile}
                    />
                </div> 

                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        dataCy="attachment_spectral_nmr"
                        title="NMR"
                        name="nmr"
                        readable={props.readable}
                        attachments={props?.attachments?.nmr}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props?.downloadFile}
                    />
                </div> 

                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        dataCy="attachment_spectral_raman"
                        title="Raman"
                        name="raman"
                        readable={props.readable}
                        attachments={props?.attachments?.raman}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props?.downloadFile}
                    />
                </div>                
            </div>
        </>
    );
};

export default SpectralInformation;