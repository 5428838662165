import React, { ReactElement } from "react";
import { Tab, Tabs, TabList, TabPanel, TabsProps } from "react-tabs";

export interface AppTabProps {
  title: string;
  component: ReactElement;
  className?: string;
  dataCy? : string;
}

const AppTabs = (props :{data: AppTabProps[]}) => {
  return (
    <>
      <Tabs>
        <div className="admin-tabs mx-card mb-6">
          <TabList className="inner mb-3 flex-wrap">
            {props.data.map((e,index) => (
              <Tab key={index}>
                <div data-cy={e?.dataCy ?? ""} className={e?.className ?? `tab-link `}>{e.title}</div>
              </Tab>
            ))}
          </TabList>
        </div>
        {props.data.map((e,index) => (
          <TabPanel key={index}>{e.component}</TabPanel>
        ))}
      </Tabs>
    </>
  );
};

export default AppTabs;
