import React from 'react'
import { Icon, Modal } from 'semantic-ui-react'
import EditableTable from './EditableTable'

interface SolventListModalProps { isModalOpen: boolean, toggleModal: (isOpen:boolean) => void }

const SolventListModal = ({ isModalOpen, toggleModal }: SolventListModalProps) => {
    return (
        <Modal open={isModalOpen} onClose={()=>toggleModal(false)} size="large"
            dimmer='blurring' className='rounded-lg shadow-sm'>
            <Modal.Header align="center" className='border-none pb-10'>
                <Icon
                    name="times circle outline"
                    color="grey"
                    className="close-icon "
                    onClick={() => toggleModal(false)}
                ></Icon>
            </Modal.Header>
            <Modal.Content className='audit-modal'>
                <div className="gap-4 p-4 mb-6p-4">
                    <h3>Create a new Solvent System</h3>
                    <div className='w-full md-4 p-4'>
                        <div className="flex flex-col space-y-4">
                            <label className="flex items-center space-x-2">
                                <span className="form-label">Import all pure solvents</span>
                                <input
                                    type="checkbox"
                                    className="form-checkbox  w-6 h-6"
                                // checked={checkedPureSolvents}
                                // onChange={handlePureSolventsChange}
                                />

                            </label>
                            <label className="flex items-center space-x-2">
                                <span className="form-label">Create solvent mixture</span>
                                <input
                                    type="checkbox"
                                    className="form-checkbox  w-6 h-6"
                                // checked={checkedSolventMixture}
                                // onChange={handleSolventMixtureChange}
                                />

                            </label>
                        </div>
                    </div>
                    <EditableTable />
                    <div className='w-full flex flex-row-reverse '>
                        <div className="w-1/6 m-4 mr-2">
                            <button
                                type="button"
                                className="bg-theme text-white py-2 px-4 rounded-lg text-lg w-full"
                            >
                                Submit Solvent List
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Content>
        </Modal>)
}

export default SolventListModal