import { APP_ENV } from "./config";
import { PERMISSION_MESSAGE, MULTIPLE_PERMISSIONS_MESSAGE } from "./constant";
import { getPermissions } from "./permissionsHelper";

// const getUserInfo = (oktaUser: any, oktaAuthState: any) => {
//     const user: any = {};
//     const internalPermissions: string[] | [] = oktaAuthState?.accessToken?.claims?.InternalPermissions??[];
//     const externalPermissions: string[] | [] = oktaAuthState?.accessToken?.claims?.ExternalPermissions??[];
//     user.id = oktaUser?.sub??'';
//     user.name = oktaUser?.name??'';
//     user.firstName = oktaUser?.given_name??'';
//     user.lastName = oktaUser?.family_name??'';
//     user.email = oktaUser?.email??'';
//     user.userType = (internalPermissions?.length && externalPermissions?.length) ? 'Invalid User' : internalPermissions?.length ? 'internal' : 'external';
//     user.owners = oktaAuthState?.accessToken?.claims?.Owners??[];
//     user.vendors = oktaAuthState?.accessToken?.claims?.Vendors??[];
//     user.permissions = {
//         original: {
//             internalPermissions: oktaAuthState?.accessToken?.claims?.InternalPermissions??[],
//             externalPermissions: oktaAuthState?.accessToken?.claims?.ExternalPermissions??[],
//         }
//     }
//     if (user.userType === 'internal') {
//         user.permissions.updated = { ...getPermissions(oktaAuthState?.accessToken?.claims?.InternalPermissions??[], 'Internal') };
//     }
//     if (user.userType === 'external') {
//         user.permissions.updated = { ...getPermissions(oktaAuthState?.accessToken?.claims?.ExternalPermissions??[], 'External') };
//     }
//     return user;
// }

const getUserInfo = (authState: any) => {
  const user: any = {};
  const internalPermissions: string[] | [] =
    authState?.accessToken?.claims?.InternalPermissions ?? [];
  const externalPermissions: string[] | [] =
    authState?.accessToken?.claims?.ExternalPermissions ?? [];
  user.id = authState?.idToken?.claims?.sub ?? "";
  user.name = authState?.idToken?.claims?.name ?? "";
  // user.firstName = authState?.given_name??'';
  // user.lastName = authState?.family_name??'';
  user.email = authState?.idToken?.claims?.email ?? "";
  user.userType =
    internalPermissions?.length && externalPermissions?.length
      ? "Invalid User"
      : internalPermissions?.length
      ? "internal"
      : "external";
  user.owners = authState?.accessToken?.claims?.Owners?.length
    ? authState?.accessToken?.claims?.Owners?.map((owner: string) => {
        owner = owner.replace(/^Owner|^owner/g, "").trim();
        return owner;
      }).sort((firstOwner: string, secondOwner: string) =>
        firstOwner.localeCompare(secondOwner, undefined, {
          sensitivity: "base",
        })
      )
    : [];

  ///^Okta-SMPD-Dev-|^Okta-SMPD-Test-|^Okta-SMPD-/g

  let regExp = /^Okta-SMPD-Dev-/g;

  if (APP_ENV == "test") {
    regExp = /^Okta-SMPD-Test-/g;
  }

  if (APP_ENV == "production") {
    regExp = /^Okta-SMPD-/g;
  }

  user.vendors = authState?.accessToken?.claims?.Vendors?.length
    ? authState?.accessToken?.claims?.Vendors?.map((vendor: string) => {
        vendor = vendor.replace(/^Vendor|^vendor/g, "").trim();
        return vendor;
      }).sort((firstVendor1: string, secondVendor: string) =>
        firstVendor1.localeCompare(secondVendor, undefined, {
          sensitivity: "base",
        })
      )
    : [];
  user.permissions = {
    original: {
      internalPermissions: authState?.accessToken?.claims?.InternalPermissions
        ?.length
        ? authState?.accessToken?.claims?.InternalPermissions.map(
            (permission: string) => permission.replace(regExp, "")
          )
        : [],
      externalPermissions: authState?.accessToken?.claims?.ExternalPermissions
        ?.length
        ? authState?.accessToken?.claims?.ExternalPermissions.map(
            (permission: string) => permission.replace(regExp, "")
          )
        : [],
    },
  };
  if (user.userType === "internal") {
    user.permissions.updated = {
      ...getPermissions(
        user.permissions?.original?.internalPermissions ?? [],
        "Internal"
      ),
    };
  }
  if (user.userType === "external") {
    user.permissions.updated = {
      ...getPermissions(
        user.permissions?.original?.externalPermissions ?? [],
        "External"
      ),
    };
  }

  user.errors = {};
  // error if user have no permission internal as well as external.
  if (
    !user.permissions.original.internalPermissions?.length &&
    !user.permissions.original.externalPermissions?.length
  ) {
    user.errors = {
      empty: {
        status: true,
        message: PERMISSION_MESSAGE,
      },
    };
  }
  // error if user have both permissions internal and external.
  if (user.userType === "Invalid User") {
    user.errors = {
      ...user.errors,
      multiple: {
        status: true,
        message: MULTIPLE_PERMISSIONS_MESSAGE,
      },
    };
  }
  // // external users have default Generic Vendor.
  // // but user have no default Owner So user must have atleast one Owner in OKTA Platform.
  // // if external user have empty owners list then user see error in frontend.
  // if (user.userType === 'external' && !user?.owners?.length) {
  //     user.errors = {
  //         ...user.errors,
  //         owners: {
  //             status: true,
  //             message: PERMISSION_MESSAGE,
  //         },
  //     }
  // }
  if (
    user.userType === "external" &&
    !user?.owners?.length &&
    (user.permissions.updated.equipment.hasRead ||
      user.permissions.updated.equipment.hasReadAndWrite)
  ) {
    user.errors = {
      ...user.errors,
      owners: {
        status: true,
        message: PERMISSION_MESSAGE,
      },
    };
  }
  // if (user.userType === 'external' && !user?.vendors?.length && (user.permissions.updated.material.hasRead || user.permissions.updated.material.hasReadAndWrite)) {
  //     user.errors = {
  //         ...user.errors,
  //         vendors: {
  //             status: true,
  //             message: PERMISSION_MESSAGE,
  //         },
  //     }
  // }
  return user;
};

export { getUserInfo };
