import { equipmentConstants } from "../constants/equipmentConstants";

export const equipmentsAction = (equipments: any[]) => {
  return {
    type: equipmentConstants.EQUIPMENTS,
    equipments,
  };
};

export const equipmentAction = (equipment: any) => {
  return {
    type: equipmentConstants.EQUIPMENT,
    equipment,
  };
};

export const addEquipmentAction = (equipment: any) => {
  return {
    type: equipmentConstants.ADD_EQUIPMENT,
    equipment,
  };
};

export const updateEquipmentAction = (equipment: any) => {
  return {
    type: equipmentConstants.UPDATE_EQUIPMENT,
    equipment,
  };
};

export const solidEquipmentAction = (equipment: any) => {
  return {
    type: equipmentConstants.SOLID_EQUIPMENT,
    equipment,
  };
};
