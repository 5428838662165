export interface ReadWritePermissionsProps {
    hasRead: boolean;
    hasReadAndWrite: boolean;
}

export interface moduleLevelPermissions {
    material: ReadWritePermissionsProps;
    equipment: ReadWritePermissionsProps;
}

export interface PermissionsProps {
    internal: moduleLevelPermissions;
    external: moduleLevelPermissions;
}

// export const permissions = {
//     material: {
//         internal: {
//             hasRead: false,
//             hasReadAndWrite: false,
//         },
//         external: {
//             hasRead: false,
//             hasReadAndWrite: false,
//         }
//     },
//     equipment: {
//         internal: {
//             hasRead: false,
//             hasReadAndWrite: false,
//         },
//         external: {
//             hasRead: false,
//             hasReadAndWrite: false,
//         }
//     },
// }

// export const getPermissions = (items: any) => {
//     items?.map((key: string) => {
//         switch(key.toUpperCase()) {
//             case 'INT-MAT-R':
//                 permissions.material.internal.hasRead = true;
//                 break;
//             case 'INT-MAT-RW':
//                 permissions.material.internal.hasReadAndWrite = true;
//                 break;
//             case 'EXT-MAT-R':
//                 permissions.material.external.hasRead = true;
//                 break;
//             case 'EXT-MAT-RW':
//                 permissions.material.external.hasReadAndWrite = true;
//                 break;
//             case 'INT-EQP-R':
//                 permissions.equipment.internal.hasRead = true;
//                 break;
//             case 'INT-EQP-RW':
//                 permissions.equipment.internal.hasReadAndWrite = true;
//                 break;
//             case 'EXT-EQP-R':
//                 permissions.equipment.external.hasRead = true;
//                 break;
//             case 'EXT-EQP-RW':
//                 permissions.equipment.external.hasReadAndWrite = true;
//                 break;
//         }
//     });
//     return permissions;
// }

export const defaultPermissions = {
    internal: {
        material: {
            hasRead: false,
            hasReadAndWrite: false,
        },
        equipment: {
            hasRead: false,
            hasReadAndWrite: false,
        }
    },
    external: {
        material: {
            hasRead: false,
            hasReadAndWrite: false,
        },
        equipment: {
            hasRead: false,
            hasReadAndWrite: false,
        }
    },
}

export const getPermissions = (items: any, type: string) => {
    items?.map((key: string) => {
        switch(key.toUpperCase()) {
            case 'INT-MAT-R':
                defaultPermissions.internal.material.hasRead = true;
                break;
            case 'INT-MAT-RW':
                defaultPermissions.internal.material.hasRead = true;
                defaultPermissions.internal.material.hasReadAndWrite = true;
                break;
            case 'EXT-MAT-R':
                defaultPermissions.external.material.hasRead = true;
                break;
            case 'EXT-MAT-RW':
                defaultPermissions.external.material.hasRead = true;
                defaultPermissions.external.material.hasReadAndWrite = true;
                break;
            case 'INT-EQP-R':
                defaultPermissions.internal.equipment.hasRead = true;
                break;
            case 'INT-EQP-RW':
                defaultPermissions.internal.equipment.hasRead = true;
                defaultPermissions.internal.equipment.hasReadAndWrite = true;
                break;
            case 'EXT-EQP-R':
                defaultPermissions.external.equipment.hasRead = true;
                break;
            case 'EXT-EQP-RW':
                defaultPermissions.external.equipment.hasRead = true;
                defaultPermissions.external.equipment.hasReadAndWrite = true;
                break;
        }
    });
    if (type === 'Internal') {
        return defaultPermissions.internal;
    }
    return defaultPermissions.external;
}

export const checkUserType = (permissions: string[]) => {
    if (!permissions?.length) return "";
    const userTypes = Array.from(new Set(permissions?.map(
        (permission: string) => permission.split('-')[0])));
    return userTypes[0]?.toUpperCase() === 'INT' ? "internal": "external";
}