import { useState } from "react";
import FileService from "../services/fileService";
import useAlert from "./useAlert";

export async function saveFile(fileURL: string) {
  const res = await fetch(fileURL);
  const blob = await res.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  document.body.appendChild(a);
  a.download = fileURL.split("?")[0].split("/").slice(-1)[0];
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
  return Promise.resolve(null);
}

export default function useFileDownload() {
  const [loading, setLoading] = useState(false);
  const { successAlert, errorAlert } = useAlert();

  function downloadFile(filePath: string) {
    setLoading(true);
    FileService.create("/files/download", {
      file: filePath,
    }).then(async ({ status, data }) => {
      if (status === 200) {
        const fileURL = data.body;
        try {
          await saveFile(fileURL);
          successAlert("File Downloaded successfully");
          setLoading(false);
        } catch (e) {
          errorAlert("Oops! something went wrong.");
        }
      }
    });
  }

  return { loading, downloadFile };
}