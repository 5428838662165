import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

// services
import EquipmentService from "../../services/equipmentService";

// props
import { RootState } from "../../redux/store";

// redux actions
import { equipmentAction } from "../../redux/actions/equipmentActions";
import { alertCloseAction, alertOpenAction } from "../../redux/actions";

// helpers
import { EquipmentTypes } from "../../utils/equipmentData";
import { onlyUnique, getEquipmentsTypesByKey } from "../../utils/equipmentHelper";
import { sortItemsByKey } from "../../utils/common";
import { capitalise } from "../../utils";
import { apiRoutes } from "../../utils/apiRoutes";


const equipmentTypesByKeys = getEquipmentsTypesByKey();

const EquipmentHeaderRow = (props: any) => {
  const dispatch = useDispatch();
  // auth 
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : "";
  const equipment = useSelector((state: RootState) => state.equipment.item);

  const initialData = {
    loading: false,
    equipment_type: equipment?.type ?? "",
    equipment_owner: equipment?.owner ?? "",
    tag_number: equipment?.tag_number ?? "",
  };

  // states
  const [loading, setLoading] = useState<boolean>(false);
  const [equipmentOwnersOptions, setEquipmentOwnersOptions] = useState<any[]>([]);
  const [tagNumberOptions, setTagNumberOptions] = useState<any[]>([]);
  const { control, watch, setValue } = useForm({ defaultValues: initialData });

  //Get onChange values for search
  const watchedFields = {
    loading,
    equipment_type: watch("equipment_type"),
    equipment_owner: watch("equipment_owner"),
    tag_number: watch("tag_number"),
  };

  const [equipmentType, setEquipmentType] = useState("");
  const [equipmentOwner, setEquipmentOwner] = useState("");
  const [tagNumber, setTagNumber] = useState("");
  const { equipmentDeleted } = props;



  useEffect(() => {
    setTagNumber(watchedFields.tag_number);
    props?.handleSelectedFields(watchedFields);
  }, [watchedFields?.tag_number]);

  //Get material names and lot numbers dropdown options
  useEffect(() => {
    if (watchedFields?.equipment_type && watchedFields?.equipment_owner) {
      if (
        equipment?.equipment_type !== watchedFields?.equipment_type ||
        equipment?.equipment_owner !== watchedFields?.equipment_owner
      ) {
        watchedFields?.tag_number ? setLoading(true) : "";
        setValue("tag_number", "");
        dispatch(equipmentAction(null));

        getTagNumbers();
      }
    }

    setEquipmentType(watchedFields?.equipment_type);
    setEquipmentOwner(watchedFields?.equipment_owner);
  }, [watchedFields?.equipment_type, watchedFields?.equipment_owner]);

  useEffect(() => {
    if (watchedFields?.equipment_type.length > 0) {
      if (equipment?.equipment_type !== watchedFields?.equipment_type) {
        watchedFields?.equipment_owner ? setLoading(true) : "";
        setValue("equipment_owner", "");
        dispatch(equipmentAction(null));
        getEquipmentOwners();
      }
    }

    setEquipmentType(watchedFields?.equipment_type);
  }, [watchedFields?.equipment_type]);

  // method to get equipment owners for selected equipment_type.
  const getEquipmentOwners = async () => {
    setEquipmentOwnersOptions([]);
    const payload = {
      equipment_type: watchedFields?.equipment_type,
      uid: `${auth?.claims?.uid}`,
    };
    setLoading(true); // enable loading
    const res = await EquipmentService.create(apiRoutes.LIST_OWNERS_EQUIPMENT, payload);
    setLoading(false); // disable loading
    if (res?.data?.code == 200) {
      if (res?.data?.body?.length) {
        //getting labels for values to display
        let arr: any = [];
        res?.data?.body.map((key: any) => {
          arr.push(key?.equipment_owner);
        });
        arr = arr.filter(onlyUnique); // To remove repetative names

        // let labels = labelValueArray(arr, "owners");
        // setEquipmentOwnersOptions(labels);
        setEquipmentOwnersOptions(sortItemsByKey(arr.map(
          ( item: string) => ({ label: item, value: item} )), 'label'));
      } else {
        dispatch(alertOpenAction("No related equipments present.", "error"));
        setTimeout(() => dispatch(alertCloseAction()));
      }
    } else {
      dispatch(
        alertOpenAction("Unable to load the related Equiments.", "error")
      );
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  // method to get equipment tag numbers for selected equipment type and owner.
  const getTagNumbers = async () => {
    setTagNumberOptions([]);
    const payload = {
      uid: `${auth?.claims?.uid}`,
      equipment_type: watchedFields?.equipment_type,
      equipment_owner: watchedFields?.equipment_owner,
    };
    setLoading(true); // enable loading
    const res = await EquipmentService.create(apiRoutes.LIST_TAG_NUMBERS_EQUIPMENT, payload);
    setLoading(false); // disable loading
    if (res?.data?.code == 200) {
      if (res?.data?.body?.length) {
        setTagNumberOptions(sortItemsByKey(res?.data?.body??[], 'tag_number'));
      } else {
        dispatch(
          alertOpenAction("Unable to load the related Lot Numbers.", "error")
        );
        setTimeout(() => dispatch(alertCloseAction()));
      }
    } else {
      dispatch(
        alertOpenAction("Unable to load the related Lot Numbers.", "error")
      );
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  useEffect(() => {
    props.headerData(watchedFields);
  }, [
    watchedFields?.equipment_type,
    watchedFields?.tag_number,
    watchedFields?.equipment_owner,
    watchedFields?.loading,
  ]);

  useEffect(() => {
    setValue("equipment_type", '');
    setValue("equipment_owner", '');
    setValue("tag_number", '');
    setEquipmentOwnersOptions([]);
    setTagNumberOptions([]);
    dispatch(equipmentAction(null));
}, [equipmentDeleted]);

  return (
    <div className="row">
      <div className="col-lg-4 col-md-6">
        <div className="form-group">
          <label className="ip-wrap" htmlFor="vendor">
            <span className="form-label">Type</span>
            <div
              className={`input-wrap ${props?.readOnly ? "" : "select-outer"}`}
            >
              {!props.readOnly ? (
                <Controller
                  name="equipment_type"
                  control={control}
                  render={({ field }) => (
                    <select className="theme-ip" {...field}>
                      <option value="" disabled selected>
                        Select
                      </option>

                      {EquipmentTypes?.map((type: any, index: number) => (
                        <option key={index} value={type?.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                  )}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">
                  {equipmentTypesByKeys?.[equipment?.equipment_type?.toString()]?.label??""}
                </p>
              )}
            </div>
          </label>
        </div>
      </div>

      <div className="col-lg-4 col-md-6">
        <div className="form-group">
          <label className="ip-wrap" htmlFor="equipment_owner">
            <span className="form-label">Owner</span>
            <div
              className={`input-wrap ${props?.readOnly ? "" : "select-outer"}`}
            >
              {!props.readOnly ? (
                <Controller
                  name="equipment_owner"
                  control={control}
                  defaultValue={equipment?.equipment_owner ?? ""}
                  render={({ field }) => (
                    <select
                      className="theme-ip"
                      disabled={
                        equipmentOwnersOptions?.length &&
                          watchedFields?.equipment_type
                          ? false
                          : true
                      }
                      {...field}
                    >
                      <option
                        value=""
                        disabled
                        selected={equipment ? false : true}
                      >
                        Select
                      </option>
                      {equipmentOwnersOptions?.length &&
                        equipmentOwnersOptions?.map(
                          (item: any, index: number) => (
                            <option
                              key={index}
                              selected={
                                equipment?.equipment_owner === item?.value
                              }
                              value={item?.value}
                            >
                              {item?.label}
                            </option>
                          )
                        )}
                    </select>
                  )}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">
                  {capitalise(equipment?.equipment_owner?.toString()) ?? ""}
                </p>
              )}
            </div>
          </label>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="form-group ">
          <label className="ip-wrap" htmlFor="tag_number">
            <span className="form-label">Tag Number</span>
            <div
              className={`input-wrap ${props?.readOnly ? "" : "select-outer"}`}
            >
              {!props.readOnly ? (
                <Controller
                  name="tag_number"
                  control={control}
                  render={({ field }) => (
                    <select
                      className="theme-ip"
                      disabled={
                        tagNumberOptions?.length &&
                          watchedFields?.equipment_type &&
                          watchedFields?.equipment_owner
                          ? false
                          : true
                      }
                      {...field}
                    >
                      <option
                        value=""
                        disabled
                        selected={equipment ? false : true}
                      >
                        Select
                      </option>
                      {tagNumberOptions?.length &&
                        tagNumberOptions?.map((name: any, index: number) => (
                          <option
                            key={index}
                            selected={
                              equipment?.tag_number === name?.tag_number
                            }
                            value={name.tag_number}
                          >
                            {name?.tag_number}
                          </option>
                        ))}
                    </select>
                  )}
                />
              ) : (
                <p className="theme-ip pt-3 disabled">
                  {capitalise(equipment?.tag_number?.toString()) ?? ""}
                </p>
              )}
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default EquipmentHeaderRow;
