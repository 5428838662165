import { Controller, useFormContext } from "react-hook-form";

// helpers
import { equipmentFields, parseEquipmentFieldsExport } from "../../utils/equipmentHelper";
import { useEffect } from "react";

const equipmentLabels = (key: string) => {
    let newKey: string = key;
    return <label>{(newKey).charAt(0).toUpperCase()}{(newKey).slice(1)}</label>
}

const ExportFields = (props: any) => {
    const methods = useFormContext();
    const { equipmentTypes } = props;

    const selectedEquipmentKeys = [
        'general',
        ...Object.keys(equipmentFields).filter((eqf: any) => equipmentTypes.includes(eqf))
    ];


    useEffect(()=>{
        const newValue: any = parseEquipmentFieldsExport(false);
        methods.reset({
            'general': newValue.general,
            'mixer': newValue.mixer,
            'vessel': newValue.vessel,
            'reactor': newValue.reactor,
            'distillation': newValue.distillation,
            'filtration': newValue.filtration,
            'evaporator': newValue.evaporator,
        });
    },[])

    const handleChange = (value: boolean) => {
        const newValue: any = parseEquipmentFieldsExport(value);

        methods.reset({
            'general': newValue.general,
            'mixer': newValue.mixer,
            'vessel': newValue.vessel,
            'reactor': newValue.reactor,
            'distillation': newValue.distillation,
            'filtration': newValue.filtration,
            'evaporator': newValue.evaporator,
        });
    }

    return (
        <div className="filter-sidebar list-tab">
            <div className="filter-block">
                <div>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="custom-checkbox">
                                <Controller
                                    name={`selectAll`}
                                    control={methods.control}
                                    render={() => (
                                        <input className="ip" type="checkbox"
                                            {...methods.register(`selectAll`)}
                                            onChange={(e: any) => { handleChange(e.target.checked) }} />

                                    )}
                                />
                                <span className="check"></span>
                                <span className="txt">Select All</span>
                            </label>
                        </div>
                    </div>
                    <div className="row xxs-row">
                        <>
                            {
                                selectedEquipmentKeys.map(
                                    (key: string) =>
                                    (
                                        <>
                                            <div className="col-12 mb-2">
                                                {equipmentLabels(key)}
                                            </div>
                                            {/* <div className="col-md-6">
                                                    <label className="custom-checkbox">
                                                        <Controller
                                                            name={`${[key]}.selectAll`}
                                                            control={methods.control}
                                                            defaultValue={false}
                                                            render={() => (
                                                                <input className="ip" type="checkbox"
                                                                    {...methods.register(`${[key]}.selectAll`)} />
                                                            )}
                                                        />
                                                        <span className="check"></span>
                                                        <span className="txt">Select All</span>
                                                    </label>
                                                </div> */}
                                            {equipmentFields?.[key]?.map((item: any) => (
                                                <div className="col-md-6">
                                                    <label className="custom-checkbox">
                                                        <Controller
                                                            name={`${[key]}.${item.value}`}
                                                            control={methods.control}
                                                            defaultValue={false}
                                                            render={() => (
                                                                <input className="ip" type="checkbox"
                                                                    {...methods.register(`${[key]}.${item.value}`)} />
                                                            )}
                                                        />
                                                        <span className="check"></span>
                                                        <span className="txt">{item.name}</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </>
                                    )
                                )
                            }

                        </>
                    </div>
                </div>
            </div>
        </div >
        // </form>
    );
};

export default ExportFields;