import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import Header from "./Header";
import Sidebar from "./Sidebar";

import { RootState } from "../../redux/store";
import { LayoutProps } from "../../types";


export const Layout = ({ children, title, breadCrumbItems }: LayoutProps) => {
  const alert = useSelector((state: RootState) => state.alert);

  useEffect(() => {
    document.title = `Takeda | ${title ? title : 'Home'}`;
    if (alert.open && alert?.message && alert.alertType == "error") {
      toast.dismiss(); toast.error(alert.message)
    }
    if (alert.open && alert?.message && alert?.alertType == "success") {
      toast.dismiss(); toast.success(alert.message)
    }
  }, [alert]);
  return (
    <main className="main-wrapper user-main m-normal">
      <ToastContainer autoClose={1000} />
      <Sidebar />
      <section className="admin-home">
        <Header title={title} items={breadCrumbItems} />
        <div className="inner-container">
          <div className="main-data">{children}</div>
        </div>
      </section>
    </main>
  )
}

export default Layout;