import { useState } from 'react';
import moment from 'moment';

import CompareAuditModal from '../modals/CompareAuditModal';

const AuditTrail = (props: any) => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    return (
        <>
            <div className="table-outer w-full Table-outer-audit">
                <div className="table-responsive theme-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>User</th>
                                {/* <th>Change Type</th> */}
                                <th>Modified At</th>
                                <th className="w-10">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props?.items?.length ? props.items.map((item: any, index: number) => (
                                <tr key={index}>
                                    <td className="f-heavy">{index + 1}</td>
                                    <td>{`${item?.first_name??""} ${item?.last_name??""}`}</td>
                                    {/* <td>{(capitalise(item?.action)) ?? ""}</td> */}
                                    <td>{item?.id ? moment(item?.created_at).format('lll') : ""}</td>
                                    <td className="w-10">
                                        <div className="tb-actions">
                                            <button title='View' type="button" className="icon-btn sm plain" onClick={() => {
                                                setSelectedItem(item);
                                                setOpen(!open);
                                            }}>
                                                <i className="fa-solid fa-eye"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                            ) : (
                                <tr className="text-center">
                                    <td className="f-heavy" colSpan={5}>Data not found.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {selectedItem ? <CompareAuditModal open={open} item={selectedItem} onClose={setOpen} /> : ''}

        </>
    );
};

export default AuditTrail;