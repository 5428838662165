// general
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";

// components
import Layout from "../../components/layout";

// modals
import HelpModal from "../../components/modals/HelpModal";
import CancelConfirmModal from "../../components/modals/CancelFormModal";
import BackConfirmModal from "../../components/modals/BackFormModal";

// Tabs
import {
  Vessel,
  Reactor,
  Filtration,
  Mixer,
  Evaporator,
  GeneralEquipment,
  Distillation
} from "../../components/equipment/tabs";

// context
import { useUser } from "../../components/context/UserContext";

// services
import EquipmentService from "../../services/equipmentService";
import FileService from "../../services/fileService";

// redux
import { RootState } from "../../redux/store";

// interface
import { EquipmentData } from "../../types/equipmentTypes";
import { ResponseProps } from "../../types";

// redux-actions
import { equipmentAction } from "../../redux/actions/equipmentActions";
import { alertCloseAction, alertOpenAction } from "../../redux/actions";

// helpers
import { initialEquipmentData } from "../../utils/equipmentHelper";
import { checkDuplicateFiles } from "../../utils/materialHelper";
import { DashboardHelp } from "../../utils/equipmentHelpContent";
import { findFile, getUpdatedFiles, setTitleNumberInput } from "../../utils/common";
import {
  UPDATE_EQUIPMENT_SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  layoutTitles,
} from "../../utils/constant";
import { cleanPayload } from "./utils";

const EquipmentUpdate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const { authState } = useOktaAuth();
  const { state }: any = useLocation();

  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
  }, []);

  // user context
  const { user } = useUser();
  const auth: any = authState ? authState?.accessToken : "";

  // redux variables
  const equipment = useSelector((state: RootState) => state.equipment);

  useEffect(() => {
    methods.watch();
  });

  useEffect(() => {
    let data = initialEquipmentData(equipment.item)
    methods.reset(data ?? {});
  }, [equipment?.item]);

  // form
  const methods = useForm<EquipmentData>({
    defaultValues: initialEquipmentData(equipment.item),
  });


  // state variables
  const [loading, setLoading] = useState<Boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [openBack, setOpenBack] = useState<boolean>(false);

  useEffect(() => {
    if (params && params?.id) {
      getEquipment();
    }
  }, [params]);

  // To show warning when trying to exit form
  useEffect(() => {
    window.addEventListener("beforeunload", refreshData);
    return () => {
      window.removeEventListener("beforeunload", refreshData);
    };
  }, []);

  const refreshData = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };


  useEffect(() => {
    const filesByCategory: any = {
      drawing: [],
      manufacturer_manual: [],
      sop: [],
      service_log: [],
    };

    Object.keys(equipment?.item?.[equipment?.item?.equipment_type]?.attachments ?? {}).forEach((key: string) => {
      switch (key) {
        case 'drawing':
          filesByCategory.drawing = [
            ...filesByCategory.drawing,
            ...equipment?.item?.[equipment?.item?.equipment_type]?.attachments[key]?.map((attachment: any) => parseFile(attachment)),
          ];
          break;
        case 'manufacturer_manual':
          filesByCategory.manufacturer_manual = [
            ...filesByCategory.manufacturer_manual,
            ...equipment?.item?.[equipment?.item?.equipment_type]?.attachments[key]?.map((attachment: any) => parseFile(attachment)),
          ];
          break;
        case 'sop':
          filesByCategory.sop = [
            ...filesByCategory.sop,
            ...equipment?.item?.[equipment?.item?.equipment_type]?.attachments[key]?.map((attachment: any) => parseFile(attachment)),
          ];
          break;
        case 'service_log':
          filesByCategory.service_log = [
            ...filesByCategory.service_log,
            ...equipment?.item?.[equipment?.item?.equipment_type]?.attachments[key]?.map((attachment: any) => parseFile(attachment)),
          ];
          break;
        default:
        // default
      }
    });

    methods.setValue(`${equipment?.item?.equipment_type}.attachments` as any, filesByCategory);

  }, [equipment.item]);

  const parseFile = (attachment: any) => {
    return {
      category: attachment.category,
      file: {
        name: attachment.display_name,
        type: attachment.mime_type,
      },
      error: false,
      isUpload: true
    }
  }

  const getEquipment = async () => {
    setLoading(true);

    const res = await EquipmentService.getById(params?.id);
    setLoading(false);
    if (res?.status === 200) {
      dispatch(equipmentAction(res?.data?.body?.Item));
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const uploadFile = async (attachmentList: any[], file: any) => {
    let newAttachment = await findFile(attachmentList, file);
    if (newAttachment) {
      const blob = new Blob([newAttachment?.file as any], {
        type: newAttachment?.file?.type,
      });
      const uploadResponse = await fetch(file.signedUrl, {
        method: "PUT",
        body: blob,
      });
      if (uploadResponse.ok) return true;
      return false;
    }
    return false;
  };

  const uploadFileAPI = async (
    data: any,
    attachmentList: any[],
    id?: string
  ) => {
    const result: ResponseProps = {
      code: 200,
      status: "success",
      message: "",
      data: {},
    }
    // setLoading(true); // enable loading
    const filesResponse = await FileService.create("/files/upload", {
      id: equipment?.item?.id ?? id,
      file_for: 'Equipment',
      files: attachmentList.map(
        (attachment: any) => ({
          category: attachment?.category,
          key: attachment.file?.name,
          mimeType: attachment?.file?.type,
        })),
    });
    // setLoading(false); // disable loading
    if (filesResponse?.status === 200) {
      const filesResult = filesResponse?.data?.body;
      // setLoading(true); // enable loading
      return Promise.all(filesResult.files.map((file: any) => uploadFile(attachmentList, file)))
        .then(async (res) => {
          // update equipment object
          filesResult.files.map((file: any) => delete file.signedUrl);

          if (equipment?.item?.attachments) {
            filesResult.files = [
              ...equipment?.item?.attachments,
              ...filesResult.files,
            ];
          }

          const r = filesResult?.files?.reduce((e: any, t: any) => {
            switch (t.category) {
              case 'Drawing':
                e.drawing = [...e?.drawing, t];
                break;
              case 'Manufacturer Manual':
                e.manufacturer_manual = [...e?.manufacturer_manual, t];
                break;
              case 'SOP':
                e.sop = [...e?.sop, t];
                break;
              case 'Service Log':
                e.service_log = [...e?.service_log, t];
                break;
            }
            return e;
          }, {
            drawing: [],
            manufacturer_manual: [],
            sop: [],
            service_log: [],
          });

          filesResult.files = await getUpdatedFiles(filesResult?.files ?? [], attachmentList, !0);

          const payload = {
            ...data,
            id: equipment?.item?.id ?? id,
            uid: `${auth?.claims?.uid}`,
          }

          payload[methods.control._formValues?.equipment_type] = {
            ...methods.control._formValues[methods.control._formValues?.equipment_type],
            attachments: {
              drawing: await getUpdatedFiles(
                [...equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.drawing ?? [], ...r.drawing],
                methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.drawing ?? [], !0),
              manufacturer_manual: await getUpdatedFiles(
                [...equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.manufacturer_manual ?? [], ...r.manufacturer_manual],
                methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.manufacturer_manual ?? [], !0),
              sop: await getUpdatedFiles([...equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.sop ?? [], ...r.sop],
                methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.sop ?? [], !0),
              service_log: await getUpdatedFiles([...equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.service_log ?? [], ...r.service_log],
                methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.service_log ?? [], !0),
            },
          }

          cleanPayload(payload)
          const equipmentResponse = await EquipmentService.update(payload);
          if (equipmentResponse?.status === 200) {
            dispatch(equipmentAction(equipmentResponse?.data?.body));
            result.data = equipmentResponse?.data?.body;
            result.status = "success";
            result.message = equipmentResponse?.data?.message ?? UPDATE_EQUIPMENT_SUCCESS_MESSAGE;
            return result;
          }
          result.code = equipmentResponse?.data?.code;
          result.status = "error";
          result.message = equipmentResponse?.data?.message ?? ERROR_MESSAGE;
          return result;
        })
        .catch((err) => {
          result.code = 400;
          result.status = "error";
          result.message = err.message ?? ERROR_MESSAGE;
          return result;
        });
    }
    result.code = filesResponse?.data?.code;
    result.status = "error";
    result.message = filesResponse?.data?.message ?? ERROR_MESSAGE;
    return result;
  };

  const apiRequest = async () => {
    const result: ResponseProps = {
      code: 200,
      status: "success",
      message: "",
      data: {},
    }
    const attachments = Object.keys(methods.control._formValues[methods.control._formValues?.equipment_type]?.attachments).reduce((e: any, t: any) => {
      e = [...e, ...methods.control._formValues[methods.control._formValues?.equipment_type].attachments[t]];
      return e;
    }, []);
    // attachments list
    let attachmentList = attachments.filter((attachment: any) => !attachment.isUpload);
    // setLoading(true);

    if (attachmentList?.length) {
      // upload files API
      return { ...result, ...await uploadFileAPI(methods.control._formValues, attachmentList) };
    }

    const {
      id,
      equipment_type,
      equipment_owner,
      tag_number,
      location,
      brand,
      manufacturer_model_number,
      material_of_construction,
      application_and_scale,
    } = methods.control._formValues;

    let payload: any = {
      uid: auth?.claims?.uid,
      id: params?.id ?? id,
      equipment_type,
      equipment_owner,
      tag_number,
      location,
      brand,
      manufacturer_model_number,
      material_of_construction,
      application_and_scale,
    }

    payload[methods.control._formValues?.equipment_type] = {
      ...methods.control._formValues[methods.control._formValues?.equipment_type],
      attachments: {
        drawing: await getUpdatedFiles(
          equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.drawing ?? [],
          methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.drawing ?? [], !1),
        manufacturer_manual: await getUpdatedFiles(
          equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.manufacturer_manual ?? [],
          methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.manufacturer_manual ?? [], !1),
        sop: await getUpdatedFiles(equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.sop ?? [],
          methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.sop ?? [], !1),
        service_log: await getUpdatedFiles(equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.service_log ?? [],
          methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.service_log ?? [], !1),
      },
    }

    // setLoading(true); // enable loading
    cleanPayload(payload)
    const res = await EquipmentService.update(payload);
    if (res?.data?.code == 200) {
      dispatch(equipmentAction(res?.data?.body));
      result.data = res?.data?.body;
      result.status = "success";
      result.message = res?.data?.message ?? UPDATE_EQUIPMENT_SUCCESS_MESSAGE;
      return result;
    }
    result.code = res?.data?.code;
    result.status = "error";
    result.message = res?.data?.message ?? ERROR_MESSAGE;
    return result;
  }

  const validateForm = async () => {
    //To check data in required fields on save&close button click
    if (
      !(
        methods.control._formValues?.equipment_type?.length &&
        methods.control._formValues?.equipment_owner?.length &&
        methods.control._formValues?.tag_number?.length
      )
    ) {
      dispatch(alertOpenAction("Please fill required fields first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }

    const attachments =
      Object.keys(methods.control._formValues[methods.control._formValues?.equipment_type]?.attachments).reduce((e: any, t: any) => {
        e = [...e, ...methods.control._formValues[methods.control._formValues?.equipment_type].attachments[t]];
        return e;
      }, []);

    const invalidateFiles = attachments.find((attachment: any) => attachment.error);
    if (invalidateFiles) { // Check invalid file
      dispatch(alertOpenAction('Max file size exceed. Please try again with valid files.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }

    const isDuplicate = await (await checkDuplicateFiles(attachments))?.map((item: any) => item.isDuplicate).includes(true);
    if (isDuplicate) {
      dispatch(alertOpenAction("Please remove duplicate files.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }
    return true;
  }

  const onSubmit: SubmitHandler<any> = async () => {
    if (await validateForm()) {
      setLoading(true); // enable loading
      const apiResponse = await apiRequest();
      setLoading(false); // disable loading
      if (apiResponse.code === 200) {
        dispatch(alertOpenAction(apiResponse.message, 'success'));
      } else {
        dispatch(alertOpenAction(apiResponse.message, 'error'));
      }
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  // Cancel button functions
  const saveEquipment = async () => {
    if (
      methods.formState.isDirty ||
      Object.keys(methods.formState.dirtyFields).length
    ) {
      setOpen(false);
      setOpenBack(false);
      if (await validateForm()) {
        setLoading(true); // enable loading
        const apiResponse = await apiRequest();
        setLoading(false); // disable loading
        if (apiResponse.code === 200) {
          dispatch(alertOpenAction(apiResponse.message, 'success'));
          if (localStorage?.getItem('page') == 'find') {
            localStorage?.removeItem('page');
            history.push(`/equipments`)
          } else {
            history.push("/equipments/screening");
          }
        } else {
          dispatch(alertOpenAction(apiResponse.message, 'error'));
        }
        setTimeout(() => dispatch(alertCloseAction()));
      }
    } else {
      setOpen(false);
      dispatch(alertOpenAction("Please update data first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const dontSave = () => {
    if (localStorage?.getItem('page') == 'find') {
      localStorage?.removeItem('page');
      history.push(`/equipments`)
    } else {
      history.push("/equipments/screening");
    }
  };

  const handleClose = (path: string) => {
    if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
      setOpen(true);
      return;
    }
    history.push(path);
  }

  const breadCrumbItems = [
    { label: "Home", path: "#", onClick: () => handleClose("/") },
    { label: "Equipments", path: "#", onClick: () => handleClose("/equipments") },
    { label: state?.page === 'list' ?  "Equipment Screening" : "Find Equipment", path: "#", onClick: () => handleClose(state?.page === 'list' ?  "/equipments/screening": "/equipments/find") },
    { label: "Update Equipment", path: "#", }
  ];

  return (
    <Layout title={layoutTitles.updateEquipment} breadCrumbItems={breadCrumbItems}>
      <CancelConfirmModal
        open={open}
        setOpen={setOpen}
        saveMaterial={saveEquipment}
        dontSave={dontSave}
      />
      <BackConfirmModal
        open={openBack}
        setOpen={setOpenBack}
        saveMaterial={saveEquipment}
        dontSave={dontSave}
      />
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={DashboardHelp.title}
        content={DashboardHelp.content}
      />

      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            methods.handleSubmit(onSubmit);
          }}
          noValidate
        >
          <div className="sec-info control-head">
            <div className="back">
              <button title="Back" className="icon-btn alter" onClick={() => {
                if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
                  setOpenBack(true);
                  return;
                }
                history.push(`/equipments`);
              }}>
                <i className="fa-solid fa-arrow-left" />
              </button>
            </div>
            <div className="head">
              <h1 className="head-lg">Update Equipment</h1>
            </div>
            <div className="right">
              <button
                type="submit"
                title="Save"
                disabled={loading ? true : false}
                className={`icon-btn ${loading ? "disabled  " : ""}`}
                onClick={onSubmit}
              >
                <i className="fa-regular fa-floppy-disk"></i>
              </button>

              <button className="icon-btn alter" title="Close" onClick={() => handleClose(`/equipments`)}>
                <i className="fa-solid fa-xmark" />
              </button>
              <button
                className="icon-btn alter"
                title="Help"
                onClick={() => {
                  setOpenHelp(true);
                }}
              >
                <i className="fa-solid fa-question" />
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="theme-card">
                <div className="body">
                  <GeneralEquipment readOnly={true} user={user} /> {/*Readonly is applied on 3 fields in child component*/}

                  {methods?.control?._formValues?.equipment_type == "vessel" ? (
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3">
                          <Tab>
                            <div className="tab-link">Vessel</div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Vessel
                          attachments={
                            methods.control._formValues.vessel?.attachments
                          }
                        />
                      </TabPanel>
                    </Tabs>
                  ) : (
                    ""
                  )}
                  {methods?.control?._formValues?.equipment_type ==
                    "reactor" ? (
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3">
                          <Tab>
                            <div className="tab-link">Reactor/Crystallizer</div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Reactor
                          attachments={
                            methods.control._formValues.reactor?.attachments
                          }
                        />
                      </TabPanel>
                    </Tabs>
                  ) : (
                    ""
                  )}
                  {methods?.control?._formValues?.equipment_type ==
                    "distillation" ? (
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3">
                          <Tab>
                            <div className="tab-link">Distillation</div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Distillation
                          attachments={methods.control._formValues.distillation?.attachments ?? {}}
                        />
                      </TabPanel>
                    </Tabs>
                  ) : (
                    ""
                  )}
                  {methods?.control?._formValues?.equipment_type ==
                    "filtration" ? (
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3">
                          <Tab>
                            <div className="tab-link">Filtration</div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Filtration
                          attachments={
                            methods.control._formValues.filtration?.attachments
                          }
                        />
                      </TabPanel>
                    </Tabs>
                  ) : (
                    ""
                  )}
                  {methods?.control?._formValues?.equipment_type == "mixer" ? (
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3">
                          <Tab>
                            <div className="tab-link">Mixer</div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Mixer
                          attachments={
                            methods.control._formValues.mixer?.attachments
                          }
                        />
                      </TabPanel>
                    </Tabs>
                  ) : (
                    ""
                  )}
                  {methods?.control?._formValues?.equipment_type ==
                    "evaporator" ? (
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3">
                          <Tab>
                            <div className="tab-link">Evaporator</div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Evaporator
                          attachments={
                            methods.control._formValues.evaporator?.attachments
                          }
                        />
                      </TabPanel>
                    </Tabs>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <div className="theme-loader show fixed">
              <div className="loader-outer">
                <div className="loader"></div>
              </div>
            </div>
          ) : (
            ""
          )}
        </form>
      </FormProvider>
    </Layout>
  );
};

export default EquipmentUpdate;
