import { alertConstants } from "../constants";

const initialState = {
  open: false,
  close: false,
  alertType: "",
  message: "",
};

export function alertReducer(state = initialState, action: any) {
  switch (action.type) {
    case alertConstants.ALERT_OPEN:
      return {
        ...state,
        open: true,
        alertType: action.payload.alertType,
        message: action.payload.message,
      };
    case alertConstants.ALERT_CLOSE:
      return {
        ...state,
        open: false,
        message: "",
      };
    default:
      return {
        ...state,
      };
  }
}
