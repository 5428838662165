import { TableHeadProps, TableHeadType } from "./TableHeader";

interface TableCellProps {
  head: TableHeadProps;
  onClick?: () => void;
  style?: string;
  value?: any;
}

const TableCell = (props: TableCellProps) => {
  const { head, onClick, value, style = "" } = props;

  const handleClick = () => {
    if (typeof onClick === "function") {
      onClick();
    }
  }

  switch (head?.type ?? TableHeadType.DEFAULT) {
    case TableHeadType.CHECKBOX: {
      return (
        <td className={style}>
          <input
            type="checkbox"
            onChange={handleClick}
            checked={value}
          />
        </td>
      )
    };

    case TableHeadType.EXPAND: {
      return (
        <td className={`w-1 expand-cell ${style}`}>
          <button
            type="button"
            className="icon-btn sm plain"
            onClick={handleClick}
          >
            <i
              className={`fa-solid fa-chevron-${value ? "down" : "right"}`}
            />
          </button>
        </td>
      )
    }

    case TableHeadType.CUSTOM_CELL:
    case TableHeadType.DEFAULT:
    default: {
      return (
        <td className={style}>{value}</td>
      )
    }
  }
}

export default TableCell;
