import { Link } from "react-router-dom";

export interface TabType {
  title: string;
  onClick?: () => void;
  navigateTo?: string;
  isCompleted?: boolean;
  dataCy?: string;
  isActive?: boolean;
}

interface TabProps {
  tabs: TabType[];
}

const Tabs = (props: TabProps) => {
  const { tabs } = props;

  return (
    <div className="admin-tabs">
      <ul className="inner">
        {tabs.map((tab, index) => (
          <li key={index}>
            <Link
              data-cy={tab.dataCy}
              to={tab.navigateTo || "#"}
              className={`tab-link ${tab.isActive ? "active" : ""} ${tab.isCompleted ? "done" : ""} `}
              onClick={tab?.onClick}
            >
              {tab.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Tabs;
