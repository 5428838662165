import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberInput from "../NumberInput";

import useAlert from "../../../../hooks/useAlert";
import useFileDownload from "../../../../hooks/useFileDownload";
import DataGrid from "./DataGrid";
import FileService from "../../../../services/fileService";
import CrystallizationService from "../../../../services/crystallization";
import CustomLoader from "../../../../components/common/CustomLoader";
import MaterialService from "../../../../services/materialService";
import Select from 'react-select';
import AppSelect from "../../../../components/common/AppSelect";

const zeros = Array(100).fill(0)
  .map(
    (_, i) => ({
        "one": 0,
        "two": 0,
        "three": 0,
      }
    ),
  );

function useSolvents() {
  const [loading, setLoading] = useState(false);
  const [solvents, setSolvents] = useState<any>([]);
  useEffect(() => {
    setLoading(true);
    MaterialService.getSolvents().then(res => {
      if (res.status === 200 && res.data.body.solvensts) {
        setSolvents(res.data.body.solvensts || []);
      }
    });

  }, []);
  return solvents;
}

const defaultValues = {
  api_url: process.env.REACT_APP_API_URL,
  rho_solv: "",
  rho_antisolv: "",
  // sol_yield: null,
  // solv_init: null,
  // antisolv_init: null,
  // temp_init: null,
  // antisolv_added: null,
  // temp_final: null,
  user_query: "Yes",
  sheet_data: zeros,
};

const schema = yup.object().shape({
  api_url: yup.string(),
  rho_solv: yup.string().required("Please Select Solvent"),
  rho_antisolv: yup.string().required("Please Select Antisolvent"),
  sol_yield: yup.number().required("Please enter Upstream (Reaction + Extration etc.) Yield (%)"),
  solv_init: yup.number().required("Please enter Start Solvent (L/kg)"),
  antisolv_init: yup.number().required("Please enter Start Antisolvent (L/kg)"),
  temp_init: yup.number().required("Please enter Start Temperature (Celsius)"),
  antisolv_added: yup.number().required("Please enter Antisolvent to Add (L/kg)"),
  temp_final: yup.number().required("Please enter Final Temperature (Celsius)"),
  user_query: yup.string(),
  sheet_data: yup.array().test(
    "has-values",
    "Please enter the solubility data",
    // @ts-ignore
    (value: any[]) => value.length !== 0,
  ),
});

type FormSchema = yup.InferType<typeof schema>


export default function Tool1Form({ onSubmit, responseData, setResponseData }: {
  onSubmit: any,
  responseData: any,
  setResponseData: any
}) {
  const form = useForm<FormSchema>({
    // @ts-ignore
    resolver: yupResolver(schema), defaultValues,
  });
  const solvents = useSolvents();
  const { setValue, handleSubmit } = form;
  const { errorAlert, successAlert } = useAlert();
  const { formState: { errors } } = form;
  const [onReset, setOnReset] = useState<any>(null)
  useEffect(() => {
    if (Object.keys(errors).length) {
      const key: any = Object.keys(errors)[0];
      // @ts-ignore
      const errorMessage = errors[key].message;
      errorAlert(errorMessage);
    }
  }, [errors]);
  const { downloadFile, loading } = useFileDownload();
  const [showGenFile, setShowGenFile] = useState(true);

  const options = solvents.map((solvent:any) => ({
    value: solvent.density,
    label: solvent.material_name,
  }));

  return (<FormProvider {...form}>
    <form onSubmit={handleSubmit(async data => {
        setResponseData(null);
        onSubmit({
          ...data,
          // @ts-ignore
          sheet_data: data.sheet_data.filter(
            vals => {
              // @ts-ignore
            return  Array.from(Object.values(vals)).reduce((a, v) => a + v, 0) !== 0;
            },
          ),
        });
        setShowGenFile(true);
      },
    )}>
      {loading && <CustomLoader />}
      <div className="form-group">
        {
          responseData && showGenFile && <div className="uploaded-files">
            <label className="form-label">Generated Output File</label>
            <div className={`upfile ${loading && "cursor-wait"}`}>
                  <span onClick={() => {
                  }}>
                    <i className={`fa-solid fa-file`} />
                  </span>
              <div className="text w-full" style={{
                marginRight: "0 !important",
              }}>
                <div className="attachment-name w-full flex justify-between">
                    <span>
                    {responseData.xlsx_file_name.split("/").slice(-1)[0]}
                    </span>
                  <button type="button" className="fa-solid fa-download close"
                          style={{
                            cursor: loading ? "wait" : "pointer",
                          }}
                          onClick={() => !loading && downloadFile(
                            responseData.xlsx_file_name,
                          )}></button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <h3>Input to Crystallization</h3>

      {/* Field rho_solv - Solvent density dropdown */}
      <AppSelect
        label="Solvent"
        name="rho_solv"
        control={form.control}
        options={options}
        placeholder="Type Solvent..."
        defaultValue={form.control._formValues.rho_solv}
        />

      {/* Field rho_antisolv - Antisolvent dropdown */}
      <AppSelect
        label="AntiSolvent"
        name="rho_antisolv"
        control={form.control}
        options={options}
        placeholder="Type AntiSolvent..."
        defaultValue={form.control._formValues.rho_antisolv}
        />

      {/* Field sol_yield */}
      <div className="form-group">
        <label htmlFor="sol_yield">
          <span className="form-label">Upstream (Reaction + Extration etc.) Yield (%):</span>
          <NumberInput name="sol_yield" defaultValue={100} />
        </label>
      </div>

      {/* Field solv0 */}
      <div className="form-group">
        <label htmlFor="solv_init">
          <span className="form-label">Start Solvent (L/kg):</span>
          <NumberInput name="solv_init" />
        </label>
      </div>

      {/* Field anti0 */}
      <div className="form-group">
        <label htmlFor="antisolv_init">
          <span className="form-label">Start Antisolvent (L/kg):</span>
          <NumberInput name="antisolv_init" />
        </label>
      </div>

      {/* Field temp0 */}
      <div className="form-group">
        <label htmlFor="temp_init">
          <span className="form-label">Start Temperature (Celsius):</span>
          <NumberInput name="temp_init" />
        </label>
      </div>

      <h3>Crystallization Process Variables</h3>

      {/* Field anti1 */}
      <div className="form-group">
        <label htmlFor="antisolv_added">
          <span className="form-label">Antisolvent to add (L/kg):</span>
          <NumberInput name="antisolv_added" />
        </label>
      </div>

      {/* Field temp1 */}
      <div className="form-group">
        <label htmlFor="temp_final">
          <span className="form-label">Final Temperature (Celsius):</span>
          <NumberInput name="temp_final" />
        </label>
      </div>

      <DataGrid setOnReset={setOnReset} />

      <div className="flex justify-between mt-4">
        <button type="submit" className="bg-theme text-white py-2 px-4 rounded-lg text-lg">Run</button>
        <button type="reset" className="bg-theme text-white py-2 px-4 rounded-lg text-lg" onClick={(e) => {
          e.preventDefault();
          // @ts-ignore
          form.reset({
            ...defaultValues,
            // @ts-ignore
            sol_yield: null,
            // @ts-ignore
            solv_init: null,
            // @ts-ignore
            antisolv_init: null,
            // @ts-ignore
            temp_init: null,
            // @ts-ignore
            antisolv_added: null,
            // @ts-ignore
            temp_final: null,
          });
          if (onReset) onReset();
          setResponseData(null);
        }}>Reset
        </button>
      </div>

    </form>
  </FormProvider>);

}
