import React from 'react'

const Table = () => {
    return (
        <table className="min-w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-scroll" title='Solvents'>
            <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-2 py-3">
                        Solvent
                    </th>
                    <th scope="col" className="px-2 py-3">
                        Solvent
                    </th>
                    <th scope="col" className="px-2 py-3">
                        Solvent
                    </th>
                    <th scope="col" className="px-2 py-3">
                        Solvent
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="px-2 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        x
                    </th>
                    <td className="px-2 py-3">
                        y
                    </td>
                    <td className="px-2 py-3">
                        z
                    </td>
                    <td className="px-2 py-3">
                        a
                    </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="px-2 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        x
                    </th>
                    <td className="px-2 py-3">
                        y
                    </td>
                    <td className="px-2 py-3">
                        z
                    </td>
                    <td className="px-2 py-3">
                        a
                    </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="px-2 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        x
                    </th>
                    <td className="px-2 py-3">
                        y
                    </td>
                    <td className="px-2 py-3">
                        z
                    </td>
                    <td className="px-2 py-3">
                        a
                    </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="px-2 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        x
                    </th>
                    <td className="px-2 py-3">
                        y
                    </td>
                    <td className="px-2 py-3">
                        z
                    </td>
                    <td className="px-2 py-3">
                        a
                    </td>
                </tr>
            </tbody>
        </table>

    )
}

export default Table;