import { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import BackConfirmModal from "../modals/BackFormModal";

// redux actions
import { alertOpenAction, alertCloseAction } from "../../redux/actions";

const BackButton = (props: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params: any = useParams();
    const [openBack, setOpenBack] = useState(false);

    const saveMaterialBack = async () => {
        if (props.material) {
            setOpenBack(false); 
            await props.onSubmit(props.material);
            // params?.id ? history.push(`/materials/${props?.material?.id}/update`) : history.push(`/materials/new/`);
        } else {
            //setOpen(false)
            setOpenBack(false);
            dispatch(alertOpenAction('No data updated to save.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        }
    }

    const dontSaveBack = () => {
        params?.id ? history.push(`/materials/${props?.material?.id}/update`) : history.push(`/materials/new/`);
    }

    const handleBack = () => {
        params?.id ? history.push(`/materials/${props?.material?.id}/update`) : history.push(`/materials/new/`);
    }

    return (
        <>
            <BackConfirmModal
                open={openBack}
                setOpen={setOpenBack}
                saveMaterial={saveMaterialBack}
                dontSave={dontSaveBack}
            />
            <Link title="Back" to='#' className="icon-btn alter" onClick={() => {
                if (props.formState.isDirty || Object.keys(props.formState.dirtyFields).length) {
                    setOpenBack(true);
                    return;
                }
                handleBack();
            }}>
                <i className="fa-solid fa-arrow-left" />
            </Link>
        </>
    )
}

export default BackButton;