import { getValueFromConcatenatedKey } from "../../../utils/materialKeyLabelHelper";

export interface Field {
  label: string;
  key: string;
  type?: any;
  selected?: boolean;
}
export interface KeyValueType {
  [key: string]: any;
}
export interface MaterialVisualizationTableProps {
  materials: Material[];
  selectedFields: Field[];
}
export type Attachment = {
  mime_type: string;
  file_name: string;
  location: string;
  id: string;
  file_for: string;
  category: string;
  display_name: string;
  field_name: string;
};

export type Material = {
  id: string;
  phase: "Solid" | "Liquid" | "Gas";
  material_name: string;
  lot_number: string;
  attachments?: Record<string, Attachment[]>;
  vendor_name?: string;
  [key: string]: any; // Allow any additional properties
};

export type TransformedMaterial = Record<string, (Attachment & Material)[]>;

export const transformMaterialsData = (materials: Material[]): TransformedMaterial[] => {
  return materials.map((material) => {
    const { attachments, id, phase, material_name, lot_number, vendor_name } =
      material;

    const transformedAttachments = attachments
      ? Object.entries(attachments).reduce((acc, [key, attachmentArray]) => {
          acc[key] = attachmentArray.map((attachment) => ({
            ...attachment,
            id,
            phase,
            material_name,
            lot_number,
            vendor_name,
          }));
          return acc;
        }, {} as Record<string, (Attachment & Material)[]>)
      : {};

    return transformedAttachments;
  });
};

export const parseFields = (fields: string[]) => {
  return fields.map((key) => {
    const keys = key.split("-");
    return {
      label: getValueFromConcatenatedKey(key),
      key: key,
      selected: true,
    };
  });
};

const flattenArray = (arr: any, parentKey = '') => {
  const result: string[] = [];

  arr.forEach((item: any) => {
      if (typeof item === 'string') {
          result.push(parentKey ? `${parentKey}-${item}` : item);
      } else if (Array.isArray(item)) {
          // Recursively flatten any nested arrays
          result.push(...flattenArray(item, parentKey));
      } else if (typeof item === 'object') {
          for (const key in item) {
              const subArray = item[key];
              result.push(...flattenArray(subArray, parentKey ? `${parentKey}-${key}` : key));
          }
      }
  });

  return result;
}