import http from "./httpService";
import { apiRoutes } from "../utils/apiRoutes";

const create = async (url: string, data: any) => {
  return await http.post(url, data);
};

const download = async (payload: { file: string }) => {
  return await http.post(apiRoutes.DOWNLOAD_FILE, payload);
}

const FileService = {
  create,
  download
};

export default FileService;