import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import _ from "lodash";

// components
import Layout from "../../components/layout";
import BackButton from "../../components/common/BackButton";
import SolubilityInformation from "../../components/material/tabs/SolubilityInformation";
import UploadSolubilityData from "../../components/solubility/uploadSolubilityData";

// modals
import CancelConfirmModal from "../../components/modals/CancelFormModal";
import HelpModal from "../../components/modals/HelpModal";

// services
import FileService from "../../services/fileService";
import MaterialService from "../../services/materialService";

// redux actions
import { alertCloseAction, alertOpenAction } from "../../redux/actions";
import { materialAction } from "../../redux/actions/materialActions";

// props
import { RootState } from "../../redux/store";

// helpers
import {
  checkDuplicateFiles,
  getSolubilityFileFields,
  materialSolubilityFileFields,
  parseAttachments,
} from "../../utils/materialHelper";
import { convertStringToNumber, findFile, getUpdatedFiles, setTitleNumberInput } from "../../utils/common";
import { MaterialInnerSolubilityHelp } from "../../utils/helpContent";
import { UPLOAD_FILE_MAXLENGTH } from "../../utils";
import { layoutTitles } from "../../utils/constant";

const Solubility = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : "";

  // Show data in input fields to update
  const material = useSelector((state: RootState) => state?.material?.item);
  // Added for solubility attachment fields to handle attachments
  const [simulation, setSimulation] = useState<any>(null);

  // states
  const [open, setOpen] = useState(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const materialSolubility = useSelector((state: RootState) => state.material);

  // solubility
  useEffect(() => {
    if (simulation) resetUploadSolubility(simulation);
  }, [simulation]);

  // initialData
  const initialData: any = {
    model: "",
    solvent: "",
    solvent1: "",
    solvent2: "",
    temperature: 5,
    solubility_value: "",
    file_path: "",
    turbidity_measured: "",
    visual: false,
    plot: false,
    material_name: material?.material_name ?? null,
    vendor_name: material?.vendor_name ?? null,
    lot_number: material?.lot_number ?? null,
    solubility: {
      main_solvent: material?.solubility?.main_solvent?.toString() ?? "",
      solubility_in_main_solvent: material?.solubility?.solubility_in_main_solvent?.toString() ?? "",
      main_solvent_temperature: material?.solubility?.main_solvent_temperature?.toString() ?? "",
      main_solvent_reference: material?.solubility?.main_solvent_reference?.toString() ?? "",
      second_solvent: material?.solubility?.second_solvent?.toString() ?? "",
      solubility_in_second_solvent: material?.solubility?.solubility_in_second_solvent?.toString() ?? "",
      second_solvent_temperature: material?.solubility?.second_solvent_temperature?.toString() ?? "",
      second_solvent_reference: material?.solubility?.second_solvent_reference?.toString() ?? "",
      attachments: material?.solubility?.attachments ? parseAttachments(material?.solubility?.attachments) : [],
    },
    // Added for solubility attachment fields to handle attachments
    attachments: {
      model3: [],
      solubility: {
        unchainedLab: [],
        hplc: [],
        crystal16: [],
        crystalline: [],
        gravimetryMethod: [],
        solubilityCurve1: [],
        solubilityCurve2: [],
        solubilityCurve3: [],
        solubilityCurve4: [],
        solubilityCurve5: [],
      },
    },
  };

  const methods = useForm({ defaultValues: initialData });

  useEffect(() => {
    methods?.watch();
  });
  // Added for solubility attachment fields to handle attachments
  let allAttachments: any = {
    model3: [],
    solubility: {
      unchainedLab: [],
      hplc: [],
      crystal16: [],
      crystalline: [],
      gravimetryMethod: [],
      solubilityCurve1: [],
      solubilityCurve2: [],
      solubilityCurve3: [],
      solubilityCurve4: [],
      solubilityCurve5: [],
    },
  };
  // Added for solubility attachment fields to handle attachments
  useEffect(() => {
    if (material && !Array.isArray(material)) {
      if (material?.vendor_name !== methods.control._formValues?.vendor_name) {
        methods.setValue("material_name", ""); // clear material_name field
        methods.setValue("lot_number", "");    // clear lot_number field
        methods.setValue("attachments", allAttachments);
      }
      if (material?.material_name !== methods.control._formValues?.material_name) {
        methods.setValue("lot_number", "");    // clear lot_number field
        methods.setValue("attachments", allAttachments);
      }
      if (material?.id) {
        getSaveSimulations(material.id)
      }
    }
  }, [
    methods.control._formValues.vendor_name,
    methods.control._formValues.material_name,
    methods.control._formValues.lot_number,
  ]);

  const getSaveSimulations = async (id: string) => {
    setLoading(true); // enable loading
    const res = await MaterialService.create("/simulations/get-simulations-files-data", {
      id,
      uid: auth?.claims?.uid,
      simulation_type: "material"
    });

    setLoading(false); // disable loading
    if (res?.data?.code == 200) {
      const result = res?.data;
      if (result.body && Object.keys(result.body).length) {
        setSimulation(result.body);
      }
    } else {
      dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
      return false;
    };
  }

  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
    methods.reset(methods.control._formValues);
    if (material?.solubility?.attachments?.length) {
      methods.setValue("solubility.attachments", parseAttachments(material.solubility.attachments));
    }

  }, [material]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      let displayNames = material?.solubility?.attachments ?? [];
      displayNames = displayNames?.map((attachment: any) => attachment.display_name);
      const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
        if (displayNames.includes(e?.target?.files?.[key].name)) {
          return true;
        }
      });
      if (isDuplicate) {
        dispatch(alertOpenAction("Same file already uploaded.", "error"));
        setTimeout(() => dispatch(alertCloseAction()));
        return;
      }
      methods.setValue("solubility.attachments", [...methods?.control?._formValues?.solubility?.attachments ?? [], ...Object.keys(e?.target?.files ?? []).map(
        (key: any) => {
          const fileSize = e?.target?.files?.[key]?.size ?? 0;
          return {
            category: "Solubility Curve",
            file: e?.target?.files?.[key],
            error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
          };
        })], { shouldDirty: true, shouldTouch: true });
    }
  };

  // Remove selected file
  const removeFile = (index: number, name: string) => {
    methods.setValue("solubility.attachments", methods.control._formValues.solubility.attachments.filter((attachment: any, i: number) => i !== index), {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const uploadFile = async (file: any) => {
    const newAttachment = await findFile(methods.control._formValues.solubility.attachments, file);
    if (newAttachment) {
      const blob = new Blob([newAttachment?.file as any], { type: newAttachment?.file?.type });
      const uploadResponse = await fetch(file.signedUrl,
        {
          method: "PUT",
          body: blob,
        });
      if (uploadResponse.ok) return true;
      return false;
    }
    return false;
  };

  const uploadFileAPI = async (attachmentList: any[]) => {
    if (attachmentList?.length) {
      // file upload request
      const res = await FileService.create("/files/upload", {
        id: material?.id,
        file_for: "Material",
        files: attachmentList.map((attachment: any) => ({
          category: attachment?.category,
          key: attachment.file?.name,
          mimeType: attachment?.file?.type,
        })),
      });

      if (res?.status === 200) {
        const filesResult = res?.data;
        // setLoading(true); // enable loading
        if (filesResult?.body?.files)
          return Promise.all(filesResult.body.files.map((file: any) => uploadFile(file)))
            .then(async (res) => {
              // update material object
              filesResult.body.files.map((file: any) => delete file.signedUrl);

              let solubilityData = {
                ...methods.control._formValues.solubility,
                ...[
                  "solubility_in_main_solvent", "main_solvent_temperature", "solubility_in_second_solvent", "second_solvent_temperature",
                ].reduce((e: any, t: any) => {
                  e[t] = convertStringToNumber(methods?.control?._formValues?.solubility[t]);
                  return e;
                }, {}),
              };

              delete solubilityData.id;
              delete solubilityData.uid;
              delete solubilityData.lot_number;
              delete solubilityData.material_name;
              delete solubilityData.vendor_name;
              delete solubilityData.phase;
              delete solubilityData.page;
              delete solubilityData.sort_key;
              delete solubilityData.partition_key;

              const payload = {
                ...material,
                id: material?.id,
                uid: auth?.claims?.uid,
                solubility: solubilityData,
              };

              if (material?.solubility?.attachments?.length) {
                payload.solubility.attachments = [...material?.solubility?.attachments, ...filesResult.body.files];
              } else {
                payload.solubility.attachments = filesResult.body.files;
              }


              payload.solubility.attachments = _.uniqBy(payload.solubility.attachments, function (e: any) {
                return e.display_name;
              });
              payload.solubility.attachments = await getUpdatedFiles(payload.solubility.attachments, methods.control._formValues.solubility.attachments, !0);

              // material update request
              const materialResponse = await MaterialService.update(payload);
              if (materialResponse?.status === 200) {
                dispatch(materialAction(materialResponse?.data?.body));
                return true;
              }
              return false;
            })
            .catch(() => {
              return false;
            });
      }
      return false;
    }
    return true;
  };

  const apiRequest = async (newAttachments: any[]) => {
    if (newAttachments?.length) {
      return await uploadFileAPI(newAttachments);
    }

    let solubilityData = {
      ...methods.control._formValues.solubility,
      ...[
        "solubility_in_main_solvent", "main_solvent_temperature", "solubility_in_second_solvent", "second_solvent_temperature",
      ].reduce((e: any, t: any) => {
        e[t] = convertStringToNumber(methods?.control?._formValues?.solubility[t]);
        return e;
      }, {}),
    };

    delete solubilityData.id;
    delete solubilityData.uid;
    delete solubilityData.lot_number;
    delete solubilityData.material_name;
    delete solubilityData.vendor_name;
    delete solubilityData.phase;
    delete solubilityData.page;
    delete solubilityData.sort_key;
    delete solubilityData.partition_key;

    const payload = {
      ...material,
      id: material?.id,
      uid: auth?.claims?.uid,
      solubility: {
        ...solubilityData,
        attachments: await getUpdatedFiles(material?.solubility?.attachments ?? [], methods.control._formValues?.solubility?.attachments ?? [], !1),
      },
    };

    // material update request
    const materialResponse = await MaterialService.update(payload);
    if (materialResponse?.status === 200) {
      dispatch(materialAction(materialResponse?.data?.body));
      return true;
    }
    return false;
  };

  const validateForm = async () => {
    const newAttachments = methods.control._formValues?.solubility?.attachments ?? [].filter((attachment: any) => !attachment.isUpload);
    if (newAttachments?.length) {
      const invalidateFiles = methods.control._formValues.solubility.attachments.find((attachment: any) => attachment.error);
      // Check invalid files.
      if (invalidateFiles) {
        dispatch(alertOpenAction("Max file size exceed. Please try again with valid files.", "error"));
        setTimeout(() => dispatch(alertCloseAction()));
        return;
      }
      // Check duplicate files in same category.
      const isDuplicate = await (await checkDuplicateFiles(methods.control._formValues?.solubility?.attachments))?.map((item: any) => item.isDuplicate).includes(true);
      if (isDuplicate) {
        dispatch(alertOpenAction("Please remove duplicate files.", "error"));
        setTimeout(() => dispatch(alertCloseAction()));
        return;
      }

      return true;
    }
    return true;
  };


  // Save and close modal button
  const saveMaterial = async () => {
    if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
      setOpen(false);
      if (await validateForm()) {
        const newAttachments = methods.control._formValues?.solubility?.attachments?.filter((attachment: any) => !attachment.isUpload);
        setLoading(true); // enable Loading
        const apiResponse = await apiRequest(newAttachments);
        setLoading(false); // disble Loading
        if (apiResponse) {
          dispatch(alertOpenAction(`Solubility Data ${params?.id ? "updated" : "added"} successfully.`, "success"));
          params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
        } else {
          dispatch(alertOpenAction("Oops! something went wrong.", "error"));
        }
        setTimeout(() => dispatch(alertCloseAction()));
      }
    } else {
      setOpen(false);
      dispatch(alertOpenAction("No data updated to save.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  // Don't save and close modal button
  const dontSave = () => {
    setOpen(false);
    params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
  };


  // *****************  Solubility Attachments fields code ******************  //

  // method trigger when user select files.
  const handleFileChangeSolubilityAttachments = (e: React.ChangeEvent<HTMLInputElement>) => {
    methods.setValue(`attachments.solubility.${e.target.name}`, [
      ...methods.control._formValues.attachments.solubility?.[e.target.name],
      ...Object.keys(e?.target?.files ?? []).map(
        (key: any) => {
          const fileSize = e?.target?.files?.[key]?.size ?? 0;
          return {
            category: materialSolubilityFileFields?.[e.target.name] ?? "",
            file: e?.target?.files?.[key],
            error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
          };
        }),
    ], { shouldDirty: true, shouldTouch: true });
  };

  // method trigger when user remove file.
  const removeFileSolubilityAttachments = (index: number, name: string) => {
    methods.setValue(
      `attachments.solubility.${name}`,
      methods.control._formValues.attachments?.solubility?.[name]?.filter((file: any, i: number) => i !== index),
      { shouldDirty: true, shouldTouch: true });
  };
  // method trigger to download selected file.
  const downloadFileSolubilityAttachments = async (file: any) => {
    let attachments: any = getSolubilityFileFields(simulation);

    if (!file?.display_name) {
      file = attachments.find((attachment: any) => {
        if (attachment.category === file.category && attachment.display_name === file.file.name) {
          return file;
        }
      });
    }
    if (!file) return;
    setLoading(true); // enable loading
    const res = await FileService.create("/files/download", {
      file: file.file_name,
    });
    setLoading(false); // disable loading
    if (res?.status === 200) {
      const fileRes = await fetch(res?.data.body);
      const blob = await fileRes.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = file.display_name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      dispatch(alertOpenAction("File downloaded successfully.", "success"));
      setTimeout(() => dispatch(alertCloseAction()));
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };
  // method trigger when user upload select files.
  const uploadFileSolubility = async (file: any, attachments: any[]) => {
    let newAttachment = await findFile(attachments, file);
    if (newAttachment) {
      const blob = new Blob([newAttachment?.file as any], { type: newAttachment?.file?.type });
      const res = await fetch(
        file.signedUrl,
        {
          method: "PUT",
          body: blob,
        },
      );
      return res.ok ? true : false;
    }
    return false;
  };
  // method trigger when user parse solubility files for name and type
  const parseFileSolubility = (items: any[]) => {
    return items.map((item: any) => ({
      category: item.category,
      file: {
        name: item.display_name,
        type: item.mime_type,
      },
      error: false,
      isUpload: true,
    }));
  };
  // method trigger when for reset upload
  const resetUploadSolubility = (simulation: any, override = {}) => {
    let data: any = { ...initialData, ...methods.control._formValues };
    data.vendor_name = simulation?.vendor_name ?? "";
    data.material_name = simulation?.material_name ?? "";
    data.lot_number = simulation?.lot_number ?? "";
    data.model = methods.control._formValues?.model ? methods.control._formValues?.model : simulation?.model ?? "";
    data.solvent = methods.control._formValues?.solvent ? methods.control._formValues?.solvent : simulation?.solvent ?? "";
    data.temperature = methods.control._formValues?.temperature ? methods.control._formValues?.temperature : simulation?.temperature ?? 5;
    data.turbidity_measured = methods.control._formValues?.turbidity_measured ? methods.control._formValues?.turbidity_measured : simulation?.turbidity_measured ?? 1017.16;
    data.solubility_value = methods.control._formValues?.solubility_value ? methods.control._formValues?.solubility_value : simulation?.solubility_value ?? "";
    // data.solvent1 = simulation?.solvent1 ?? "";
    // data.solvent2 = simulation?.solvent2 ?? "";
    // data.temperature = simulation?.temperature ?? 5;
    // data.solubility_value = simulation?.solubility_value ?? "";
    data.visual = methods.control._formValues?.visual ?? false;
    data.plot = methods.control._formValues?.plot ?? false;
    data.attachments = { ...allAttachments };

    let allSolubilityAttachmentNames: Array<string> = [];

    for (const attachment in allAttachments.solubility) {
      if (Object.prototype.hasOwnProperty.call(allAttachments.solubility, attachment)) {
        allSolubilityAttachmentNames.push(attachment);
      }
    }

    for (const attachment of allSolubilityAttachmentNames) {
      data.attachments.solubility[attachment] = simulation?.[attachment] ? parseFileSolubility(simulation[attachment]) : [];
    }


    methods.reset({ ...data, ...override });
  };
  // method trigger when solubility upload api
  const uploadFileSolubilityAPI = async (attachments: any[]) => {
    let result = {
      code: 200,
      status: "success",
      message: "",
      data: {},
    };
    // file upload API
    const filesResponse = await FileService.create("/files/upload", {
      id: material?.id ?? "",
      file_for: "Material",
      files: attachments.map(
        (attachment) => ({
          category: attachment?.category,
          key: attachment.file?.name,
          mimeType: attachment?.file?.type,
        })),
    });
    if (filesResponse?.status === 200) {
      const filesResult = filesResponse?.data;
      return Promise.all(filesResult.body.files.map((file: any) => uploadFileSolubility(file, attachments)))
        .then(async (res) => {
          // update material object
          filesResult.body.files.map((file: any) => {
            delete file.signedUrl;
            delete file.location;
            return file;
          });

          const r = filesResult?.body?.files?.reduce((e: any, t: any) => {
            switch (t.category) {
              case "Unchained Lab Data":
                e.unchainedLab = [...e?.unchainedLab, t];
                break;
              case "HPLC Data":
                e.hplc = [...e?.hplc, t];
                break;
              case "Crystal 16 Data":
                e.crystal16 = [...e?.crystal16, t];
                break;
              case "Crystalline Data":
                e.crystalline = [...e?.crystalline, t];
                break;
              case "Gravimetry Method":
                e.gravimetryMethod = [...e?.gravimetryMethod, t];
                break;
              case "Solubility Curve 1":
                e.solubilityCurve1 = [...e?.solubilityCurve1, t];
                break;
              case "Solubility Curve 2":
                e.solubilityCurve2 = [...e?.solubilityCurve2, t];
                break;
              case "Solubility Curve 3":
                e.solubilityCurve3 = [...e?.solubilityCurve3, t];
                break;
              case "Solubility Curve 4":
                e.solubilityCurve4 = [...e?.solubilityCurve4, t];
                break;
              case "Solubility Curve 5":
                e.solubilityCurve5 = [...e?.solubilityCurve5, t];
                break;
            }
            return e;
          }, {
            unchainedLab: [],
            hplc: [],
            crystal16: [],
            crystalline: [],
            gravimetryMethod: [],
            solubilityCurve1: [],
            solubilityCurve2: [],
            solubilityCurve3: [],
            solubilityCurve4: [],
            solubilityCurve5: [],
          });

          const payload = {
            material_id: material?.id ?? "",
            material_name: material?.material_name ?? "",
            vendor_name: material?.vendor_name ?? "",
            lot_number: material?.lot_number ?? "",
            uid: material?.uid ?? "",
            simulation_type: "material",
            unchainedLab: await getUpdatedFiles([...simulation?.unchainedLab ?? [], ...r.unchainedLab], methods?.control?._formValues?.attachments?.solubility.unchainedLab ?? [], !0),
            hplc: await getUpdatedFiles([...simulation?.hplc ?? [], ...r.hplc], methods?.control?._formValues?.attachments?.solubility.hplc ?? [], !0),
            crystal16: await getUpdatedFiles([...simulation?.crystal16 ?? [], ...r.crystal16], methods?.control?._formValues?.attachments?.solubility.crystal16 ?? [], !0),
            crystalline: await getUpdatedFiles([...simulation?.crystalline ?? [], ...r.crystalline], methods?.control?._formValues?.attachments?.solubility.crystalline ?? [], !0),
            gravimetryMethod: await getUpdatedFiles([...simulation?.gravimetryMethod ?? [], ...r.gravimetryMethod], methods?.control?._formValues?.attachments?.solubility.gravimetryMethod ?? [], !0),
            solubilityCurve1: await getUpdatedFiles([...simulation?.solubilityCurve1 ?? [], ...r.solubilityCurve1], methods?.control?._formValues?.attachments?.solubility.solubilityCurve1 ?? [], !0),
            solubilityCurve2: await getUpdatedFiles([...simulation?.solubilityCurve2 ?? [], ...r.solubilityCurve2], methods?.control?._formValues?.attachments?.solubility.solubilityCurve2 ?? [], !0),
            solubilityCurve3: await getUpdatedFiles([...simulation?.solubilityCurve3 ?? [], ...r.solubilityCurve3], methods?.control?._formValues?.attachments?.solubility.solubilityCurve3 ?? [], !0),
            solubilityCurve4: await getUpdatedFiles([...simulation?.solubilityCurve4 ?? [], ...r.solubilityCurve4], methods?.control?._formValues?.attachments?.solubility.solubilityCurve4 ?? [], !0),
            solubilityCurve5: await getUpdatedFiles([...simulation?.solubilityCurve5 ?? [], ...r.solubilityCurve5], methods?.control?._formValues?.attachments?.solubility.solubilityCurve5 ?? [], !0),
          };
          const materialResponse = await MaterialService.create("/simulations/save-simulations-files-data", payload);
          if (materialResponse?.data?.code == 200) {
            const materialResult = materialResponse?.data;
            // setSimulation(materialResult.body);
            resetUploadSolubility(materialResult.body);
            result.data = materialResult.body;
            result.status = "success";
            result.message = "Data saved.";
            return result;
          }
          result.code = 400;
          result.status = "error";
          result.message = "Oops! something went wrong.";
          return result;
        })
        .catch(err => {
          result.code = 400;
          result.status = "error";
          result.message = "Oops! something went wrong.";
          return result;
        });
    }
    result.code = 400;
    result.status = "error";
    result.message = "Oops! something went wrong.";
    return result;
  };
  // method trigger when user save upload solubility
  const saveUploadSolubility = async () => {
    let result: any = {
      code: 200,
      status: "success",
      message: "",
      data: {},
    };

    let attachments: any = [];
    if (methods.control._formValues.attachments.solubility) {
      attachments = Object.keys(methods.control._formValues.attachments.solubility).reduce((e: any, t: any) => {
        let e2: any = methods.control._formValues.attachments.solubility[t];
        if (e2 !== null && e2 !== undefined) {
          e = [...e, ...methods.control._formValues.attachments.solubility[t]];
        }
        return e;
      }, []);
    }


    if (attachments.length) {
      // check if files are duplicate in same category.
      const isDuplicate = await (await checkDuplicateFiles(attachments))?.map((item: any) => item.isDuplicate).includes(true);
      if (isDuplicate) {
        result.code = 400;
        result.status = "error";
        result.message = "Please remove duplicate files.",
          result.data = {};
        return result;
      }

      // updated files list
      const newAttachments = attachments?.filter((file: any) => !file.isUpload);
      if (newAttachments.length) { // @ts-ignore
        return { ...result, ...await uploadFileSolubilityAPI(newAttachments) };
      }
    }

    const payload = {
      material_id: material?.id ?? "",
      material_name: material?.material_name ?? "",
      vendor_name: material?.vendor_name ?? "",
      lot_number: material?.lot_number ?? "",
      uid: material?.uid ?? "",
      simulation_type: "material",
      unchainedLab: await getUpdatedFiles(simulation?.unchainedLab ?? [], methods?.control?._formValues?.attachments?.solubility?.unchainedLab ?? [], !1),
      hplc: await getUpdatedFiles(simulation?.hplc ?? [], methods?.control?._formValues?.attachments?.solubility?.hplc ?? [], !1),
      crystal16: await getUpdatedFiles(simulation?.crystal16 ?? [], methods?.control?._formValues?.attachments?.solubility?.crystal16 ?? [], !1),
      crystalline: await getUpdatedFiles(simulation?.crystalline ?? [], methods?.control?._formValues?.attachments?.solubility?.crystalline ?? [], !1),
      gravimetryMethod: await getUpdatedFiles(simulation?.gravimetryMethod ?? [], methods?.control?._formValues?.attachments?.solubility?.gravimetryMethod ?? [], !1),
      solubilityCurve1: await getUpdatedFiles(simulation?.solubilityCurve1 ?? [], methods?.control?._formValues?.attachments?.solubility?.solubilityCurve1 ?? [], !1),
      solubilityCurve2: await getUpdatedFiles(simulation?.solubilityCurve2 ?? [], methods?.control?._formValues?.attachments?.solubility?.solubilityCurve2 ?? [], !1),
      solubilityCurve3: await getUpdatedFiles(simulation?.solubilityCurve3 ?? [], methods?.control?._formValues?.attachments?.solubility?.solubilityCurve3 ?? [], !1),
      solubilityCurve4: await getUpdatedFiles(simulation?.solubilityCurve4 ?? [], methods?.control?._formValues?.attachments?.solubility?.solubilityCurve4 ?? [], !1),
      solubilityCurve5: await getUpdatedFiles(simulation?.solubilityCurve5 ?? [], methods?.control?._formValues?.attachments?.solubility?.solubilityCurve5 ?? [], !1),
    };

    const res = await MaterialService.create("/simulations/save-simulations-files-data", payload);
    if (res?.status == 200) {
      //   setSimulation(res?.data?.body);
      //   resetUpload(res?.data?.body);
      // resetUploadSolubility(res?.data.body);
      result.data = res?.data?.body;
      result.status = "success";
      result.message = "Data saved.";
      return result;
    }
    result.code = 400;
    result.status = "error";
    result.message = "Oops! something went wrong.";
    return result;
  };


  // Submit Form
  const onSubmit: SubmitHandler<any> = async (data) => {
    if (await validateForm()) {
      const newAttachments = methods.control._formValues?.solubility?.attachments ?? []?.filter((attachment: any) => !attachment.isUpload);
      setLoading(true); // enable Loading
      const apiResponse = await apiRequest(newAttachments);
      if (!apiResponse) {
        dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
      }
      setTimeout(() => dispatch(alertCloseAction()));
      let result = {
        code: 200,
        status: "success",
        message: "",
        data: {},
        uploadData: {},
      };
      let uploadedSolubilityRes: any = await saveUploadSolubility();
      if (uploadedSolubilityRes !== null && uploadedSolubilityRes !== undefined) {
        result = { ...result, ...uploadedSolubilityRes };
      }
      result = { ...result, uploadData: result.data };
      setLoading(false); // disable loading
      if (result.code === 200) {
        setSimulation(result.uploadData);
        // resetUploadSolubility(result.uploadData)
        dispatch(alertOpenAction(`Solubility Data ${simulation ? "updated" : "saved"} successfully.`, "success"));
      } else {
        dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      }
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const handleClose = (path: string) => {
    if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
      setOpen(true);
      return;
    }
    history.push(path);
  }

  const breadCrumbItems = [
    { label: "Home", path: "#", onClick: () => handleClose("/") },
    { label: "Materials", path: "#", onClick: () => handleClose("/materials") },
    { label: params.id ? "Update Material" : "Add Material", path: "#", onClick: () => handleClose(params?.id ? `/materials/${params?.id}/update` : `/materials/new/`) },
    { label: "Solubility", path: "#" }
  ];

  return (
    <>
      <Layout title={layoutTitles.materialSolidSolubility} breadCrumbItems={breadCrumbItems}>
        <CancelConfirmModal open={open} setOpen={setOpen} saveMaterial={saveMaterial} dontSave={dontSave} />
        <HelpModal
          open={openHelp}
          setOpen={setOpenHelp}
          title={MaterialInnerSolubilityHelp.title}
          content={MaterialInnerSolubilityHelp.content} />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="sec-info control-head">
              <div className="back">
                <BackButton onSubmit={saveMaterial} formState={methods.formState} material={material} />
              </div>
              <div className="head">
                <h1 className="head-lg">Solubility Information</h1>
              </div>
              <div className="right">
                <button data-cy="save-btn" type="submit" title="Save" disabled={loading ? true : false}
                  className={`icon-btn ${loading ? "disabled  " : ""}`}>
                  <i className="fa-regular fa-floppy-disk" />
                </button>
                <Link className="icon-btn alter" title="Close" to="#" onClick={() => handleClose(params?.id ? `/materials/${params?.id}/update` :`/materials/new/`)}>
                  <i className="fa-solid fa-xmark" />
                </Link>
                <Link to="#" className="icon-btn alter" title="Help" onClick={() => {
                  setOpenHelp(true);
                }}>
                  <i className="fa-solid fa-question" />
                </Link>
              </div>
            </div>

            <div className="theme-card">
              <div className="body">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label className="ip-wrap" htmlFor="materialName" title="Material Name">
                        <span className="form-label">Material Name</span>
                        <Controller
                          name="material_name"
                          control={methods.control}
                          render={({ field }) => <input disabled className="theme-ip" {...field} />}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label className="ip-wrap" htmlFor="vendor" title="Vendor">
                        <span className="form-label">Vendor</span>
                        <div className="input-wrap">
                          <Controller
                            name="vendor_name"
                            control={methods.control}
                            render={({ field }) => <input disabled className="theme-ip" {...field} />}
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <label className="ip-wrap" htmlFor="lot_number" title="Lot Number">
                        <span className="form-label">Lot Number</span>
                        <Controller
                          name="lot_number"
                          control={methods.control}
                          render={({ field }) => <input disabled className="theme-ip" {...field} />}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                {/* divider */}
                <div className="divider"></div>

               <SolubilityInformation
                  attachments={methods?.control?._formValues?.solubility?.attachments ?? []}
                  handleFileChange={handleFileChange}
                  removeFile={removeFile}
                  onSubmit={onSubmit}
                  material={material}
                />
                <div className="divider"></div>
                <UploadSolubilityData
                  loading={loading}
                  attachments={methods.control._formValues?.attachments?.solubility}
                  handleFileChange={handleFileChangeSolubilityAttachments}
                  removeFile={removeFileSolubilityAttachments}
                  downloadFile={downloadFileSolubilityAttachments}
                />
              </div>
              {loading ? <div className="theme-loader show fixed">
                <div className="loader-outer">
                  <div className="loader"></div>
                </div>
              </div> : ""}
            </div>
          </form>
        </FormProvider>
      </Layout>
    </>
  );
};
export default Solubility;