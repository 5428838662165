import { Controller, useForm, useFormContext } from "react-hook-form";

// components
import MultiFileUpload from "../../../../common/MultiFileUpload";

// helpers
import { NUMBER_INPUT_MAXLENGTH, UPLOAD_FILE_MAXLENGTH } from "../../../../../utils";
import { limitDigits, setTitleNumberInput } from "../../../../../utils/common";
import { alertCloseAction, alertOpenAction } from "../../../../../redux/actions";
import { useEffect } from "react";

const Permeability = (props: any) => {
    const { control } = useForm<any>({ defaultValues: props.initialData });
    const methods = useFormContext();
    useEffect(() => {
        // set default title on number input fields.
        setTitleNumberInput();
        // params?.id ? setSavedData(checkSavedData(material?.solid)) : ''
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="conditional_bulk_density">
                            <span className="form-label">Conditionated Bulk Density (g/ml)</span>
                            {!props.readable ? (
                                <Controller
                                    name="permeability.conditional_bulk_density"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.permeability?.conditional_bulk_density??""}
                                    render={({ field }) => <input
                                      data-cy="permeability_conditional_bulk_density"
                                      type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.conditional_bulk_density}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="permeability_value">
                            <span className="form-label">Permeability K 15,2 x10^-9 (cm2)</span>
                            {!props.readable ? (
                                <Controller
                                    name="permeability.permeability_value"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.permeability?.permeability_value??""}
                                    render={({ field }) => <input
                                      data-cy="permeability_permeability_value"
                                      type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.permeability_value}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="density">
                            <span className="form-label">Density at 15 kPa (g/ml)</span>
                            {!props.readable ? (
                                <Controller
                                    name="permeability.density"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.permeability?.density??""}
                                    render={({ field }) => <input
                                      data-cy="permeability_density"
                                      type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.density}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="pressure_drop">
                            <span className="form-label">Pressure Drop, PD 15,2 (mBar)</span>
                            {!props.readable ? (
                                <Controller
                                    name="permeability.pressure_drop"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.permeability?.pressure_drop??""}
                                    render={({ field }) => <input
                                      data-cy="permeability_pressure_drop"
                                      type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.pressure_drop}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="eln_number">
                            <span className="form-label">ELN Number</span>
                            {!props.readable ? (
                                <Controller
                                    name="permeability.eln_number"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.permeability?.eln_number??""}
                                    render={({ field }) => <input
                                      data-cy="permeability_eln_number"
                                      className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.eln_number}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="method_name">
                            <span className="form-label">Method Name/#</span>
                            {!props.readable ? (
                                <Controller
                                    name="permeability.method_name"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.permeability?.method_name??""}
                                    render={({ field }) => <input
                                      data-cy="permeability_method_name"
                                      className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.method_name}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label
                            className="ip-wrap"
                            htmlFor="regulatory_filing"
                        >
                            <span className="form-label">
                                Regulatory Filing
                            </span>
                            <div className="input-wrap select-outer">
                                {!props.readable ? (
                                    <Controller
                                        name="permeability.regulatory_filing"
                                        control={methods.control}
                                        disabled={props.readable ? true : false}
                                        defaultValue={methods.control._formValues?.permeability?.regulatory_filing??""}
                                        render={({ field }) =>
                                            <select className="theme-ip" {...field} data-cy="permeability_regulatory_filing" >
                                                <option value="">Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>}
                                    />
                                ) : (
                                    <p className="theme-ip pt-3 disabled">{control?._formValues?.regulatory_filing}</p>
                                )}
                            </div>
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    {!props.readable ? (
                        <MultiFileUpload
                            title="SOP File, Instrument Information"
                            name="permeabilityAttachments"
                            dataCy="attachment_permeabilityAttachments"
                            readable={props.readable ? true : false}
                            attachments={methods.control._formValues.permeability.attachments}
                            handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                let displayNames = props.material?.solid?.permeability?.attachments ?? [];
                                displayNames = displayNames?.map((attachment: any) => attachment.display_name);
                                const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                                    if (displayNames.includes(e?.target?.files?.[key].name)) {
                                        return true;
                                    }
                                });
                                if (isDuplicate) {
                                    props.dispatch(alertOpenAction('Same file already uploaded.', 'error'));
                                    setTimeout(() => props.dispatch(alertCloseAction()));
                                    return;
                                }
                                methods.setValue('permeability.attachments', [
                                    ...(methods.getValues('permeability.attachments') || []),
                                    ...Object.keys(e?.target?.files ?? []).map(
                                        (key: any) => {
                                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                                            return {
                                                category: 'Permeability',
                                                file: e?.target?.files?.[key],
                                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                                            }
                                        })
                                ], { shouldDirty: true, shouldTouch: true });
                            }}
                            removeFile={(index: number) => {
                                methods.setValue('permeability.attachments', (methods.getValues('permeability.attachments') || []).filter((attachment: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true })
                            }}
                        />
                    ) : (
                        <MultiFileUpload
                            title="SOP File, Instrument Information"
                            name="permeabilityFiles"
                            readable={props.readable}
                            attachments={props.attachments}
                            handleFileChange={props.handleFileChange}
                            removeFile={props.removeFile}
                            downloadFile={props.downloadFile}
                        />
                    )}
                </div>
            </div>

        </>
    );
};

export default Permeability;