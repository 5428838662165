import React from 'react';
// compoenents
import Layout from "../../components/layout";
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  return (
    <Layout title="Not Found">
      <div className="lg-container">
        <div className="theme-card not-found-page">
          <div className="body">
            <div className="row">
              <div className="col-12">
                <h1 className='head-lg head-404'>404 - Not Found</h1>
                <p>Sorry, the page you are looking for does not exist.</p>
                <div className="row justify-center">
                  <div className="col-lg-4 col-md-6 full-flex">
                    <Link title='Home' to="/">
                      <div className="theme-btn">
                        Home
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default NotFoundPage;