import { Modal, Button, Icon } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { Bar, Line } from "react-chartjs-2"; // Import Line
import {
  Chart as ChartJS,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Material } from "../../pages/materials/visualize/utils";
import { useEffect, useState } from "react";
import { getValueFromConcatenatedKey } from "../../utils/materialKeyLabelHelper";
import CustomLoader from "../common/CustomLoader";

ChartJS.register(
  BarElement,
  LineElement, // Register LineElement
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

export interface MaterialBarChartModalProps {
  open: boolean;
  handleOpen: (val: boolean) => void;
  materialProperty: string;
  materials: Material[];
}

const MaterialBarChartModal = ({
  open,
  handleOpen,
  materialProperty,
  materials,
}: MaterialBarChartModalProps) => {
  const [loading, setLoading] = useState(false);
  const [labels, setLabels] = useState<string[]>([]);
  const [dataValues, setDataValues] = useState<number[]>([]);
  const [chartType, setChartType] = useState<'bar' | 'line'>('bar'); // State for chart type

  useEffect(() => {
    setLoading(true);
    setLabels(
      materials.map((material) => `Name: ${material.material_name} | Lot Number: ${material.lot_number} | Vendor: ${material?.vendor_name} `)
    );
    setDataValues(
      materials.map((material) => material[materialProperty] ?? 0)
    );
    setLoading(false);
  }, [materials, materialProperty]);

  const data = {
    labels,
    datasets: [
      {
        label: getValueFromConcatenatedKey(materialProperty),
        data: dataValues,
        backgroundColor: chartType === 'bar' ? "rgb(238, 75, 43, 0.3)" : "rgb(238, 75, 43, 1)", // Change based on chart type
        borderColor: "rgb(238, 75, 43)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: getValueFromConcatenatedKey(materialProperty), // Y-axis label
        },
      },
    },
  };

  return (
    <>
      {loading && <CustomLoader />}
      <Modal
        open={open && !loading}
        size="large"
        dimmer="blurring"
        onClose={() => handleOpen(false)}
      >
        <Modal.Header align="center" className="border-none pb-0">
          <Icon
            name="times circle outline"
            onClick={() => {
              handleOpen(false);
            }}
            color="grey"
            className="close-icon"
          />
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Button.Group>
              <Button
                active={chartType === 'bar'}
                onClick={() => setChartType('bar')}
              >
                Bar Chart
              </Button>
              <Button
                active={chartType === 'line'}
                onClick={() => setChartType('line')}
              >
                Line Chart
              </Button>
            </Button.Group>
            {chartType === 'bar' ? (
              <Bar data={data} options={options} />
            ) : (
              <Line data={data} options={options} /> // Render Line chart
            )}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default MaterialBarChartModal;
