import http from "./httpService";
// import ITutorialData from "../types/Tutorial";
interface IUserData {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    city: string;
    state: string;
    streetAddress: string;
    zipCode: string;
    mobilePhone: string;
    partition_key: string;
    sort_key: string;
}

interface ResponseData {
    code: number;
    message: string;
    body: IUserData;
}

const getAll = () => {
  return http.get<ResponseData>("/users");
};

const getById = (id: any) => {
  return http.get<IUserData>(`/users/${id}`);
};

const create = (data: IUserData) => {
  return http.post<IUserData>("/users", data);
};

const update = (id: any, data: IUserData) => {
  return http.put<any>(`/users/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/users/${id}`);
};

// const findByTitle = (title: string) => {
//   return http.get<Array<ITutorialData>>(`/users?title=${title}`);
// };

const UserService = {
  getAll,
  getById,
  create,
  update,
  remove,
//   findByTitle,
};

export default UserService;