import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

// props
import { VendorProps } from "../../types/materialsTypes";

// helpers
import {
    vendorNames,
    screeningInitialData,
    screenFilterInitialDataByPhase,
    materialFiltersData,
} from "../../utils/materialHelper";
import { NUMBER_INPUT_MAXLENGTH } from "../../utils";
import { limitDigits, sortItemsByKey } from "../../utils/common";

const FilterSidebar = (props: any) => {
    const { control, watch, formState, handleSubmit, reset } = useForm({ defaultValues: screeningInitialData });
    const {handleReset} = props;

    const [vendors, setVendors] = useState<VendorProps[]>(vendorNames);

    useEffect(() => {
        watch();
    });

    useEffect(() => {
        if (props.user) {
            const { userType, vendors } = props.user;
            if (userType.includes('external')) {
                // okta dashboard vendors for external users.
                setVendors(
                    sortItemsByKey([
                        { label: 'Generic', value: 'Generic' },
                        ...vendors.map((vendor: VendorProps) => ({ label: vendor, value: vendor })),
                    ], 'label'),
                );
            }
        }
    }, [props.user]);

    // useEffect(() => {
    //     const {
    //         smiles,
    //         phase,
    //         phase_options,
    //     } = control._formValues;

    //     if (!phase) {
    //         reset({
    //             ...control._formValues,
    //             phase_options: "",
    //             ...Object.keys(screenFilterInitialDataByPhase).reduce((e: any, t: string) => {
    //                 e = { ...e, ...screenFilterInitialDataByPhase[t]}
    //                 return e;
    //             }, {}),
    //         });
    //     }
    //     if (phase_options?.length) {
    //         reset({
    //             ...control._formValues,
    //             ...screenFilterInitialDataByPhase[phase_options.toLowerCase()],
    //         });
    //     }
    //     if (!smiles) {
    //         reset({
    //             ...control._formValues,
    //             toggle_smiles: false,
    //         });
    //     }
    // }, [
    //     control._formValues?.smiles,
    //     control._formValues?.phase,
    //     control._formValues?.phase_options,
    // ]);

    useEffect(() => {
        const newFilters = materialFiltersData(control._formValues);
        if (formState.isDirty) {
            reset({ ...newFilters });
        }
    });

    // method to submit form
    const onSubmit = async (data: any) => {
        data.toggle_smiles = data.toggle_smiles ? "yes" : "no";
        data.solid_form_value = data.solid_form_value ? "Salt" : "Parent";
        let vendorArray: any = [];
        data?.vendor_name ? data?.vendor_name.forEach(function (item: any) {
            vendorArray.push(item.value);
        }) : ''

        let copy = {
            vendor_name: data?.vendor ? vendorArray : '',
            project_name: data?.has_project_filter ? data?.project_name : '',
            phase: data?.phase ? data.phase_options : '',
            smiles: data?.smiles ? data.toggle_smiles : "",
            shelf_life: {
                operation: data?.shelf_life ? data.shelf_life_operation : '',
                value: data?.shelf_life ? data.shelf_life_value : '',
            },
            solid: data?.phase_options == "Solid" ? {

                solid_form: data?.solid_form ? data?.solid_form_value : '',
                type: data?.type ? data?.attachment_type : '',
                log_s: { option: data?.log_s ? data?.log_s_option : "", value: data?.log_s ? data?.log_s_value : "" },
                form: data?.form ? data?.attachments : '',
            } : {},
            liquid: data?.phase_options == "Liquid" ? {
                liquid_type: data?.liquid_type ? data.liquid_type_value : '',
                flash_point: {
                    operation: data?.flash_point ? data.flash_point_operation : '',
                    value: data?.flash_point ? data.flash_point_value : '',
                },
                log_p: {
                    operation: data?.log_p ? data.log_p_operation : '',
                    value: data?.log_p ? data.log_p_value : '',
                },
            } : {},
            gas: data?.phase_options == "Gas" ? {
                density: {
                    operation: data?.density ? data.density_operation : '',
                    value: data?.density ? data.density_value : '',
                },
                vapor_density: {
                    operation: data?.vapor_density ? data.vapor_density_operation : '',
                    value: data?.vapor_density ? data.vapor_density_value : '',
                },
            } : {},
        }
        props.filters(copy);
    }

    // method to reset filters
    const onReset = () => {
        reset(screeningInitialData);
        handleReset();
        props.filters({
            gas: {},
            liquid: {},
            phase: "",
            shelf_life: { operation: '', value: '' },
            smiles: "",
            solid: {},
            vendor_name: [],
            project_name: ""
        });
    };
    
    return (
      <form onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
          <div className="filter-sidebar">
              <div className="filter-block">
                  <div className="parent">
                      <label className="custom-checkbox">
                          <Controller
                            name="vendor"
                            control={control}
                            defaultValue={control?._formValues?.vendor ?? false}
                            render={({ field: { onBlur, onChange, value } }) => (
                              <input type="checkbox" className="ip"
                                     onBlur={onBlur} // notify when input is touched
                                     onChange={onChange} // send value to hook form
                                     checked={value}
                              />
                            )}
                          />
                          <span className="check"></span>
                          <span className="txt ">Vendor Filter</span>
                      </label>
                  </div>
                  <div className={`child ${control?._formValues?.vendor ? '' : 'disabled'}`}>
                      <label className="ip-wrap mb-2" htmlFor="attachment_type">
                          <div className="input-wrap">
                              <Controller
                                name="vendor_name"
                                control={control}
                                render={({ field }) =>
                                  <Select
                                    styles={{
                                        control: (base: any, state) => ({
                                            ...base,
                                            '&:hover': { borderColor: 'red' }, // border style on hover
                                            border: '1px solid lightgray', // default border color
                                            boxShadow: 'none', // no box-shadow
                                        }),
                                    }}
                                    {...field}
                                    value={control?._formValues?.vendor_name ?? []}
                                    isMulti
                                    name="colors"
                                    options={vendors}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                  />
                                }
                              />
                          </div>
                      </label>
                  </div>
              </div>

              <div className="filter-block">
                  <div className="parent">
                      <label className="custom-checkbox">
                          <Controller
                            name="shelf_life"
                            control={control}
                            defaultValue={false}
                            render={({ field: { onBlur, onChange, value } }) => (
                              <input type="checkbox" className="ip"
                                     onBlur={onBlur} // notify when input is touched
                                     onChange={onChange} // send value to hook form
                                     checked={value}
                              />
                            )}
                          />
                          <span className="check"></span>
                          <span className="txt">Shelf-Life Filter</span>
                      </label>
                  </div>
                  <div className={`child ${control._formValues?.shelf_life ? '' : 'disabled'}`}>
                      <div className="row xxs-row flex-nowrap justify-between">
                          <div className="col-lg-5">
                              <label className="f-14">
                                  Decomposition
                              </label>
                          </div>

                          <div className="col-lg-3 pr-5">
                              <label className="ip-wrap mb-2" htmlFor="chemicalSafety">
                                  <div className="input-wrap select-outer">
                                      <Controller
                                        name="shelf_life_operation"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) =>
                                          <select
                                            className="theme-ip sm"
                                            {...field}
                                          >
                                              <option value="" disabled>Select</option>
                                              <option value="equal">
                                                  =
                                              </option>
                                              <option value="less">
                                                  &lt;
                                              </option>
                                              <option value="more">&gt;</option>
                                              <option value="equalMore">
                                                  &gt; =
                                              </option>
                                              <option value="equalLess">
                                                  &lt; =
                                              </option>
                                              <option value="notEqual">~=</option>
                                          </select>
                                        }
                                      />
                                  </div>
                              </label>
                          </div>
                          <div className="col-lg-4 ">
                              <Controller
                                name="shelf_life_value"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className="theme-ip sm"
                                    type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}
                                    placeholder="Value"
                                    {...field}
                                  />
                                )}
                              />
                          </div>
                      </div>
                  </div>
              </div>

              <div className="filter-block">
                  <div className="parent">
                      <label className="custom-checkbox">
                          <Controller
                            name="smiles"
                            control={control}
                            defaultValue={false}
                            render={({ field: { onBlur, onChange, value } }) => (
                              <input type="checkbox" className="ip"
                                     onBlur={onBlur} // notify when input is touched
                                     onChange={onChange} // send value to hook form
                                     checked={value}
                              />
                            )}
                          />
                          <span className="check"></span>
                          <span className="txt">SMILES Filter</span>
                      </label>
                  </div>
                  <div className={`child ${control._formValues?.smiles ? '' : 'disabled'}`}>
                      <div className="flex items-center mb-2">
                          <div className="custom-switch">
                              <Controller
                                name="toggle_smiles"
                                control={control}
                                defaultValue={control._formValues?.smiles ?? false} // Set the default value to false (off)
                                render={({ field: { onBlur, onChange, value } }) => (
                                  <input
                                    disabled={control._formValues?.smiles ? false : true}
                                    type="checkbox"
                                    id="toggle"
                                    className="ip"
                                    onBlur={onBlur} // notify when input is touched
                                    onChange={onChange} // send value to hook form
                                    checked={value}
                                  />
                                )}
                              />
                              <span className="toggle"></span>
                          </div>
                          <div className="text ml-2">Blank</div>
                      </div>
                  </div>
              </div>

              <div className="filter-block">
                  <div className="parent">
                      <label className="custom-checkbox">
                          <Controller
                            name="has_project_filter"
                            control={control}
                            defaultValue={control?._formValues?.has_project_filter ?? false}
                            render={({ field: { onBlur, onChange, value } }) => (
                              <input type="checkbox" className="ip"
                                     onBlur={onBlur} // notify when input is touched
                                     onChange={onChange} // send value to hook form
                                     checked={value}
                              />
                            )}
                          />
                          <span className="check"></span>
                          <span className="txt ">Project Name Filter</span>
                      </label>
                  </div>
                  <div className={`child ${control?._formValues?.has_project_filter ? '' : 'disabled'}`}>
                      <label className="ip-wrap mb-2" htmlFor="attachment_type">
                          <div className="input-wrap">
                              <Controller
                                name="project_name"
                                control={control}
                                render={({ field }) =>
                                  <>
                                      <input placeholder="Enter project name" className="theme-ip" {...field} />
                                  </>
                                }
                              />
                          </div>
                      </label>
                  </div>
              </div>

              <div className="filter-block">
                  <div className="parent">
                      <label className="custom-checkbox">
                          <Controller
                            name="phase"
                            control={control}
                            defaultValue={false}
                            render={({ field: { onBlur, onChange, value } }) => (
                              <input type="checkbox" className="ip"
                                     onBlur={onBlur} // notify when input is touched
                                     onChange={onChange} // send value to hook form
                                     checked={value}
                              />
                            )}
                          />
                          <span className="check"></span>
                          <span className="txt">Phase Property Filter</span>
                      </label>
                  </div>
                  <div className={`child ${control._formValues?.phase ? '' : 'disabled'}`}>
                      <div className="flex">
                          <label className="custom-checkbox">
                              <Controller
                                name="phase_options"
                                control={control}
                                defaultValue={control._formValues.phase_options ?? ""}
                                render={({ field }) => (
                                  <input className="ip" type="radio" {...field} value="Gas"
                                         checked={control._formValues.phase_options === "Gas" ? true : false} />
                                )}
                              />
                              <span className="check"></span>
                              <span className="txt">Gas</span>
                          </label>
                          <label className="custom-checkbox">
                              <Controller
                                name="phase_options"
                                control={control}
                                defaultValue={control._formValues.phase_options ?? ""}
                                render={({ field }) => (
                                  <input className="ip" type="radio" {...field} value="Liquid"
                                         checked={control._formValues.phase_options === "Liquid" ? true : false} />
                                )}
                              />
                              <span className="check"></span>
                              <span className="txt">Liquid</span>
                          </label>
                          <label className="custom-checkbox">
                              <Controller
                                name="phase_options"
                                control={control}
                                defaultValue={control._formValues.phase_options ?? ""}
                                render={({ field }) => (
                                  <input className="ip" type="radio" {...field} value="Solid"
                                         checked={control._formValues.phase_options === "Solid" ? true : false} />
                                )}
                              />
                              <span className="check"></span>
                              <span className="txt">Solid</span>
                          </label>
                      </div>
                      <div className={`child ${control._formValues?.phase_options == "Solid" ? '' : 'hide'}`}>
                          <div className="row xxs-row">
                              <div className="col-md-6">
                                  <label className="custom-checkbox">
                                      <Controller
                                        name="solid_form"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onBlur, onChange, value } }) => (
                                          <input type="checkbox"
                                                 className="ip"
                                                 onBlur={onBlur}     // notify when input is touched
                                                 onChange={onChange} // send value to hook form
                                                 checked={value}
                                          />
                                        )}
                                      />
                                      <span className="check"></span>
                                      <span className="txt">Solid Form</span>
                                  </label>
                              </div>
                              <div className="col-md-6">
                                  <div className="flex items-center mb-2">
                                      <div className="custom-switch">
                                          <Controller
                                            name="solid_form_value"
                                            control={control}
                                            defaultValue={control._formValues?.solid_form_value ?? false} // Set the default value to false (off)
                                            render={({ field }) => (
                                              <input
                                                disabled={control._formValues?.solid_form ? false : true}
                                                checked={control._formValues?.solid_form_value ?? false}
                                                type="checkbox"
                                                id="toggle"
                                                className="ip"
                                                {...field}
                                              />
                                            )}
                                          />
                                          <span className="toggle"></span>
                                      </div>
                                      <div
                                        className="text ml-2">{control._formValues?.solid_form_value ? 'Salt' : 'Parent'}</div>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <label className="custom-checkbox">
                                      <Controller
                                        name="type"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onBlur, onChange, value } }) => (
                                          <input type="checkbox" className="ip"
                                                 onBlur={onBlur} // notify when input is touched
                                                 onChange={onChange} // send value to hook form
                                                 checked={value}
                                          />
                                        )}
                                      />
                                      <span className="check"></span>
                                      <span className="txt">Type</span>
                                  </label>
                              </div>
                              <div className="col-md-6">
                                  <label className="ip-wrap mb-2" htmlFor="attachment_type">
                                      <div className="input-wrap select-outer">
                                          <Controller
                                            name="attachment_type"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                              <select
                                                className="theme-ip sm"
                                                disabled={control._formValues?.type ? false : true}
                                                {...field}
                                              >
                                                  <option value="" disabled>Select</option>
                                                  <option value="API">API</option>
                                                  <option value="Impurity">Impurity</option>
                                                  <option value="Intermediate">Intermediate</option>
                                                  <option value="Starting Material">Starting Material</option>
                                                  <option value="Reagents">Reagents</option>
                                                  <option value="Excipients">Excipients</option>
                                                  <option value="Catalyst">Catalyst</option>
                                                  <option value="Solvent">Solvent</option>
                                              </select>}
                                          />
                                      </div>
                                  </label>
                              </div>
                              <div className="col-md-6">
                                  <label className="custom-checkbox" htmlFor="log_s">
                                      <Controller
                                        name="log_s"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onBlur, onChange, value } }) => (
                                          <input className="ip" type="checkbox"
                                                 onBlur={onBlur} // notify when input is touched
                                                 onChange={onChange} // send value to hook form
                                                 checked={value}
                                          />
                                        )}
                                      />
                                      <span className="check"></span>
                                      <span className="txt">Log S</span>
                                  </label>
                              </div>
                              <div className="col-md-6">
                                  <div className="row xxs-row">
                                      <div className="col-6">
                                          <label className="ip-wrap mb-2" htmlFor="log_s_option">
                                              <div className="input-wrap select-outer">
                                                  <Controller
                                                    name="log_s_option"
                                                    disabled={control._formValues?.log_s ? false : true}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                      <select
                                                        className="theme-ip sm"
                                                        {...field}
                                                      >
                                                          <option value="" disabled>Select</option>
                                                          <option value="less">
                                                              &lt;
                                                          </option>
                                                          <option value="more">&gt;</option>
                                                          <option value="equal">
                                                              =
                                                          </option>
                                                          <option value="equalMore">
                                                              &gt; =
                                                          </option>
                                                          <option value="equalLess">
                                                              &lt; =
                                                          </option>
                                                          <option value="notEqual">~ =</option>
                                                      </select>
                                                    }
                                                  />
                                              </div>
                                          </label>
                                      </div>
                                      <div className="col-6">
                                          <label className="ip-wrap" htmlFor="log_s_value">
                                              <Controller
                                                name="log_s_value"
                                                disabled={control._formValues?.log_s ? false : true}
                                                control={control}
                                                render={({ field }) => (
                                                  <input
                                                    className="theme-ip sm"
                                                    type="number"
                                                    onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}
                                                    placeholder="Value"
                                                    {...field}
                                                  />
                                                )}
                                              />
                                          </label>
                                      </div>
                                  </div>

                              </div>
                              <div className="col-md-6">
                                  <label className="custom-checkbox">
                                      <Controller
                                        name="form"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onBlur, onChange, value } }) => (
                                          <input className="ip" type="checkbox"
                                                 onBlur={onBlur} // notify when input is touched
                                                 onChange={onChange} // send value to hook form
                                                 checked={value}
                                          />
                                        )}
                                      />
                                      <span className="check"></span>
                                      <span className="txt">Form</span>
                                  </label>
                              </div>
                              <div className="col-md-6">
                                  <label className="ip-wrap" htmlFor="chemicalSafety">
                                      <div className="input-wrap select-outer">
                                          <Controller
                                            name="attachments"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                              <select
                                                className="theme-ip sm"
                                                disabled={control._formValues?.form ? false : true}
                                                {...field}
                                              >
                                                  <option value="" disabled>Select</option>
                                                  <option value="form_crystal">
                                                      Crystal
                                                  </option>
                                                  <option value="form_amorphous">
                                                      Amorphous
                                                  </option>
                                              </select>
                                            }
                                          />
                                      </div>
                                  </label>
                              </div>
                          </div>
                      </div>
                      <div className={`child ${control._formValues?.phase_options == "Gas" ? '' : 'hide'}`}>
                          <div className="row xxs-row">
                              <div className="col-md-6">
                                  <label className="custom-checkbox" htmlFor="density">
                                      <Controller
                                        name="density"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onBlur, onChange, value } }) => (
                                          <input className="ip" type="checkbox"
                                                 onBlur={onBlur} // notify when input is touched
                                                 onChange={onChange} // send value to hook form
                                                 checked={value}
                                          />
                                        )}
                                      />
                                      <span className="check"></span>
                                      <span className="txt">Density (g/ml)</span>
                                  </label>
                              </div>
                              <div className="col-md-6">
                                  <div className="row xxs-row">
                                      <div className="col-6">
                                          <label className="ip-wrap mb-2" htmlFor="density_operation">
                                              <div className="input-wrap select-outer">
                                                  <Controller
                                                    name="density_operation"
                                                    disabled={control._formValues?.density ? false : true}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                      <select
                                                        className="theme-ip sm"
                                                        {...field}
                                                      >
                                                          <option value="" disabled>Select</option>
                                                          <option value="less">
                                                              &lt;
                                                          </option>
                                                          <option value="more">&gt;</option>
                                                          <option value="equal">
                                                              =
                                                          </option>
                                                          <option value="equalMore">
                                                              &gt; =
                                                          </option>
                                                          <option value="equalLess">
                                                              &lt; =
                                                          </option>
                                                          <option value="notEqual">~ =</option>
                                                      </select>
                                                    }
                                                  />
                                              </div>
                                          </label>
                                      </div>
                                      <div className="col-6">
                                          <label className="ip-wrap" htmlFor="density_value">
                                              <Controller
                                                name="density_value"
                                                disabled={control._formValues?.density ? false : true}
                                                control={control}
                                                render={({ field }) => (
                                                  <input
                                                    className="theme-ip sm"
                                                    type="number"
                                                    onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}
                                                    placeholder="Value"
                                                    {...field}
                                                  />
                                                )}
                                              />
                                          </label>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="row xxs-row">
                              <div className="col-md-6">
                                  <label className="custom-checkbox" htmlFor="vapor_density">
                                      <Controller
                                        name="vapor_density"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onBlur, onChange, value } }) => (
                                          <input className="ip" type="checkbox"
                                                 onBlur={onBlur} // notify when input is touched
                                                 onChange={onChange} // send value to hook form
                                                 checked={value}
                                          />
                                        )}
                                      />
                                      <span className="check"></span>
                                      <span className="txt">Vapor Density (g/ml)</span>
                                  </label>
                              </div>
                              <div className="col-md-6">
                                  <div className="row xxs-row">
                                      <div className="col-6">
                                          <label className="ip-wrap mb-2" htmlFor="density_operation">
                                              <div className="input-wrap select-outer">
                                                  <Controller
                                                    name="vapor_density_operation"
                                                    disabled={control._formValues?.vapor_density ? false : true}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                      <select
                                                        className="theme-ip sm"
                                                        {...field}
                                                      >
                                                          <option value="" disabled>Select</option>
                                                          <option value="less">
                                                              &lt;
                                                          </option>
                                                          <option value="more">&gt;</option>
                                                          <option value="equal">
                                                              =
                                                          </option>
                                                          <option value="equalMore">
                                                              &gt; =
                                                          </option>
                                                          <option value="equalLess">
                                                              &lt; =
                                                          </option>
                                                          <option value="notEqual">~ =</option>
                                                      </select>
                                                    }
                                                  />
                                              </div>
                                          </label>
                                      </div>
                                      <div className="col-6">
                                          <label className="ip-wrap" htmlFor="vapor_density_value">
                                              <Controller
                                                name="vapor_density_value"
                                                disabled={control._formValues?.vapor_density ? false : true}
                                                control={control}
                                                render={({ field }) => (
                                                  <input
                                                    className="theme-ip sm"
                                                    type="number"
                                                    onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}
                                                    placeholder="Value"
                                                    {...field}
                                                  />
                                                )}
                                              />
                                          </label>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className={`child ${control._formValues?.phase_options == "Liquid" ? '' : 'hide'}`}>
                          <div className="row xxs-row">
                              <div className="col-md-6">
                                  <label className="custom-checkbox">
                                      <Controller
                                        name="liquid_type"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onBlur, onChange, value } }) => (
                                          <input className="ip" type="checkbox"
                                                 onBlur={onBlur} // notify when input is touched
                                                 onChange={onChange} // send value to hook form
                                                 checked={value}
                                          />
                                        )}
                                      />
                                      <span className="check"></span>
                                      <span className="txt">Type</span>
                                  </label>
                              </div>
                              <div className="col-md-6">
                                  <label className="ip-wrap mb-2" htmlFor="liquid_type">
                                      <div className="input-wrap select-outer">
                                          <Controller
                                            name="liquid_type_value"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) =>
                                              <select
                                                className="theme-ip sm"
                                                disabled={control._formValues?.liquid_type ? false : true}
                                                {...field}
                                              >
                                                  <option value="" disabled>Select</option>
                                                  <option value="API">API</option>
                                                  <option value="Impurity">Impurity</option>
                                                  <option value="Intermediate">Intermediate</option>
                                                  <option value="Starting Material">Starting Material</option>
                                                  <option value="Reagents">Reagents</option>
                                                  <option value="Excipients">Excipients</option>
                                                  <option value="Catalyst">Catalyst</option>
                                                  <option value="Solvent">Solvent</option>
                                              </select>}
                                          />
                                      </div>
                                  </label>
                              </div>
                          </div>
                          <div className="row xxs-row">
                              <div className="col-md-6">
                                  <label className="custom-checkbox" htmlFor="flash_point">
                                      <Controller
                                        name="flash_point"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onBlur, onChange, value } }) => (
                                          <input type="checkbox" className="ip"
                                                 onBlur={onBlur} // notify when input is touched
                                                 onChange={onChange} // send value to hook form
                                                 checked={value}
                                          />
                                        )}
                                      />
                                      <span className="check"></span>
                                      <span className="txt">Flash Point (C)</span>
                                  </label>
                              </div>
                              <div className="col-md-6">
                                  <div className="row xxs-row">
                                      <div className="col-6">
                                          <label className="ip-wrap mb-2" htmlFor="flash_point_operation">
                                              <div className="input-wrap select-outer">
                                                  <Controller
                                                    name="flash_point_operation"
                                                    disabled={control._formValues?.flash_point ? false : true}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                      <select
                                                        className="theme-ip sm"
                                                        {...field}
                                                      >
                                                          <option value="" disabled>Select</option>
                                                          <option value="less">
                                                              &lt;
                                                          </option>
                                                          <option value="more">&gt;</option>
                                                          <option value="equal">
                                                              =
                                                          </option>
                                                          <option value="equalMore">
                                                              &gt; =
                                                          </option>
                                                          <option value="equalLess">
                                                              &lt; =
                                                          </option>
                                                          <option value="notEqual">~ =</option>
                                                      </select>
                                                    }
                                                  />
                                              </div>
                                          </label>
                                      </div>
                                      <div className="col-6">
                                          <label className="ip-wrap" htmlFor="flash_point_value">
                                              <Controller
                                                name="flash_point_value"
                                                disabled={control._formValues?.flash_point ? false : true}
                                                control={control}
                                                render={({ field }) => (
                                                  <input
                                                    className="theme-ip sm"
                                                    type="number"
                                                    onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}
                                                    placeholder="Value"
                                                    {...field}
                                                  />
                                                )}
                                              />
                                          </label>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="row xxs-row">
                              <div className="col-md-6">
                                  <label className="custom-checkbox" htmlFor="log_p">
                                      <Controller
                                        name="log_p"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onBlur, onChange, value } }) => (
                                          <input type="checkbox" className="ip"
                                                 onBlur={onBlur} // notify when input is touched
                                                 onChange={onChange} // send value to hook form
                                                 checked={value}
                                          />
                                        )}
                                      />
                                      <span className="check"></span>
                                      <span className="txt">Log P</span>
                                  </label>
                              </div>
                              <div className="col-md-6">
                                  <div className="row xxs-row">
                                      <div className="col-6">
                                          <label className="ip-wrap mb-2" htmlFor="log_p_operation">
                                              <div className="input-wrap select-outer">
                                                  <Controller
                                                    name="log_p_operation"
                                                    disabled={control._formValues?.log_p ? false : true}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) =>
                                                      <select
                                                        className="theme-ip sm"
                                                        {...field}
                                                      >
                                                          <option value="" disabled>Select</option>
                                                          <option value="less">
                                                              &lt;
                                                          </option>
                                                          <option value="more">&gt;</option>
                                                          <option value="equal">
                                                              =
                                                          </option>
                                                          <option value="equalMore">
                                                              &gt; =
                                                          </option>
                                                          <option value="equalLess">
                                                              &lt; =
                                                          </option>
                                                          <option value="notEqual">~ =</option>
                                                      </select>
                                                    }
                                                  />
                                              </div>
                                          </label>
                                      </div>
                                      <div className="col-6">
                                          <label className="ip-wrap" htmlFor="log_p_value">
                                              <Controller
                                                name="log_p_value"
                                                disabled={control._formValues?.log_p ? false : true}
                                                control={control}
                                                render={({ field }) => (
                                                  <input
                                                    className="theme-ip sm"
                                                    type="number"
                                                    onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}
                                                    placeholder="Value"
                                                    {...field}
                                                  />
                                                )}
                                              />
                                          </label>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

          </div>

          <div className="row p-4">
              <div className="col-6">
                  <button title="Filter" type="submit" className="theme-btn sm w-full">
                      Filter
                  </button>
              </div>
              <div className="col-6">
                  <button type="reset" title="Reset" className="theme-btn sm alter w-full">
                      Reset
                  </button>
              </div>
          </div>
      </form>
    );
};

export default FilterSidebar;