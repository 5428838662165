import { materialConstants } from '../constants/materialConstants';

export const materialsAction = (materials: any[]) => {
  return {
    type: materialConstants.MATERIALS,
    materials,
  };
};
export const materialAction = (material: any) => {
  return {
    type: materialConstants.MATERIAL,
    material,
  };
};
export const addMaterialAction = (material: any) => {
  return {
    type: materialConstants.ADD_MATERIAL,
    material,
  };
};

export const updateMaterialAction = (material: any) => {
  return {
    type: materialConstants.UPDATE_MATERIAL,
    material,
  };
};

export const solidMaterialAction = (material: any) => {
  return {
    type: materialConstants.SOLID_MATERIAL,
    material,
  };
};
