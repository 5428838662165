import { Controller } from 'react-hook-form';

const SelectFilterController = (props: any) => {
    //control, name,label, defaultValue
    return (
        <div className="col-md-6">
            <label className="ip-wrap mb-2" htmlFor={props?.name}>
                <div className="input-wrap select-outer">
                    <Controller
                        name={props?.name}
                        control={props.control}
                        defaultValue={props.value}
                        render={({ field }) =>
                            <select
                                className="theme-ip sm"
                                disabled={props?.disabled}
                                {...field}
                            >
                                <option value="" selected>Select</option>
                                {props?.options?.map((item: any, index: number) => (
                                    <option key={index} value={item?.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>}
                    />
                </div>
            </label>
        </div>
    )
}

export default SelectFilterController;