import Layout from "../../components/layout";

const UserForm = () => {
  return (
    <Layout title="Add User">
      <h1>TBD...</h1>
    </Layout >
  );
};

export default UserForm;
