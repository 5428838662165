import React from "react";
import Plot from "react-plotly.js";

const defaultPlot = {
  data: [
    {
      z: [
        [null, null],
        [null, null],
      ],
      type: "contour",
      colorscale: "white",
    },
  ],
  layout: {
    title: "No Data Available",
    xaxis: { title: "X-axis" },
    yaxis: { title: "Y-axis" },
    height: 600,
    width: 600,
  },
};

export default function ContourGraph({ plot }: { plot: any }) {
  return (
    <Plot
      data={plot?.data && plot.data.length > 0 ? plot.data : defaultPlot.data}
      layout={{
        ...defaultPlot.layout,
        ...plot?.layout,
      }}
    />
  );
}
