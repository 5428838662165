import ContourGraph from "../ContourGraph";
import MaxSolubillityTable from "../MaxSolubillityTable";
import Tool2CrystallizationForm from "./Tool2CrystalizationForm";

export default function Tool2Screening({ handleFormSubmit, responseData, setResponseData }: {
  handleFormSubmit: any,
  responseData: any,
  setResponseData: any
}) {

  return <div >
    <Tool2CrystallizationForm onSubmit={handleFormSubmit} responseData={responseData} setResponseData={setResponseData} />
    {
      responseData &&
      <div className="flex justify-around" > 
        <MaxSolubillityTable data={responseData?.solubility_data} />
        <ContourGraph plot={responseData?.plot1} />
      </div>
    }
    
  </div>;
}
