export const UPLOAD_FILE_MAXLENGTH = 256;

export const parseAttachments = (attachments: any[]) => {
  return attachments?.map((attachment: any) => ({
    category: attachment.category,
    file: {
      name: attachment.display_name,
      type: attachment.mime_type,
    },
    error: false,
    isUpload: true,
  }));
}

export const capitalise = (string: string) => {
  if (string && string?.length) {
    return (string[0].toUpperCase() + string.slice(1));
  }
  return
}
export const NUMBER_INPUT_MAXLENGTH = 12;
