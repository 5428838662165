import { alertConstants } from '../constants';

export const alertOpenAction = (message: string,alertType?:string) => {
  return {
    type: alertConstants.ALERT_OPEN,
    payload: {message ,alertType },
  };
};

export const alertCloseAction = () => {
  return {
    type: alertConstants.ALERT_CLOSE,
  };
};
