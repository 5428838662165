import { Controller } from 'react-hook-form';
import { NUMBER_INPUT_MAXLENGTH } from '../../../utils';
import { limitDigits } from '../../../utils/common';

const FilterInputController = (props: any) => {
  return (
    <div className="col-md-6">
      <div className="row xxs-row">
        <div className="col-6">
          <label className="ip-wrap mb-2" htmlFor={props?.name}>
            <div className="input-wrap select-outer">
              <Controller
                name={props?.operationName}
                control={props?.control}
                defaultValue=""
                render={({ field }) =>
                  <select
                    className="theme-ip sm"
                    disabled={props?.disabled}
                    {...field}
                  >
                    <option value="" selected>Select</option>
                    <option value="less">&lt;</option>
                    <option value="more">&gt;</option>
                    <option value="equal">=</option>
                    <option value="equalMore">&gt; =</option>
                    <option value="equalLess">&lt; =</option>
                    <option value="notEqual">~ =</option>
                  </select>
                }
              />
            </div>
          </label>
        </div>
        <div className="col-6">
          <label className="ip-wrap" htmlFor={props.name}>
            <Controller
              name={props?.name}
              disabled={props.disabled}
              control={props.control}
              render={({ field }) => (
                <input
                  type="number"
                  {...field}
                  className="theme-ip sm"
                  disabled={props?.disabled}
                  onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}
                  placeholder="Value"
                />
              )}
            />
          </label>
        </div>
      </div>
    </div>
  )
}

export default FilterInputController