import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";

// components
import Layout from "../../../components/layout";
import BackButton from '../../../components/common/BackButton';
import CustomLoader from '../../../components/common/CustomLoader';

// modals
import CancelConfirmModal from '../../../components/modals/CancelFormModal';
import HelpModal from '../../../components/modals/HelpModal';

// tabs
import Liquid from '../../../components/material/tabs/phase/liquid';

// redux actions
import { alertCloseAction, alertOpenAction } from '../../../redux/actions';
import { materialAction } from '../../../redux/actions/materialActions';

// props
import { RootState } from '../../../redux/store';

// helpers
import { liquidInitialData } from '../../../utils/materialHelper';
import { MaterialLiquidPhaseHelp } from '../../../utils/helpContent';
import { convertStringToNumber, setTitleNumberInput } from '../../../utils/common';
import MaterialService from '../../../services/materialService';
import { ButtonIcons, ButtonProps, ButtonTypes } from "../../../components/common/Button";
import BodyHeader from "../../../components/layout/BodyHeader";
import RenderIf from "../../../components/common/RenderIf";
import { LIQUID_PHASE_PAGE_TITLE } from "../../../utils/constant";

const MaterialLiquidPhase = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params: any = useParams();

    // auth
    const { authState } = useOktaAuth();
    const auth: any = authState ? authState?.accessToken : '';

    // states
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [openHelp, setOpenHelp] = useState<boolean>(false);
    const [materialData, setMaterialData] = useState<any>();

    // Show data in input fields to update
    const material = useSelector((state: RootState) => state.material.item);
    
    //form
    const methods = useForm({ defaultValues: liquidInitialData(material) });

    useEffect(() => {
        methods.watch();
    });

    // To show warning when trying to exit form
    useEffect(() => {
        window.addEventListener('beforeunload', refreshData);
        return () => {
            window.removeEventListener('beforeunload', refreshData);
        };
    }, []);

    const refreshData = (e: any) => {
        e.preventDefault();
        e.returnValue = '';
    };

    useEffect(() => {
        // set default title on number input fields.
        setTitleNumberInput();
    }, []);

    useEffect(() => {
        if (!material) {
            history.push("/materials");
            return;
        }
        setMaterialData(material);
    }, [material]);

    const apiRequest = async () => {
        // setLoading(true); // enable loading
        const payload = {
            uid: auth?.claims?.uid,
            ...materialData,
            id: materialData.id,
            liquid: {
                ...methods?.control?._formValues?.liquid,
                ...[
                    'autoignition_temperature', 'color_l', 'color_a', 'color_b', 'density', 'flash_point',
                    'heat_of_combustion', 'heat_of_vaporization', 'henry_law_constant', 'ionization_potential',
                    'log_p', 'pKa', 'refractive_index', 'relative_evaporation_rate', 'specific_heat_capacity',
                    'surface_tension', 'vapor_density', 'vapor_pressure', 'viscosity', 'volatility'
                ].reduce((e: any, t: any) => {
                    e[t] = convertStringToNumber(methods?.control?._formValues?.liquid[t]);
                    return e;
                }, {}),
            }
        };
        const res = await MaterialService.update(payload);
        if (res?.status === 200) {
            dispatch(materialAction(res?.data?.body));
            methods.reset({
                material_name: methods.control._formValues?.material_name,
                vendor_name: methods.control._formValues?.vendor_name,
                lot_number: methods.control._formValues?.lot_number,
                liquid: methods.control._formValues?.liquid,
            }, { keepValues: true, keepIsValid: true });
            return true;
        };
        return false;
    }

    // Submit Form
    const onSubmit: SubmitHandler<any> = async () => {
        setLoading(true); // enable Loading
        const apiResponse = await apiRequest();
        setLoading(false); // disble Loading
        if (apiResponse) {
            dispatch(alertOpenAction(`Liquid information ${params?.id ? 'updated' : 'added'} successfully.`, 'success'));
        } else {
            dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
        }
        setTimeout(() => dispatch(alertCloseAction()));
    };

    // modal submit buttons
    const saveMaterial = async () => {
        if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
            setOpen(false);
            setLoading(true); // enable Loading
            const apiResponse = await apiRequest();
            setLoading(false); // disble Loading
            if (apiResponse) {
                dispatch(alertOpenAction(`Liquid information ${params?.id ? 'updated' : 'added'} successfully.`, 'success'));
                params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
            } else {
                dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
            }
            setTimeout(() => dispatch(alertCloseAction()));
        } else {
            setOpen(false);
            dispatch(alertOpenAction('No data updated to save.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        }
    }

    // modal cancel buttons
    const dontSave = () => {
        setOpen(false);
        params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
    }
// Header Buttons
const headerButtons: ButtonProps[] = [
    {
      isIconButton: true,
      navigateTo: "",
      icon: ButtonIcons.SAVE,
      title: "Save",
      type: ButtonTypes.PRIMARY,
      onClick: onSubmit as any,
      hideForDesktop: false,
      disabled: loading,
      dataCy:"save-btn"
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.CROSS,
      title: "Close",
      type: ButtonTypes.SECONDARY,
      onClick: () => {
        if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
            setOpen(true);
            return;
        }
        params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
    },
      hideForDesktop: false,
      disabled: loading,
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.HELP,
      title: "Help",
      type: ButtonTypes.SECONDARY,
      onClick: () => {
        setOpenHelp(true);
      },
      hideForDesktop: false,
      disabled: loading,
    },
  ];
    return (
        <Layout title="Material-Liquid">
            <RenderIf condition={loading}><CustomLoader /></RenderIf>
            <FormProvider {...methods}>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    methods.handleSubmit(onSubmit);
                }} noValidate>
                    <CancelConfirmModal open={open} setOpen={setOpen} saveMaterial={saveMaterial} dontSave={dontSave} />
                    <HelpModal
                        open={openHelp}
                        setOpen={setOpenHelp}
                        title={MaterialLiquidPhaseHelp.title}
                        content={MaterialLiquidPhaseHelp.content} />
                    <BodyHeader title={LIQUID_PHASE_PAGE_TITLE} buttons={headerButtons} backButton={<BackButton onSubmit={saveMaterial} formState={methods.formState} material={material} />}/>
                    <div className="row">
                        <div className="col-12">
                            <div className="theme-card">
                                <div className="body">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <label className="ip-wrap" htmlFor="material_name" title="Material Name">
        <span className="form-label">Material Name</span>
                                                    <Controller
                                                        name="material_name"
                                                        control={methods?.control}
                                                        render={({ field }) => <input disabled className="theme-ip" {...field} />}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <label className="ip-wrap" htmlFor="vendor" title="Vendor">
        <span className="form-label">Vendor</span>
                                                    <div className="input-wrap">
                                                        <Controller
                                                            name="vendor_name"
                                                            control={methods?.control}
                                                            render={({ field }) => <input disabled className="theme-ip" {...field} />}
                                                        />
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form-group">
                                                <label className="ip-wrap" htmlFor="lot_number" title="Lot Number">
        <span className="form-label">Lot Number</span>
                                                    <div className="input-wrap">
                                                        <Controller
                                                            name="lot_number"
                                                            control={methods?.control}
                                                            render={({ field }) => <input disabled className="theme-ip" {...field} />}
                                                        />
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* divider */}
                                    <div className="divider"></div>
                                    <Liquid />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </Layout>
    );
};

export default MaterialLiquidPhase;
