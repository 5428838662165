import React from 'react'

const CustomLoader = () => {
  return (
    <div className="theme-loader show fixed">
    <div className="loader-outer">
      <div className="loader"></div>
    </div>
  </div>
  )
}

export default CustomLoader;