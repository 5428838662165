import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import MultiFileUpload from "../common/MultiFileUpload";


const UploadCfdModels = (props: any) => {
  const methods = useFormContext();

  useEffect(() => {
    methods.watch();
  });

  const attachmentKeys = Object.keys(props.attachmentNames);

  const generateMultiFileUpload = (title: string, name: string) => {
    return (
      <div className="col-12" key={name}>
        <MultiFileUpload
          title={title}
          name={name}
          showDownload={true}
          attachments={props.attachments?.[name] ?? []}
          handleFileChange={props.handleFileChange}
          removeFile={props.removeFile}
          downloadFile={props.downloadFile}
          hasPermission={props.hasPermission}
        />
      </div>
    );
  };

  return (
    <div className="row">
        <div className="col-lg-5 ">
        <div className="row">
          {attachmentKeys.slice(0, 3).map((key) => {
            return generateMultiFileUpload(props.attachmentNames[key], key);
          })}
          <div className="col-12">
            <div className="form-group">
              <label className="ip-wrap" htmlFor={Object.keys(props.textField)?.[0]}>
                <span className="form-label">{Object.values(props.textField)}</span>
                <Controller
                  name={Object.keys(props.textField)?.[0]}
                  control={methods.control}
                  render={({ field }) => <input className="theme-ip" {...field} />}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-2 full-flex d-md-none">
        <div className="divider-mid divider-left">

        </div>
      </div>
      <div className="col-lg-5 ">
        <div className="row">
          {attachmentKeys.slice(3, 7).map((key) => {
            return generateMultiFileUpload(props.attachmentNames[key], key);
          })}
        </div>
      </div>
    </div>
  );
};

export default UploadCfdModels;
