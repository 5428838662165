import React from 'react';
import { Controller, Control } from 'react-hook-form';
import Select, { Props as SelectProps } from 'react-select';

interface OptionType {
  label: string;
  value: string | number;
}

interface AppSelectProps {
  label: string;
  name: string;
  control: Control<any>; 
  options: OptionType[];
  placeholder?: string;
  defaultValue?: string | number | null;
}

const AppSelect: React.FC<AppSelectProps> = ({
  label,  
  name,
  control,
  options,
  placeholder,
  defaultValue,
}) => {
  return (
    <div className="form-group">
      <label className="ip-wrap" htmlFor={name}>
        <span className="form-label" title={`Select ${label}`}>{label}</span>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Select
              ref={ref}
              onChange={(selectedOption) => onChange(selectedOption ? selectedOption.value : null)}
              onBlur={onBlur}
              value={options.find(option => option.value === value) || null}
              options={options}
              placeholder={placeholder}
              className="basic-single"
              classNamePrefix="select"
            />
          )}
        />
      </label>
    </div>
  );
};

export default AppSelect;
