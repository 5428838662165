import { authConstants } from '../constants';

const initialState = {
  loading: false,
  authModal: {
    name: '',
    open: false,
  },
};

export function authReducer(state = initialState, action: any) {
  switch (action.type) {
    case authConstants.FETCH:
      return {
        ...state,
        loading: action.loading,
      };
    case authConstants.LOGIN:
      return {
        ...state,
        user: action.user,
        token: action.token,
        loading: false,
      };
    case authConstants.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        loading: false,
      };
    case authConstants.UPDATE_PROFILE:
      return {
        ...state,
        user: action.user,
        loading: false,
      };
    case authConstants.OPEN_MODAL: {
      return {
        ...state,
        authModal: {
          name: action.name,
          open: true,
        },
        loading: false,
      };
    }
    case authConstants.CLOSE_MODAL: {
      return {
        ...state,
        authModal: {
          name: '',
          open: false,
        },
        loading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
