import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useOktaAuth } from "@okta/okta-react";

// components
import Layout from "../../components/layout";
import BodyHeader from "../../components/layout/BodyHeader";
import {
  ButtonIcons,
  ButtonProps,
  ButtonTypes,
} from "../../components/common/Button";

// modals
import HelpModal from "../../components/modals/HelpModal";

// context
import { useUser } from "../../components/context/UserContext";

// Tabs
import {
  Material,
  Gas,
  Liquid,
  SolubilityInformation,
  SpectralInformation,
  EnvironmentalData,
  General,
  Aeration,
  Compressibility,
  Densities,
  DrugInformation,
  FlowEnergy,
  Attachments,
  Hygroscopicity,
  Morphologi,
  Permeability,
  PSD,
  SEM,
  ShearCell,
  ThermalAnalysis,
  ImpurityResiduals
} from "../../components/material/tabs";
import AuditTrail from "../../components/common/AuditTrail";

// services
import LogService from "../../services/logService";

// redux actions
import { materialAction } from "../../redux/actions/materialActions";
import { alertCloseAction, alertOpenAction } from "../../redux/actions";

// props
import { RootState } from "../../redux/store";
import { FilesProps } from "../../types";

// helpers
import {
  aerationInitialData,
  compressibilityInitialData,
  densityInitialData,
  drugInfoInitialData,
  environmentInitialData,
  flowEnergyInitialData,
  formatMaterialFiles,
  gasInitialData,
  getSolubilityFileFields,
  generalSolidInitialData,
  hygroscopicityInitialData,
  initialMaterialData,
  liquidInitialData,
  morphologiInitialData,
  permeabilityInitialData,
  psdInitialData,
  semInitialData,
  shearCellInitialData,
  solubilityInitialData,
  thermalInitialData,
  impurityAndResidualsInitialData,
} from "../../utils/materialHelper";
import { ViewMaterialHelp } from "../../utils/helpContent";
import FileService from "../../services/fileService";
import MaterialService from "../../services/materialService";
import { downloadFileFromURL } from "../../utils/downloadFile";
import CustomLoader from "../../components/common/CustomLoader";
import { setTitleNumberInput } from "../../utils/common";
import UploadSolubilityData from "../../components/solubility/uploadSolubilityData";
import { parseAttachments } from "../../utils";
import { layoutTitles, VIEW_MATERIAL_PAGE_TITLE } from "../../utils/constant";

interface filesByCategoryProps {
  solubility: FilesProps[] | [];
  spectralInformation: {
    atrIR: FilesProps[] | [];
    gcMS: FilesProps[] | [];
    ir: FilesProps[] | [];
    lcMS: FilesProps[] | [];
    msMS: FilesProps[] | [];
    nmr: FilesProps[] | [];
    raman: FilesProps[] | [];
  };
  environmental: FilesProps[] | [];
  attachments: {
    chemicalSafety: FilesProps[] | [];
    molecularStructure: FilesProps[] | [];
    purityHPLC: FilesProps[] | [];
    smiles: FilesProps[] | [];
    uspMonograph: FilesProps[] | [];
    othersDocuments: FilesProps[] | [];
    takedaProcess: FilesProps[] | [];
  };
}

const MaterialView = () => {
  const dispatch = useDispatch();
  const params = useParams<any>();
  const history = useHistory();

  const material = useSelector((state: RootState) => state.material.item);

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : "";

  // form
  const { control, setValue } = useForm();

  // user context
  const { user } = useUser();
  // Added for solubility attachment fields to handle attachments
  const [simulation, setSimulation] = useState<any>(null);
  // solubility
  useEffect(() => {
    if (simulation) resetUploadSolubility(simulation);
  }, [simulation]);

  // states
  const [loading, setLoading] = useState<boolean>(false);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [logs, setLogs] = useState<any[]>([]);
  const [downloadingFile, setDownloadingFile] = useState(false);
  const [filesByCategory, setFilesByCategory] = useState<filesByCategoryProps>({
    solubility: [],
    spectralInformation: {
      atrIR: [],
      gcMS: [],
      ir: [],
      lcMS: [],
      msMS: [],
      nmr: [],
      raman: [],
    },
    environmental: [],
    attachments: {
      chemicalSafety: [],
      molecularStructure: [],
      purityHPLC: [],
      smiles: [],
      uspMonograph: [],
      othersDocuments: [],
      takedaProcess: [],
    },
  });

  // Added for solubility attachment fields to handle attachments
  const initialData: any = {
    model: "",
    solvent: "",
    solvent1: "",
    solvent2: "",
    temperature: 5,
    solubility_value: "",
    file_path: "",
    turbidity_measured: "",
    visual: false,
    plot: false,
    material_name: material?.material_name ?? null,
    vendor_name: material?.vendor_name ?? null,
    lot_number: material?.lot_number ?? null,
    solubility: {
      main_solvent: material?.solubility?.main_solvent?.toString() ?? "",
      solubility_in_main_solvent:
        material?.solubility?.solubility_in_main_solvent?.toString() ?? "",
      main_solvent_temperature:
        material?.solubility?.main_solvent_temperature?.toString() ?? "",
      main_solvent_reference:
        material?.solubility?.main_solvent_reference?.toString() ?? "",
      second_solvent: material?.solubility?.second_solvent?.toString() ?? "",
      solubility_in_second_solvent:
        material?.solubility?.solubility_in_second_solvent?.toString() ?? "",
      second_solvent_temperature:
        material?.solubility?.second_solvent_temperature?.toString() ?? "",
      second_solvent_reference:
        material?.solubility?.second_solvent_reference?.toString() ?? "",
      attachments: material?.solubility?.attachments
        ? parseAttachments(material?.solubility?.attachments)
        : [],
    },
    // Added for solubility attachment fields to handle attachments
    attachments: {
      model3: [],
      solubility: {
        unchainedLab: [],
        hplc: [],
        crystal16: [],
        crystalline: [],
        gravimetryMethod: [],
        solubilityCurve1: [],
        solubilityCurve2: [],
        solubilityCurve3: [],
        solubilityCurve4: [],
        solubilityCurve5: [],
      },
    },
  };
  let allAttachments: any = {
    model3: [],
    solubility: {
      unchainedLab: [],
      hplc: [],
      crystal16: [],
      crystalline: [],
      gravimetryMethod: [],
      solubilityCurve1: [],
      solubilityCurve2: [],
      solubilityCurve3: [],
      solubilityCurve4: [],
      solubilityCurve5: [],
    },
  };

  const methods = useForm({ defaultValues: initialData });

  useEffect(() => {
    user && getMaterialById(params?.id);
  }, [user]);

  // Get onChange values for search
  useEffect(() => {
    if (material) {
      setValue("vendor_name", material?.vendor_name);
      setValue("material_name", material?.material_name);
      setValue("lot_number", material?.lot_number);
    }
  }, [material]);

  const getSaveSimulations = async (id: string) => {
    const res = await MaterialService.create(
      "/simulations/get-simulations-files-data",
      {
        id,
        uid: auth?.claims?.uid,
        simulation_type: "material",
      }
    );

    if (res?.data?.code == 200) {
      const result = res?.data;
      if (result.body && Object.keys(result.body).length) {
        console.log(result.body);
        setSimulation(result.body);
      }
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
      return false;
    }
  };

  const getMaterialById = async (id: string) => {
    const res = await MaterialService.getById(params?.id);
    if (res?.data?.code === 200) {
      const result = res?.data;
      // material redux
      dispatch(materialAction(result.body?.Item));
      await getSaveSimulations(params?.id);

      // material logs request
      await getLogs(params?.id);

      filesByCategory.solubility = [];
      filesByCategory.environmental = [];
      filesByCategory.spectralInformation.atrIR = [];
      filesByCategory.spectralInformation.gcMS = [];
      filesByCategory.spectralInformation.ir = [];
      filesByCategory.spectralInformation.lcMS = [];
      filesByCategory.spectralInformation.msMS = [];
      filesByCategory.spectralInformation.nmr = [];
      filesByCategory.spectralInformation.raman = [];
      // solubility files
      if (result.body?.Item?.solubility?.attachments?.length) {
        filesByCategory.solubility =
          result.body?.Item?.solubility?.attachments ?? [];
      }

      // environment files
      if (result.body?.Item?.environment?.attachments?.length) {
        filesByCategory.environmental =
          result.body?.Item?.environment?.attachments ?? [];
      }

      // spectral information files
      if (result.body?.Item?.spectralInfo?.attachments) {
        for (var item in result.body?.Item?.spectralInfo?.attachments) {
          switch (item) {
            case "atrIR":
              filesByCategory.spectralInformation.atrIR =
                result.body?.Item?.spectralInfo?.attachments[item];
              break;
            case "gcMS":
              filesByCategory.spectralInformation.gcMS =
                result.body?.Item?.spectralInfo?.attachments[item];
              break;
            case "ir":
              filesByCategory.spectralInformation.ir =
                result.body?.Item?.spectralInfo?.attachments[item];
              break;
            case "lcMS":
              filesByCategory.spectralInformation.lcMS =
                result.body?.Item?.spectralInfo?.attachments[item];
              break;
            case "msMS":
              filesByCategory.spectralInformation.msMS =
                result.body?.Item?.spectralInfo?.attachments[item];
              break;
            case "nmr":
              filesByCategory.spectralInformation.nmr =
                result.body?.Item?.spectralInfo?.attachments[item];
              break;
            case "raman":
              filesByCategory.spectralInformation.raman =
                result.body?.Item?.spectralInfo?.attachments[item];
              break;
            default:
            // default
          }
        }
      }

      setFilesByCategory({ ...filesByCategory });
      if (
        result.body?.Item?.attachments &&
        Array.isArray(result.body?.Item?.attachments) &&
        result.body?.Item?.attachments?.length
      ) {
        const attachments = formatMaterialFiles(result.body?.Item?.attachments);
        for (var attachment in attachments) {
          switch (attachment) {
            case "Solubility Curve":
              filesByCategory.solubility = attachments[attachment];
              break;
            case "XX Report":
              filesByCategory.environmental = attachments[attachment];
              break;
            case "ATR-IR":
              filesByCategory.spectralInformation.atrIR =
                attachments[attachment];
              break;
            case "GC-MS":
              filesByCategory.spectralInformation.gcMS =
                attachments[attachment];
              break;
            case "IR":
              filesByCategory.spectralInformation.ir = attachments[attachment];
              break;
            case "LC-MS":
              filesByCategory.spectralInformation.lcMS =
                attachments[attachment];
              break;
            case "MS-MS":
              filesByCategory.spectralInformation.msMS =
                attachments[attachment];
              break;
            case "NMR":
              filesByCategory.spectralInformation.nmr = attachments[attachment];
              break;
            case "Raman":
              filesByCategory.spectralInformation.raman =
                attachments[attachment];
              break;
            case "Molecular Structure":
              filesByCategory.attachments.molecularStructure =
                attachments[attachment];
              break;
            case "USP Monograph":
              filesByCategory.attachments.uspMonograph =
                attachments[attachment];
              break;
            case "Chemical Safety":
              filesByCategory.attachments.chemicalSafety =
                attachments[attachment];
              break;
            case "HPLC Method":
              filesByCategory.attachments.purityHPLC = attachments[attachment];
              break;
            case "SMILES":
              filesByCategory.attachments.smiles = attachments[attachment];
              break;
            case "Process Attachment":
              filesByCategory.attachments.takedaProcess =
                attachments[attachment];
              break;
            case "Other Documents":
              filesByCategory.attachments.othersDocuments =
                attachments[attachment];
              break;
            case "Others":
              filesByCategory.attachments.othersDocuments =
                attachments[attachment];
              break;
            default:
            // default
          }
        }
        setFilesByCategory({ ...filesByCategory });
      }
    }
  };

  const getLogs = async (id: number) => {
    const payload = {
      entity_type: "material",
      entity_id: id,
      uid: `${auth?.claims?.uid}`,
    };
    const res = await LogService.get("/logs/get", payload);
    if (res?.data?.code === 200) {
      setLogs(res?.data?.body ?? []);
    }
  };

  const downloadFile = async (file: any) => {
    const res = await FileService.create("/files/download", {
      file: file.file_name,
    });
    if (res?.status === 200) {
      const fileRes = await fetch(res?.data.body);
      const blob = await fileRes.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = file.display_name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      dispatch(alertOpenAction("File downloaded successfully.", "success"));
      setTimeout(() => dispatch(alertCloseAction()));
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const editMaterial = (data: any) => {
    history.push({
      pathname: `/materials/${data?.id}/update`,
      state: { id: `${data?.id}`, page: `list` },
    });
  };

  const hasWritePermission =
    user?.permissions?.updated?.material?.hasReadAndWrite ?? false;

  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
  }, []);

  // method trigger to download selected file.
  const downloadFileSolubilityAttachments = async (file: any) => {
    let attachments: any = getSolubilityFileFields(simulation);

    if (!file?.display_name) {
      file = attachments.find((attachment: any) => {
        if (
          attachment.category === file.category &&
          attachment.display_name === file.file.name
        ) {
          return file;
        }
      });
    }
    if (!file) return;
    setFileLoading(true); // enable loading
    const res = await FileService.create("/files/download", {
      file: file.file_name,
    });
    setFileLoading(false); // disable loading
    if (res?.status === 200) {
      const fileRes = await fetch(res?.data.body);
      const blob = await fileRes.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = file.display_name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      dispatch(alertOpenAction("File downloaded successfully.", "success"));
      setTimeout(() => dispatch(alertCloseAction()));
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };
  const parseFileSolubility = (items: any[]) => {
    return items.map((item: any) => ({
      category: item.category,
      file: {
        name: item.display_name,
        type: item.mime_type,
      },
      error: false,
      isUpload: true,
    }));
  };

  // // method trigger when for reset upload, SOLUBILITY
  const resetUploadSolubility = (simulation: any, override = {}) => {
    let data: any = { ...initialData, ...methods.control._formValues };
    data.vendor_name = simulation?.vendor_name ?? "";
    data.material_name = simulation?.material_name ?? "";
    data.lot_number = simulation?.lot_number ?? "";
    data.model = methods.control._formValues?.model
      ? methods.control._formValues?.model
      : simulation?.model ?? "";
    data.solvent = methods.control._formValues?.solvent
      ? methods.control._formValues?.solvent
      : simulation?.solvent ?? "";
    data.temperature = methods.control._formValues?.temperature
      ? methods.control._formValues?.temperature
      : simulation?.temperature ?? 5;
    data.turbidity_measured = methods.control._formValues?.turbidity_measured
      ? methods.control._formValues?.turbidity_measured
      : simulation?.turbidity_measured ?? 1017.16;
    data.solubility_value = methods.control._formValues?.solubility_value
      ? methods.control._formValues?.solubility_value
      : simulation?.solubility_value ?? "";
    // data.solvent1 = simulation?.solvent1 ?? "";
    // data.solvent2 = simulation?.solvent2 ?? "";
    // data.temperature = simulation?.temperature ?? 5;
    // data.solubility_value = simulation?.solubility_value ?? "";
    data.visual = methods.control._formValues?.visual ?? false;
    data.plot = methods.control._formValues?.plot ?? false;
    data.attachments = { ...allAttachments };

    let allSolubilityAttachmentNames: Array<string> = [];

    for (const attachment in allAttachments.solubility) {
      if (
        Object.prototype.hasOwnProperty.call(
          allAttachments.solubility,
          attachment
        )
      ) {
        allSolubilityAttachmentNames.push(attachment);
      }
    }

    for (const attachment of allSolubilityAttachmentNames) {
      data.attachments.solubility[attachment] = simulation?.[attachment]
        ? parseFileSolubility(simulation[attachment])
        : [];
    }

    if (data.model == "Model 3") {
      data.solubility_value = "";
      data.solvent = "";
      data.solvent1 = "";
      data.temperature = 5;
      data.turbidity_measured = "";
    }

    methods.reset({ ...data, ...override });
  };

  const headerButtons: ButtonProps[] = [
    ...(hasWritePermission
      ? [
          {
            isIconButton: true,
            title: "Edit",
            onClick: () => editMaterial(material),
            icon: ButtonIcons.EDIT,
            type: ButtonTypes.SECONDARY,
          },
        ]
      : []),
    {
      isIconButton: true,
      title: "Close",
      onClick: () => history.push("/materials/list"),
      icon: ButtonIcons.CROSS,
      type: ButtonTypes.SECONDARY,
    },
    {
      isIconButton: true,
      title: "Help",
      onClick: () => setOpenHelp(true),
      icon: ButtonIcons.HELP,
      type: ButtonTypes.SECONDARY,
    },
  ];

  return (
    <>
      {downloadingFile && <CustomLoader />}
      <Layout title={layoutTitles.viewMaterial}>
        <HelpModal
          open={openHelp}
          setOpen={setOpenHelp}
          title={ViewMaterialHelp.title}
          content={ViewMaterialHelp.content}
        />

        {/* Header */}
        <BodyHeader
          title={VIEW_MATERIAL_PAGE_TITLE}
          buttons={headerButtons}
          showBackButton
          onBackClick={() => history.goBack()}
        />

        <div className="row">
          <div className="col-12">
            <div className="theme-card">
              <div className="body">
                <form>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label
                          className="ip-wrap"
                          htmlFor="vendor"
                          title="Vendor"
                        >
                          <span className="form-label">Vendor</span>
                          <div className="input-wrap ">
                            <Controller
                              name="vendor_name"
                              control={control}
                              render={({ field }) => (
                                <input
                                  disabled
                                  className="theme-ip"
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label
                          className="ip-wrap"
                          htmlFor="material_name"
                          title="Material Name"
                        >
                          <span className="form-label">Material Name</span>
                          <div className="input-wrap ">
                            <Controller
                              name="material_name"
                              control={control}
                              render={({ field }) => (
                                <input
                                  disabled
                                  className="theme-ip"
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group ">
                        <label
                          className="ip-wrap"
                          htmlFor="lot_number"
                          title="Lot Number"
                        >
                          <span className="form-label">Lot Number</span>
                          <div className="input-wrap ">
                            <Controller
                              name="lot_number"
                              control={control}
                              render={({ field }) => (
                                <input
                                  disabled
                                  className="theme-ip"
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  {loading ? (
                    <div className="theme-loader show">
                      <div className="loader-outer">
                        <div className="loader"></div>
                      </div>
                    </div>
                  ) : (
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3">
                          <Tab>
                            <div className="tab-link">Material</div>
                          </Tab>
                          <Tab>
                            <div className="tab-link">Solubility</div>
                          </Tab>
                          <Tab>
                            <div className="tab-link">Spectral Information</div>
                          </Tab>
                          <Tab>
                            <div className="tab-link">Environmental Data</div>
                          </Tab>
                          <Tab>
                            <div className="tab-link">Attachments</div>
                          </Tab>
                          <Tab>
                            <div className="tab-link">Audit Trail</div>
                          </Tab>

                          {/* TODO: here as per phase */}
                        </TabList>
                      </div>
                      <TabPanel>
                        <Material
                          readable={true}
                          initialData={initialMaterialData(material)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <SolubilityInformation
                          readable={true}
                          initialData={solubilityInitialData(material)}
                          attachments={filesByCategory.solubility}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                        <div className="divider"></div>
                        <UploadSolubilityData
                          readable={true}
                          loading={fileLoading}
                          attachments={
                            methods.control._formValues?.attachments?.solubility
                          }
                          handleFileChange={() => {}}
                          removeFile={() => {}}
                          downloadFile={downloadFileSolubilityAttachments}
                          hasPermission={false}
                        />
                      </TabPanel>
                      <TabPanel>
                        <SpectralInformation
                          readable={true}
                          attachments={filesByCategory?.spectralInformation}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <EnvironmentalData
                          readable={true}
                          initialData={environmentInitialData(material)}
                          attachments={filesByCategory.environmental}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Attachments
                          readable={true}
                          attachments={filesByCategory.attachments}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <div className="flex w-full items-center justify-center">
                          <button
                            className="ml-auto theme-btn sm"
                            type="button"
                            onClick={async () => {
                              let res;
                              if (material?.id) {
                                setDownloadingFile(true);
                                res = await MaterialService.downloadAuditTrail({
                                  material_id: material.id,
                                });
                                if (res && res?.data?.body)
                                  downloadFileFromURL(
                                    res.data.body,
                                    dispatch,
                                    `${material.id}.pdf`
                                  )
                                    .then(() => setDownloadingFile(false))
                                    .catch(() => setDownloadingFile(false));
                                else setDownloadingFile(false);
                              }
                            }}
                          >
                            Generate Audit Report
                          </button>
                        </div>
                        <div className="find-tab-scroll-trail">
                          <AuditTrail items={logs} readable={true} />
                        </div>
                      </TabPanel>
                    </Tabs>
                  )}

                  {/* divider */}
                  <div
                    className={material?.phase == "Solid" ? "divider" : "hide"}
                  ></div>

                  {material?.phase == "Gas" ? (
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3 flex-wrap">
                          <Tab>
                            <div className={`tab-link `}>Gas Information</div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Gas
                          initialData={gasInitialData(material)}
                          readable={true}
                        />
                      </TabPanel>
                    </Tabs>
                  ) : (
                    ""
                  )}

                  {material?.phase == "Liquid" ? (
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3 flex-wrap">
                          <Tab>
                            <div className={`tab-link `}>
                              Liquid Information
                            </div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Liquid
                          initialData={liquidInitialData(material)}
                          readable={true}
                        />
                      </TabPanel>
                    </Tabs>
                  ) : (
                    ""
                  )}
                  {material?.phase == "Solid" ? (
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3 flex-wrap">
                          <Tab>
                            <div className={`tab-link `}>#</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link `}>Impurity & residuals</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link `}>Aeration</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link `}>Compressibility</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Densities</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Drug Info</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Flow Energy</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Hygroscopicity</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Morphologi G3</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Permeability</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>PSD</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>SEM</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Shear Cell</div>
                          </Tab>
                          <Tab>
                            <div className={`tab-link`}>Thermal</div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <General
                          readable={true}
                          initialData={generalSolidInitialData(material)}
                          attachments={
                            generalSolidInitialData(material)?.attachments
                          }
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <ImpurityResiduals
                          readable={true}
                          initialData={impurityAndResidualsInitialData(
                            material
                          )}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Aeration
                          readable={true}
                          initialData={aerationInitialData(material)}
                          attachments={
                            aerationInitialData(material)?.attachments
                          }
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Compressibility
                          readable={true}
                          initialData={compressibilityInitialData(material)}
                          attachments={
                            compressibilityInitialData(material)?.attachments
                          }
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Densities
                          readable={true}
                          initialData={densityInitialData(material)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <DrugInformation
                          readable={true}
                          initialData={drugInfoInitialData(material)}
                          attachments={
                            drugInfoInitialData(material)?.attachments
                          }
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <FlowEnergy
                          readable={true}
                          initialData={flowEnergyInitialData(material)}
                          attachments={
                            flowEnergyInitialData(material)?.attachments
                          }
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Hygroscopicity
                          readable={true}
                          initialData={hygroscopicityInitialData(material)}
                          attachments={
                            hygroscopicityInitialData(material)?.attachments
                          }
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Morphologi
                          readable={true}
                          initialData={morphologiInitialData(material)}
                          attachments={
                            morphologiInitialData(material)?.attachments
                          }
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Permeability
                          readable={true}
                          initialData={permeabilityInitialData(material)}
                          attachments={
                            permeabilityInitialData(material)?.attachments
                          }
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <PSD
                          readable={true}
                          initialData={psdInitialData(material)}
                          attachments={psdInitialData(material)?.attachments}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <SEM
                          readable={true}
                          initialData={semInitialData(material)}
                          attachments={semInitialData(material)?.attachments}
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <ShearCell
                          readable={true}
                          initialData={shearCellInitialData(material)}
                          attachments={
                            shearCellInitialData(material)?.attachments
                          }
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <ThermalAnalysis
                          readable={true}
                          initialData={thermalInitialData(material)}
                          attachments={
                            thermalInitialData(material)?.attachments
                          }
                          downloadFile={(file: any) => downloadFile(file)}
                        />
                      </TabPanel>
                    </Tabs>
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default MaterialView;
