import React, { useEffect, useState } from "react";
import { Rnd } from "react-rnd";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Attachment, Material } from "../../pages/materials/visualize/utils";
import FileService from "../../services/fileService";
import CustomLoader from "../common/CustomLoader";
import { getValueFromConcatenatedKey } from "../../utils/materialKeyLabelHelper";
import { ButtonIcons, ButtonTypes } from "../common/Button";
import { Icon } from "semantic-ui-react";

interface DraggableImageModalProps {
  modalId: string;
  attachments: (Attachment & Material)[];
  onClose: (id: string) => void;
  index: number;
}

interface ImageDetails {
  url: string;
  fileName: string;
}
const DraggableImageModal: React.FC<DraggableImageModalProps> = ({
  modalId,
  attachments,
  onClose,
  index,
}) => {
  const [imageUrls, setImageUrls] = useState<ImageDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean[]>([]);
  const fetchImages = async () => {
    setLoading(true);
    try {
      const fetchedUrls: ImageDetails[] = await Promise.all(
        attachments.map(async (attachment) => {
          const response = await FileService.create("/files/download", {
            file: attachment.file_name,
          });
          return { url: response.data.body, fileName: attachment.display_name };
        })
      );
      setImageUrls(
        fetchedUrls.filter((data) => data.url !== null) as ImageDetails[]
      );
      setImageLoading(new Array(fetchedUrls.length).fill(true));
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImages();
  }, [attachments]);

  const handleImageLoad = (index: number) => {
    const newImageLoading = [...imageLoading];
    newImageLoading[index] = false;
    setImageLoading(newImageLoading);
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Rnd
          key={modalId}
          default={{
            x: 50,
            y: 50,
            width: 600,
            height: 600,
          }}
          minWidth={600}
          minHeight={600}
          enableResizing={{
            top: false,
            right: false,
            bottom: false,
            left: false,
            topRight: true,
            topLeft: true,
            bottomRight: true,
            bottomLeft: true,
          }}
          lockAspectRatio={true}
          bounds="window"
          className="shadow-lg rounded bg-white p-4 border border-gray-300 rounded-md shadow-lg z-50"
        >
          <div className="h-5/6">
            <div className="flex justify-between items-center mb-2 border-b border-gray-200">
              <h2 className="text-xl text-gray-700 text-center">
                <span>Material: {attachments[0]?.material_name} &nbsp; Vendor: {attachments[0]?.vendor_name} &nbsp; Lot: {attachments[0].lot_number}</span>
              </h2>
              <Icon
                name="times circle outline"
                onClick={() => onClose(modalId)}
                color="red"
                className="text-2xl cursor-pointer hover:text-red-500"
              />
            </div>

            <div className="space-y-4 h-5/6 bg-gray-50 rounded-lg">
              <Carousel
                showArrows={true}
                showThumbs={true}
                infiniteLoop={true}
                autoPlay={false}
                className="carousel-container h-full"
              >
                {imageUrls.map((imageUrl, idx) => (
                  <div key={idx} className="h-5/6 relative">
                    {/* Show loader while image is loading */}
                    <span><b>File Name : </b>{imageUrl.fileName}</span>
                    {imageLoading[idx] && (
                      <div className="absolute inset-0 flex items-center justify-center bg-white opacity-75">
                        <div className="loader"></div>
                      </div>
                    )}
                    <img
                      src={imageUrl.url}
                      alt={`Material ${modalId} Image ${idx + 1}`}
                      className="w-full h-5/6 object-contain transition-opacity duration-500 ease-in-out"
                      onLoad={() => handleImageLoad(idx)}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </Rnd>
      )}
    </>
  );
};

export default DraggableImageModal;
